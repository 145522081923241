import Button from '@/view/components/Button';
import Tooltip from '@/view/components/Tooltip';
import clsx from 'clsx';
import Link from 'next/link';
import {useRouter} from 'next/router';
import React from 'react';
import styles from '@/view/styles/providers/Nav/Drawer.module.scss';
import {MenuOptionType} from '@/view/types/types';
import RemixIcon from '@/view/components/RemixIcon';
import {useLoginModalActions} from '@/state/hooks/auth/loginModal';
import {
  INBOX_LOGIN_MESSAGE,
  PROFILE_LOGIN_MESSAGE,
  WALLET_LOGIN_MESSAGE,
} from '@/content';
import {
  useCreateStatus,
  useCreateStatusActions,
} from '@/state/hooks/createStatus';
import {
  BATCH_MINTER,
  NOTIFICATIONS,
  SUBSCRIPTIONS,
  THEME_TOGGLE,
} from '@/config';
import Menu from '@/view/components/Menu';
import {DarkModeActions, useDarkMode} from '@/controller/hooks/darkMode';
import {graphql, useLazyLoadQuery} from '@/kits/relay-kit/src';
import {useAccount} from '@/kits/account-kit/src';
import {Avatar} from '@/view/components/Avatar';
import {getImageUrl} from '@/utils/conversions/conversions';
import {PublicMagic} from '@/kits/magic-auth-kit/src';
import {trim} from '@/utils/trim';
import {CreateDialog} from '@/view/components/CMDk/Create/CreateDialog';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import {getBeaconWallet} from '@/kits/beacon-auth-kit/src';
import {TezBalanceActions, useTezBalance} from '@/state/hooks/useTezBalance';
import {useShoppingCart} from '@/state/hooks/shoppingCart';
import {useNotifications} from '@/state/hooks/notifications';
import Skeleton from '@/view/components/Skeleton';
import {DNSLogo} from '../../components/DNSLogo';
import type {DrawerProfileQuery as DrawerProfileQueryType} from './__generated__/DrawerProfileQuery.graphql';

const tabs: {
  label: string;
  value: string;
  icon: (active?: boolean) => React.ReactNode;
  url?: string | ((wallet?: string) => string);
}[] = [
  {
    label: 'Home',
    value: 'home',
    icon: active => (
      <RemixIcon icon={`home-7-${active ? 'fill' : 'line'}`} size={22} />
    ),
    url: '/',
  },
  {
    label: 'Profile',
    value: 'profile',
    icon: active => (
      <RemixIcon icon={`user-${active ? 'fill' : 'line'}`} size={22} />
    ),
    url: (wallet?: string) => (wallet ? `/${wallet}` : 'not-signed-in'),
  },
  {
    label: 'Wallet',
    value: 'wallet',
    icon: active => (
      <RemixIcon icon={`wallet-${active ? 'fill' : 'line'}`} size={22} />
    ),
    url: (wallet?: string) => (wallet ? `/wallet` : 'not-signed-in'),
  },
  // ...(NOTIFICATIONS
  //   ? [
  //       {
  //         label: 'Inbox',
  //         value: 'inbox',
  //         icon: (active?: boolean) => (
  //           <RemixIcon icon={`mail-${active ? 'fill' : 'line'}`} size={22} />
  //         ),
  //         url: (wallet?: string) => (wallet ? `/inbox` : 'not-signed-in'),
  //       },
  //     ]
  //   : []),
  {
    label: 'Collections',
    value: 'collections',
    icon: active => (
      <RemixIcon icon={`stack-${active ? 'fill' : 'line'}`} size={22} />
    ),
    url: (wallet?: string) => (wallet ? `/collections` : 'not-signed-in'),
  },
];

const DrawerProfileQuery = graphql`
  query DrawerProfileQuery($id: BigInt!) {
    identitiesCollection(filter: {id: {eq: $id}}) {
      edges {
        node {
          nodeId
          id
          profilesCollection {
            edges {
              node {
                nodeId
                id
                username
                avatar_uri
              }
            }
          }
        }
      }
    }
  }
`;

export default function Drawer({
  isNavHidden,
  showCreateModal,
  identityId,
  loggedIn,
}: {
  isNavHidden: boolean;
  showCreateModal: boolean;
  identityId: string;
  loggedIn: boolean;
}) {
  const router = useRouter();

  const {previousRoute} = useCreateStatus();

  const {getAccount} = useAccount();

  const account = getAccount();

  const {data} = useLazyLoadQuery<DrawerProfileQueryType>(
    DrawerProfileQuery,
    {
      id: identityId || '',
    },
    {
      skip: !identityId || identityId === '',
    }
  );
  const userAccount = data?.identitiesCollection?.edges?.[0]?.node;

  const {isMobile} = useBreakpoint();

  const {unreadMessageCount} = useNotifications();

  return (
    <div className={styles.nav} data-hidden={isNavHidden}>
      <Link href="/" className={styles.logo}>
        <DNSLogo />
      </Link>
      <ul className={styles.links}>
        {tabs.map((tab, i) => {
          const url =
            typeof tab.url === 'function'
              ? tab.url(
                  account.isLoggedIn
                    ? userAccount?.profilesCollection?.edges?.[0]?.node
                        ?.username || account.identityId
                    : undefined
                )
              : tab.url;
          const isActive = router.asPath === url;
          if (url) {
            if (url === 'not-signed-in') {
              return (
                <Tooltip
                  text={tab.value.charAt(0).toUpperCase() + tab.value.slice(1)}
                  side="right"
                  key={tab.value + '-' + i}
                >
                  <button
                    key={tab.value}
                    className={styles.link}
                    data-not-signed-in={true}
                    onClick={() => {
                      useLoginModalActions.setShowLoginModal(
                        true,
                        tab.value === 'profile'
                          ? PROFILE_LOGIN_MESSAGE
                          : tab.value === 'wallet'
                          ? WALLET_LOGIN_MESSAGE
                          : tab.value === 'inbox'
                          ? INBOX_LOGIN_MESSAGE
                          : undefined
                      );
                    }}
                  >
                    {tab.icon(isActive)}
                    <span>{tab.label}</span>
                  </button>
                </Tooltip>
              );
            }
            if (tab.value === 'inbox') {
              return (
                <Link
                  href={url}
                  key={tab.value + `-${i}`}
                  target={url.startsWith('http') ? '_blank' : undefined}
                  rel={
                    url.startsWith('http') ? 'noopener noreferrer' : undefined
                  }
                >
                  <Tooltip
                    key={'inbox'}
                    text={
                      tab.value.charAt(0).toUpperCase() + tab.value.slice(1)
                    }
                    side="right"
                  >
                    <li
                      className={styles.link}
                      key={i}
                      data-state={
                        router.asPath === '/not-signed-in'
                          ? undefined
                          : router.asPath === url
                          ? 'active'
                          : undefined
                      }
                    >
                      {tab.icon(isActive)}
                      <span className={styles.unread}>
                        Inbox{' '}
                        {unreadMessageCount > 0 ? (
                          <div>
                            {unreadMessageCount > 99
                              ? '99+'
                              : unreadMessageCount}
                          </div>
                        ) : null}
                      </span>
                    </li>
                  </Tooltip>
                </Link>
              );
            }
            return (
              <Link
                href={url}
                key={tab.value + `-${i}`}
                target={url.startsWith('http') ? '_blank' : undefined}
                rel={url.startsWith('http') ? 'noopener noreferrer' : undefined}
              >
                <Tooltip
                  text={tab.value.charAt(0).toUpperCase() + tab.value.slice(1)}
                  side="right"
                >
                  <li
                    className={styles.link}
                    data-state={
                      router.asPath === '/not-signed-in'
                        ? undefined
                        : router.asPath === url
                        ? 'active'
                        : tab.value === 'profile' &&
                          loggedIn &&
                          (router.asPath === `/${identityId}` ||
                            router.asPath ===
                              `/${userAccount?.profilesCollection?.edges?.[0]?.node?.username}`)
                        ? 'active'
                        : undefined
                    }
                    key={i}
                  >
                    {tab.value === 'profile' && loggedIn ? (
                      !!userAccount?.profilesCollection?.edges?.[0]?.node
                        ?.avatar_uri ? (
                        <Avatar
                          src={getImageUrl(
                            userAccount?.profilesCollection?.edges?.[0]?.node
                              ?.avatar_uri || undefined
                          )}
                          alt="ID"
                          className={styles.avatar}
                        >
                          <RemixIcon icon="user-line" size={22} />
                        </Avatar>
                      ) : (
                        <RemixIcon icon="user-line" size={22} />
                      )
                    ) : (
                      tab.icon(isActive)
                    )}
                    <span>{tab.label}</span>
                  </li>
                </Tooltip>
              </Link>
            );
          }
          return null;
        })}
      </ul>
      {!loggedIn ? (
        <Button
          filled
          accent
          onClick={() => {
            useLoginModalActions.setShowLoginModal(true);
          }}
          className={styles.signInButton}
          key={'sign-in'}
        >
          <span>Sign in</span>
        </Button>
      ) : (
        <CreateDialog
          trigger={
            <Button
              tooltip={'Create'}
              tooltipSide="right"
              tooltipOffset={42}
              gradient
              onClick={() => {
                if (isMobile) {
                  router.push(
                    {pathname: router.pathname, query: {...router.query}},
                    router.asPath + '#',
                    {
                      shallow: true,
                    }
                  );
                }
              }}
              className={styles.createButton}
              key={'create'}
            >
              <RemixIcon icon="add-circle-fill" size={18} />
              <span>Create</span>
            </Button>
          }
        />
      )}
      <MoreMenu
        isNavHidden={isNavHidden}
        username={
          userAccount?.profilesCollection?.edges?.[0]?.node?.username || 'User'
        }
        avatar={
          !!userAccount?.profilesCollection?.edges?.[0]?.node?.avatar_uri
            ? getImageUrl(
                userAccount?.profilesCollection?.edges?.[0]?.node?.avatar_uri
              )
            : undefined
        }
      />
      {showCreateModal && (
        <div
          className={styles.createModalBackdrop}
          onClick={
            previousRoute
              ? () => {
                  router.push(previousRoute);
                  useCreateStatusActions.setShowCreateModal(false);
                  useCreateStatusActions.setPreviousRoute(undefined);
                }
              : () => {
                  useCreateStatusActions.setShowCreateModal(false);
                  router.back();
                  useCreateStatusActions.setPreviousRoute(undefined);
                }
          }
        />
      )}
    </div>
  );
}

export const DrawerLoader = () => {
  return (
    <Skeleton
      animation="wave"
      variant="rect"
      borderRadius={0}
      className={styles.nav}
    />
  );
};

const MoreMenu = ({
  isNavHidden,
  username,
  avatar,
}: {
  isNavHidden: boolean;
  username?: string;
  avatar?: string;
}) => {
  const router = useRouter();
  const {getAccount, logoutAccount} = useAccount();
  const user = getAccount();
  const address = user?.isLoggedIn ? user.address : undefined;
  const {clear} = useShoppingCart();

  const {darkMode} = useDarkMode();
  const [addressText, setAddressText] = React.useState<string | undefined>(
    trim(address || 'Copy address')
  );

  const {balance} = useTezBalance();

  const menuOptions: MenuOptionType[] = [
    ...(address
      ? [
          {
            noHighlight: true,
            icon: null,
            value: 'balance',
            label: (
              <div className={styles.balance}>
                <div>
                  <div>Balance:</div>
                  <div>
                    {balance} {balance && 'xtz'}
                  </div>
                </div>
                <Button
                  text
                  onMouseEnter={() => {
                    setAddressText('Copy address');
                  }}
                  onMouseLeave={() => {
                    setAddressText(address ? trim(address) : undefined);
                  }}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (address) {
                      navigator.clipboard.writeText(address);
                      setAddressText('Copied!');
                      setTimeout(() => {
                        setAddressText(address ? trim(address) : undefined);
                      }, 1000);
                    }
                  }}
                  className={styles.addressCopy}
                >
                  <span>{addressText}</span>
                  <RemixIcon
                    icon={
                      addressText === 'Copied!'
                        ? 'check-line'
                        : 'file-copy-2-line'
                    }
                    size={18}
                  />
                </Button>
              </div>
            ),
          },
        ]
      : []),
    ...(THEME_TOGGLE
      ? [
          {
            icon: <RemixIcon icon="moon-line" size={24} />,
            label: 'Dark mode',
            value: 'dark-mode',
            onClick: () => {
              DarkModeActions.setDarkMode(!darkMode);
            },
          },
        ]
      : []),
    ...(user?.isLoggedIn
      ? [
          {
            icon: <RemixIcon icon="settings-4-line" size={24} />,
            label: 'Settings',
            value: 'settings',
            onClick: () => {
              router.push('/settings');
            },
          },
        ]
      : []),
    ...(SUBSCRIPTIONS
      ? [
          {
            icon: <RemixIcon icon="money-dollar-circle-line" size={24} />,
            label: 'Subscriptions',
            value: 'subscriptions',
            onClick: () => {
              router.push('/subscriptions');
            },
          },
        ]
      : []),
    ...(BATCH_MINTER
      ? [
          {
            icon: <RemixIcon icon="add-circle-line" size={24} />,
            label: 'Batch minter',
            value: 'batch-minter',
            onClick: () => {},
          },
        ]
      : []),
    {
      icon: <RemixIcon icon="play-line" size={24} />,
      label: 'DNS Studio',
      value: 'dns-studio',
      onClick: () => {
        window.open('https://studio.dns.xyz', '_blank', 'noopener noreferrer');
      },
    },
    ...(user.isLoggedIn
      ? [
          {
            icon: <RemixIcon icon="logout-circle-line" size={24} />,
            label: 'Logout',
            value: 'logout',
            onClick: () => {
              const account = getAccount();
              const {wallet: beaconWallet} = getBeaconWallet();

              if (account.isLoggedIn && account.walletType === 'beacon') {
                console.log('Disconnecting beacon wallet.');
                beaconWallet.client.clearActiveAccount();
              } else if (account.isLoggedIn && account.walletType === 'magic') {
                if (PublicMagic) {
                  console.log('Disconnecting magic wallet.');
                  PublicMagic.user.logout();
                }
              }

              console.log('Logging out of DNS.');
              logoutAccount();
              clear();
              router.reload();
            },
          },
        ]
      : [
          {
            icon: <RemixIcon icon="login-circle-line" size={24} />,
            label: 'Login',
            value: 'login',
            onClick: () => {
              useLoginModalActions.setShowLoginModal(true);
            },
          },
        ]),
  ];

  return (
    <>
      <ul
        className={clsx(styles.links, styles.floatBottom)}
        data-hidden={isNavHidden}
      >
        <Menu
          options={menuOptions}
          asModal
          arrow={false}
          onOpen={() => {
            address && TezBalanceActions.updateBalance(address);
          }}
        >
          <div>
            <Tooltip text="More" side="right">
              <li className={styles.link}>
                <div>
                  {user.isLoggedIn ? (
                    avatar ? (
                      <Avatar
                        src={avatar}
                        alt="ID"
                        className={clsx(styles.avatar, styles.avatarLarge)}
                      >
                        <RemixIcon icon="user-line" size={22} />
                      </Avatar>
                    ) : (
                      <RemixIcon icon="user-line" size={22} />
                    )
                  ) : (
                    <RemixIcon icon="menu-line" size={22} />
                  )}
                  <span>{user.isLoggedIn ? username : 'More'}</span>
                </div>
                {user.isLoggedIn && <RemixIcon icon="more-fill" size={22} />}
              </li>
            </Tooltip>
          </div>
        </Menu>
      </ul>
    </>
  );
};
