import clsx from 'clsx';
import {MenuOptionType} from '@/view/types/types';
import UserProfileItem from './UserProfileItem';
import styles from '@/view/styles/components/Comment.module.scss';
import React from 'react';
import TextClamp from './TextClamp';
import Tooltip from './Tooltip';
import Menu from './Menu';
import RemixIcon from './RemixIcon';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import ReactionChips from './ReactionChips';
import {ReactionTo} from '@/controller/hooks/eventRollup';
import {CommentContentOwnerFragment$key} from './__generated__/CommentContentOwnerFragment.graphql';
import {CommentContentIsReplyToFragment$key} from './__generated__/CommentContentIsReplyToFragment.graphql';
import {CommentContentFragment$key} from './__generated__/CommentContentFragment.graphql';
import TimestampTooltip from './TimestampTooltip';

const CommentContentFragment = graphql`
  fragment CommentContentFragment on comments {
    id
    nodeId
    content
    created_at
    accounts {
      id
      nodeId
      ...CommentContentOwnerFragment
    }
    comments_reactionsCollection {
      edges {
        node {
          id
          reaction
          accounts {
            id
            identities {
              profilesCollection {
                edges {
                  node {
                    username
                  }
                }
              }
            }
            ...CommentContentIsReplyToFragment
          }
        }
      }
    }
  }
`;

const CommentContentOwnerFragment = graphql`
  fragment CommentContentOwnerFragment on accounts {
    id
    nodeId
    identities {
      profilesCollection {
        edges {
          node {
            username
            avatar_uri
          }
        }
      }
    }
    ...UserProfileItemFragment
  }
`;

const CommentContentIsReplyToFragment = graphql`
  fragment CommentContentIsReplyToFragment on accounts {
    id
    nodeId
    identities {
      profilesCollection {
        edges {
          node {
            username
          }
        }
      }
    }
    ...UserProfileItemFragment
  }
`;

export const CommentContent = ({
  hideAvatar,
  commentOwner: commentOwnerKey,
  isAReplyTo: isAReplyToKey,
  setReplyToCommentId,
  commentContent,
  setReplyToUsername,
  commentContentKey,
  justAdded,
  age,
  menuOptions,
}: {
  hideAvatar?: boolean;
  commentOwner: CommentContentOwnerFragment$key;
  isAReplyTo: CommentContentIsReplyToFragment$key | null | undefined;
  setReplyToCommentId?: (id: string | null) => void;
  commentContent: string;
  setReplyToUsername?: (username: string | null) => void;
  commentContentKey: CommentContentFragment$key;
  justAdded: boolean;
  age: string;
  menuOptions: MenuOptionType[];
}) => {
  const comment = useFragment(CommentContentFragment, commentContentKey);
  const op = useFragment(CommentContentOwnerFragment, commentOwnerKey);
  const isAReplyTo = useFragment(
    CommentContentIsReplyToFragment,
    isAReplyToKey
  );
  return (
    <>
      <UserProfileItem user={op} hideIcon={hideAvatar} />
      <div className={styles.comment}>
        <TextClamp
          prefix={
            <>
              {isAReplyTo && (
                <span className={styles.replyTo}>
                  <UserProfileItem user={isAReplyTo} hideIcon prefix="@" />
                </span>
              )}{' '}
            </>
          }
          text={commentContent}
          maxChars={200}
          prefixClassName={styles.prefix}
          className={clsx(isAReplyToKey && styles.replyToPrefix)}
          enableUrls
          enableMentions
          enableHashtags
        />
        <TimestampTooltip date={comment?.created_at} />
      </div>
      <div className={styles.actions}>
        <span
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            if (justAdded) {
              return;
            }
            setReplyToCommentId && setReplyToCommentId(comment?.id);
            setReplyToUsername &&
              setReplyToUsername(
                op?.identities.profilesCollection?.edges?.[0].node.username &&
                  op?.identities.profilesCollection?.edges?.[0].node
                    .username !== ''
                  ? op?.identities.profilesCollection?.edges?.[0].node.username
                  : op?.id || ''
              );
          }}
        >
          {justAdded ? `Posting...` : `Reply`}
        </span>
        <div>
          <ReactionChips
            showPicker
            id={comment?.id}
            nodeId={comment?.nodeId}
            reactions={comment?.comments_reactionsCollection?.edges?.map(
              edge =>
                ({
                  by: edge.node.accounts.id || '',
                  byUsername:
                    edge.node.accounts.identities.profilesCollection?.edges?.[0]
                      .node?.username || '',
                  id: edge.node.id,
                  reaction: edge.node.reaction,
                } || [])
            )}
            type={ReactionTo.Comment}
            max={5}
          />
        </div>
        {menuOptions.length > 0 && (
          <Menu options={menuOptions} arrow={false} asModal>
            <div>
              <Tooltip text="Action" side="top">
                <RemixIcon icon="more-line" size={16} />
              </Tooltip>
            </div>
          </Menu>
        )}
      </div>
    </>
  );
};
