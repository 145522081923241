import React, {Suspense} from 'react';
import styles from '@/view/styles/components/HoverCard/UserHoverCard.module.scss';
import Image from '../Image';
import {Avatar} from '../Avatar';
import clsx from 'clsx';
import {trim} from '@/utils/trim';
import Skeleton from '../Skeleton';
import {usePlaceholderIds} from '@/view/placeholderIds';
import Link from 'next/link';
import {MemoIcon} from '../utils/MemoChildren';
import FollowButton from '../FollowButton';
import RemixIcon from '../RemixIcon';
import {randomBanner} from '@/utils/images';
import {getImageUrl} from '@/utils/conversions/conversions';
import {graphql, useFragment, useLazyLoadQuery} from '@/kits/relay-kit/src';
import {useAccount} from '@/kits/account-kit/src';
import Tooltip from '../Tooltip';
import {ItemModalActions} from '@/state/hooks/itemModal';
import {
  UserHoverCardFragment$data,
  UserHoverCardFragment$key,
} from './__generated__/UserHoverCardFragment.graphql';
import type {UserHoverCardFollowersAndFollowingCountQuery as UserHoverCardFollowersAndFollowingCountQueryType} from './__generated__/UserHoverCardFollowersAndFollowingCountQuery.graphql';

const UserHoverCardFragment = graphql`
  fragment UserHoverCardFragment on accounts {
    id
    nodeId
    type
    identities {
      id
      nodeId
      profilesCollection {
        edges {
          node {
            id
            nodeId
            username
            name
            bio
            avatar_uri
            banner_uri
            website
            tezos_profilesCollection {
              edges {
                node {
                  id
                  nodeId
                  description
                  logo
                }
              }
            }
          }
        }
      }
    }
    magic_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
        }
      }
    }
    beacon_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
        }
      }
    }
    teztok_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
        }
      }
    }
  }
`;

export default function UserHoverCard({
  loading = false,
  user: userKey,
}: {
  loading?: boolean;
  user: UserHoverCardFragment$key | null | undefined;
}) {
  const user = useFragment(UserHoverCardFragment, userKey);

  const [bannerPlaceholder, setBannerPlaceholder] = React.useState<string>(() =>
    randomBanner(user?.identities.id || '')
  );

  const [bannerLoading, setBannerLoading] = React.useState(true);

  const [avatarLoading, setAvatarLoading] = React.useState(true);
  const [avatarErrored, setAvatarErrored] = React.useState(false);

  const {getAccount} = useAccount();

  const account = getAccount();
  const identityId = account?.isLoggedIn ? account.identityId : undefined;

  const placeholders = usePlaceholderIds(3);

  const profileLink = !!user?.identities?.profilesCollection?.edges?.[0]?.node
    ?.username
    ? user?.identities?.profilesCollection?.edges?.[0]?.node?.username
    : user?.type === 'magic'
    ? user?.magic_accountsCollection?.edges?.[0]?.node?.public_key_hash || ''
    : user?.type === 'beacon'
    ? user?.beacon_accountsCollection?.edges?.[0]?.node?.public_key_hash || ''
    : user?.teztok_accountsCollection?.edges?.[0]?.node?.public_key_hash ||
      '' ||
      user?.identities.id;

  const isNonDNSUser = user?.type === 'teztok';

  return (
    <div className={styles.root}>
      <Link
        href={`/${profileLink}`}
        className={styles.banner}
        onClick={() => {
          ItemModalActions.setItem(false, null, null);
        }}
      >
        <div>
          {bannerLoading && (
            <Skeleton variant="rect" animation="wave" borderRadius={0} />
          )}
          <Image
            dynamic
            src={
              !!user?.identities.profilesCollection?.edges?.[0].node.banner_uri
                ? getImageUrl(
                    user?.identities.profilesCollection?.edges?.[0].node
                      .banner_uri || ''
                  )
                : bannerPlaceholder || ''
            }
            alt=""
            onError={e => {
              setBannerLoading(false);
              e.currentTarget.src = randomBanner(user?.identities.id || '');
              setBannerPlaceholder(randomBanner(user?.identities.id || ''));
            }}
            onLoad={() => {
              setBannerLoading(false);
            }}
            className={clsx(
              styles.bannerImage,
              bannerLoading && styles.bannerLoading
            )}
          />
        </div>
      </Link>
      <Link
        href={`/${profileLink}`}
        className={styles.avatar}
        onClick={() => {
          ItemModalActions.setItem(false, null, null);
        }}
      >
        <div>
          {!!(
            user?.identities.profilesCollection?.edges?.[0].node.avatar_uri ||
            user?.identities.profilesCollection?.edges?.[0].node
              .tezos_profilesCollection?.edges?.[0]?.node?.logo
          ) ? (
            <Avatar
              src={getImageUrl(
                user?.identities.profilesCollection?.edges?.[0].node
                  .avatar_uri ||
                  user?.identities.profilesCollection?.edges?.[0].node
                    .tezos_profilesCollection?.edges?.[0]?.node?.logo ||
                  ''
              )}
              alt=""
              onLoad={() => {
                setAvatarLoading(false);
              }}
              onError={() => {
                setAvatarLoading(false);
                setAvatarErrored(true);
              }}
              className={clsx(
                styles.avatarImage,
                avatarLoading && styles.loading
              )}
            >
              <MemoIcon Component={'user-line'} size={54} opacity={0.2} />
            </Avatar>
          ) : (
            <MemoIcon Component={'user-line'} size={54} opacity={0.2} />
          )}
        </div>
      </Link>
      <div className={styles.body}>
        <div className={styles.info}>
          {loading ? (
            <Skeleton variant="text" animation="wave" height={20} width={120} />
          ) : (
            <Link
              href={`/${profileLink}`}
              onClick={() => {
                ItemModalActions.setItem(false, null, null);
              }}
            >
              <div className={styles.username}>
                {user?.identities.profilesCollection?.edges?.[0].node
                  .username ||
                  user?.identities.profilesCollection?.edges?.[0].node.name ||
                  trim(
                    user?.type === 'magic'
                      ? user?.magic_accountsCollection?.edges?.[0]?.node
                          ?.public_key_hash || ''
                      : user?.type === 'beacon'
                      ? user?.beacon_accountsCollection?.edges?.[0]?.node
                          ?.public_key_hash || ''
                      : user?.teztok_accountsCollection?.edges?.[0]?.node
                          ?.public_key_hash || ''
                  )}
                {isNonDNSUser && (
                  <Tooltip
                    text="This user is not on DNS"
                    className="non-dns-user-tag"
                  >
                    <span />
                  </Tooltip>
                )}
              </div>
            </Link>
          )}
          {loading ? (
            <>
              <Skeleton
                variant="text"
                animation="wave"
                height={17}
                width={200}
                styles={{
                  marginTop: 1,
                }}
              />
              <Skeleton
                variant="text"
                animation="wave"
                height={17}
                width={160}
              />
            </>
          ) : (
            <div className={styles.description}>
              {user?.identities.profilesCollection?.edges?.[0].node.bio ||
                user?.identities.profilesCollection?.edges?.[0].node
                  .tezos_profilesCollection?.edges?.[0]?.node?.description ||
                ''}
            </div>
          )}
          <Suspense
            fallback={
              <div className={styles.stats}>
                <Skeleton
                  variant="text"
                  animation="wave"
                  height={12}
                  width={80}
                  styles={{
                    marginTop: 4,
                  }}
                />
                <Skeleton
                  variant="text"
                  animation="wave"
                  height={12}
                  width={80}
                  styles={{
                    marginTop: 4,
                  }}
                />
              </div>
            }
          >
            <FollowersAndFollowingCount identityId={user?.identities.id} />
          </Suspense>
        </div>
        <Suspense
          fallback={
            <div
              className={styles.more}
              data-is-my-profile={identityId === (user?.identities?.id || '')}
            >
              <Skeleton
                variant="rect"
                animation="wave"
                width={64}
                height={25}
                borderRadius={99}
                styles={{marginBottom: 'auto'}}
              />
              <div className={styles.links}>
                {loading
                  ? placeholders.map(id => (
                      <Skeleton
                        variant="circle"
                        animation="wave"
                        width={20}
                        height={20}
                        key={id}
                      />
                    ))
                  : userSocials(user)}
              </div>
            </div>
          }
        >
          <StatsAndSocials user={user} identityId={identityId || ''} />
        </Suspense>
      </div>
    </div>
  );
}

const UserHoverCardFollowersAndFollowingCountQuery = graphql`
  query UserHoverCardFollowersAndFollowingCountQuery($id: BigInt!) {
    followers: followsCollection(filter: {followee_id: {eq: $id}}) {
      totalCount
    }
    following: followsCollection(filter: {follower_id: {eq: $id}}) {
      totalCount
    }
  }
`;

const FollowersAndFollowingCount = ({identityId}: {identityId?: string}) => {
  const {data} =
    useLazyLoadQuery<UserHoverCardFollowersAndFollowingCountQueryType>(
      UserHoverCardFollowersAndFollowingCountQuery,
      {
        id: identityId || '',
      },
      {
        skip: !identityId || identityId === '',
      }
    );

  const followersCount = data?.followers?.totalCount || 0;
  const followingCount = data?.following?.totalCount || 0;

  return (
    <div className={styles.stats}>
      <span>{followersCount} followers</span>
      <span>{followingCount} following</span>
    </div>
  );
};

const StatsAndSocials = ({
  user,
  identityId,
}: {
  user: UserHoverCardFragment$data | null | undefined;
  identityId: string;
}) => {
  return (
    <div
      className={styles.more}
      data-is-my-profile={identityId === (user?.identities?.id || '')}
    >
      {identityId !== (user?.identities?.id || '') && (
        <FollowButton
          id={user?.identities?.id || ''}
          username={
            user?.identities.profilesCollection?.edges?.[0].node.username || ''
          }
        />
      )}
      <div className={styles.links}>{userSocials(user)}</div>
    </div>
  );
};

const userSocials = (user: UserHoverCardFragment$data | null | undefined) => {
  return [
    ...(user?.identities.profilesCollection?.edges?.[0]?.node?.website
      ? [
          {
            name: 'Website',
            icon: <RemixIcon icon="earth-fill" size={18} />,
            url: user?.identities.profilesCollection?.edges?.[0].node.website,
          },
        ]
      : []),
  ].map(link => (
    <a
      onClick={e => e.stopPropagation()}
      key={link.name}
      href={link.url?.startsWith('http') ? link.url : `https://${link.url}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      {link.icon}
    </a>
  ));
};
