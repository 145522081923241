import React from 'react';
import styles from '@/view/styles/components/InfiniteLoader.module.scss';
import RemixIcon from './RemixIcon';

export default function InfiniteLoader(props: {
  height?: number | string;
  width?: number | string;
  marginTop?: number | string;
  errorComponent?: React.ReactNode;
  loaderSize?: number;
  styles?: React.CSSProperties;
}) {
  const Error = props.errorComponent;
  return (
    <div
      style={{
        height: props.height || '100%',
        width: props.width || '100%',
        margin: 'auto',
        overflow: 'hidden',
        display: 'grid',
        placeItems: 'center',
        marginTop: props.marginTop || 0,
        ...props.styles,
      }}
    >
      {Error ? (
        Error
      ) : (
        <RemixIcon
          icon="loader-4-line"
          size={props.loaderSize || 32}
          className={styles.loader}
        />
      )}
    </div>
  );
}
