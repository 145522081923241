import React, {Suspense} from 'react';
import styles from '@/view/styles/components/ManageItem/DeleteToken/DeleteToken.module.scss';
import clsx from 'clsx';
import Button from '../../Button';
import Input from '../../Input/Input';
import Image from '../../Image';
import {tezosTokensFragment$key} from '@/graphql/__generated__/tezosTokensFragment.graphql';
import {trim} from '@/utils/trim';
import {getImageUrl} from '@/utils/conversions/conversions';
import {graphql, useFragment, useMutation} from '@/kits/relay-kit/src';
import updateTezosTokenMutation from '@/graphql/update-tezos-token';
import type {updateTezosTokenMutation as UpdateTezosTokenMutationType} from '@/graphql/__generated__/updateTezosTokenMutation.graphql';
import removeTezosTokenMutation from '@/graphql/remove-tezos-token';
import type {removeTezosTokenMutation as RemoveTezosTokenMutationType} from '@/graphql/__generated__/removeTezosTokenMutation.graphql';
import tezosTokensFragment from '@/graphql/tezos-tokens-fragment';
import {useAccount} from '@/kits/account-kit/src';
import RemixIcon from '../../RemixIcon';
import {ToastActions} from '@/state/hooks/toasts';
import {v4 as uuid} from 'uuid';
import Router from 'next/router';
import {DeleteTokenTokenFragment$key} from './__generated__/DeleteTokenTokenFragment.graphql';

const DeleteTokenTokenFragment = graphql`
  fragment DeleteTokenTokenFragment on tezos_tokens {
    id
    nodeId
    title
    editions
    editions_minted
    thumbnail_uri
    accounts {
      id
      nodeId
      type
      identities {
        id
        nodeId
        profilesCollection {
          edges {
            node {
              id
              nodeId
              username
            }
          }
        }
      }
      magic_accountsCollection {
        edges {
          node {
            id
            nodeId
            public_key_hash
          }
        }
      }
      beacon_accountsCollection {
        edges {
          node {
            id
            nodeId
            public_key_hash
          }
        }
      }
      teztok_accountsCollection {
        edges {
          node {
            id
            nodeId
            public_key_hash
          }
        }
      }
    }
  }
`;

export default function DeleteToken({
  tokenKey,
  className,
  closeDialog,
}: {
  tokenKey: DeleteTokenTokenFragment$key;
  className?: string;
  closeDialog: () => void;
}) {
  const {getAccount} = useAccount();
  const user = getAccount();
  const token = useFragment(DeleteTokenTokenFragment, tokenKey);

  const username = !!token.accounts?.identities?.profilesCollection?.edges?.[0]
    ?.node?.username
    ? token.accounts?.identities?.profilesCollection?.edges?.[0]?.node?.username
    : token.accounts?.type === 'magic'
    ? trim(
        token.accounts?.magic_accountsCollection?.edges?.[0]?.node
          ?.public_key_hash || ''
      )
    : token.accounts?.type === 'beacon'
    ? trim(
        token.accounts?.beacon_accountsCollection?.edges?.[0]?.node
          ?.public_key_hash || ''
      )
    : trim(
        token.accounts?.teztok_accountsCollection?.edges?.[0]?.node
          ?.public_key_hash || ''
      ) || token.accounts?.identities.id;

  const [deleteTezosToken] = useMutation<RemoveTezosTokenMutationType>(
    removeTezosTokenMutation
  );

  const [updateTezosToken] = useMutation<UpdateTezosTokenMutationType>(
    updateTezosTokenMutation
  );

  const isDeletable =
    Number(token?.editions_minted) === 0 &&
    user.isLoggedIn &&
    user.accountId === token?.accounts?.id;

  const remainingEditions =
    Number(token.editions) - Number(token.editions_minted);

  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);

  const [deleting, setDeleting] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  return (
    <div className={clsx(styles.root, className)}>
      <div data-center={confirmDelete}>
        <span>{confirmDelete ? 'Are you sure?' : 'Delete this item'}</span>
        {!isDeletable && (
          <span>
            This will delete the remaining ({remainingEditions}) editions.
          </span>
        )}
      </div>
      {!confirmDelete && (
        <div className={styles.content}>
          <div>
            <div>ITEM</div>
            <div>
              <span>EDITIONS</span>
            </div>
          </div>
          <div>
            <hr />
            <div>
              <div>
                <Image
                  dynamic
                  src={getImageUrl(token.thumbnail_uri)}
                  alt=""
                  onError={e => {
                    e.currentTarget.src = '/poster.svg';
                  }}
                />
                <div>
                  <div>{token.title}</div>
                  <span>by {username}</span>
                </div>
              </div>
              <div>
                <Input
                  type="number"
                  integer
                  simple
                  defaultValue={token.editions}
                  onChange={() => {}}
                  placeholder="0"
                  min={Number(token.editions) || 0}
                  max={Number(token.editions) || 0}
                  disabled
                />
              </div>
            </div>
            <hr />
          </div>
        </div>
      )}
      <div className={styles.actions}>
        <Button
          text={!confirmDelete}
          filled={confirmDelete}
          onClick={() => {
            closeDialog();
          }}
        >
          <span>{confirmDelete ? 'No' : 'Cancel'}</span>
        </Button>
        <Button
          accent
          onClick={async e => {
            e.preventDefault();
            if (!user.isLoggedIn) return;
            setError(null);
            if (!confirmDelete) {
              setConfirmDelete(true);
            } else {
              setDeleting(true);
              if (isDeletable) {
                deleteTezosToken({
                  variables: {
                    filter: {
                      id: {
                        eq: token.id,
                      },
                      editions_minted: {
                        eq: '0',
                      },
                      account_id: {
                        eq: user.accountId,
                      },
                    },
                  },
                  onCompleted(response) {
                    if (
                      response.deleteFromtezos_tokensCollection?.records?.length
                    ) {
                      setDeleting(false);
                      closeDialog();
                      ToastActions.addToast(
                        uuid(),
                        'Deleted!',
                        'You have successfully deleted the item.',
                        'success'
                      );
                    }
                  },
                  onError(error) {
                    setError(error.message);
                  },
                  updater(store) {
                    const deleted = store
                      .getRootField('deleteFromtezos_tokensCollection')
                      ?.getLinkedRecords('records')?.[0];
                    if (deleted) {
                      setTimeout(() => {
                        store.delete(deleted.getDataID());
                        Router.reload();
                      }, 1000);
                    }
                  },
                });
              } else {
                updateTezosToken({
                  // TODO: do we need a contract call here to stop people from minting the item elsewhere (externally)?
                  variables: {
                    filter: {
                      id: {
                        eq: token.id,
                      },
                      editions_minted: {
                        eq: token.editions_minted,
                      },
                      account_id: {
                        eq: user.accountId,
                      },
                    },
                    input: {
                      editions: token.editions_minted,
                    },
                  },
                  onCompleted(response) {
                    if (
                      response.updatetezos_tokensCollection?.records?.length
                    ) {
                      setDeleting(false);
                      closeDialog();
                      ToastActions.addToast(
                        uuid(),
                        'Deleted!',
                        'Remaining editions have been deleted.',
                        'success'
                      );
                    }
                  },
                  onError(error) {
                    setError(error.message);
                  },
                });
              }
            }
          }}
          disabled={deleting || !user.isLoggedIn}
          data-delete="true"
        >
          <span>{confirmDelete ? 'Yes, delete' : 'Delete'}</span>
        </Button>
      </div>
      {error && (
        <div className={styles.error}>
          <RemixIcon icon="error-warning-line" size={16} />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
}
