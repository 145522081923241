import {graphql, useMutation, useQuery} from '@/kits/relay-kit/src';
import {useEffect, useState} from 'react';
import insertNotificationMutation from '@/graphql/insert-notification';
import type {insertNotificationMutation as InsertNotificationMutationType} from '@/graphql/__generated__/insertNotificationMutation.graphql';
import type {useCreateNotificationsForMentionsGetAccountsFromUsernamesQuery as useCreateNotificationsForMentionsGetAccountsFromUsernamesQueryType} from './__generated__/useCreateNotificationsForMentionsGetAccountsFromUsernamesQuery.graphql';

const useCreateNotificationsForMentionsGetAccountsFromUsernamesQuery = graphql`
  query useCreateNotificationsForMentionsGetAccountsFromUsernamesQuery(
    $usernames: [String!]!
  ) {
    profilesCollection(filter: {username: {in: $usernames}}) {
      edges {
        node {
          id
          nodeId
          identities {
            accountsCollection {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export function useCreateNotificationsForMentions() {
  const [usernames, setUsernames] = useState<string[]>([]);
  const [otherData, setOtherData] = useState<{
    creator: string;
    token_id: string;
    comment_id?: string;
  } | null>(null);

  const [insertNotification] = useMutation<InsertNotificationMutationType>(
    insertNotificationMutation
  );

  const {data: profilesData} =
    useQuery<useCreateNotificationsForMentionsGetAccountsFromUsernamesQueryType>(
      useCreateNotificationsForMentionsGetAccountsFromUsernamesQuery,
      {
        usernames,
      },
      {
        skip: !usernames.length || usernames.length === 0,
        onComplete(err) {
          if (err) {
            console.error(err);
          }
        },
      }
    );

  useEffect(() => {
    if (!otherData || !profilesData) {
      return;
    }
    if (
      usernames.length === 0 ||
      !profilesData?.profilesCollection?.edges?.length
    ) {
      return;
    }
    const accounts = profilesData?.profilesCollection?.edges?.map(
      edge => edge.node.identities.accountsCollection?.edges?.[0]?.node.id
    );
    if (!accounts) {
      return;
    }
    insertNotification({
      variables: {
        input: accounts?.map(account_id => ({
          type: otherData?.comment_id ? 'comment_mention' : 'mention',
          receiver_id: account_id,
          sender_id: otherData?.creator,
          tezos_token_id: otherData?.token_id,
          comment_id: otherData?.comment_id ? otherData.comment_id : null,
        })),
      },
      onCompleted() {
        setUsernames([]);
        setOtherData(null);
      },
    });

    return () => {
      setUsernames([]);
      setOtherData(null);
    };
  }, [otherData, profilesData, insertNotification, usernames]);

  function createNotifications({
    creator,
    token_id,
    comment_id,
  }: {
    creator: string;
    token_id: string;
    comment_id?: string;
  }) {
    setOtherData({creator, token_id, comment_id});
  }

  return {
    setMentions: setUsernames,
    createNotifications,
  };
}
