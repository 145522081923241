export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export async function retryPromise<T>(
  retries: number,
  promiseMaker: () => Promise<T>
): Promise<T> {
  let err: any;
  while (retries-- > 0) {
    try {
      return await promiseMaker();
    } catch (e) {
      err = e;
    }
  }
  throw err;
}

/**
 * a functional equivalent of the for...await...of loop with more features
 *
 * @param of the async iterator to loop over
 * @param body the consumer of the value yielded by the iterator
 * @param synchronousBodyResults if the `body` results in a promise,
 *   this signals to wait for that promise to resolve before running
 *   the `body` again on the next value. regardless of this value all
 *   the promises returned by the `body` are awaited once the iterator
 *   is finished
 * @returns the result of the iterator
 */
export async function forAwait<T, TReturn = any>(
  of: AsyncIterator<T, TReturn>,
  body: (value: T) => void | Promise<void>,
  synchronousBodyResults: boolean = false
) {
  const bodyPromises: Promise<void>[] = [];
  while (true) {
    const {done, value} = await of.next();
    if (done) {
      await Promise.all(bodyPromises);
      return value;
    } else {
      const bodyResult = body(value);
      if (!bodyResult) {
        bodyPromises.push(Promise.resolve());
      } else {
        if (synchronousBodyResults) {
          await bodyResult;
        }
        bodyPromises.push(bodyResult);
      }
    }
  }
}

export const neverResolvingPromise = <T>() => new Promise<T>(() => {});

export async function waitForDefined<
  T,
  Num extends number | undefined = undefined
>(
  getValue: () => T | undefined,
  ms?: Num
): Promise<Num extends undefined ? T : T | undefined> {
  if (ms === undefined) {
    return waitForChange<ReturnType<typeof getValue>, undefined>(
      undefined,
      getValue,
      ms
    ) as unknown as T;
  } else {
    // @ts-ignore type is correct outside of function
    return waitForChange<ReturnType<typeof getValue>, undefined>(
      undefined,
      getValue,
      ms
    );
  }
}

export async function waitForChange<R, T extends R>(
  initialValue: T,
  getValue: () => R,
  ms?: number
) {
  const DELAY = 50;
  let result = getValue();
  let waited = 0;
  while (result === initialValue && (ms ? ms > waited : true)) {
    await sleep(DELAY);
    waited += DELAY;
    result = getValue();
  }
  return result;
}

export type ImperativePromiseHandle<T> = {
  get promise(): Promise<T>;
  get resolve(): (result: T) => void;
  get reject(): (reason?: any) => void;
  get resolved(): boolean;
  get rejected(): boolean;
  get settled(): boolean;
};

export async function imperativePromise<T>(): Promise<
  ImperativePromiseHandle<T>
> {
  let resolveTemp: (value: T | PromiseLike<T>) => void,
    rejectTemp: (reason?: any) => void;
  const promise = new Promise<T>((s, j) => {
    resolveTemp = s;
    rejectTemp = j;
  });
  const [resolve, reject] = await Promise.all([
    waitForDefined(() => resolveTemp),
    waitForDefined(() => rejectTemp),
  ]);

  let resolved = false;
  let rejected = false;
  promise
    .then(() => {
      resolved = true;
    })
    .catch(() => {
      rejected = true;
    });

  return {
    get promise() {
      return promise;
    },
    get resolve() {
      return resolve;
    },
    get reject() {
      return reject;
    },
    get resolved() {
      return resolved;
    },
    get rejected() {
      return rejected;
    },
    get settled() {
      return resolved || rejected;
    },
  };
}

export async function functionalError<T>(
  promise: Promise<T>
): Promise<[T, null] | [null, any]> {
  try {
    return [await promise, null];
  } catch (e) {
    return [null, e];
  }
}
