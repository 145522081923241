import React from 'react';
import Button from './Button';
import {useRouter} from 'next/router';
import styles from '@/view/styles/components/AppWideErrorBoundary.module.scss';
import {useAccount} from '@/kits/account-kit/src';

export default function AppWideErrorBoundary() {
  const router = useRouter();
  const account = useAccount();
  return (
    <div className={styles.error}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src="/pulp-fiction-john-travolta-x.gif"
        alt="Something went wrong"
        loading="eager"
      />
      <span>Something went wrong. Please refresh the page.</span>
      <Button
        outlined
        onClick={async () => {
          await fetch('/api/empty-cookie-jar', {
            method: 'POST',
          }).then(() => {
            account.logoutAccount();
            router.reload();
          });
        }}
      >
        Refresh
      </Button>
    </div>
  );
}
