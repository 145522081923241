import styles from '@/view/styles/components/NftAsset.module.scss';
import {MimeType} from '@/view/types/types';
import Image from '@/view/components/Image';
import {useMediaLoaderStatus} from '@/controller/hooks/MediaLoaderStatus';
import clsx from 'clsx';
import PostView from '@/view/components/NFTPageItem/NFTPageItem';
import React, {Suspense, useRef} from 'react';
import {trim} from '@/utils/trim';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import {convertGqlMimeTypetoMimeType} from '@/utils/conversions/mimeTypeConversions';
import {getImageUrl} from '@/utils/conversions/conversions';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import ManageItem from './ManageItem/ManageItem';
import {useItemModal} from '@/state/hooks/itemModal';
import {NftAssetTokenFragment$key} from './__generated__/NftAssetTokenFragment.graphql';
import {NftAssetByCreatorFragment$key} from './__generated__/NftAssetByCreatorFragment.graphql';

const NftAssetTokenFragment = graphql`
  fragment NftAssetTokenFragment on tezos_tokens {
    id
    nodeId
    title
    description
    mime_type
    display_uri
    thumbnail_uri
    artifact_uri
    type
    accounts {
      id
      nodeId
      ...NftAssetByCreatorFragment
    }
    ...NFTPageItemPostViewTokenFragment
    ...ManageItemTokenFragment
  }
`;

const TokenAsset = ({
  tokenKey,
  modalId,
}: {
  tokenKey: NftAssetTokenFragment$key;
  modalId?: string;
}) => {
  const token = useFragment(NftAssetTokenFragment, tokenKey);
  const {
    props: thumbnailProps,
    loaded: thumbnailLoaded,
    errored: thumbnailErrored,
  } = useMediaLoaderStatus({resetDependencies: [token?.thumbnail_uri]});
  const {
    props: assetProps,
    loaded: assetLoaded,
    errored: assetErrored,
  } = useMediaLoaderStatus({resetDependencies: [token?.artifact_uri]});

  const postMimeType = convertGqlMimeTypetoMimeType(
    token?.mime_type || undefined
  );
  const isPostType = token.type === 'post';

  const {breakpoint} = useBreakpoint();

  const audioRef = useRef<HTMLAudioElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const {isOpen, id} = useItemModal();

  React.useEffect(() => {
    const video = videoRef.current;
    if (!video) return;
    if (modalId === id && isOpen) {
      if (video && video.paused) {
        video.play();
      }

      return () => {
        if (video && !video.paused) {
          video.pause();
        }
      };
    }
  }, [id, isOpen, modalId]);

  React.useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;
    if (modalId === id && isOpen) {
      if (audio && audio.paused) {
        audio.play();
      }

      return () => {
        if (audio && !audio.paused) {
          audio.pause();
        }
      };
    }
  }, [id, isOpen, modalId]);

  function RenderAsset() {
    return (
      <>
        <div
          className={clsx(
            styles.root,
            postMimeType === MimeType.VIDEO &&
              breakpoint === 'mobile' &&
              styles.videoRoot,
            postMimeType === MimeType.IMAGE && styles.imageRoot
          )}
          data-playable={
            postMimeType === MimeType.AUDIO || postMimeType === MimeType.VIDEO
          }
          data-nft-page={true}
          key={`${token.id}-root`}
          onDoubleClickCapture={e => {
            if (document.fullscreenElement === e.currentTarget) {
              document.exitFullscreen();
            } else {
              e.currentTarget.requestFullscreen();
            }
          }}
        >
          {!assetLoaded && postMimeType !== MimeType.VIDEO && (
            <Image
              {...thumbnailProps}
              className={clsx(styles.thumb, thumbnailLoaded && styles.show)}
              dynamic
              src={getImageUrl(token.thumbnail_uri || '')}
              alt={''}
              key={`${token.title}-thumb`}
              onError={e => {
                e.currentTarget.src = '/poster.svg';
              }}
            />
          )}
          <div
            className={clsx(
              assetLoaded || thumbnailLoaded || postMimeType === MimeType.AUDIO
                ? styles.show
                : styles.show
            )}
            key={`${token.id}-asset`}
          >
            {postMimeType === MimeType.IMAGE ||
            postMimeType === MimeType.AUDIO ? (
              <>
                <Image
                  {...assetProps}
                  className={clsx(
                    styles.asset,
                    postMimeType === MimeType.AUDIO && styles.audioAsset
                  )}
                  dynamic
                  src={
                    postMimeType === MimeType.AUDIO
                      ? getImageUrl(token.display_uri || '')
                      : getImageUrl(token.artifact_uri || '')
                  }
                  alt={token.title}
                  onError={e => {
                    e.currentTarget.src = '/poster.svg';
                  }}
                />
                {postMimeType === MimeType.AUDIO && token && (
                  <div
                    className={styles.audioOverlay}
                    onClick={() => {
                      if (!audioRef.current) return;
                      if (audioRef.current.paused) {
                        audioRef.current.play();
                        if (audioRef.current.muted) {
                          audioRef.current.muted = false;
                        }
                      } else {
                        audioRef.current.pause();
                      }
                    }}
                  >
                    <audio
                      {...assetProps}
                      key={token.id}
                      playsInline
                      src={getImageUrl(token.artifact_uri || '')}
                      crossOrigin="anonymous"
                      ref={audioRef}
                      controls
                      controlsList="nodownload noremoteplayback noplaybackrate"
                    />
                  </div>
                )}
              </>
            ) : postMimeType === MimeType.VIDEO ? (
              <video
                {...assetProps}
                key={token.id}
                playsInline
                src={getImageUrl(token.artifact_uri || '')}
                crossOrigin="anonymous"
                ref={videoRef}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate"
                disablePictureInPicture
                onClick={() => {
                  if (!videoRef.current) return;
                  if (videoRef.current.paused) {
                    videoRef.current.play();
                    if (videoRef.current.muted) {
                      videoRef.current.muted = false;
                    }
                  } else {
                    videoRef.current.pause();
                  }
                }}
              />
            ) : postMimeType === MimeType.APPLICATION ? (
              <iframe
                {...assetProps}
                className={styles.asset}
                src={getImageUrl(token.artifact_uri || '')}
                loading="lazy"
                frameBorder="0"
                allowFullScreen
              />
            ) : (
              <Image
                {...assetProps}
                className={styles.asset}
                dynamic
                src={'/poster.svg'}
                alt={token.title}
              />
            )}
          </div>
        </div>
        <ManageItem tokenKey={token} className={styles.assetManageItem} />
      </>
    );
  }

  return token ? (
    <>
      {assetLoaded && (
        <div className={clsx(styles.assetInfo, styles.assetInfoMobile)}>
          <div>{token?.accounts && <ByCreator account={token.accounts} />}</div>
        </div>
      )}
      {isPostType ? (
        <Suspense>
          <PostView
            tokenKey={token}
            aspectRatio={{
              width: 1,
              height: 1,
            }}
            asset={postMimeType !== MimeType.TEXT && RenderAsset()}
          />
        </Suspense>
      ) : (
        RenderAsset()
      )}
    </>
  ) : null;
};

export default TokenAsset;

const NftAssetByCreatorFragment = graphql`
  fragment NftAssetByCreatorFragment on accounts {
    identities {
      profilesCollection {
        edges {
          node {
            username
          }
        }
      }
    }
  }
`;

const ByCreator = ({
  account: accountKey,
}: {
  account: NftAssetByCreatorFragment$key;
}) => {
  const account = useFragment(NftAssetByCreatorFragment, accountKey);

  return (
    <div className={styles.creator}>
      {`by ${trim(
        account.identities.profilesCollection?.edges?.[0].node.username || ''
      )}`}
    </div>
  );
};
