import React, {Suspense, useCallback} from 'react';
import styles from '@/view/styles/components/CMDk/Search/SearchDialog.module.scss';
import Tooltip from '@/view/components/Tooltip';
import Router, {useRouter} from 'next/router';
import dynamic from 'next/dynamic';
import BottomSheet from '@/view/components/BottomSheet/BottomSheet';
import InfiniteLoader from '../../InfiniteLoader';
import RemixIcon from '../../RemixIcon';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
const Search = dynamic(() => import('@/view/components/CMDk/Search/Search'), {
  ssr: false,
});

const SearchDialog = ({
  iconSize,
  text,
  trigger,
  defaultOpen,
  onClose,
}: {
  iconSize?: number;
  text?: string;
  trigger?: React.ReactNode;
  defaultOpen?: boolean;
  onClose?: (isPopState?: boolean) => void;
}) => {
  const router = useRouter();
  const {isMobile} = useBreakpoint();
  return (
    <div>
      <BottomSheet
        dialogOnDesktop
        defaultOpen={defaultOpen}
        trigger={
          trigger ? (
            trigger
          ) : (
            <div
              onClick={() => {
                if (isMobile) {
                  router.push(
                    {pathname: router.pathname, query: {...router.query}},
                    router.asPath + '#',
                    {
                      shallow: true,
                      scroll: false,
                    }
                  );
                }
              }}
            >
              <Tooltip text="Search" side="top">
                <RemixIcon icon="search-line" size={iconSize || 24} />
              </Tooltip>
            </div>
          )
        }
        className={styles.bottomSheet}
        handleOnClose={useCallback(() => {
          onClose && onClose();
        }, [onClose])}
      >
        {useCallback(
          (closeDialog: () => void) => (
            <Suspense fallback={<InfiniteLoader height={400} width={400} />}>
              <Search
                className={styles.root}
                onClose={(isPopState: boolean) => {
                  closeDialog();
                }}
                defaultSearchTerm={text}
              />
            </Suspense>
          ),
          [text]
        )}
      </BottomSheet>
    </div>
  );
};

export default SearchDialog;
