import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation insertBeaconAccountMutation($input: [beacon_accountsInsertInput!]!) {
    insertIntobeacon_accountsCollection(objects: $input) {
      records {
        public_key_hash
        account_id
      }
    }
  }
`;
