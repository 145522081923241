import {ListActions} from '@/state/hooks/actionsMenu';
import {ToastActions} from '@/state/hooks/toasts';
import RemixIcon from '@/view/components/RemixIcon';
import {ActionType} from '@/view/types/types';
import {useMemo} from 'react';
import styles from '@/view/styles/providers/Dialog.module.scss';
import {v4 as uuid} from 'uuid';
import {graphql, useLazyLoadQuery} from '@/kits/relay-kit/src';
import Image from '@/view/components/Image';
import WarpcastIcon from '@/view/resources/icons/Warpcast.svg';
import type {listActionsPlaylistInfoQuery as listActionsPlaylistInfoQueryType} from './__generated__/listActionsPlaylistInfoQuery.graphql';

const copyLinkText = 'Copy link';

const listActionsPlaylistInfoQuery = graphql`
  query listActionsPlaylistInfoQuery($id: BigInt!) {
    playlistsCollection(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          nodeId
          title
        }
      }
    }
  }
`;

export const useListViewToActions = ({
  view,
  share,
}: {
  view: ListActions | undefined;
  share?: (
    item: {
      id: string;
      nodeId: string;
    },
    type: 'post' | 'list' | 'user' | 'blog'
  ) => void;
}) => {
  const {data: info, retry: retryInfo} =
    useLazyLoadQuery<listActionsPlaylistInfoQueryType>(
      listActionsPlaylistInfoQuery,
      {
        id: view?.list || '',
      },
      {
        skip: !view || !view.list,
      }
    );

  const playtlistInfo = info?.playlistsCollection?.edges?.[0]?.node;

  const listActions: ActionType[][] = useMemo(() => {
    if (!playtlistInfo) return [];

    const listUrl = `https://dns.xyz/collection/${playtlistInfo.id}`;

    return [
      [
        {
          icon: <RemixIcon icon="share-forward-line" size={22} />,
          onClick: () => {},
          title: 'Share',
          doNotClose: true,
          setPage: 1,
          override: false as false,
        },
      ],
      [
        {
          icon: <RemixIcon icon="arrow-left-s-line" size={22} />,
          ...((view?.page || 0) === 0
            ? {setPage: 0, doNotClose: true}
            : {
                onClick: () => {},
              }),
          title: 'Back',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="file-copy-line" size={22} />,
          onClick: () => {
            navigator.clipboard.writeText(listUrl);
            ToastActions.addToast(
              uuid(),
              'Copied to clipboard!',
              '',
              'success',
              undefined,
              undefined,
              undefined,
              'file-copy-line'
            );
            share?.(
              {
                id: playtlistInfo.id,
                nodeId: playtlistInfo.nodeId,
              },
              'list'
            );
          },
          doNotClose: true,
          title: copyLinkText,
          override: false as false,
        },
        {
          icon: <RemixIcon icon="facebook-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${listUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              share?.(
                {
                  id: playtlistInfo.id,
                  nodeId: playtlistInfo.nodeId,
                },
                'list'
              );
            }
          },
          title: 'Share on Facebook',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="twitter-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://twitter.com/intent/tweet?url=${listUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              share?.(
                {
                  id: playtlistInfo.id,
                  nodeId: playtlistInfo.nodeId,
                },
                'list'
              );
            }
          },
          title: 'Share on Twitter',
          override: false as false,
        },
        {
          icon: (
            <Image
              src={WarpcastIcon}
              alt="Warpcast"
              className={styles.customIcon}
            />
          ),
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://warpcast.com/~/compose?embeds[]=${listUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              share?.(
                {
                  id: playtlistInfo.id,
                  nodeId: playtlistInfo.nodeId,
                },
                'list'
              );
            }
          },
          title: 'Share on Warpcast',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="whatsapp-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://wa.me/?text=${listUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              share?.(
                {
                  id: playtlistInfo.id,
                  nodeId: playtlistInfo.nodeId,
                },
                'list'
              );
            }
          },
          title: 'Share on WhatsApp',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="telegram-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://t.me/share/url?url=${listUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              share?.(
                {
                  id: playtlistInfo.id,
                  nodeId: playtlistInfo.nodeId,
                },
                'list'
              );
            }
          },
          title: 'Share on Telegram',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="mail-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `mailto:?subject=${playtlistInfo.title} on DNS&body=${listUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              share?.(
                {
                  id: playtlistInfo.id,
                  nodeId: playtlistInfo.nodeId,
                },
                'list'
              );
            }
          },
          title: 'Share via email',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="reddit-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://www.reddit.com/submit?url=${listUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              share?.(
                {
                  id: playtlistInfo.id,
                  nodeId: playtlistInfo.nodeId,
                },
                'list'
              );
            }
          },
          title: 'Share on Reddit',
          override: false as false,
        },
      ],
    ];
  }, [share, view, playtlistInfo]);

  return listActions;
};
