import React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import styles from '@/view/styles/components/Select.module.scss';
import clsx from 'clsx';
import RemixIcon from './RemixIcon';

export default function Select({
  options,
  id,
  placeholder,
  defaultValue,
  onChange,
  fullWidth,
  label,
  className,
  disabled,
}: {
  options: string[];
  id: string;
  placeholder?: string;
  defaultValue: string | undefined;
  onChange: (value: string | undefined) => void;
  fullWidth?: boolean;
  label?: string;
  className?: string;
  disabled?: boolean;
}) {
  const [open, setOpen] = React.useState(false);
  return (
    <div className={clsx(styles.root, className)}>
      {label && (
        <label htmlFor={id} className={styles.label} data-disabled={disabled}>
          {label}
        </label>
      )}
      <SelectPrimitive.Root
        open={open}
        onOpenChange={setOpen}
        onValueChange={value => onChange(value)}
        defaultValue={defaultValue}
        disabled={disabled}
      >
        <SelectPrimitive.Trigger>
          <SelectPrimitive.Value placeholder={placeholder} />
          <SelectPrimitive.Icon>
            <RemixIcon icon="arrow-down-s-line" size={20} />
          </SelectPrimitive.Icon>
        </SelectPrimitive.Trigger>

        <SelectPrimitive.Content className={styles.content}>
          <SelectPrimitive.ScrollUpButton>
            <RemixIcon icon="arrow-up-s-line" size={20} />
          </SelectPrimitive.ScrollUpButton>
          <SelectPrimitive.Viewport>
            {options.map(option => {
              return (
                <SelectPrimitive.Item value={option} key={option}>
                  <SelectPrimitive.ItemText>{option}</SelectPrimitive.ItemText>
                </SelectPrimitive.Item>
              );
            })}
          </SelectPrimitive.Viewport>
          <SelectPrimitive.ScrollDownButton>
            <RemixIcon icon="arrow-down-s-line" size={20} />
          </SelectPrimitive.ScrollDownButton>
        </SelectPrimitive.Content>
      </SelectPrimitive.Root>
    </div>
  );
}
