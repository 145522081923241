import {create} from 'zustand';

type ToastState = {
  isToastOpen?: boolean;
  closeToast?: () => void;
  toasts?: {
    id: string;
    title?: string;
    description?: string;
    type?: 'success' | 'failure' | 'info' | 'default';
    action?: string | (() => void);
    actionText?: string;
    image?: string | React.ReactNode;
    fallback?: string;
  }[];
  toast?: (
    id: string,
    title: string,
    description: string,
    type?: 'success' | 'failure' | 'info' | 'default',
    action?: string | (() => void),
    actionText?: string,
    image?: string,
    fallback?: string
  ) => void;
};

type ToastFunctions = {
  addToast: (
    id: string,
    title: string,
    description: string,
    type?: 'success' | 'failure' | 'info' | 'default',
    action?: string | (() => void),
    actionText?: string,
    image?: string | React.ReactNode,
    fallback?: string
  ) => void;
  closeToast: (id: string) => void;
  closeToasts: () => void;
};

export const useToasts = create<ToastState>((get, set) => {
  const initialState: ToastState = {};

  return {
    ...initialState,
  };
});

const DEFAULT_TEMPORARY_VIEW_RESET_TIMEOUT = 10000;

let resetTimeout: ReturnType<typeof setTimeout> | undefined;
export const ToastActions: ToastFunctions = {
  addToast(id, title, description, type, action, actionText, image, fallback) {
    useToasts.setState({
      toasts: [
        ...(useToasts.getState().toasts || []),
        {
          id,
          title,
          description,
          type: type || 'default',
          action: action,
          actionText,
          image,
          fallback,
        },
      ],
      isToastOpen: true,
    });

    resetTimeout = setTimeout(() => {
      useToasts.setState({
        toasts: (useToasts.getState().toasts || []).filter(
          toast => toast.id !== id
        ),
        isToastOpen: false,
      });
    }, DEFAULT_TEMPORARY_VIEW_RESET_TIMEOUT);

    clearTimeout(resetTimeout);
  },
  closeToast(id) {
    useToasts.setState({
      toasts: (useToasts.getState().toasts || []).filter(
        toast => toast.id !== id
      ),
      isToastOpen: true,
    });
  },
  closeToasts() {
    useToasts.setState({
      toasts: [],
      isToastOpen: false,
    });
  },
};
