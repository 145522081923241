/**
 * @generated SignedSource<<39a0e455bb5dd03f91ae9a534da3215b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FetchUsernameHoverCardCardFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UserHoverCardFragment">;
  readonly " $fragmentType": "FetchUsernameHoverCardCardFragment";
};
export type FetchUsernameHoverCardCardFragment$key = {
  readonly " $data"?: FetchUsernameHoverCardCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FetchUsernameHoverCardCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FetchUsernameHoverCardCardFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserHoverCardFragment"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "493393b548f5908c5f4a3f43aa410e46";

export default node;
