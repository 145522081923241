/**
 * @generated SignedSource<<0fcb50c9e5689473d842e7d0cc1f4598>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type createProfileQuery$variables = {
  id: string;
};
export type createProfileQuery$data = {
  readonly identitiesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly nodeId: string;
        readonly profilesCollection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly avatar_uri: string | null;
              readonly id: string;
              readonly nodeId: string;
              readonly username: string | null;
            };
          }>;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"NewPostCardProfileFragment">;
      };
    }>;
  } | null;
};
export type createProfileQuery = {
  response: createProfileQuery$data;
  variables: createProfileQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "profilesConnection",
  "kind": "LinkedField",
  "name": "profilesCollection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "profilesEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "profiles",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "username",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "avatar_uri",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "identitiesConnection",
        "kind": "LinkedField",
        "name": "identitiesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "identitiesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "identities",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "NewPostCardProfileFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "identitiesConnection",
        "kind": "LinkedField",
        "name": "identitiesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "identitiesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "identities",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "02a5e33a6ea386562de6d59d7828610d",
    "id": null,
    "metadata": {},
    "name": "createProfileQuery",
    "operationKind": "query",
    "text": "query createProfileQuery(\n  $id: BigInt!\n) {\n  identitiesCollection(filter: {id: {eq: $id}}) {\n    edges {\n      node {\n        id\n        nodeId\n        profilesCollection {\n          edges {\n            node {\n              id\n              nodeId\n              username\n              avatar_uri\n            }\n          }\n        }\n        ...NewPostCardProfileFragment\n      }\n    }\n  }\n}\n\nfragment NewPostCardDetailsHeaderFragment on identities {\n  id\n  nodeId\n  profilesCollection {\n    edges {\n      node {\n        id\n        nodeId\n        username\n        avatar_uri\n      }\n    }\n  }\n}\n\nfragment NewPostCardProfileFragment on identities {\n  ...NewPostCardDetailsHeaderFragment\n}\n"
  }
};
})();

(node as any).hash = "a96f19c9d5a9876cf3962c48ff49997d";

export default node;
