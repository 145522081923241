import {validateAddress, ValidationResult} from '@taquito/utils';

export function isValidTezosAddress(profileId: string): boolean {
  if (!profileId?.startsWith('tz')) {
    return false;
  }
  return validateAddress(profileId) === ValidationResult.VALID;
}

export function isValidTezosContractAddress(id: string): boolean {
  if (!id?.startsWith('KT')) {
    return false;
  }
  return validateAddress(id) === ValidationResult.VALID;
}
