/**
 * @generated SignedSource<<739e3c184831800d4e4d17ebd7bfedf9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ListForSaleDialogTokenFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ListForSaleTokenFragment">;
  readonly " $fragmentType": "ListForSaleDialogTokenFragment";
};
export type ListForSaleDialogTokenFragment$key = {
  readonly " $data"?: ListForSaleDialogTokenFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ListForSaleDialogTokenFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ListForSaleDialogTokenFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ListForSaleTokenFragment"
    }
  ],
  "type": "tezos_tokens",
  "abstractKey": null
};

(node as any).hash = "9d44a9cc14c121e0b96c520074df759c";

export default node;
