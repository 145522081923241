import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation updateShareMutation(
    $input: tokens_sharesUpdateInput!
    $filter: tokens_sharesFilter!
  ) {
    updatetokens_sharesCollection(set: $input, filter: $filter) {
      records {
        id
        share_count
        repost_count
        quote_post_count
      }
    }
  }
`;
