import {create} from 'zustand';
import {persist} from 'zustand/middleware';
import {v4 as uuid} from 'uuid';

export type ShoppingCartItem = {
  type: 'nft';
  token: {
    id: string;
    nodeId: string;
    title: string;
    editions_minted: string | null;
    purchase_price: number | null;
    is_purchasable: boolean;
    thumbnail_uri: string | null;
    creator: {
      address: string | null;
      username: string | null;
    };
    metadata_uri?: string;
    editions?: string;
  };
  quantity: number;
  sale?: {
    saleId: string | null | undefined;
    price: number | undefined;
    amount: string | undefined;
  };
  createCollectible?: boolean;
};

export type WithShoppingCartId<T extends ShoppingCartItem> = T & {
  shoppingCartItemId: string;
};

type ShoppingCartState = {
  isOpen: boolean;
  makeFreeItemsCollectible: boolean;
  content: WithShoppingCartId<ShoppingCartItem>[];
};

type ShoppingCartFunctions = {
  editItem<ItemType extends ShoppingCartItem>(
    itemId: string,
    item: ItemType
  ): WithShoppingCartId<ItemType> | undefined;
  addItem<ItemType extends ShoppingCartItem>(
    item: ItemType
  ): WithShoppingCartId<ItemType>;
  addItems(items: ShoppingCartItem[]): WithShoppingCartId<ShoppingCartItem>[];
  removeItem(itemId: string): boolean;
  removeItems(itemIds: string[]): boolean;
  makeCollectibles(isCollectible: boolean): void;
  clear(): void;
  open(): void;
  close(): void;
};

type ShoppingCartStore = ShoppingCartState & ShoppingCartFunctions;

export const useShoppingCart = create(
  persist<ShoppingCartStore>(
    (set, get) => {
      const initialState: ShoppingCartState = {
        content: [],
        makeFreeItemsCollectible: true,
        isOpen: false,
      };

      const self: ShoppingCartFunctions = {
        editItem(id, item) {
          const {content} = get();
          const index = content.findIndex(
            item => item.shoppingCartItemId === id
          );
          if (index === -1) {
            return undefined;
          }
          const result = {shoppingCartItemId: id, ...item};
          content[index] = result;
          set({content: [...content]});
          return result;
        },
        addItem(item) {
          const itemId = uuid();
          const {content} = get();
          const newItem = {...item, shoppingCartItemId: itemId};
          content.push(newItem);
          set({content: [...content]});
          return newItem;
        },
        addItems(items) {
          const {content} = get();
          const result: WithShoppingCartId<ShoppingCartItem>[] = [];
          for (const item of items) {
            const itemId = uuid();
            const newItem = {...item, shoppingCartItemId: itemId};
            content.push(newItem);
            result.push(newItem);
          }
          set({content: [...content]});
          return result;
        },
        removeItem(itemId) {
          const {content} = get();
          const indexToRemove = content.findIndex(
            item => item.shoppingCartItemId === itemId
          );
          if (indexToRemove === -1) {
            return false;
          }
          content.splice(indexToRemove, 1);
          set({content: [...content]});
          return true;
        },
        removeItems(itemIds) {
          const {content} = get();
          let result = true;
          const indexesToRemove: number[] = [];
          for (const itemId of itemIds) {
            const indexToRemove = content.findIndex(
              item => item.shoppingCartItemId === itemId
            );
            if (indexToRemove === -1) {
              result = false;
              continue;
            }
            indexesToRemove.push(indexToRemove);
          }
          if (!result) {
            return false;
          }
          for (const indexToRemove of indexesToRemove) {
            content.splice(indexToRemove, 1);
          }
          set({content: [...content]});
          return true;
        },
        makeCollectibles(isCollectible) {
          set({makeFreeItemsCollectible: isCollectible});
        },
        clear() {
          const {content} = get();
          content.splice(0, content.length);
          set({content: [...content]});
        },
        open() {
          const {content} = get();
          set({content: [...content], isOpen: true});
        },
        close() {
          const {content} = get();
          set({content: [...content], isOpen: false});
        },
      };
      return {
        ...initialState,
        ...self,
      };
    },
    {
      name: 'shoppingCart',
      version: 1,
      storage: {
        getItem(name) {
          const str = localStorage.getItem(name);
          if (!str) {
            return undefined;
          }
          return JSON.parse(str);
        },
        setItem(name, value) {
          localStorage.setItem(name, JSON.stringify(value));
        },
        removeItem(name) {
          localStorage.removeItem(name);
        },
      },
    }
  )
);
