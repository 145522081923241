import React, {Suspense, useCallback} from 'react';
import styles from '@/view/styles/components/CMDk/CreateCollection/CreateCollectionDialog.module.scss';
import Tooltip from '@/view/components/Tooltip';
import Router, {useRouter} from 'next/router';
import clsx from 'clsx';
import BottomSheet from '@/view/components/BottomSheet/BottomSheet';
import {LabelsPosition} from '@/view/types/types';
import RemixIcon from '../../RemixIcon';
import {useAccount} from '@/kits/account-kit/src';
import Button from '../../Button';
import {MemoIcon} from '../../utils/MemoChildren';
import Input from '../../Input/Input';
import Menu from '../../Menu';
import {ToastActions} from '@/state/hooks/toasts';
import {MenuOptionType} from '@/view/types/types';
import {v4 as uuid} from 'uuid';
import {useLoginModalActions} from '@/state/hooks/auth/loginModal';
import {CREATE_COLLECTION_LOGIN_MESSAGE} from '@/content';
import insertPlaylistTokenMutation from '@/graphql/insert-playlist-token';
import type {insertPlaylistTokenMutation as PlaylistTokenInsertMutationType} from '@/graphql/__generated__/insertPlaylistTokenMutation.graphql';
import insertPlaylistMutation from '@/graphql/insert-playlist';
import type {insertPlaylistMutation as PlaylistMutationType} from '@/graphql/__generated__/insertPlaylistMutation.graphql';
import {graphql, useLazyLoadQuery, useMutation} from '@/kits/relay-kit/src';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import InfiniteLoader from '../../InfiniteLoader';
import type {CreateCollectionDialogPlaylistsQuery as CreateCollectionDialogPlaylistsQueryType} from './__generated__/CreateCollectionDialogPlaylistsQuery.graphql';

export const CreateCollectionDialog = ({
  iconSize,
  tokenId,
  trigger,
  labels,
  tooltipPosition,
  defaultOpen,
  onClose,
  asModal,
  backdropClassName,
}: {
  iconSize?: number;
  tokenId?: string;
  trigger?: React.ReactNode;
  labels?: LabelsPosition;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  defaultOpen?: boolean;
  onClose?: (created?: boolean) => void;
  asModal?: boolean;
  backdropClassName?: string;
}) => {
  const router = useRouter();
  const [open, setOpen] = React.useState(defaultOpen || false);

  const {isMobile} = useBreakpoint();

  React.useEffect(() => {
    if (defaultOpen !== undefined) {
      setOpen(defaultOpen);
      if (!isMobile) return;
      if (defaultOpen) {
        Router.push(
          {pathname: router.pathname, query: {...router.query}},
          router.asPath + '#',
          {
            shallow: true,
            scroll: false,
          }
        );
      }
    }
  }, [defaultOpen, isMobile]);

  const {getAccount} = useAccount();
  const user = getAccount();
  const identityId = user.isLoggedIn ? user.identityId : undefined;

  return (
    <div>
      <BottomSheet
        dialogOnDesktop
        defaultOpen={open}
        trigger={
          trigger ? (
            trigger
          ) : (
            <div
              className={clsx(
                styles.addTo,
                labels === 'right'
                  ? styles.rightLabels
                  : labels === 'bottom'
                  ? styles.bottomLabels
                  : null
              )}
              onClick={() => {
                if (isMobile) {
                  router.push(
                    {pathname: router.pathname, query: {...router.query}},
                    router.asPath + '#',
                    {
                      shallow: true,
                      scroll: false,
                    }
                  );
                }
              }}
            >
              <Tooltip text="Save to list" side={tooltipPosition || 'top'}>
                <RemixIcon icon="add-circle-fill" size={iconSize || 24} />
                {labels !== 'off' && <span>Save to</span>}
              </Tooltip>
            </div>
          )
        }
        handleOnClose={useCallback(() => {
          if (isMobile) {
            Router.back();
          }
          onClose?.();
        }, [onClose, isMobile])}
        className={styles.bottomSheet}
        asModal={asModal || undefined}
        backdropClassName={backdropClassName}
      >
        <Suspense fallback={<InfiniteLoader height={200} />}>
          <CreateNewPlaylist
            close={(created?: boolean) => {
              onClose?.(created);
            }}
            tokenId={tokenId}
            identityId={identityId}
          />
        </Suspense>
      </BottomSheet>
    </div>
  );
};

export default CreateCollectionDialog;

const CreateCollectionDialogPlaylistsQuery = graphql`
  query CreateCollectionDialogPlaylistsQuery($identityIds: [BigInt!]) {
    playlistsCollection(filter: {identity_id: {in: $identityIds}}, first: 30) {
      edges {
        node {
          nodeId
          id
          title
          image_uri
          visibility
          playlists_tokensCollection {
            totalCount
          }
        }
      }
    }
  }
`;

export const CreateNewPlaylist = ({
  close,
  tokenId,
  identityId,
}: {
  close: (created?: boolean) => void;
  tokenId: string | undefined;
  identityId: string | undefined;
}) => {
  const [title, setTitle] = React.useState<string>('');
  const [privacy, setPrivacy] = React.useState<
    'public' | 'unlisted' | 'private'
  >('public');

  const menuOptions: MenuOptionType[] = [
    {
      label: (
        <div className={styles.privacyOptionLabel}>
          <span>Public</span>
          {/* <span>Anyone can search for and view</span> */}
        </div>
      ),
      value: 'public',
      icon: <RemixIcon icon="global-line" size={24} />,
      onClick: () => {
        setPrivacy('public');
      },
    },
    {
      label: (
        <div className={styles.privacyOptionLabel}>
          <span>Unlisted</span>
          {/* <span>Anyone with the link can view</span> */}
        </div>
      ),
      value: 'unlisted',
      icon: <RemixIcon icon="link" size={24} />,
      onClick: () => {
        setPrivacy('unlisted');
      },
    },
    {
      label: (
        <div className={styles.privacyOptionLabel}>
          <span>Private</span>
          {/* <span>Only you can view</span> */}
        </div>
      ),
      value: 'private',
      icon: <RemixIcon icon="lock-fill" size={24} />,
      onClick: () => {
        setPrivacy('private');
      },
    },
  ];

  const {retry} = useLazyLoadQuery<CreateCollectionDialogPlaylistsQueryType>(
    CreateCollectionDialogPlaylistsQuery,
    {
      identityIds: [identityId || ''],
    },
    {
      skip: true,
    }
  );

  const [createPlaylist, {loading: creatingPlaylist}] =
    useMutation<PlaylistMutationType>(insertPlaylistMutation);

  const [addTo, {loading: addingToPlaylist}] =
    useMutation<PlaylistTokenInsertMutationType>(insertPlaylistTokenMutation);

  const addToPlaylist = (
    listId: string,
    listNodeId: string,
    playlistTitle: string
  ) => {
    if (!tokenId) return;
    addTo({
      variables: {
        input: [
          {
            created_at: 'now',
            playlist_id: listId,
            tezos_token_id: tokenId,
            updated_at: 'now',
          },
        ],
      },
      updater: store => {
        const affectedMutation = store.getRootField(
          'insertIntoplaylists_tokensCollection'
        );
        const newAddedRecords = affectedMutation?.getLinkedRecords('records');
        const newAddedRecord = newAddedRecords?.[0];
        const affectedPlaylist = store.get(listNodeId);
        const tokens = affectedPlaylist?.getLinkedRecord(
          'playlists_tokensCollection'
        );
        const edges = tokens?.getLinkedRecords('edges');
        const newEdge = store.create(
          `client:token:${newAddedRecord?.getDataID()}`,
          'token'
        );
        newEdge?.setLinkedRecord(newAddedRecord, 'node');
        edges?.push(newEdge);
        tokens?.setLinkedRecords(edges, 'edges');
        affectedPlaylist?.setLinkedRecord(
          tokens || null,
          'playlists_tokensCollection'
        );
        store.getRoot().setLinkedRecord(affectedPlaylist || null, listNodeId);
      },
    })
      .then(res => {
        close(true);
      })
      .catch(() => {
        ToastActions.addToast(
          uuid(),
          'Error adding to collection',
          '',
          'failure',
          undefined,
          undefined,
          undefined,
          'emotion-sad-line'
        );
      });
  };

  return (
    <div className={styles.dialog}>
      <div>New collection</div>
      <Input
        placeholder="Title"
        defaultValue={title}
        onChange={setTitle}
        type="text"
        fullWidth
      />
      <div>
        <Menu
          side="bottom"
          options={menuOptions}
          arrow={false}
          className={styles.menu}
        >
          <div>
            <div>
              <MemoIcon
                size={20}
                Component={
                  privacy === 'private'
                    ? 'lock-fill'
                    : privacy === 'unlisted'
                    ? 'link'
                    : 'global-line'
                }
              />
              <span>{privacy.charAt(0).toUpperCase() + privacy.slice(1)}</span>
            </div>
            <RemixIcon icon="arrow-down-s-line" size={20} />
          </div>
        </Menu>
      </div>
      <div className={styles.actions}>
        <Button
          text
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            close();
          }}
        >
          Cancel
        </Button>
        <Button
          text
          onClick={async () => {
            if (!identityId || identityId === '') {
              useLoginModalActions.setShowLoginModal(
                true,
                CREATE_COLLECTION_LOGIN_MESSAGE
              );
              return;
            }
            createPlaylist({
              variables: {
                input: [
                  {
                    identity_id: identityId,
                    title: title,
                    visibility: privacy || 'public',
                    created_at: 'now',
                    updated_at: 'now',
                  },
                ],
              },
              // updater: store => {
              //   const affectedMutation = store.getRootField(
              //     'insertIntoplaylistsCollection'
              //   );
              //   const concerningId = `client:root:__collectionsPlaylistsPaginated_playlistsCollection_connection(filter:{"identity_id":{"in":["${identityId}"]}})`;
              //   const newAddedRecords =
              //     affectedMutation?.getLinkedRecords('records');
              //   const newAddedRecord = newAddedRecords?.[0];

              //   const playlists = store.get(concerningId);
              //   const edges = playlists?.getLinkedRecords('edges');

              //   const newEdge = store.create(
              //     `${concerningId}:edges:${edges?.length || 0}`,
              //     'playlistsEdge'
              //   );
              //   newEdge?.setLinkedRecord(newAddedRecord, 'node');
              //   edges?.push(newEdge);
              //   playlists?.setLinkedRecords(edges, 'edges');
              //   playlists &&
              //     store.getRoot().setLinkedRecord(playlists, concerningId);
              // },
              onCompleted(res) {
                retry();
                const createdId =
                  res.insertIntoplaylistsCollection?.records[0].id;
                const createdNodeId =
                  res.insertIntoplaylistsCollection?.records[0].nodeId;
                const createdTitle =
                  res.insertIntoplaylistsCollection?.records[0].title;
                if (!tokenId || !createdId || !createdTitle) {
                  close(true);
                  return;
                }
                ToastActions.addToast(
                  uuid(),
                  'Collection created',
                  '',
                  'success',
                  () => {
                    Router.push(`/collection/${createdId}`);
                  },
                  'View',
                  undefined,
                  'checkbox-circle-line'
                );
                addToPlaylist(createdId, createdNodeId || '', createdTitle);
              },
              onError(error) {
                console.error(error);
              },
            });
          }}
          disabled={!title || creatingPlaylist || addingToPlaylist}
        >
          {creatingPlaylist || addingToPlaylist ? (
            <div className={styles.loader}>
              <RemixIcon icon="loader-4-line" size={24} />
            </div>
          ) : (
            'Create'
          )}
        </Button>
      </div>
    </div>
  );
};
