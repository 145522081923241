/**
 * @generated SignedSource<<9a682b33048e20ce91809fd93865515b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentContentIsReplyToFragment$data = {
  readonly id: string;
  readonly identities: {
    readonly profilesCollection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly username: string | null;
        };
      }>;
    } | null;
  };
  readonly nodeId: string;
  readonly " $fragmentSpreads": FragmentRefs<"UserProfileItemFragment">;
  readonly " $fragmentType": "CommentContentIsReplyToFragment";
};
export type CommentContentIsReplyToFragment$key = {
  readonly " $data"?: CommentContentIsReplyToFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentContentIsReplyToFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentContentIsReplyToFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nodeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "identities",
      "kind": "LinkedField",
      "name": "identities",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "profilesConnection",
          "kind": "LinkedField",
          "name": "profilesCollection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "profilesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "profiles",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "username",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserProfileItemFragment"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "1d228f6319360995c54e542a1cbaec33";

export default node;
