import {imperativePromise} from '@/utils/promises';
import {trim} from '@/utils/trim';

export const generateTextPostThumbnail = async ({
  address,
  description,
}: {
  address: string;
  description: string;
}) => {
  const {
    resolve: resolveFile,
    reject: rejectFile,
    promise: promiseFile,
  } = await imperativePromise<File>();
  const {
    resolve: resolveThumb,
    reject: rejectThumb,
    promise: promiseThumb,
  } = await imperativePromise<File>();
  const canvas = document.createElement('canvas');
  canvas.width = 1000;
  canvas.height = 1000;
  const ctx = canvas.getContext('2d');
  if (!ctx) return;
  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 0, 1000, 1000);
  ctx.fillStyle = '#fbfbfb';

  // add a rounded rectangle with a white background and 20px radius and 2px black border
  ctx.beginPath();
  ctx.moveTo(40, 20);
  ctx.lineTo(960, 20);
  ctx.quadraticCurveTo(980, 20, 980, 40);
  ctx.lineTo(980, 960);
  ctx.quadraticCurveTo(980, 980, 960, 980);
  ctx.lineTo(40, 980);
  ctx.quadraticCurveTo(20, 980, 20, 960);
  ctx.lineTo(20, 40);
  ctx.quadraticCurveTo(20, 20, 40, 20);
  ctx.closePath();
  ctx.fillStyle = '#fff';
  ctx.fill();

  ctx.font = 'bold 42px Arial';
  ctx.fillStyle = '#252525';
  ctx.fillText(trim(address), 60, 110);

  // description
  ctx.font = '42px Arial';
  ctx.fillStyle = '#252525';

  function splitLines(
    ctx: CanvasRenderingContext2D,
    text: string,
    maxWidth: number
  ) {
    const words = text.split(' ');
    const lines = [];
    let line = '';
    let lineCount = 0;
    let lineLength = 0;
    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' ';
      const metrics = ctx.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        lines.push(line);
        line = words[n] + ' ';
        lineCount++;
        lineLength = 0;
      } else {
        line = testLine;
        lineLength += words[n].length;
      }
      if (lineCount > 15 || lineLength > 890) {
        lines.push('...');
        break;
      }
    }
    if (lineCount < 15 && lineLength < 890) lines.push(line);
    return lines;
  }

  const lines = splitLines(ctx, description, 890);
  lines.forEach((line, index) => {
    ctx.fillText(line, 60, 190 + index * 46);
  });

  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(1000, 0);
  ctx.lineTo(1000, 1000);
  ctx.lineTo(0, 1000);
  ctx.lineTo(0, 0);
  ctx.closePath();
  ctx.lineWidth = 80;
  ctx.strokeStyle = '#ffffff20';
  ctx.stroke();
  ctx.lineWidth = 76;
  ctx.strokeStyle = '#ffffff40';
  ctx.stroke();
  ctx.lineWidth = 72;
  ctx.strokeStyle = '#ffffff80';
  ctx.stroke();
  ctx.lineWidth = 70;
  ctx.strokeStyle = '#ffffff';
  ctx.stroke();

  ctx.beginPath();
  ctx.moveTo(40, 20);
  ctx.lineTo(960, 20);
  ctx.quadraticCurveTo(980, 20, 980, 40);
  ctx.lineTo(980, 960);
  ctx.quadraticCurveTo(980, 980, 960, 980);
  ctx.lineTo(40, 980);
  ctx.quadraticCurveTo(20, 980, 20, 960);
  ctx.lineTo(20, 40);
  ctx.quadraticCurveTo(20, 20, 40, 20);
  ctx.closePath();
  ctx.lineWidth = 2;
  ctx.strokeStyle = '#2525251a';
  ctx.stroke();

  canvas.toBlob(blob => {
    if (!blob) {
      rejectFile(new Error('TODO'));
      return;
    }
    const file = new File([blob], 'image.png', {
      type: 'image/png',
    });
    resolveFile(file);
  });

  const resizedTo300 = document.createElement('canvas');
  resizedTo300.width = 300;
  resizedTo300.height = 300;

  const resizedTo300Ctx = resizedTo300.getContext('2d');
  if (!resizedTo300Ctx) return;
  resizedTo300Ctx.drawImage(canvas, 0, 0, 300, 300);

  resizedTo300.toBlob(blob => {
    if (!blob) {
      rejectThumb(new Error('TODO'));
      return;
    }

    const thumb = new File([blob], 'image.png', {
      type: 'image/png',
    });
    resolveThumb(thumb);
  });

  return Promise.all([promiseFile, promiseThumb]);
};
