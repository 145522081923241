import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation removeSaleMutation($filter: salesFilter!) {
    deleteFromsalesCollection(filter: $filter) {
      records {
        id
        nodeId
      }
    }
  }
`;
