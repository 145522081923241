import {Magic} from 'magic-sdk';
import {OAuthExtension} from '@magic-ext/oauth';
import {TaquitoExtension} from '@magic-ext/taquito';
import authConfig from '@/kits/authentication-kit/config.json';
import config from '../config.json';

const createPublicMagic = () => {
  if (!config.publicKey) {
    throw new Error('configuration does not include public key');
  }

  return (
    typeof window != 'undefined' &&
    new Magic(config.publicKey, {
      extensions: [
        new OAuthExtension(),
        new TaquitoExtension({
          rpcUrl: authConfig.tezosConfig.tezosRpcUri,
        }),
      ],
    })
  );
};

export const PublicMagic = createPublicMagic();

export function getConfig() {
  return config;
}
