import {mintStore} from '../../mint/mint-nfts/utils/MintStore';
import {
  CreateView,
  useCreateStatus,
  useCreateStatusActions,
} from '@/state/hooks/createStatus';
import Router from 'next/router';
import {waitForChange} from '@/utils/promises';
import {uploadMetadata} from '../../mint/mint-nfts/utils/mint';
import {convertToMutez} from '@/utils/tezos';
import {fromString, toString} from 'uint8arrays';
import {Account} from '@/kits/account-kit/src';
import {MutateWithVariables} from 'relay-hooks';
import type {
  insertTezosTokenMutation as TezosTokenMutationType,
  enum_token_type,
} from '@/graphql/__generated__/insertTezosTokenMutation.graphql';
import {MINTER_CONTRACT} from '@/config';
import {updateMintAndSubmitToIPFS} from './addToIPFS';
import {ItemDataType} from '@/view/types/types';

const generateURI = (linkOrCID: string) => {
  if (linkOrCID.startsWith('ipfs://')) {
    return linkOrCID.split('ipfs://')[1];
  } else {
    const split = linkOrCID.split('https://ipfs.dns.xyz/ipfs/')[1];
    if (split) {
      return split;
    } else {
      return linkOrCID;
    }
  }
};

export async function processNFT({
  wsRef,
  fallbackTitle,
  itemData,
  setUploading,
  resetCreateFields,
  user,
  insertPost,
  onComplete,
  view,
  createNotifications,
  setMentions,
  userAvatar,
}: {
  wsRef: React.MutableRefObject<WebSocket | undefined>;
  fallbackTitle: string;
  itemData?: ItemDataType;
  setUploading: (message: string) => void;
  resetCreateFields: () => void;
  user: Account;
  insertPost: MutateWithVariables<TezosTokenMutationType>;
  onComplete: (postId: string | undefined) => void;
  view: CreateView;
  createNotifications: ({
    creator,
    token_id,
  }: {
    creator: string;
    token_id: string;
  }) => void;
  setMentions: React.Dispatch<React.SetStateAction<string[]>>;
  userAvatar?: string;
}) {
  if (!user.isLoggedIn) return;

  const {getMintMetadata} = mintStore.getState();

  try {
    setUploading('Sharing post');
    useCreateStatusActions.setStatus('uploading');
    if (itemData?.file && view === 'image') {
      useCreateStatusActions.setThumbnail(
        URL.createObjectURL(itemData?.file) || ''
      );
    }
    if (Router.asPath === '/create') {
      Router.push(useCreateStatus.getState().previousRoute || '/');
    }
    useCreateStatusActions.setCanCancel(true);
    useCreateStatusActions.setShowCreateModal(false);

    Router.push(useCreateStatus.getState().previousRoute || '/');
    await updateMintAndSubmitToIPFS(wsRef, itemData, fallbackTitle, userAvatar);

    await waitForChange(false, () => {
      const {currentMint} = mintStore.getState();
      return currentMint?.ready;
    });

    const mintMetadata = getMintMetadata(user.address);
    const metadata = await uploadMetadata({mintMetadata});

    if (!metadata?.[0]?.cid) {
      throw new Error('there is no cid. this is a bug');
    }

    const metadataCid = metadata[0].cid; // Already hex encoded

    useCreateStatusActions.setCanCancel(false);

    insertPost({
      variables: {
        input: [
          {
            account_id: user.accountId,
            allow_collection: itemData?.allowCollecting || false,
            allow_comments: itemData?.allowComments || false,
            created_at: 'now',
            description: itemData?.description || '',
            artifact_uri: mintMetadata.artifactUri
              ? generateURI(mintMetadata.artifactUri)
              : '',
            display_uri: mintMetadata.displayUri
              ? generateURI(mintMetadata.displayUri)
              : '',
            thumbnail_uri: mintMetadata.thumbnailUri
              ? generateURI(mintMetadata.thumbnailUri)
              : '',
            editions:
              mintMetadata.editions?.toString() ||
              itemData?.editions.toString() ||
              '1',
            metadata_uri: toString(
              fromString(metadataCid, 'hex'),
              'utf8'
            ).substring('ipfs://'.length),
            mime_type:
              mintMetadata.formats?.find(
                (
                  item: any
                  // sorry I'll add a type later :(
                ) => item.fileName === 'artifact'
              )?.mimeType || '',
            title: mintMetadata.name || '',
            updated_at: 'now',
            minted_at: null,
            purchase_price:
              itemData?.price !== null
                ? Number(convertToMutez(itemData?.price || 0.0))
                : null,
            price_denomination: itemData?.price_denomination || 'usd',
            is_purchasable:
              (itemData?.price !== null && itemData?.allowCollecting) || false,
            artist_address: user.address,
            attributes: JSON.stringify(itemData?.attributes || []),
            contract_address: MINTER_CONTRACT,
            editions_burned: '0',
            editions_minted: '0',
            minter_address: user.address,
            minting_platform: 'DNS',
            price:
              itemData?.price !== null
                ? Number(convertToMutez(itemData?.price || 0.0)).toString()
                : null,
            type: view as enum_token_type,
          },
        ],
      },
      onCompleted(response) {
        const record = response.insertIntotezos_tokensCollection?.records?.[0];
        if (!record) {
          throw new Error('no record returned from insertPost mutation');
        }
        const usernames =
          record?.description
            ?.split(/((?:@)[a-zA-Z_]+)/)
            .filter(s => s.length && s.startsWith('@'))
            ?.map(s => s.slice(1)) || [];
        if (usernames.length) {
          setMentions(usernames);
          setTimeout(() => {
            createNotifications({
              creator: record.account_id,
              token_id: record.id,
            });
          }, 1000);
        }

        onComplete(record.id); // Post ID (from Postgres)
      },
      updater: store => {
        store
          .get(
            `client:root:__HomeTabFeedEventsPaginated_eventsCollection_connection(orderBy:{"created_at":"DescNullsLast"})`
          )
          ?.invalidateRecord();
      },
    });

    setUploading('Your post has been shared');
    useCreateStatusActions.setStatus('done');
    resetCreateFields();

    setTimeout(() => {
      useCreateStatusActions.reset();
    }, 5000);
  } catch (e) {
    setUploading('Oops, something went wrong. Please try again.');
    useCreateStatusActions.setStatus('error');
  }
}
