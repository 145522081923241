/**
 * @generated SignedSource<<2b970d7d815401834d4c443dd9fc2052>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type enum_token_type = "audio" | "image" | "link" | "post" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NftAssetTokenFragment$data = {
  readonly accounts: {
    readonly id: string;
    readonly nodeId: string;
    readonly " $fragmentSpreads": FragmentRefs<"NftAssetByCreatorFragment">;
  };
  readonly artifact_uri: string;
  readonly description: string | null;
  readonly display_uri: string;
  readonly id: string;
  readonly mime_type: string;
  readonly nodeId: string;
  readonly thumbnail_uri: string;
  readonly title: string;
  readonly type: enum_token_type;
  readonly " $fragmentSpreads": FragmentRefs<"ManageItemTokenFragment" | "NFTPageItemPostViewTokenFragment">;
  readonly " $fragmentType": "NftAssetTokenFragment";
};
export type NftAssetTokenFragment$key = {
  readonly " $data"?: NftAssetTokenFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NftAssetTokenFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NftAssetTokenFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mime_type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "display_uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail_uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "artifact_uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "NftAssetByCreatorFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NFTPageItemPostViewTokenFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ManageItemTokenFragment"
    }
  ],
  "type": "tezos_tokens",
  "abstractKey": null
};
})();

(node as any).hash = "69f81f85781ef2604191e694e550f329";

export default node;
