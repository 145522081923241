import React from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import styles from '@/view/styles/components/Toast/Toast.module.scss';
import {ToastActions, useToasts} from '@/state/hooks/toasts';
import clsx from 'clsx';
import Link from 'next/link';
import Image from '../Image';
import Button from '../Button';
import {MemoIcon} from '../utils/MemoChildren';
import RemixIcon from '../RemixIcon';

export default function Toast() {
  const {isToastOpen, toasts} = useToasts();

  return isToastOpen ? (
    <>
      {toasts?.map(toast => {
        const hasLink = toast.action && typeof toast.action === 'string';

        return hasLink ? (
          <Link
            href={typeof toast.action === 'string' ? toast.action : ''}
            key={toast.id}
          >
            <ToastPrimitive.Root
              className={clsx(
                styles.toast,
                styles[toast.type || 'default'],
                hasLink && styles.link
              )}
            >
              <div>
                {toast.image !== undefined ? (
                  <div className={styles.image}>
                    {toast.image && typeof toast.image === 'string' ? (
                      <Image
                        dynamic
                        src={toast.image}
                        alt=""
                        onError={e => {
                          e.currentTarget.style.display = 'none';
                        }}
                      />
                    ) : (
                      toast.image
                    )}
                  </div>
                ) : toast.fallback ? (
                  <MemoIcon Component={toast.fallback} size={24} />
                ) : null}
                <div>
                  <ToastPrimitive.Title
                    className={clsx(styles.text, styles.title)}
                  >
                    {toast.title}
                  </ToastPrimitive.Title>
                  <ToastPrimitive.Description className={styles.text}>
                    {toast.description}
                  </ToastPrimitive.Description>
                </div>
                {/* <ToastPrimitive.ToastClose asChild>
                  <Button
                    icon
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      ToastActions.closeToast(toast.id);
                    }}
                  >
                    <RemixIcon icon="close-fill" size={24} />
                  </Button>
                </ToastPrimitive.ToastClose> */}
              </div>
            </ToastPrimitive.Root>
          </Link>
        ) : (
          <ToastPrimitive.Root
            className={clsx(styles.toast, styles[toast.type || 'default'])}
            key={toast.id}
          >
            <div>
              {toast.image !== undefined ? (
                <div className={styles.image}>
                  {toast.image && typeof toast.image === 'string' ? (
                    <Image
                      dynamic
                      src={toast.image}
                      alt=""
                      onError={e => {
                        e.currentTarget.style.display = 'none';
                      }}
                    />
                  ) : (
                    toast.image
                  )}
                </div>
              ) : toast.fallback ? (
                <MemoIcon Component={toast.fallback} size={24} />
              ) : null}
              <div
                className={clsx(
                  toast.action && toast.actionText && styles.grow
                )}
              >
                <ToastPrimitive.Title
                  className={clsx(styles.text, styles.title)}
                >
                  {toast.title?.toString()}
                </ToastPrimitive.Title>
                <ToastPrimitive.Description className={styles.text}>
                  {toast.description?.toString()}
                </ToastPrimitive.Description>
              </div>
              {toast.action && toast.actionText && (
                <Button
                  text
                  onClick={() => {
                    if (typeof toast.action === 'function') {
                      toast.action();
                    }
                    ToastActions.closeToast(toast.id);
                  }}
                  className={styles.action}
                >
                  <span>{toast.actionText}</span>
                </Button>
              )}
            </div>
            {/* {!toast.action && !toast.actionText && (
              <Button
                icon
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  ToastActions.closeToast(toast.id);
                }}
              >
                <RemixIcon icon="close-fill" size={24} />
              </Button>
            )} */}
          </ToastPrimitive.Root>
        );
      })}
    </>
  ) : (
    <></>
  );
}
