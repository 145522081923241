import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation removeCommentMutation($filter: commentsFilter!) {
    deleteFromcommentsCollection(filter: $filter) {
      records {
        id
        nodeId
      }
    }
  }
`;
