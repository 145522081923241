import React from 'react';
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';
import styles from '@/view/styles/components/HoverCard/HoverCard.module.scss';
import clsx from 'clsx';

export default function HoverCard({
  arrow = true,
  trigger,
  children,
  className,
  side = 'top',
}: React.PropsWithChildren<{
  arrow?: boolean;
  trigger: React.ReactNode;
  className?: string;
  side?: 'top' | 'right' | 'bottom' | 'left';
}>) {
  return (
    <HoverCardPrimitive.Root openDelay={40} closeDelay={40}>
      <HoverCardPrimitive.Trigger asChild>{trigger}</HoverCardPrimitive.Trigger>
      <HoverCardPrimitive.Portal>
        <HoverCardPrimitive.Content
          onClick={e => {
            e.stopPropagation();
          }}
          className={clsx(styles.content, className)}
          side={side}
        >
          {children}
          {!arrow ? null : (
            <HoverCardPrimitive.Arrow className={styles.arrow} />
          )}
        </HoverCardPrimitive.Content>
      </HoverCardPrimitive.Portal>
    </HoverCardPrimitive.Root>
  );
}
