import React from 'react';
import UserProfileItem from './UserProfileItem';
import FollowButton from './FollowButton';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import {UserItemFragment$key} from './__generated__/UserItemFragment.graphql';

const UserItemFragment = graphql`
  fragment UserItemFragment on accounts {
    id
    nodeId
    type
    identities {
      id
      nodeId
      profilesCollection {
        edges {
          node {
            id
            nodeId
            username
            name
          }
        }
      }
    }
    ...UserProfileItemFragment
  }
`;

export const UserItem = ({
  account: accountKey,
  hideIcon,
  hideText,
  showFollowButton,
}: {
  account: UserItemFragment$key;
  hideIcon?: boolean;
  hideText?: boolean;
  showFollowButton?: boolean;
}) => {
  const account = useFragment(UserItemFragment, accountKey);

  return (
    <>
      <UserProfileItem
        user={account}
        hideUsername
        hideIcon={hideIcon}
        hideText={hideText}
        showFollowButton={showFollowButton}
      />
    </>
  );
};
