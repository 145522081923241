import {useCreateStatusActions} from '@/state/hooks/createStatus';
import {imperativePromise} from '@/utils/promises';
import {v4 as uuid} from 'uuid';
import {IMint, mintStore} from '../../mint/mint-nfts/utils/MintStore';
import {RightsEnum} from '@/view/types/mintTypes';
import {ItemDataType} from '@/view/types/types';
import {MINTER_CONTRACT, MINTER_URI} from '@/config';
import Axios from 'axios';
import {generateTextPostThumbnail} from './generateTextPostThumbnail';
import {trim} from '@/utils/trim';
import {uploadText} from './uploadText';
import {uploadCover} from './uploadCover';
import {uploadThumb} from './uploadThumb';
import {jsonToBlob} from '@/utils/objects';
import {
  generateMusicCoverThumbnail,
  generateMusicFallbackThumbnail,
} from './generateMusicCoverThumbnail';
import {uploadAsset} from './uploadAsset';
import {getIPFSuri} from '@/utils/conversions/conversions';

const uploadSingleAssetForResizing = async (
  wsRef: React.MutableRefObject<WebSocket | undefined>,
  file: File,
  id: string
) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await Axios.post(`${MINTER_URI}/upload/ipfs`, formData, {
      onUploadProgress(progressEvent) {
        const {currentMint: mint} = mintStore.getState();
        if (!mint) {
          return;
        }
        mint.uploading = {
          progress: progressEvent.loaded,
          maxProgress: progressEvent.total || progressEvent.loaded,
        };
        mintStore.getState().updateMint(mint);
      },
    });

    const oldId = id;

    wsRef.current?.send(
      JSON.stringify({
        type: 'LISTEN_ID',
        id: response.data.id,
        old_id: oldId,
      })
    );
  } catch (error) {
    console.error(error);
  }
};

export const updateMintAndSubmitToIPFS = async (
  wsRef: React.MutableRefObject<WebSocket | undefined> | null,
  itemData?: ItemDataType,
  fallbackTitle?: string,
  userAvatar?: string
) => {
  const id = uuid();
  if (!itemData) return;

  const postTitle = itemData.title !== '' ? itemData.title : undefined;

  const localFileURLstring = itemData?.file
    ? URL.createObjectURL(itemData.file)
    : undefined;

  const baseSingleMintData: IMint = {
    id: id,
    ipfs: false,
    name: postTitle || fallbackTitle || 'Untitled',
    type: itemData.file?.type || 'text/plain',
    size: itemData.file?.size || 0,
    tmpThumb: localFileURLstring,
    dimensions: `0x0`,
    editions: itemData.editions,
    license: itemData.license || RightsEnum.NONE,
    description: itemData.description,
    cocreators: itemData.coCreators.map(creator => creator.address) || [],
    nsfw: itemData.nsfw,
    photosensitiveWarning: itemData.photosensitiveWarning,
    royalty: itemData.royalty || 0,
    royaltySplit: Object.fromEntries(
      Object.entries([...itemData.royaltySplits]).map(([_, split]) => [
        split.user.address,
        split.split * 10,
      ])
    ),
    metadata: {
      name: itemData.file?.name,
      formats: [],

      tags: itemData.tags || [],

      mintingTool: 'https://dns.xyz',
      mintingToolVersion: 'v1',
      minter: MINTER_CONTRACT,

      date: new Date().toISOString(),

      symbol: 'DNS', // OBJKT Collections // TODO @tyler: do we change this?
      decimals: 0,
      shouldPreferSymbol: false,
      isBooleanAmount: false,

      contentRating: itemData.nsfw ? 'mature' : undefined,
    },
    ready: false,
    processing: {
      progress: 0,
      completeTasks: [],
      maxProgress: 6,
    },
    uploading: {
      progress: 0,
      maxProgress: itemData.file?.size || 1,
    },
    attributes: itemData.attributes,
    tags: itemData.tags,
  };

  if (itemData?.multipleFiles && itemData?.multipleFiles.length > 1) {
    // Multiple Files Attached
    const tmp = itemData?.multipleFiles.map(file => URL.createObjectURL(file));
    alert('Not implemented yet! :(');
  } else if (itemData?.file) {
    // Single File Attached

    const {promise, resolve} = await imperativePromise<void>();

    if (!itemData) return;
    if (itemData?.file.type.includes('image/')) {
      // IMAGE TYPE OR POST WITH SINGLE IMAGE
      const image = new Image();
      image.src = localFileURLstring || '';
      useCreateStatusActions.setType('image');
      image.onload = async () => {
        const imageSize = [image.naturalWidth, image.naturalHeight];

        if (!itemData.file) return;

        const mintData: IMint = {
          ...baseSingleMintData,
          dimensions: `${imageSize[0]}x${imageSize[1]}`,
        };

        mintStore.getState().updateMint(mintData);
        if (!wsRef) return;
        await uploadSingleAssetForResizing(wsRef, itemData.file, id);
        resolve();
      };
      await promise;
    } else if (itemData.file.type.includes('video/')) {
      // VIDEO TYPE OR POST WITH SINGLE VIDEO
      useCreateStatusActions.setType('video');
      const mintData = {
        ...baseSingleMintData,
        attributes:
          itemData.attributes.length !== 0
            ? itemData.attributes
            : itemData.fileDuration
            ? [
                {
                  name: 'duration',
                  value: itemData.fileDuration || '00:00',
                },
              ]
            : [],
      };

      mintStore.getState().updateMint(mintData);
      if (!wsRef) return;
      await uploadSingleAssetForResizing(wsRef, itemData.file, id);
    } else {
      // AUDIO TYPE
      // TODO: refactor this code block (could not figure out a good way to deal with promises conditionally)
      if (itemData.cover) {
        useCreateStatusActions.setType('audio');
        const promises: Promise<string>[] = [];
        const {file, thumb} =
          (await generateMusicCoverThumbnail({
            cover: itemData.cover,
          })) || [];
        promises.push(uploadAsset(itemData.file));
        if (file) {
          promises.push(uploadCover(file));
        }
        if (thumb) {
          promises.push(uploadThumb(thumb));
        }
        const [audioFile, coverDisplay, coverThumbnail] = await Promise.all(
          promises
        );

        const mintData: IMint = {
          ...baseSingleMintData,
          metadata: {
            ...baseSingleMintData.metadata,
            artifactUri: getIPFSuri(audioFile),
            displayUri: getIPFSuri(coverDisplay),
            thumbnailUri: getIPFSuri(coverThumbnail),
            image: coverDisplay,
            formats: [
              {
                uri: getIPFSuri(audioFile),
                mimeType: itemData.file.type,
                fileSize: itemData.file.size,
                fileName: 'artifact',
              },
              {
                uri: getIPFSuri(coverDisplay),
                mimeType: 'image/png',
                fileSize: file?.size || 0,
                fileName: 'cover',
                dimensions: {value: '1000x1000', unit: 'px'},
              },
              {
                uri: getIPFSuri(coverThumbnail),
                mimeType: 'image/png',
                fileSize: thumb?.size || 0,
                fileName: 'thumb',
                dimensions: {value: '300x300', unit: 'px'},
              },
            ],
          },
          attributes:
            itemData.attributes.length !== 0
              ? itemData.attributes
              : itemData.fileDuration
              ? [
                  {
                    name: 'duration',
                    value: itemData.fileDuration || '00:00',
                  },
                ]
              : [],
          ready: true,
          uploading: {
            progress: 0,
            maxProgress: 1,
          },
        };
        mintStore.getState().updateMint(mintData);
      } else if (userAvatar) {
        useCreateStatusActions.setType('audio');
        const promise: Promise<string> = uploadAsset(itemData.file);
        const audioFile = await promise;

        const mintData: IMint = {
          ...baseSingleMintData,
          metadata: {
            ...baseSingleMintData.metadata,
            artifactUri: getIPFSuri(audioFile),
            displayUri: getIPFSuri(userAvatar),
            thumbnailUri: getIPFSuri(userAvatar),
            image: getIPFSuri(userAvatar),
            formats: [
              {
                uri: getIPFSuri(audioFile),
                mimeType: itemData.file.type,
                fileSize: itemData.file.size,
                fileName: 'artifact',
              },
              {
                uri: getIPFSuri(userAvatar),
                mimeType: 'image/webp',
                fileSize: 0,
                fileName: 'cover',
                dimensions: {value: '300x300', unit: 'px'},
              },
              {
                uri: getIPFSuri(userAvatar),
                mimeType: 'image/webp',
                fileSize: 0,
                fileName: 'thumb',
                dimensions: {value: '300x300', unit: 'px'},
              },
            ],
          },
          attributes:
            itemData.attributes.length !== 0
              ? itemData.attributes
              : itemData.fileDuration
              ? [
                  {
                    name: 'duration',
                    value: itemData.fileDuration || '00:00',
                  },
                ]
              : [],
          ready: true,
          uploading: {
            progress: 0,
            maxProgress: 1,
          },
        };
        mintStore.getState().updateMint(mintData);
      } else {
        // no cover or userAvatar = generate a text thumbnail
        useCreateStatusActions.setType('audio');
        const promises: Promise<string>[] = [];
        const [file, thumb] =
          (await generateMusicFallbackThumbnail({
            title: itemData.title,
          })) || [];
        promises.push(uploadAsset(itemData.file));
        if (file) {
          promises.push(uploadCover(file));
        }
        if (thumb) {
          promises.push(uploadThumb(thumb));
        }
        const [audioFile, coverDisplay, coverThumbnail] = await Promise.all(
          promises
        );

        const mintData: IMint = {
          ...baseSingleMintData,
          metadata: {
            ...baseSingleMintData.metadata,
            artifactUri: getIPFSuri(audioFile),
            displayUri: coverDisplay,
            thumbnailUri: coverThumbnail,
            image: coverDisplay,
            formats: [
              {
                uri: getIPFSuri(audioFile),
                mimeType: itemData.file.type,
                fileSize: itemData.file.size,
                fileName: 'artifact',
              },
              {
                uri: coverDisplay,
                mimeType: file?.type,
                fileSize: file?.size || 0,
                fileName: 'cover',
                dimensions: {
                  value: '1000x1000',
                  unit: 'px',
                },
              },
              {
                uri: coverThumbnail,
                mimeType: thumb?.type,
                fileSize: thumb?.size || 0,
                fileName: 'thumb',
                dimensions: {value: '300x300', unit: 'px'},
              },
            ],
          },
          attributes:
            itemData.attributes.length !== 0
              ? itemData.attributes
              : itemData.fileDuration
              ? [
                  {
                    name: 'duration',
                    value: itemData.fileDuration || '00:00',
                  },
                ]
              : [],
          ready: true,
          uploading: {
            progress: 0,
            maxProgress: 1,
          },
        };
        mintStore.getState().updateMint(mintData);
      }
    }
  } else {
    // POST TYPE WITH ONLY TEXT
    const promises: Promise<string>[] = [];

    const [canvasFile, canvasThumb] =
      (await generateTextPostThumbnail({
        address:
          itemData.coCreators?.[0]?.username ||
          trim(itemData.coCreators?.[0]?.address),
        description: itemData.description,
      })) || [];

    promises.push(uploadText(itemData.description));

    if (canvasFile) {
      promises.push(uploadCover(canvasFile));
    }

    if (canvasThumb) {
      promises.push(uploadThumb(canvasThumb));
    }

    const [textPostArtifact, textPostDisplay, textPostThumbnail] =
      await Promise.all(promises);

    const mintData: IMint = {
      ...baseSingleMintData,
      type: 'text/plain',
      size: canvasFile?.size || 0,
      tmpThumb: canvasFile ? URL.createObjectURL(canvasFile) : undefined,
      dimensions: '1000x1000',
      metadata: {
        artifactUri: getIPFSuri(textPostArtifact),
        displayUri: getIPFSuri(textPostDisplay),
        thumbnailUri: getIPFSuri(textPostThumbnail),
        formats: [
          {
            uri: getIPFSuri(textPostArtifact),
            mimeType: 'text/plain',
            fileSize: jsonToBlob(itemData.description).size,
            fileName: 'artifact',
          },
          {
            uri: getIPFSuri(textPostDisplay),
            mimeType: 'image/png',
            fileSize: canvasFile?.size || 0,
            fileName: 'cover',
            dimensions: {value: '1000x1000', unit: 'px'},
          },
          {
            uri: getIPFSuri(textPostThumbnail),
            mimeType: 'image/png',
            fileSize: canvasThumb?.size || 0,
            fileName: 'thumb',
            dimensions: {value: '300x300', unit: 'px'},
          },
        ],
      },
      ready: true,
      uploading: {
        progress: 0,
        maxProgress: 1,
      },
    };

    mintStore.getState().updateMint(mintData);
  }
};
