import React from 'react';
import Switch from '@/view/components/Switch';
import styles from '@/view/styles/components/NewPostCard/blocs/NewPostCardAdvanced.module.scss';
import Input from '@/view/components/Input/Input';
import TezSymbol from '@/view/components/TezSymbol';
import {ItemDataType} from '@/view/types/types';
import Select from '../../Select';
import {enum_denomination_type} from '@/graphql/__generated__/tezosTokensFragment.graphql';

export const licenseOptions = [
  'No license / All rights reserved',
  'CC0', // Creative Commons Zero
  'CC BY', // Creative Commons Attribution
  'CC BY-SA', // Creative Commons Attribution Share Alike
  'CC BY-ND', // Creative Commons Attribution No Derivatives
  'CC BY-NC', // Creative Commons Attribution Non-Commercial
];

const denominationOptions = [
  {label: 'XTZ', value: 'xtz'},
  // {label: 'USD', value: 'usd'},
];

export default function NewPostCardAdvanced({
  itemData,
  setItemData,
}: {
  itemData: ItemDataType;
  setItemData: React.Dispatch<React.SetStateAction<ItemDataType>>;
}) {
  return (
    <>
      <div className={styles.group} data-hidden={!itemData.allowCollecting}>
        <span>Price</span>
        <div className={styles.collectPrice}>
          <Input
            id="collectPrice"
            defaultValue={itemData.price ?? undefined}
            onChange={val => {
              if (!val) {
                setItemData({...itemData, price: undefined});
                return;
              }
              setItemData({...itemData, price: Number(val)});
            }}
            type="number"
            min={0}
            max={99999}
            step={0.00001}
            placeholder="Free (0)"
            disabled={!itemData.allowCollecting}
            onBlur={e => {
              if (
                Number(e.currentTarget.value) >=
                Number(e.currentTarget.getAttribute('max'))
              ) {
                e.currentTarget.value =
                  e.currentTarget.getAttribute('max') ?? '';
              }
              if (e.currentTarget.value.includes('.')) {
                e.currentTarget.value = (
                  Number(itemData.price?.toFixed(5)) / 1
                ).toString();
              }
            }}
          />
          <Select
            defaultValue={itemData.price_denomination}
            id="denomination"
            onChange={value => {
              setItemData({
                ...itemData,
                price_denomination: value as enum_denomination_type,
              });
            }}
            options={denominationOptions?.map(option => option.value)}
          />
        </div>
      </div>
    </>
  );
}
