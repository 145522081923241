import React, {Suspense, useCallback, useMemo} from 'react';
import styles from '@/view/styles/components/CMDk/Owners/OwnersDialog.module.scss';
import {LabelsPosition} from '@/view/types/types';
import Tooltip from '@/view/components/Tooltip';
import Router, {useRouter} from 'next/router';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import BottomSheet from '@/view/components/BottomSheet/BottomSheet';
import RemixIcon from '../../RemixIcon';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
const Owners = dynamic(() => import('@/view/components/CMDk/Owners/Owners'), {
  ssr: false,
});

const OwnersDialog = ({
  iconSize,
  editions,
  tokenId,
  trigger,
  labels,
  tooltipPosition,
}: {
  iconSize?: number;
  editions?: string;
  tokenId: string;
  trigger?: React.ReactNode;
  labels?: LabelsPosition;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
}) => {
  const router = useRouter();

  const {isMobile} = useBreakpoint();

  return (
    <div>
      <BottomSheet
        dialogOnDesktop
        trigger={
          trigger ? (
            trigger
          ) : (
            <div
              className={clsx(
                styles.editions,
                labels === 'right'
                  ? styles.rightLabels
                  : labels === 'bottom'
                  ? styles.bottomLabels
                  : null
              )}
              onClick={() => {
                router.push(
                  {pathname: router.pathname, query: {...router.query}},
                  router.asPath + '#',
                  {
                    shallow: true,
                    scroll: false,
                  }
                );
              }}
            >
              <Tooltip text="Collectors" side={tooltipPosition || 'top'}>
                <RemixIcon icon="group-line" size={iconSize || 24} />
                {labels !== 'off' && <span>{editions}</span>}
              </Tooltip>
            </div>
          )
        }
        className={styles.bottomSheet}
        handleOnClose={useCallback(() => {
          if (isMobile) {
            Router.back();
          }
        }, [isMobile])}
      >
        {useCallback(
          (closeDialog: () => void) => (
            <Suspense fallback={<></>}>
              <Owners
                className={styles.root}
                onClose={(isPopState: boolean) => {
                  closeDialog();
                }}
                tokenId={tokenId}
              />
            </Suspense>
          ),
          [tokenId]
        )}
      </BottomSheet>
    </div>
  );
};

export default OwnersDialog;
