import styles from '@/view/styles/components/FrozenGif.module.scss';
import clsx from 'clsx';
import {
  HTMLProps,
  ImgHTMLAttributes,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export function FrozenGif({
  src,
  componentProps,
  fill,
}: {
  src: string;
  componentProps?: {
    containerProps?: HTMLProps<HTMLDivElement>;
    imgProps?: ImgHTMLAttributes<HTMLImageElement>;
    canvasProps?: HTMLProps<HTMLCanvasElement>;
  };
  fill?: boolean;
}) {
  const resizeHanlerRef = useRef<() => void>();
  useEffect(() => {
    const runResizeHandler = () => resizeHanlerRef.current?.();
    window.addEventListener('resize', runResizeHandler);
    return () => window.removeEventListener('resize', runResizeHandler);
  }, []);

  const [imageLoaded, setImageLoaded] = useState(false);

  const onLoad = useCallback(
    (e: SyntheticEvent<HTMLImageElement, Event>) => {
      const img = e.currentTarget;
      if (!img) {
        return;
      }
      const canvas = canvasRef.current;

      if (!canvas) {
        return;
      }

      const renderImageToCanvas = () => {
        const rect = canvas.parentElement?.getBoundingClientRect();
        const ctx = canvas.getContext('2d');

        canvas.width = Math.ceil(rect?.width ?? 300);
        canvas.height = Math.ceil(rect?.height ?? 150);

        if (fill) {
          const x = 0;
          const y = 0;
          const w = canvas.width;
          const h = canvas.height;

          const offsetX = 0.5;
          const offsetY = 0.5;

          const iw = img.naturalWidth;
          const ih = img.naturalHeight;
          const r = Math.min(w / iw, h / ih);
          let nw = iw * r; // new prop. width
          let nh = ih * r; // new prop. height

          // crop
          let cx: number;
          let cy: number;
          let cw: number;
          let ch: number;
          let ar = 1;

          if (nw < w) {
            ar = w / nw;
          }

          if (Math.abs(ar - 1) < 1e-14 && nh < h) {
            ar = h / nh;
          }

          // resize with 1/scale %
          nw *= ar;
          nh *= ar;

          cw = iw / (nw / w);
          ch = ih / (nh / h);

          cx = (iw - cw) * offsetX;
          cy = (ih - ch) * offsetY;

          if (cx < 0) {
            cx = 0;
          }

          if (cy < 0) {
            cy = 0;
          }

          if (cw > iw) {
            cw = iw;
          }

          if (ch > ih) {
            ch = ih;
          }

          ctx?.drawImage(img, cx, cy, cw, ch, x, y, w, h);
        } else {
          const useWidthRatio =
            img.naturalWidth / canvas.width > img.naturalHeight / canvas.height;

          const width = useWidthRatio
            ? canvas.width
            : (canvas.height / img.naturalHeight) * img.naturalWidth;

          const height = useWidthRatio
            ? (canvas.width / img.naturalWidth) * img.naturalHeight
            : canvas.height;

          const x = (canvas.width - width) / 2;
          const y = (canvas.height - height) / 2;

          ctx?.drawImage(img, x, y, width, height);
        }
      };

      resizeHanlerRef.current = renderImageToCanvas;
      renderImageToCanvas();
      setImageLoaded(true);

      componentProps?.imgProps?.onLoad?.(e);
    },
    [componentProps?.imgProps, fill]
  );

  const imgProps: ImgHTMLAttributes<HTMLImageElement> = useMemo(
    () => ({
      src,
      style: {
        opacity: 0,
        height: 0,
        width: 0,
      },
      onLoad,
    }),
    [onLoad, src]
  );

  resizeHanlerRef.current?.();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasProps: HTMLProps<HTMLCanvasElement> = useMemo(
    () => ({
      className: clsx(
        componentProps?.canvasProps?.className,
        imageLoaded ? styles.show : styles.hide
      ),
    }),
    [componentProps?.canvasProps, imageLoaded]
  );

  const containerProps: HTMLProps<HTMLDivElement> = useMemo(() => ({}), []);
  return (
    <div {...componentProps?.containerProps} {...containerProps}>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img alt="" {...componentProps?.imgProps} {...imgProps} />
      <canvas
        {...componentProps?.canvasProps}
        {...canvasProps}
        ref={canvasRef}
      />
    </div>
  );
}
