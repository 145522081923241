import React, {PropsWithChildren} from 'react';
import {Command} from 'cmdk';
import styles from '@/view/styles/components/CMDk/CMDk.module.scss';
import * as ScrollArea from '@radix-ui/react-scroll-area';
import Tabs from '../Tabs';
import TabPanel from '../TabPanel';
import clsx from 'clsx';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import {TabType} from '@/view/types/types';

export default function CMDk({
  header,
  tabs,
  tabsAbove,
  updateTab,
  defaultTab,
  search,
  searchPlaceholder,
  updateSearch,
  fallback,
  className,
  children,
}: PropsWithChildren<{
  header?: React.ReactNode;
  tabs?: TabType[];
  tabsAbove?: boolean;
  updateTab?: (tab: number) => void;
  defaultTab?: number;
  search?: boolean;
  searchPlaceholder?: string;
  updateSearch?: (search: string) => void;
  fallback?: boolean;
  className?: string;
}>) {
  const textFieldRef = React.useRef<HTMLInputElement>(null);

  const {isMobile} = useBreakpoint();

  React.useEffect(() => {
    if (!isMobile) {
      textFieldRef.current?.focus();
    }
  }, [isMobile]);

  return (
    <Command
      label="Actions"
      className={clsx(styles.root, className)}
      filter={(value, search) => {
        if (fallback) return 1;
        if (value.toLowerCase().includes(search.toLowerCase())) return 1;
        return 0;
      }}
    >
      {(header || tabs || search) && (
        <div
          className={clsx(
            styles.tabsContainer,
            tabsAbove && styles.tabsAbove,
            !tabs && styles.searchOnly
          )}
        >
          {header && header}
          {search && (
            <div
              className={styles.inputContainer}
              data-has-tabs={tabs ? true : false}
            >
              <Command.Input
                className={styles.input}
                placeholder={searchPlaceholder || 'Type to search...'}
                ref={textFieldRef}
                onValueChange={value => {
                  updateSearch?.(value);
                }}
                autoFocus={!isMobile}
              />
            </div>
          )}
          {tabs && (
            <div className={styles.tabs}>
              <Tabs
                tabs={tabs || []}
                tab={defaultTab || 0}
                setTab={tab => {
                  updateTab?.(tab);
                }}
              />
            </div>
          )}
        </div>
      )}
      <Command.List className={styles.list}>
        <div className={styles.content}>
          <ScrollArea.Root type="hover">
            <ScrollArea.Viewport>
              {tabs ? (
                tabs.map((tab, index) => (
                  <TabPanel
                    key={tab.value}
                    show={index}
                    tab={defaultTab || 0}
                    scrollToTopOnChange={false}
                  >
                    <Command.Group className={styles.results}>
                      {children}
                    </Command.Group>
                  </TabPanel>
                ))
              ) : (
                <TabPanel
                  key="default"
                  show={0}
                  tab={0}
                  scrollToTopOnChange={false}
                >
                  <Command.Group className={styles.results}>
                    {children}
                  </Command.Group>
                </TabPanel>
              )}
              {!fallback && (
                <Command.Empty className={styles.empty}>
                  No results found.
                </Command.Empty>
              )}
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar orientation="vertical">
              <ScrollArea.Thumb />
            </ScrollArea.Scrollbar>
          </ScrollArea.Root>
        </div>
      </Command.List>
    </Command>
  );
}

export const CommandItem = ({
  keyOverride,
  value,
  onSelect,
  className,
  children,
}: {
  keyOverride: string;
  value: string;
  onSelect: (value: string) => void;
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <Command.Item
      className={clsx(styles.item, className)}
      key={keyOverride}
      value={value}
      onSelect={() => {
        onSelect(value);
      }}
    >
      {children}
    </Command.Item>
  );
};
