import React, {Suspense, useCallback} from 'react';
import styles from '@/view/styles/components/CMDk/Create/CreateDialog.module.scss';
import Router from 'next/router';
import dynamic from 'next/dynamic';
import BottomSheet from '@/view/components/BottomSheet/BottomSheet';
const Create = dynamic(() => import('@/view/components/CMDk/Create/Create'), {
  ssr: false,
});

export const CreateDialog = ({trigger}: {trigger: React.ReactNode}) => {
  return (
    <div>
      <BottomSheet
        dialogOnDesktop
        trigger={trigger}
        className={styles.bottomSheet}
        handleOnClose={(isPopState?: boolean) => {}}
      >
        {useCallback(
          (closeDialog: () => void) => (
            <Suspense>
              <Create
                className={styles.connectionsModal}
                onClose={(isPopState: boolean) => {
                  if (!isPopState) {
                    Router.back();
                  }
                  closeDialog();
                }}
              />
            </Suspense>
          ),
          []
        )}
      </BottomSheet>
    </div>
  );
};
