import React, {ForwardedRef, Suspense} from 'react';
import dynamic from 'next/dynamic';
import Button from './Button';
import Input from './Input/Input';
import styles from '@/view/styles/components/NFTCommentBox.module.scss';
import {useDarkMode} from '@/controller/hooks/darkMode';
import clsx from 'clsx';
import Menu from './Menu';
const EmojiPickerComponent = dynamic(() => import('emoji-picker-react'), {
  ssr: false,
});
import {EmojiStyle, SuggestionMode, Theme} from 'emoji-picker-react';
import {emojiCategories} from '@/view/placeholders/placeholderData';
import RemixIcon from './RemixIcon';
import {useLoginModalActions} from '@/state/hooks/auth/loginModal';
import {COMMENT_LOGIN_MESSAGE} from '@/content';
import {useMutation} from '@/kits/relay-kit/src';
import insertCommentMutation from '@/graphql/insert-comment';
import type {insertCommentMutation as CommentMutationType} from '@/graphql/__generated__/insertCommentMutation.graphql';
import {useAccount} from '@/kits/account-kit/src';
import {UsernameSuggestions} from './NewPostCard/NewPostCardDetails';
import {useCreateNotificationsForMentions} from './utils/useCreateNotificationsForMentions';
import {DraftsActions, useDrafts} from '@/state/hooks/drafts';

export default React.forwardRef(function NFTCommentBox(
  {
    tokenId,
    tokenNodeId,
    replyToCommentId,
    replyingTo,
    resetReplyTo,
    onAdd,
    allowComments,
    editing,
    onUpdate,
  }: {
    tokenId: string;
    tokenNodeId: string;
    replyToCommentId: string | null;
    replyingTo: string | null;
    resetReplyTo: () => void;
    onAdd: (commentId?: string, replyId?: string) => void;
    allowComments: boolean;
    editing: boolean;
    onUpdate?: () => void;
  },
  ref: ForwardedRef<HTMLInputElement>
) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  if (ref) {
    if (typeof ref === 'function') {
      ref(inputRef.current);
    } else if (inputRef.current) {
      ref.current = inputRef.current;
    } else {
      ref.current = null;
    }
  }

  const {getAccount} = useAccount();

  const user = getAccount();
  const identityId = user?.isLoggedIn ? user.identityId : undefined;
  const accountId = user?.isLoggedIn ? user.accountId : undefined;

  const {drafts} = useDrafts();

  const [comment, setComment] = React.useState<string>(() => {
    const draft = drafts.find(d => d.id === `new-comment-${tokenId}`);
    return draft?.content || '';
  });

  const [showEmojiPicker, setShowEmojiPicker] = React.useState<boolean>(false);
  const {darkMode} = useDarkMode();

  React.useEffect(() => {
    if (!editing) return;
    setComment(inputRef.current?.value || '');
  }, [inputRef.current?.value, editing]);

  const [insertComment, {loading}] = useMutation<CommentMutationType>(
    insertCommentMutation
  );

  const [triggerText, setTriggerText] = React.useState<string>('');

  React.useEffect(() => {
    const current = inputRef.current;
    if (!current) return;

    // if user is typing a username starting with @, we want to trigger the username suggestions, if the word breaks, we want to stop the suggestions
    const handleInput = (e: Event) => {
      const target = e.target as HTMLInputElement;
      const value = target.value;
      const currentWord = value
        .slice(0, target.selectionStart || 0)
        .split(' ')
        .pop();
      const word = currentWord?.split('\n').pop();
      if (word && word.includes('@')) {
        setTriggerText(word);
      } else {
        setTriggerText('');
      }
    };

    current.addEventListener('input', handleInput);

    return () => {
      current.removeEventListener('input', handleInput);
    };
  }, [comment, setComment]);

  const {createNotifications, setMentions} =
    useCreateNotificationsForMentions();

  return (
    <div className={clsx(styles.commentBox, !identityId && styles.disabled)}>
      {!allowComments ? (
        <div className={styles.commentsAreDisabled}>
          Comments are disabled on this post
        </div>
      ) : (
        <>
          {user.isLoggedIn && (
            <Button
              icon
              onClick={() => {
                setShowEmojiPicker(true);
              }}
              className={styles.emojiPickerToggle}
              tooltip="Emoji"
              tooltipSide="top"
            >
              <Menu
                side="top"
                open={showEmojiPicker}
                removePadding
                disabled={!user.isLoggedIn}
                element={close => (
                  <div className={styles.emojiPicker}>
                    <EmojiPickerComponent
                      theme={darkMode ? Theme.DARK : Theme.LIGHT || Theme.AUTO}
                      onEmojiClick={(emojiObject, e) => {
                        const emoji = emojiObject.emoji;
                        const cursorPosition = inputRef.current?.selectionStart;
                        if (cursorPosition) {
                          const newComment =
                            comment.slice(0, cursorPosition) +
                            emoji +
                            comment.slice(cursorPosition);
                          setComment(newComment);
                          inputRef.current.value = newComment;
                        } else {
                          setComment(comment + emoji);
                          if (inputRef.current) {
                            inputRef.current.value = comment + emoji;
                          }
                        }
                        inputRef.current?.focus();
                        close?.();
                      }}
                      lazyLoadEmojis
                      suggestedEmojisMode={SuggestionMode.FREQUENT}
                      height={400}
                      width={300}
                      previewConfig={{
                        showPreview: false,
                      }}
                      categories={emojiCategories}
                      searchDisabled={false}
                      autoFocusSearch={false}
                      emojiVersion={'12.1'}
                      skinTonesDisabled
                      emojiStyle={EmojiStyle.NATIVE}
                    />
                  </div>
                )}
                arrow={false}
                className={styles.emojiPickerPopper}
              >
                <div>
                  <RemixIcon icon="chat-smile-3-line" size={22} />
                </div>
              </Menu>
            </Button>
          )}
          <div className={styles.input}>
            {user.isLoggedIn ? (
              <>
                <Input
                  ref={inputRef}
                  defaultValue={comment}
                  placeholder={'Add a comment...'}
                  id="comment"
                  onBackspace={() => {
                    if (comment.length === 0) {
                      resetReplyTo();
                    }
                  }}
                  onChange={val => {
                    setComment(val);
                    DraftsActions.addOrUpdateDraft({
                      type: 'comment',
                      id: `new-comment-${tokenId}`,
                      content: val,
                    });
                  }}
                  type="text"
                  fullWidth
                  disabled={!user.isLoggedIn || loading}
                  label={replyingTo || undefined}
                  onEnter={() => {
                    if (editing) {
                      onUpdate && onUpdate();
                      setComment('');
                      DraftsActions.removeDraft(`new-comment-${tokenId}`);
                      return;
                    }
                    const trimmedComment = comment.trim();
                    if (trimmedComment.length === 0 || trimmedComment === '')
                      return;
                    insertComment({
                      variables: {
                        input: [
                          {
                            content: trimmedComment,
                            tezos_token_id: tokenId,
                            created_at: 'now',
                            updated_at: 'now',
                            account_id: accountId,
                            parent_comment_id: replyToCommentId || null,
                          },
                        ],
                      },
                      updater: store => {
                        const affectedMutation = store.getRootField(
                          'insertIntocommentsCollection'
                        );
                        const newAddedRecords =
                          affectedMutation?.getLinkedRecords('records');
                        const newAddedRecord = newAddedRecords?.[0];
                        const affectedPost = store.get(tokenNodeId);
                        const commentsCollection =
                          affectedPost?.getLinkedRecord('commentsCollection');
                        const count =
                          commentsCollection?.getValue('totalCount');
                        const edges =
                          commentsCollection?.getLinkedRecords('edges');
                        const newCount = count ? Number(count) + 1 : 1;
                        const newEdge = store.create(
                          `client:comment:${newAddedRecord?.getDataID()}`,
                          'comment'
                        );
                        newEdge?.setLinkedRecord(newAddedRecord, 'node');
                        count &&
                          commentsCollection?.setValue(newCount, 'totalCount');
                        edges?.push(newEdge);
                        commentsCollection?.setLinkedRecords(edges, 'edges');
                        affectedPost?.setLinkedRecord(
                          commentsCollection || null,
                          'commentsCollection'
                        );
                        store
                          .getRoot()
                          .setLinkedRecord(affectedPost || null, tokenNodeId);
                      },
                    }).then(res => {
                      DraftsActions.removeDraft(`new-comment-${tokenId}`);
                      const record =
                        res.insertIntocommentsCollection?.records?.[0];
                      if (!record) {
                        throw new Error(
                          'no record returned from insertPost mutation'
                        );
                      }
                      const usernames =
                        record?.content
                          ?.split(/((?:@)[a-zA-Z_]+)/)
                          .filter(s => s.length && s.startsWith('@'))
                          ?.map(s => s.slice(1)) || [];
                      if (usernames.length) {
                        setMentions(usernames);
                        setTimeout(() => {
                          createNotifications({
                            creator: record.accounts.id,
                            token_id: record.tezos_token_id,
                            comment_id: record.id,
                          });
                        }, 1000);
                      }
                      setComment('');
                      replyToCommentId && onAdd(replyToCommentId, record.id);
                    });
                  }}
                />
                <Button
                  text
                  accent
                  onClick={() => {
                    if (editing) {
                      onUpdate && onUpdate();
                      setComment('');
                      DraftsActions.removeDraft(`new-comment-${tokenId}`);
                      return;
                    }
                    const trimmedComment = comment.trim();
                    if (trimmedComment.length === 0 || trimmedComment === '')
                      return;
                    insertComment({
                      variables: {
                        input: [
                          {
                            content: trimmedComment,
                            tezos_token_id: tokenId,
                            created_at: 'now',
                            updated_at: 'now',
                            account_id: accountId,
                            parent_comment_id: replyToCommentId || null,
                          },
                        ],
                      },
                      updater: store => {
                        const affectedMutation = store.getRootField(
                          'insertIntocommentsCollection'
                        );
                        const newAddedRecords =
                          affectedMutation?.getLinkedRecords('records');
                        const newAddedRecord = newAddedRecords?.[0];
                        const affectedPost = store.get(tokenNodeId);
                        const commentsCollection =
                          affectedPost?.getLinkedRecord('commentsCollection');
                        const count =
                          commentsCollection?.getValue('totalCount');
                        const edges =
                          commentsCollection?.getLinkedRecords('edges');
                        const newCount = count ? Number(count) + 1 : 1;
                        const newEdge = store.create(
                          `client:comment:${newAddedRecord?.getDataID()}`,
                          'comment'
                        );
                        newEdge?.setLinkedRecord(newAddedRecord, 'node');
                        count &&
                          commentsCollection?.setValue(newCount, 'totalCount');
                        edges?.push(newEdge);
                        commentsCollection?.setLinkedRecords(edges, 'edges');
                        affectedPost?.setLinkedRecord(
                          commentsCollection || null,
                          'commentsCollection'
                        );
                        store
                          .getRoot()
                          .setLinkedRecord(affectedPost || null, tokenNodeId);
                      },
                    }).then(res => {
                      DraftsActions.removeDraft(`new-comment-${tokenId}`);
                      const record =
                        res.insertIntocommentsCollection?.records?.[0];
                      if (!record) {
                        throw new Error(
                          'no record returned from insertPost mutation'
                        );
                      }
                      const usernames =
                        record?.content
                          ?.split(/((?:@)[a-zA-Z_]+)/)
                          .filter(s => s.length && s.startsWith('@'))
                          ?.map(s => s.slice(1)) || [];
                      if (usernames.length) {
                        setMentions(usernames);
                        setTimeout(() => {
                          createNotifications({
                            creator: record.accounts.id,
                            token_id: record.tezos_token_id,
                            comment_id: record.id,
                          });
                        }, 1000);
                      }
                      setComment('');
                      replyToCommentId && onAdd(replyToCommentId, record.id);
                    });
                  }}
                  className={clsx(
                    styles.publish,
                    comment.length > 0 && styles.active
                  )}
                  disabled={loading || (comment.length === 0 && !editing)}
                >
                  <span>{!!editing ? 'Update' : 'Publish'}</span>
                </Button>
                {triggerText && triggerText.length > 3 && (
                  <Suspense fallback={null}>
                    <UsernameSuggestions
                      triggerText={triggerText}
                      addUsername={(username: string) => {
                        if (inputRef.current) {
                          const cursorPos =
                            inputRef.current.selectionStart || 0;
                          const textBeforeAll = comment.slice(0, cursorPos);
                          const textBefore = textBeforeAll.slice(
                            0,
                            textBeforeAll.length - triggerText.length
                          );
                          const textAfter = comment.slice(cursorPos);
                          const newText = `${textBefore}${username} ${textAfter}`;
                          inputRef.current.value = newText;
                          setComment(newText);
                          inputRef.current.focus();
                        }
                        setTriggerText('');
                      }}
                    />
                  </Suspense>
                )}
              </>
            ) : (
              <div className={styles.signInToComment}>
                <Button
                  accent
                  text
                  onClick={() => {
                    useLoginModalActions.setShowLoginModal(
                      true,
                      COMMENT_LOGIN_MESSAGE
                    );
                  }}
                >
                  <span>Sign in</span>
                </Button>{' '}
                to comment
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
});
