import {imperativePromise} from '@/utils/promises';

export const generateMusicCoverThumbnail = async ({cover}: {cover: File}) => {
  const {
    resolve: resolveFile,
    reject: rejectFile,
    promise: promiseFile,
  } = await imperativePromise<File>();
  const {
    resolve: resolveThumb,
    reject: rejectThumb,
    promise: promiseThumb,
  } = await imperativePromise<File>();

  const reader = new FileReader();

  reader.onload = async e => {
    const img = new Image();
    img.src = e.target?.result as string;
    img.onload = async () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) return rejectFile();
      canvas.width = 1000;
      canvas.height = 1000;
      ctx.fillStyle = '#000';

      // FIT IMAGE (BLACK BORDERS):
      // const aspect = img.width / img.height;
      // const width = 1000;
      // const height = width / aspect;
      // const xF = (1000 - width) / 2;
      // const yF = (1000 - height) / 2;
      // ctx.drawImage(img, xF, yF, width, height);

      // FILL IMAGE:
      const aspect = img.width / img.height;
      let width = 1000;
      let height = 1000;
      let xF = 0;
      let yF = 0;
      if (aspect > 1) {
        width = height * aspect;
        xF = (1000 - width) / 2;
      } else {
        height = width / aspect;
        yF = (1000 - height) / 2;
      }
      ctx.drawImage(img, xF, yF, width, height);

      canvas.toBlob(blob => {
        if (!blob) return rejectFile();
        // const file = new File([blob], 'image.png', {
        //   type: 'image/png',
        // });
        if (cover.type === 'image/gif') {
          const file = new File([blob], 'image.gif', {
            type: 'image/gif',
          });
          resolveFile(file);
          return;
        } else {
          const file = new File([blob], 'image.png', {
            type: 'image/png',
          });
          resolveFile(file);
        }
      });

      const thumbCanvas = document.createElement('canvas');
      const thumbCtx = thumbCanvas.getContext('2d');
      if (!thumbCtx) return rejectThumb();
      thumbCanvas.width = 300;
      thumbCanvas.height = 300;
      thumbCtx.fillStyle = '#000';

      // FIT IMAGE (BLACK BORDERS):
      // const thumbAspect = img.width / img.height;
      // const thumbWidth = 300;
      // const thumbHeight = thumbWidth / thumbAspect;
      // const xT = (300 - thumbWidth) / 2;
      // const yT = (300 - thumbHeight) / 2;
      // thumbCtx.drawImage(img, xT, yT, thumbWidth, thumbHeight);

      // FILL IMAGE:
      const thumbAspect = img.width / img.height;
      let thumbWidth = 300;
      let thumbHeight = 300;
      let xT = 0;
      let yT = 0;
      if (thumbAspect > 1) {
        thumbWidth = thumbHeight * thumbAspect;
        xT = (300 - thumbWidth) / 2;
      } else {
        thumbHeight = thumbWidth / thumbAspect;
        yT = (300 - thumbHeight) / 2;
      }
      thumbCtx.drawImage(img, xT, yT, thumbWidth, thumbHeight);

      thumbCanvas.toBlob(blob => {
        if (!blob) return rejectThumb();
        // const thumb = new File([blob], 'image.png', {
        //   type: 'image/png',
        // });
        if (cover.type === 'image/gif') {
          const thumb = new File([blob], 'image.gif', {
            type: 'image/gif',
          });
          resolveThumb(thumb);
          return;
        } else {
          const thumb = new File([blob], 'image.png', {
            type: 'image/png',
          });
          resolveThumb(thumb);
        }
      });
    };
  };

  reader.readAsDataURL(cover);

  return {
    file: await promiseFile,
    thumb: await promiseThumb,
  };
};

export const generateMusicFallbackThumbnail = async ({
  title,
}: {
  title: string;
}) => {
  const {
    resolve: resolveFile,
    reject: rejectFile,
    promise: promiseFile,
  } = await imperativePromise<File>();
  const {
    resolve: resolveThumb,
    reject: rejectThumb,
    promise: promiseThumb,
  } = await imperativePromise<File>();
  const canvas = document.createElement('canvas');
  canvas.width = 1000;
  canvas.height = 1000;
  const ctx = canvas.getContext('2d');
  if (!ctx) return;
  ctx.fillStyle = '#2c2c2c';
  ctx.fillRect(0, 0, 1000, 1000);
  ctx.fillStyle = '#959595';

  ctx.font = 'bold 220px Arial';
  ctx.fillStyle = '#959595';

  function splitLines(
    ctx: CanvasRenderingContext2D,
    text: string,
    maxWidth: number
  ) {
    const words = text.split(' ');
    const lines = [];
    let line = '';
    let lineCount = 0;
    let lineLength = 0;
    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' ';
      const metrics = ctx.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        lines.push(line);
        line = words[n] + ' ';
        lineCount++;
        lineLength = 0;
      } else {
        line = testLine;
        lineLength += words[n].length;
      }
      if (lineCount > 15 || lineLength > 890) {
        lines.push('...');
        break;
      }
    }
    if (lineCount < 15 && lineLength < 890) lines.push(line);
    return lines;
  }

  const truncatedTitle = insertSpaceInLongWords(title);
  // const truncatedTitle = address.replace(/(.{7})/g, '$1 ').trim(); // did not work

  const lines = splitLines(ctx, truncatedTitle, 890);
  lines.forEach((line, index) => {
    if (index > 3) return;
    ctx.fillText(line, 60, 220 + index * 220);
  });

  canvas.toBlob(blob => {
    if (!blob) {
      rejectFile(new Error('TODO'));
      return;
    }
    const file = new File([blob], 'image.png', {
      type: 'image/png',
    });
    resolveFile(file);
  });

  const resizedTo300 = document.createElement('canvas');
  resizedTo300.width = 300;
  resizedTo300.height = 300;

  const resizedTo300Ctx = resizedTo300.getContext('2d');
  if (!resizedTo300Ctx) return;
  resizedTo300Ctx.drawImage(canvas, 0, 0, 300, 300);

  resizedTo300.toBlob(blob => {
    if (!blob) {
      rejectThumb(new Error('TODO'));
      return;
    }

    const thumb = new File([blob], 'image.png', {
      type: 'image/png',
    });
    resolveThumb(thumb);
  });

  return Promise.all([promiseFile, promiseThumb]);
};

function insertSpaceInLongWords(text: string) {
  let result = '';
  let word = '';
  for (let char of text) {
    if (char === ' ') {
      result += insertSpacesInWord(word);
      result += char;
      word = '';
    } else {
      word += char;
      if (word.length >= 6) {
        result += insertSpacesInWord(word);
        word = '';
      }
    }
  }
  result += insertSpacesInWord(word);
  return result;
}
function insertSpacesInWord(word: string) {
  let formattedWord = '';
  for (let i = 0; i < word.length; i++) {
    formattedWord += word[i];
    if (i > 0 && (i + 1) % 6 === 0) {
      formattedWord += ' ';
    }
  }
  return formattedWord;
}
