/**
 * @generated SignedSource<<dac556e785dec8b9e811594c1526e381>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FilterIs = "NOT_NULL" | "NULL" | "%future added value";
export type enum_denomination_type = "usd" | "xtz" | "%future added value";
export type enum_token_type = "audio" | "image" | "link" | "post" | "video" | "%future added value";
export type tezos_tokensUpdateInput = {
  account_id?: string | null;
  allow_collection?: boolean | null;
  allow_comments?: boolean | null;
  artifact_uri?: string | null;
  artist_address?: string | null;
  attributes?: string | null;
  contract_address?: string | null;
  created_at?: string | null;
  description?: string | null;
  display_uri?: string | null;
  editions?: string | null;
  editions_burned?: string | null;
  editions_minted?: string | null;
  is_purchasable?: boolean | null;
  metadata_uri?: string | null;
  mime_type?: string | null;
  minted_at?: string | null;
  minter_address?: string | null;
  minting_platform?: string | null;
  price?: string | null;
  price_denomination?: enum_denomination_type | null;
  purchase_price?: number | null;
  symbol?: string | null;
  thumbnail_uri?: string | null;
  title?: string | null;
  token_id?: string | null;
  type?: enum_token_type | null;
  updated_at?: string | null;
};
export type tezos_tokensFilter = {
  account_id?: BigIntFilter | null;
  allow_collection?: BooleanFilter | null;
  allow_comments?: BooleanFilter | null;
  and?: ReadonlyArray<tezos_tokensFilter> | null;
  artifact_uri?: StringFilter | null;
  artist_address?: StringFilter | null;
  contract_address?: StringFilter | null;
  created_at?: DatetimeFilter | null;
  description?: StringFilter | null;
  display_uri?: StringFilter | null;
  editions?: BigIntFilter | null;
  editions_burned?: BigIntFilter | null;
  editions_minted?: BigIntFilter | null;
  id?: BigIntFilter | null;
  is_purchasable?: BooleanFilter | null;
  metadata_uri?: StringFilter | null;
  mime_type?: StringFilter | null;
  minted_at?: DatetimeFilter | null;
  minter_address?: StringFilter | null;
  minting_platform?: StringFilter | null;
  nodeId?: IDFilter | null;
  not?: tezos_tokensFilter | null;
  or?: ReadonlyArray<tezos_tokensFilter> | null;
  price?: BigIntFilter | null;
  price_denomination?: enum_denomination_typeFilter | null;
  purchase_price?: FloatFilter | null;
  symbol?: StringFilter | null;
  thumbnail_uri?: StringFilter | null;
  title?: StringFilter | null;
  token_id?: BigIntFilter | null;
  type?: enum_token_typeFilter | null;
  updated_at?: DatetimeFilter | null;
};
export type BigIntFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type DatetimeFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type StringFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  ilike?: string | null;
  in?: ReadonlyArray<string> | null;
  iregex?: string | null;
  is?: FilterIs | null;
  like?: string | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
  regex?: string | null;
  startsWith?: string | null;
};
export type BooleanFilter = {
  eq?: boolean | null;
  is?: FilterIs | null;
};
export type FloatFilter = {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number> | null;
  is?: FilterIs | null;
  lt?: number | null;
  lte?: number | null;
  neq?: number | null;
};
export type enum_token_typeFilter = {
  eq?: enum_token_type | null;
  in?: ReadonlyArray<enum_token_type> | null;
  is?: FilterIs | null;
  neq?: enum_token_type | null;
};
export type enum_denomination_typeFilter = {
  eq?: enum_denomination_type | null;
  in?: ReadonlyArray<enum_denomination_type> | null;
  is?: FilterIs | null;
  neq?: enum_denomination_type | null;
};
export type IDFilter = {
  eq?: string | null;
};
export type updateTezosTokenMutation$variables = {
  filter: tezos_tokensFilter;
  input: tezos_tokensUpdateInput;
};
export type updateTezosTokenMutation$data = {
  readonly updatetezos_tokensCollection: {
    readonly records: ReadonlyArray<{
      readonly editions: string;
      readonly editions_burned: string | null;
      readonly editions_minted: string | null;
      readonly id: string;
      readonly is_purchasable: boolean;
      readonly nodeId: string;
      readonly price_denomination: enum_denomination_type;
      readonly purchase_price: number | null;
    }>;
  };
};
export type updateTezosTokenMutation = {
  response: updateTezosTokenMutation$data;
  variables: updateTezosTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      },
      {
        "kind": "Variable",
        "name": "set",
        "variableName": "input"
      }
    ],
    "concreteType": "tezos_tokensUpdateResponse",
    "kind": "LinkedField",
    "name": "updatetezos_tokensCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "tezos_tokens",
        "kind": "LinkedField",
        "name": "records",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "purchase_price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price_denomination",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "is_purchasable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "editions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "editions_minted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "editions_burned",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateTezosTokenMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateTezosTokenMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2b5a8d57c736c6c7615ecf5ed918d538",
    "id": null,
    "metadata": {},
    "name": "updateTezosTokenMutation",
    "operationKind": "mutation",
    "text": "mutation updateTezosTokenMutation(\n  $input: tezos_tokensUpdateInput!\n  $filter: tezos_tokensFilter!\n) {\n  updatetezos_tokensCollection(set: $input, filter: $filter) {\n    records {\n      nodeId\n      id\n      purchase_price\n      price_denomination\n      is_purchasable\n      editions\n      editions_minted\n      editions_burned\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eeb6aeb7839c87d90822dfba92b6a7e0";

export default node;
