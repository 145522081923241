import React, {Suspense} from 'react';
import styles from '@/view/styles/components/FollowButton.module.scss';
import Button from './Button';
import clsx from 'clsx';
import RemixIcon from '@/view/components/RemixIcon';
import {useLoginModalActions} from '@/state/hooks/auth/loginModal';
import {FOLLOW_LOGIN_MESSAGE} from '@/content';
import {graphql, useLazyLoadQuery, useMutation} from '@/kits/relay-kit/src';
import insertFollowerMutation from '@/graphql/insert-follower';
import removeFollowerMutation from '@/graphql/remove-follower';
import type {insertFollowerMutation as InsertFollowerMutationType} from '@/graphql/__generated__/insertFollowerMutation.graphql';
import type {removeFollowerMutation as RemoveFollowerMutationType} from '@/graphql/__generated__/removeFollowerMutation.graphql';
import {useAccount} from '@/kits/account-kit/src';
import insertEventMutation from '@/graphql/insert-event';
import type {insertEventMutation as InsertEventMutationType} from '@/graphql/__generated__/insertEventMutation.graphql';
import removeEventMutation from '@/graphql/remove-event';
import type {removeEventMutation as RemoveEventMutationType} from '@/graphql/__generated__/removeEventMutation.graphql';
import Skeleton from './Skeleton';
import {ErrorBoundary, useErrorBoundary} from 'react-error-boundary';
import type {FollowButtonContentQuery as FollowButtonContentQueryType} from './__generated__/FollowButtonContentQuery.graphql';

export default function FollowButton({
  hideIcon = false,
  iconOnly = false,
  iconSmall = false,
  id,
  username,
  textOnly = false,
  className,
  hideWhenFollowing,
  status,
}: {
  hideIcon?: boolean;
  iconOnly?: boolean;
  iconSmall?: boolean;
  id: string;
  username?: string;
  textOnly?: boolean;
  className?: string;
  hideWhenFollowing?: boolean;
  status?: boolean;
}) {
  const type = () => {
    if (iconSmall) return 'small-icon';
    if (iconOnly) return 'icon';
    if (textOnly) return 'text';
    return 'default';
  };
  return (
    <ErrorBoundary
      fallback={
        <FollowButtonError id={id} type={type()} className={className} />
      }
    >
      <Suspense
        fallback={<FollowButtonSkeleton type={type()} className={className} />}
      >
        <FollowButtonContent
          hideIcon={hideIcon}
          iconOnly={iconOnly}
          id={id}
          username={username}
          textOnly={textOnly}
          className={className}
          hideWhenFollowing={hideWhenFollowing}
          status={status}
        />
      </Suspense>
    </ErrorBoundary>
  );
}

const FollowButtonContentQuery = graphql`
  query FollowButtonContentQuery(
    $myIdentityId: BigInt!
    $theirIdentityId: BigInt!
  ) {
    followsCollection(
      filter: {
        follower_id: {eq: $myIdentityId}
        followee_id: {eq: $theirIdentityId}
      }
    ) {
      edges {
        node {
          id
          nodeId
        }
      }
    }
  }
`;

const FollowButtonContent = ({
  hideIcon = false,
  iconOnly = false,
  id,
  username,
  textOnly = false,
  className,
  hideWhenFollowing,
  status,
}: {
  hideIcon?: boolean;
  iconOnly?: boolean;
  id: string;
  username?: string;
  textOnly?: boolean;
  className?: string;
  hideWhenFollowing?: boolean;
  status?: boolean;
}) => {
  const {getAccount} = useAccount();

  const user = getAccount();
  const identityId = user?.isLoggedIn ? user.identityId : undefined;
  const accountId = user?.isLoggedIn ? user.accountId : undefined;

  const {
    data: followingResults,
    retry,
    isLoading,
  } = useLazyLoadQuery<FollowButtonContentQueryType>(
    FollowButtonContentQuery,
    {
      myIdentityId: identityId || '',
      theirIdentityId: id,
    },
    {
      skip: !identityId || identityId === '' || !id || id === '',
    }
  );

  const following = followingResults?.followsCollection?.edges.length !== 0;
  const [buttonText, setButtonText] = React.useState<string>('Following');

  const [insertFollower, {loading: insertingFollower}] =
    useMutation<InsertFollowerMutationType>(insertFollowerMutation);

  const [insertEvent, {loading: insertingEvent}] =
    useMutation<InsertEventMutationType>(insertEventMutation);

  const [removeEvent, {loading: removingEvent}] =
    useMutation<RemoveEventMutationType>(removeEventMutation);

  const [removeFollower, {loading: removingFollower}] =
    useMutation<RemoveFollowerMutationType>(removeFollowerMutation);

  function handleRemoveFollower() {
    const followRecordId =
      followingResults?.followsCollection?.edges?.[0]?.node?.id;
    removeEvent({
      variables: {
        filter: {
          type: {eq: 'follow'},
          account_id: {eq: accountId},
          follow_id: {eq: followRecordId},
        },
      },
      onError() {
        retry();
      },
      onCompleted() {
        removeFollower({
          variables: {
            filter: {
              follower_id: {eq: identityId},
              followee_id: {eq: id},
            },
          },
          updater(store) {
            const storeData = store.get(
              `client:root:followsCollection(filter:{"followee_id":{"eq":"${id}"},"follower_id":{"eq":"${identityId}"}})`
            );
            const followingList = store.get(
              `client:root:__followingListPaginated_followsCollection_connection(filter:{"follower_id":{"eq":"${identityId}"}})`
            );
            const totalCountProxy = store.get(
              `client:root:followsCollection(filter:{"follower_id":{"eq":"${identityId}"}})`
            );
            const edges = storeData?.getLinkedRecords('edges');
            if (edges) {
              edges.forEach(edge => {
                store.delete(edge.getDataID());
              });
              storeData?.setLinkedRecords([], 'edges');
            }
            followingList?.invalidateRecord();
            if (totalCountProxy) {
              const totalCount: number = Number(
                totalCountProxy.getValue('totalCount')
              );
              if (totalCount > 0) {
                totalCountProxy.setValue(totalCount - 1, 'totalCount');
              }
            }
          },
          onError() {
            retry();
          },
        });
      },
    });
  }

  function handleInsertFollower() {
    insertFollower({
      variables: {
        input: [
          {
            follower_id: identityId,
            followee_id: id,
            updated_at: 'now',
            created_at: 'now',
          },
        ],
      },
      updater(store) {
        const inserted = store
          ?.getRootField('insertIntofollowsCollection')
          ?.getLinkedRecords('records')?.[0];
        const followingList = store.get(
          `client:root:__followingListPaginated_followsCollection_connection(filter:{"follower_id":{"eq":"${identityId}"}})`
        );
        const totalCountProxy = store.get(
          `client:root:followsCollection(filter:{"follower_id":{"eq":"${identityId}"}})`
        );
        if (inserted) {
          const storeData = store.get(
            `client:root:followsCollection(filter:{"followee_id":{"eq":"${id}"},"follower_id":{"eq":"${identityId}"}})`
          );
          const newEdge = store.create(
            `client:root:followsCollection(filter:{"followee_id":{"eq":"${id}"},"follower_id":{"eq":"${identityId}"}}):edges:0`,
            'followsEdge'
          );
          newEdge.setLinkedRecord(inserted, 'node');
          storeData?.setLinkedRecords([newEdge], 'edges');
        }
        followingList?.invalidateRecord();
        if (totalCountProxy) {
          const totalCount: number = Number(
            totalCountProxy.getValue('totalCount')
          );
          totalCountProxy.setValue(totalCount + 1, 'totalCount');
        }
      },
      onError() {
        retry();
      },
      onCompleted(response) {
        const addedRecord = response.insertIntofollowsCollection?.records?.[0];
        const followRecordId = addedRecord?.id;
        insertEvent({
          variables: {
            input: [
              {
                type: 'follow',
                account_id: accountId,
                follow_id: followRecordId,
                created_at: 'now',
                updated_at: 'now',
              },
            ],
          },
        });
      },
    });
  }

  if (status) {
    if (id === identityId) {
      return <p className={styles.status}>You</p>;
    }
    return <p className={styles.status}>{following ? 'You follow' : null}</p>;
  }

  if (id === identityId) return null;

  if (hideWhenFollowing && following) return null;

  return user.isLoggedIn && identityId ? (
    <Button
      outlined={!iconOnly && following && !textOnly}
      filled={!iconOnly && !following && !textOnly}
      accent={!iconOnly && !textOnly && !following}
      gradient={!iconOnly && !following && !textOnly}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (
          isLoading ||
          identityId === id ||
          insertingFollower ||
          removingFollower ||
          insertingEvent ||
          removingEvent
        ) {
          return;
        }
        if (following) {
          handleRemoveFollower();
        } else {
          handleInsertFollower();
        }
      }}
      icon={iconOnly}
      className={clsx(
        styles.followButton,
        following && styles.followingButton,
        following && buttonText === 'Unfollow' && styles.unfollowButton,
        hideIcon && styles.hideIcon,
        textOnly && styles.textOnly,
        !iconOnly && !hideIcon && styles.withIcon,
        className
      )}
      onMouseEnter={() => {
        if (following) {
          setButtonText('Unfollow');
        }
      }}
      onMouseLeave={() => {
        if (following) {
          setButtonText('Following');
        }
      }}
      data-disabled={
        isLoading ||
        identityId === id ||
        insertingFollower ||
        removingFollower ||
        insertingEvent ||
        removingEvent
      }
      tooltip={following ? 'Unfollow' : 'Follow'}
      tooltipSide="top"
      data-buttontext={buttonText}
    >
      {iconOnly &&
        (following === undefined ? (
          <RemixIcon icon="loader-4-line" size={24} className={styles.spin} />
        ) : following ? (
          <RemixIcon
            icon={
              buttonText === 'Unfollow'
                ? 'close-circle-fill'
                : 'checkbox-circle-fill'
            }
            size={24}
          />
        ) : (
          <RemixIcon icon="add-circle-fill" size={24} />
        ))}
      {!hideIcon &&
        !iconOnly &&
        (following === undefined ? (
          <RemixIcon icon="loader-4-line" size={16} className={styles.spin} />
        ) : following ? (
          <RemixIcon
            icon={
              following && buttonText === 'Unfollow'
                ? 'user-unfollow-line'
                : 'user-follow-line'
            }
            size={16}
          />
        ) : (
          <RemixIcon icon="user-add-line" size={16} />
        ))}
      {!iconOnly &&
        (following === undefined
          ? 'Loading...'
          : following
          ? buttonText
          : 'Follow')}
    </Button>
  ) : (
    <Button
      gradient
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        useLoginModalActions.setShowLoginModal(
          true,
          FOLLOW_LOGIN_MESSAGE(username)
        );
      }}
      icon={iconOnly}
      className={clsx(
        styles.followButton,
        hideIcon && styles.hideIcon,
        textOnly && styles.textOnly,
        className
      )}
      tooltip="Follow"
      tooltipSide="top"
    >
      {iconOnly && <RemixIcon icon="add-circle-fill" size={24} />}
      {!iconOnly && !hideIcon && <RemixIcon icon="user-add-line" size={16} />}
      {!iconOnly && 'Follow'}
    </Button>
  );
};

export const FollowButtonSkeleton = ({
  type = 'default',
  className,
}: {
  type?: 'small-icon' | 'icon' | 'text' | 'default';
  className?: string;
}) => {
  return (
    <Skeleton
      animation="wave"
      variant="rect"
      borderRadius={type === 'text' ? 4 : 99}
      width={
        type === 'small-icon'
          ? 18
          : type === 'icon'
          ? 24
          : type === 'text'
          ? 40
          : 90
      }
      height={
        type === 'small-icon'
          ? 18
          : type === 'icon'
          ? 24
          : type === 'text'
          ? 15
          : 32
      }
      className={className}
    />
  );
};

export const FollowButtonError = ({
  id,
  type = 'default',
  className,
}: {
  id: string;
  type?: 'small-icon' | 'icon' | 'text' | 'default';
  className?: string;
}) => {
  const {resetBoundary} = useErrorBoundary();

  const {getAccount} = useAccount();
  const user = getAccount();
  const identityId = user?.isLoggedIn ? user.identityId : undefined;

  const {retry} = useLazyLoadQuery<FollowButtonContentQueryType>(
    FollowButtonContentQuery,
    {
      myIdentityId: identityId || '',
      theirIdentityId: id,
    },
    {
      skip: true,
    }
  );

  return (
    <div
      style={{
        width: type === 'small-icon' ? 18 : type === 'icon' ? 24 : 90,
        height: type === 'small-icon' ? 18 : type === 'icon' ? 24 : 32,
        borderRadius: type === 'text' ? 4 : 99,
      }}
      className={clsx(styles.errorSkeleton, className)}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        resetBoundary();
        retry();
      }}
    >
      {(type === 'icon' || type === 'small-icon') && (
        <RemixIcon icon="user-line" size={12} />
      )}
      {type !== 'small-icon' && type !== 'icon' && <span>Error</span>}
      <div>
        <RemixIcon icon="refresh-line" size={16} />
      </div>
    </div>
  );
};
