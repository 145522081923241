import React from 'react';
import styles from '@/view/styles/components/UserProfileItem.module.scss';
import {trim} from '@/utils/trim';
import {Avatar} from './Avatar';
import HoverCard from './HoverCard/HoverCard';
import {MemoIcon} from './utils/MemoChildren';
import dynamic from 'next/dynamic';
import {getImageUrl} from '@/utils/conversions/conversions';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import Tooltip from './Tooltip';
import {useAccount} from '@/kits/account-kit/src';
import {ItemModalActions} from '@/state/hooks/itemModal';
import {useRouter} from 'next/router';
import {UserProfileItemFragment$key} from './__generated__/UserProfileItemFragment.graphql';
const FollowButton = dynamic(() => import('./FollowButton'), {
  ssr: false,
});
const UserHoverCard = dynamic(
  () => import('@/view/components/HoverCard/UserHoverCard'),
  {
    ssr: false,
  }
);

const UserProfileItemFragment = graphql`
  fragment UserProfileItemFragment on accounts {
    id
    nodeId
    type
    identities {
      id
      nodeId
      profilesCollection {
        edges {
          node {
            id
            nodeId
            username
            name
            avatar_uri
            tezos_profilesCollection {
              edges {
                node {
                  id
                  nodeId
                  logo
                }
              }
            }
          }
        }
      }
    }
    magic_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
        }
      }
    }
    beacon_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
        }
      }
    }
    teztok_accountsCollection {
      edges {
        node {
          id
          nodeId
          public_key_hash
        }
      }
    }
    ...UserHoverCardFragment
  }
`;

export default function UserProfileItem({
  prefix,
  user: userKey,
  hideIcon,
  hideText,
  hideUsername,
  className,
  showFollowButton,
  badge,
}: {
  prefix?: React.ReactNode;
  user: UserProfileItemFragment$key;
  hideIcon?: boolean;
  hideText?: boolean;
  hideUsername?: boolean;
  className?: string;
  showFollowButton?: boolean;
  badge?: React.ReactNode;
}) {
  return (
    <DefaultUserProfileItem
      user={userKey}
      className={className}
      hideIcon={hideIcon}
      hideText={hideText}
      prefix={prefix}
      hideUsername={hideUsername}
      showFollowButton={showFollowButton}
      badge={badge}
    />
  );
}

const DefaultUserProfileItem = ({
  prefix,
  user: userKey,
  hideIcon,
  hideText,
  hideUsername = undefined,
  className,
  showFollowButton,
  badge,
}: {
  prefix?: React.ReactNode;
  user: UserProfileItemFragment$key | null | undefined;
  hideIcon?: boolean;
  hideText?: boolean;
  hideUsername?: boolean;
  className?: string;
  showFollowButton?: boolean;
  badge?: React.ReactNode;
}) => {
  const router = useRouter();

  const {getAccount} = useAccount();
  const myUser = getAccount();

  const loggedIn = myUser.isLoggedIn;

  const user = useFragment(UserProfileItemFragment, userKey);

  const userProfile = user?.identities.profilesCollection?.edges?.[0]?.node;

  const name = userProfile?.name || undefined;

  const username = userProfile?.username ? userProfile.username : undefined;

  const isNonDNSUser = user?.type === 'teztok';

  return user ? (
    <div className={styles.by}>
      {prefix && prefix}
      <div
        onClick={e => {
          e.stopPropagation();
          ItemModalActions.setItem(false, null, null);
          router.push(
            `/${
              username !== undefined
                ? username
                : user?.type === 'magic'
                ? user?.magic_accountsCollection?.edges?.[0]?.node
                    ?.public_key_hash || ''
                : user?.type === 'beacon'
                ? user?.beacon_accountsCollection?.edges?.[0]?.node
                    ?.public_key_hash || ''
                : user?.teztok_accountsCollection?.edges?.[0]?.node
                    ?.public_key_hash ||
                  '' ||
                  user.identities.id
            }`
          );
        }}
      >
        <HoverCard
          trigger={
            <div className={className} data-has-avatar={!hideIcon}>
              <div className={styles.byAvatar}>
                {!hideIcon &&
                  (!!(
                    userProfile?.avatar_uri ||
                    userProfile?.tezos_profilesCollection?.edges?.[0]?.node
                      ?.logo
                  ) ? (
                    <Avatar
                      src={
                        getImageUrl(
                          userProfile?.avatar_uri ||
                            userProfile?.tezos_profilesCollection?.edges?.[0]
                              ?.node?.logo ||
                            ''
                        ) || ''
                      }
                      alt={username}
                    >
                      <MemoIcon Component={'user-line'} size={14} />
                    </Avatar>
                  ) : (
                    <span>
                      <MemoIcon Component={'user-line'} size={14} />
                    </span>
                  ))}
                {showFollowButton && loggedIn && (
                  <FollowButton
                    id={user.identities.id}
                    username={username || name}
                    iconOnly
                    iconSmall
                    className={styles.iconFollowButton}
                  />
                )}
              </div>
              {!hideText && (
                <div>
                  <span>
                    {username ||
                      name ||
                      trim(
                        user?.type === 'magic'
                          ? user?.magic_accountsCollection?.edges?.[0]?.node
                              ?.public_key_hash || ''
                          : user?.type === 'beacon'
                          ? user?.beacon_accountsCollection?.edges?.[0]?.node
                              ?.public_key_hash || ''
                          : user?.teztok_accountsCollection?.edges?.[0]?.node
                              ?.public_key_hash || ''
                      )}
                    {isNonDNSUser && (
                      <Tooltip
                        text="This user is not on DNS"
                        className="non-dns-user-tag"
                      >
                        <span />
                      </Tooltip>
                    )}
                    {badge && <span className={styles.badge}>{badge}</span>}
                  </span>
                  {username && hideUsername === false && (
                    <span>@{username}</span>
                  )}
                </div>
              )}
            </div>
          }
        >
          <UserHoverCard user={user} />
        </HoverCard>
      </div>
    </div>
  ) : (
    <></>
  );
};
