import clsx from 'clsx';
import React from 'react';
import sx from '@/view/styles/components/Skeleton.module.scss';

export default function Skeleton({
  variant,
  width,
  height,
  animation,
  className,
  borderRadius,
  styles,
}: {
  variant: 'text' | 'rect' | 'circle';
  width?: string | number;
  height?: string | number;
  animation?: 'pulse' | 'wave';
  className?: string;
  borderRadius?: string | number;
  styles?: React.CSSProperties;
}) {
  return (
    <div
      className={clsx(
        sx.skeleton,
        sx[variant],
        animation ? sx[animation] : sx.pulse,
        className
      )}
      style={{
        width,
        height,
        minWidth: width,
        minHeight: height,
        borderRadius,
        ...(variant === 'circle' && {
          height: `${height || width}${
            typeof height === 'number' || typeof height === 'number' ? 'px' : ''
          }`,
          minHeight: `${height || width}${
            typeof height === 'number' || typeof height === 'number' ? 'px' : ''
          }`,
          paddingBottom: `${height || width}${
            typeof height === 'number' || typeof height === 'number' ? 'px' : ''
          }`,
          width: `${height || width}${
            typeof height === 'number' || typeof height === 'number' ? 'px' : ''
          }`,
          minWidth: `${height || width}${
            typeof height === 'number' || typeof height === 'number' ? 'px' : ''
          }`,
        }),
        ...styles,
      }}
    />
  );
}
