/**
 * @generated SignedSource<<8dbca3fa543b57b4b370d274d0446d38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteTokenDialogLazyLoadTokenQuery$variables = {
  id: string;
};
export type DeleteTokenDialogLazyLoadTokenQuery$data = {
  readonly tezos_tokensCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly nodeId: string;
        readonly " $fragmentSpreads": FragmentRefs<"DeleteTokenTokenFragment">;
      };
    }>;
  } | null;
};
export type DeleteTokenDialogLazyLoadTokenQuery = {
  response: DeleteTokenDialogLazyLoadTokenQuery$data;
  variables: DeleteTokenDialogLazyLoadTokenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "public_key_hash",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteTokenDialogLazyLoadTokenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "tezos_tokensConnection",
        "kind": "LinkedField",
        "name": "tezos_tokensCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "tezos_tokensEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "tezos_tokens",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DeleteTokenTokenFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteTokenDialogLazyLoadTokenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "tezos_tokensConnection",
        "kind": "LinkedField",
        "name": "tezos_tokensCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "tezos_tokensEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "tezos_tokens",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "editions",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "editions_minted",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "thumbnail_uri",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "accounts",
                    "kind": "LinkedField",
                    "name": "accounts",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "identities",
                        "kind": "LinkedField",
                        "name": "identities",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "profilesConnection",
                            "kind": "LinkedField",
                            "name": "profilesCollection",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "profilesEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "profiles",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      (v3/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "username",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "magic_accountsConnection",
                        "kind": "LinkedField",
                        "name": "magic_accountsCollection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "magic_accountsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "magic_accounts",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": (v4/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "beacon_accountsConnection",
                        "kind": "LinkedField",
                        "name": "beacon_accountsCollection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "beacon_accountsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "beacon_accounts",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": (v4/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "teztok_accountsConnection",
                        "kind": "LinkedField",
                        "name": "teztok_accountsCollection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "teztok_accountsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "teztok_accounts",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": (v4/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dc6b6530aa8e15ec0602b0f2bf4231be",
    "id": null,
    "metadata": {},
    "name": "DeleteTokenDialogLazyLoadTokenQuery",
    "operationKind": "query",
    "text": "query DeleteTokenDialogLazyLoadTokenQuery(\n  $id: BigInt!\n) {\n  tezos_tokensCollection(filter: {id: {eq: $id}}) {\n    edges {\n      node {\n        id\n        nodeId\n        ...DeleteTokenTokenFragment\n      }\n    }\n  }\n}\n\nfragment DeleteTokenTokenFragment on tezos_tokens {\n  id\n  nodeId\n  title\n  editions\n  editions_minted\n  thumbnail_uri\n  accounts {\n    id\n    nodeId\n    type\n    identities {\n      id\n      nodeId\n      profilesCollection {\n        edges {\n          node {\n            id\n            nodeId\n            username\n          }\n        }\n      }\n    }\n    magic_accountsCollection {\n      edges {\n        node {\n          id\n          nodeId\n          public_key_hash\n        }\n      }\n    }\n    beacon_accountsCollection {\n      edges {\n        node {\n          id\n          nodeId\n          public_key_hash\n        }\n      }\n    }\n    teztok_accountsCollection {\n      edges {\n        node {\n          id\n          nodeId\n          public_key_hash\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "149589ece7160f61a6a98e01afdb4011";

export default node;
