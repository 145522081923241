import React, {Suspense, useCallback} from 'react';
import styles from '@/view/styles/components/ShoppingCart/ShoppingCartDialog.module.scss';
import Router from 'next/router';
import BottomSheet from '@/view/components/BottomSheet/BottomSheet';
import ShoppingCart from './ShoppingCart';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';

export const ShoppingCartDialog = ({
  trigger,
  defaultOpen,
  onClose,
}: {
  trigger: React.ReactNode;
  defaultOpen?: boolean;
  onClose?: (isPopState?: boolean) => void;
}) => {
  const {isMobile} = useBreakpoint();
  return (
    <div>
      <BottomSheet
        dialogOnDesktop
        defaultOpen={defaultOpen}
        trigger={trigger}
        className={styles.bottomSheet}
        handleOnClose={(isPopState?: boolean) => {
          if (onClose) {
            onClose(isPopState);
          }
          // if (!isPopState) {
          //   Router.back();
          // }
        }}
      >
        {useCallback(
          (closeDialog: () => void) => (
            <Suspense>
              <ShoppingCart
                onClose={(isPopState: boolean) => {
                  // if (!isPopState) {
                  //   Router.back();
                  // }
                  if (isMobile) {
                    Router.back();
                  }
                  closeDialog();
                  onClose?.(isPopState);
                }}
                className={styles.modal}
              />
            </Suspense>
          ),
          [isMobile, onClose]
        )}
      </BottomSheet>
    </div>
  );
};
