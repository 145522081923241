import {create} from 'zustand';

type LoginModalFunctions = {
  setShowLoginModal: (
    showLoginModal: boolean,
    loginModalTitle?: string
  ) => void;
};

type LoginModalState = {
  showLoginModal: boolean;
  loginModalTitle?: string;
};

const defaultState: LoginModalState = {
  showLoginModal: false,
  loginModalTitle: undefined,
};

export const useLoginModal = create<LoginModalState>((set, get) => {
  return {
    ...defaultState,
  };
});

export const useLoginModalActions: LoginModalFunctions = {
  setShowLoginModal(showLoginModal, loginModalTitle) {
    if (showLoginModal) {
      useLoginModal.setState({
        showLoginModal,
        loginModalTitle,
      });
    } else {
      useLoginModal.setState({
        showLoginModal,
      });
      const timeout = setTimeout(() => {
        useLoginModal.setState({
          loginModalTitle: undefined,
        });
      }, 50);
      return () => clearTimeout(timeout);
    }
  },
};
