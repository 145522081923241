import {getBalance} from '@/kits/beacon-auth-kit/src';
import {create} from 'zustand';

type TezBalanceFunctions = {
  updateBalance: (address: string) => void;
};

type TezBalanceState = {
  balance: string | null;
  loading: boolean;
};

const defaultState: TezBalanceState = {
  balance: null,
  loading: false,
};

export const useTezBalance = create<TezBalanceState>((set, get) => {
  return {
    ...defaultState,
  };
});

export const TezBalanceActions: TezBalanceFunctions = {
  updateBalance: address => {
    useTezBalance.setState({loading: true});
    getTezBalance(address);
  },
};

let MAX_RETRIES = 3;

const getTezBalance = async (address: string) => {
  if (address) {
    getBalance(address)
      .then(b => {
        if (b) {
          useTezBalance.setState({
            balance: parseFloat(Number(b).toFixed(3)).toString(),
            loading: false,
          });
        }
      })
      .catch(e => {
        console.error(e);
        for (let i = 0; i < MAX_RETRIES; i++) {
          getBalance(address)
            .then(b => {
              if (b) {
                useTezBalance.setState({
                  balance: parseFloat(Number(b).toFixed(3)).toString(),
                  loading: false,
                });
              }
            })
            .catch(e => {
              console.error(e);
            });
        }
      });
  }
};
