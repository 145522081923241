import {fromString, toString} from 'uint8arrays';
import {map, range, reduce} from './generator';

export function encodeTezosMessage(text: string, spaces: number = 0) {
  const michelinePrefix = '05';
  const stringPrefix = '01';
  const prefix = 'Tezos Signed Message: ';
  const spacesString = reduce(
    map(range(spaces), () => '\u2800'),
    (acc, current) => acc + current,
    ''
  );
  const len = (
    '0000000' + (prefix.length + 2 * spaces + text.length).toString(16)
  ).slice(-8);

  text = toString(fromString(prefix + spacesString + text, 'utf-8'), 'hex');
  return michelinePrefix + stringPrefix + len + text;
}

export function convertToMutez(amount: number) {
  return Math.floor(amount * 1_000_000).toFixed(0);
}

export function convertFromMutez(mutez: string | number) {
  return (Number(mutez) / 1_000_000)
    .toFixed(6)
    .replace(/0*$/, '')
    .replace(/\.$/, '');
}
