import * as config from '../config.json';

const {baseURL} = config;
const assetsUri = `${baseURL}/assets`;

export async function getAssetDetail(asset: string = 'tezos') {
  const res = await fetch(`${assetsUri + '/' + asset}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await res.json();
}
