import React from 'react';
import Button from '@/view/components/Button';
import RemixIcon from '@/view/components/RemixIcon';
import {useLoginModalActions} from '@/state/hooks/auth/loginModal';
import {ADD_TO_COLLECTION_LOGIN_MESSAGE, REPOST_LOGIN_MESSAGE} from '@/content';
import {useMutation} from '@/kits/relay-kit/src';
import {ToastActions} from '@/state/hooks/toasts';
import {v4 as uuid} from 'uuid';
import {useAccount} from '@/kits/account-kit/src';
import insertEventMutation from '@/graphql/insert-event';
import type {insertEventMutation as EventMutationType} from '@/graphql/__generated__/insertEventMutation.graphql';
import updateShareMutation from '@/graphql/update-share';
import type {updateShareMutation as ShareMutationType} from '@/graphql/__generated__/updateShareMutation.graphql';
import Menu from '../../Menu';
import Router from 'next/router';
import {useCreateStatusActions} from '@/state/hooks/createStatus';
import Tooltip from '../../Tooltip';
import AddToCollectionDialog from '../../AddToCollection/AddToCollectionDialog';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import {ItemModalActions} from '@/state/hooks/itemModal';

export const AddToListButton = ({
  tokenId,
  tokenType,
  savedCount,
}: {
  tokenId: string;
  tokenType: string;
  savedCount: number;
}) => {
  const {getAccount} = useAccount();
  const user = getAccount();

  const loggedIn = user.isLoggedIn;

  const {isMobile} = useBreakpoint();

  return (
    <Tooltip text="Save">
      {!loggedIn ? (
        <div
          onClick={async e => {
            e.preventDefault();
            e.stopPropagation();
            useLoginModalActions.setShowLoginModal(
              true,
              ADD_TO_COLLECTION_LOGIN_MESSAGE
            );
            return;
          }}
        >
          <RemixIcon icon="play-list-add-line" size={24} />
          <span>{savedCount}</span>
        </div>
      ) : (
        <AddToCollectionDialog
          tokenId={tokenId}
          tokenType={tokenType}
          trigger={
            <div
              onClick={e => {
                if (isMobile) {
                  Router.push(
                    {pathname: Router.pathname, query: {...Router.query}},
                    Router.asPath + '#',
                    {
                      shallow: true,
                      scroll: false,
                    }
                  );
                }
              }}
            >
              <RemixIcon icon="play-list-add-line" size={24} />
              <span>{savedCount}</span>
            </div>
          }
        />
      )}
    </Tooltip>
  );
};

export const RepostButton = ({
  tokenId,
  repostCount = 0,
  tooltipPosition = 'top',
  iconSize,
  label,
}: {
  tokenId: string;
  repostCount: number;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
  iconSize?: number;
  label: number | undefined;
}) => {
  const {getAccount} = useAccount();
  const user = getAccount();
  const loggedIn = user.isLoggedIn;
  const accountId = user.isLoggedIn ? user.accountId : undefined;

  const [insertEvent, {loading: reposting}] =
    useMutation<EventMutationType>(insertEventMutation);

  const [updateRepostCount] =
    useMutation<ShareMutationType>(updateShareMutation);

  const [startRepostCooldown, setStartRepostCooldown] = React.useState(false);

  React.useEffect(() => {
    if (startRepostCooldown) {
      const timeout = setTimeout(() => {
        setStartRepostCooldown(false);
      }, 10000);
      return () => clearTimeout(timeout);
    }
  }, [startRepostCooldown]);

  return (
    <Menu
      options={[
        {
          icon: <RemixIcon icon="repeat-line" size={16} />,
          label: 'Repost',
          value: 'repost',
          disabled: reposting,
          onClick(e) {
            e.stopPropagation();
            e.preventDefault();
            if (!loggedIn) {
              useLoginModalActions.setShowLoginModal(
                true,
                REPOST_LOGIN_MESSAGE
              );
              return;
            }
            if (!accountId) return;
            if (reposting) return;
            insertEvent({
              variables: {
                input: [
                  {
                    created_at: 'now',
                    updated_at: 'now',
                    type: 'repost',
                    tezos_token_id: tokenId,
                    account_id: accountId,
                  },
                ],
              },
              updater: store => {
                const eventsCollection = store.get(
                  `client:root:eventsCollection(first:10,orderBy:{"created_at":"DescNullsLast"})`
                );
                eventsCollection?.invalidateRecord();
              },
              onCompleted(response) {
                updateRepostCount({
                  variables: {
                    filter: {
                      tezos_token_id: {
                        eq: tokenId,
                      },
                    },
                    input: {
                      repost_count: (repostCount + 1).toString(),
                    },
                  },
                });
                setStartRepostCooldown(true);
                const toastId = uuid();
                ToastActions.addToast(
                  toastId,
                  `Reposted!`,
                  '',
                  'info',
                  undefined,
                  undefined,
                  undefined,
                  'repeat-line'
                );
              },
              onError(error) {
                console.error('insertEvent error', error.message);
              },
            });
          },
          tooltip: 'Repost',
        },
        {
          icon: <RemixIcon icon="edit-line" size={16} />,
          label: 'Quote post',
          value: 'quote-post',
          disabled: reposting,
          onClick(e) {
            e.stopPropagation();
            e.preventDefault();
            if (!loggedIn) {
              useLoginModalActions.setShowLoginModal(
                true,
                REPOST_LOGIN_MESSAGE
              );
              return;
            }
            if (!accountId) return;
            ItemModalActions.setItem(false, null, null);
            const route = Router.asPath.includes('/item/')
              ? '/'
              : Router.asPath;
            useCreateStatusActions.setPreviousRoute(route);
            Router.push(`/create?quote=${tokenId}`);
            useCreateStatusActions.setType('post');
          },
          tooltip: 'Quote post',
        },
      ]}
    >
      <div>
        <Button
          tooltip="Repost"
          tooltipSide={tooltipPosition}
          tooltipOffset={10}
          text
          onClick={e => {
            e.preventDefault();
          }}
          disabled={reposting}
        >
          <RemixIcon icon="repeat-line" size={iconSize || 24} />
          {label && <span>{label}</span>}
        </Button>
      </div>
    </Menu>
  );
};
