import {
  Environment,
  FetchFunction,
  Network,
  RecordSource,
  SelectorStoreUpdater,
  Store,
  commitLocalUpdate,
} from 'relay-runtime';

import config from '@/kits/supabase-kit/config.json';

let authenticatedJwt: string | null = null;

// Though its type is FetchFunction, it is not not simply for read/fetch operations.
const query: FetchFunction = async (operation, variables) => {
  try {
    const response = await fetch(`${config.supabaseUrl}/graphql/v1`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        apikey: config.supabaseKey,
        Authorization: authenticatedJwt ? `Bearer ${authenticatedJwt}` : '',
      },
      body: JSON.stringify({
        query: operation.text,
        variables,
      }),
    });

    return await response.json();
  } catch (err) {
    console.error('GraphQL Fetch Query Failure:', err);
    return {};
  }
};

const network = Network.create(query);
const store = new Store(new RecordSource());

export {graphql} from 'relay-runtime';
export type {GraphQLResponse} from 'relay-runtime';
export {
  RelayEnvironmentProvider,
  useFragment,
  useLazyLoadQuery,
  useMutation,
  commitLocalUpdate,
  useQuery,
  fetchQuery,
  loadQuery,
  usePagination,
} from 'relay-hooks';

export {ConnectionHandler} from 'react-relay';

export const relay = new Environment({
  network,
  store,
  getDataID: node => node.nodeId,
  missingFieldHandlers: [
    {
      handle: (field, record, args, store) => {
        if (field.name === 'node' && 'nodeId' in args) {
          return args.nodeId;
        }

        return undefined;
      },
      kind: 'linked',
    },
  ],
});

export const commitLocalStoreUpdate = (updater: SelectorStoreUpdater) => {
  return commitLocalUpdate(relay, updater);
};

export function setAuthTokenInRelay(token: string | null) {
  authenticatedJwt = token;
}
