import React, {PropsWithChildren} from 'react';
import styles from '@/view/styles/components/Button.module.scss';
import Tooltip from './Tooltip';

export default function Button({
  children,
  filled,
  accent,
  gradient,
  outlined,
  icon,
  text,
  onClick,
  className,
  disabled,
  wide,
  fullWidth,
  style,
  onMouseEnter,
  onMouseLeave,
  tooltip,
  tooltipSide,
  tooltipOffset,
  tooltipSticky,
  tooltipShortcut,
  tooltipSeparator,
  tooltipArrow,
  tooltipHidden,
  tooltipClassName,
  tooltipForceOpen,
  ...props
}: {
  children: React.ReactNode;
  filled?: boolean;
  accent?: boolean;
  gradient?: boolean;
  outlined?: boolean;
  icon?: boolean;
  text?: boolean;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
  disabled?: boolean;
  wide?: boolean;
  fullWidth?: boolean;
  style?: React.CSSProperties;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  tooltip?: React.ReactNode;
  tooltipSide?: 'top' | 'right' | 'bottom' | 'left';
  tooltipOffset?: number;
  tooltipSticky?: boolean;
  tooltipShortcut?: React.ReactNode | React.ReactNode[];
  tooltipSeparator?: React.ReactNode;
  tooltipArrow?: boolean;
  tooltipHidden?: boolean;
  tooltipClassName?: string;
  tooltipForceOpen?: boolean;
  props?: Record<string, unknown>;
}) {
  const classNames = () => {
    const classNames = [];
    classNames.push(`${styles.root}`);
    if (filled) {
      classNames.push(`${styles.filled}`);
    }
    if (accent) {
      classNames.push(`${styles.accent}`);
    }
    if (gradient) {
      classNames.push(`${styles.gradient}`);
    }
    if (outlined) {
      classNames.push(`${styles.outlined}`);
    }
    if (icon) {
      classNames.push(`${styles.icon}`);
    }
    if (text) {
      classNames.push(`${styles.text}`);
    }
    if (disabled) {
      classNames.push(`${styles.disabled}`);
    }
    if (wide) {
      classNames.push(`${styles.wide}`);
    }
    if (fullWidth) {
      classNames.push(`${styles.fullWidth}`);
    }
    if (className) {
      classNames.push(`${className}`);
    }

    return classNames.join(' ');
  };

  return (
    <button
      disabled={disabled}
      type="button"
      className={classNames()}
      onClick={disabled ? undefined : onClick}
      style={style}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-variant={
        filled
          ? 'filled'
          : outlined
          ? 'outlined'
          : text
          ? 'text'
          : icon
          ? 'icon'
          : gradient
          ? 'gradient'
          : accent
          ? 'accent'
          : 'default'
      }
      {...props}
    >
      {tooltip ? (
        <Tooltip
          text={tooltip}
          side={tooltipSide}
          offset={tooltipOffset}
          shortcut={tooltipShortcut}
          separator={tooltipSeparator}
          defaultOpen={tooltipForceOpen}
          contentClassName={tooltipClassName}
          arrow={tooltipArrow}
          hidden={tooltipHidden}
        >
          {children}
        </Tooltip>
      ) : (
        children
      )}
    </button>
  );
}
