import '@/view/styles/globals.css';
import type {AppContext, AppProps} from 'next/app';
import Head from 'next/head';
import {Providers} from '@/view/Providers';
import App from 'next/app';
import styles from '@/view/styles/theme.module.scss';
import {useDarkMode} from '@/controller/hooks/darkMode';
import {useEffect} from 'react';
import {PRIMARY_DOMAIN, THEME_TOGGLE} from '@/config';
import 'remixicon/fonts/remixicon.css';
import {useAccount} from '@/kits/account-kit/src';
import {setCookieVersion} from '@/view/providers/LoginModal';
import {useNotifications} from '@/state/hooks/notifications';
import {useRouter} from 'next/router';
import {useShoppingCart} from '@/state/hooks/shoppingCart';
import {useJWT} from '@/state/hooks/jwt';
import {setAuthTokenInRelay} from '@/kits/relay-kit/src';
import {ErrorBoundary} from 'react-error-boundary';
import Button from '@/view/components/Button';
import AppWideErrorBoundary from '@/view/components/AppWideErrorBoundary';

function MyApp({Component, pageProps}: AppProps) {
  const {darkMode} = useDarkMode();
  const {getAccount} = useAccount();
  const user = getAccount();
  const {token} = useJWT();

  useEffect(() => {
    if (user.isLoggedIn && !token) {
      /* Triggers on logout
      ToastActions.addToast(
        uuid(),
        'Uh oh! Something went wrong.',
        'Please log in for the best experience.',
        'failure',
        () => {
          logoutAccount();
          window.location.reload();
        },
        'Logout'
      ); */
    } else if (user.isLoggedIn && token) {
      setAuthTokenInRelay(token);
    }
  }, [token, user.isLoggedIn]);

  const router = useRouter();

  const {content} = useShoppingCart();

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      body.style.backgroundColor = darkMode
        ? '#000'
        : THEME_TOGGLE === false
        ? '#000'
        : '#fff';
      body.className = darkMode
        ? styles.themeDark
        : THEME_TOGGLE === false
        ? styles.themeDark
        : styles.themeLight;
    }
  }, [darkMode]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const loader = document.getElementsByClassName('globalLoader');
      const allLoaders = Array.from(loader);
      allLoaders.forEach(loader => {
        loader.remove();
      });
    }
  }, []);

  useEffect(() => {
    if (user.isLoggedIn === false) {
      setCookieVersion();
    }
  }, [user.isLoggedIn]);

  const unreadCount = useNotifications(
    state => state.unreadCount + state.unreadMessageCount
  );

  const cartItemCount = content.length;

  useEffect(() => {
    const root = document.documentElement;
    if (!root) return;
    let previousScrollPosition = 0;
    let currentScrollPosition = 0;

    const controlDirection = () => {
      currentScrollPosition = window.scrollY;
      if (window.scrollY < 54.2) {
        root.style.setProperty('--mobileTopBarTranslate', '0px');
        root.style.setProperty('--mobileNavBarTranslate', '0px');
      }
      if (previousScrollPosition - currentScrollPosition > 0) {
        root.style.setProperty('--mobileTopBarTranslate', '0px'); // NOTE: uncomment if we want mobile topbar to show up as soon as you scroll up
        root.style.setProperty('--mobileNavBarTranslate', '0px'); // NOTE: uncomment if we want mobile topbar to show up as soon as you scroll up
      } else {
        if (cartItemCount > 0) {
          root.style.setProperty('--mobileTopBarTranslate', '0px');
        } else {
          root.style.setProperty('--mobileTopBarTranslate', '192px');
        }
        root.style.setProperty('--mobileNavBarTranslate', '192px');
      }
      previousScrollPosition = currentScrollPosition;
    };

    window.addEventListener('scroll', controlDirection, {passive: true});
    return () => {
      window.removeEventListener('scroll', controlDirection);
    };
  }, [cartItemCount]);

  useEffect(() => {
    const root = document.documentElement;
    if (!root) return;

    if (router.pathname.startsWith('/inbox')) {
      root.style.setProperty('--mobileTopBarTranslate', '52px');
      root.style.setProperty('--mobileNavBarTranslate', '52px');
    } else {
      root.style.setProperty('--mobileTopBarTranslate', '0px');
      root.style.setProperty('--mobileNavBarTranslate', '0px');
    }
  }, [router.pathname]);

  return (
    <>
      <Head>
        <title key="title">{`DNS ${
          unreadCount > 0 ? `(${unreadCount} new)` : ''
        }`}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link
          key="favicon"
          rel="icon"
          href={
            unreadCount > 0
              ? '/favicons/favicon_android_48_unread.png'
              : '/favicons/favicon_android_48.png'
          }
        />
        <link rel="apple-touch-icon" href="/favicons/favicon_apple.png" />
        <link
          rel="shortcut icon"
          type="image/png"
          href="/favicons/favicon_android.png"
        />
        <link rel="manifest" href="/app.webmanifest" />
        <link
          rel="icon"
          type="image/png"
          href="/favicon_android.png"
          sizes="192x192"
        />
        {/* Open Graph Protocol Tags */}
        <meta property="og:site_name" content="DNS" />
        <meta
          property="og:url"
          content={`https://${PRIMARY_DOMAIN}`}
          key="og-url"
        />
        <meta property="og:type" content="website" key="og-type" />
        <meta property="og:title" content="DNS" key="og-title" />
        <meta
          property="og:description"
          content="DNS.xyz - Where everyone is a creator."
          key="og-description"
        />
        <meta property="og:image" content="/dns-avatar.webp" key="og-image" />
      </Head>
      <div className={styles.root}>
        <ErrorBoundary
          fallback={<AppWideErrorBoundary />}
          onError={e => {
            console.debug(e);
          }}
        >
          <Providers>
            <main className={styles.main}>
              <Component {...pageProps} />
            </main>
          </Providers>
        </ErrorBoundary>
      </div>
    </>
  );
}

export default MyApp;

MyApp.getInitialProps = async (ctx: AppContext) => {
  return App.getInitialProps(ctx);
};
