import React, {Suspense, useCallback} from 'react';
import styles from '@/view/styles/components/ManageItem/DeleteToken/DeleteTokenDialog.module.scss';
import BottomSheet from '@/view/components/BottomSheet/BottomSheet';
import DeleteToken from './DeleteToken';
import {graphql, useLazyLoadQuery} from '@/kits/relay-kit/src';
import type {DeleteTokenDialogLazyLoadTokenQuery as DeleteTokenDialogLazyLoadTokenQueryType} from './__generated__/DeleteTokenDialogLazyLoadTokenQuery.graphql';

export const DeleteTokenDialog = ({
  trigger,
  tokenId,
  defaultOpen,
  onClose,
}: {
  trigger: React.ReactNode;
  tokenId?: string;
  defaultOpen?: boolean;
  onClose?: () => void;
}) => {
  return (
    <div>
      <BottomSheet
        defaultOpen={defaultOpen}
        dialogOnDesktop
        trigger={trigger}
        className={styles.bottomSheet}
        handleOnClose={(isPopState?: boolean) => {
          onClose?.();
          // if (!isPopState) {
          //   Router.back();
          // }
        }}
        asModal={false}
      >
        {useCallback(
          (closeDialog: () => void) => (
            <Suspense>
              <LazyLoadDeleteToken
                tokenId={tokenId}
                closeDialog={closeDialog}
                onClose={onClose}
              />
            </Suspense>
          ),
          [tokenId, onClose]
        )}
      </BottomSheet>
    </div>
  );
};

const DeleteTokenDialogLazyLoadTokenQuery = graphql`
  query DeleteTokenDialogLazyLoadTokenQuery($id: BigInt!) {
    tezos_tokensCollection(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          nodeId
          ...DeleteTokenTokenFragment
        }
      }
    }
  }
`;

const LazyLoadDeleteToken = ({
  tokenId,
  closeDialog,
  onClose,
}: {
  tokenId?: string;
  closeDialog: () => void;
  onClose?: () => void;
}) => {
  const {data} = useLazyLoadQuery<DeleteTokenDialogLazyLoadTokenQueryType>(
    DeleteTokenDialogLazyLoadTokenQuery,
    {
      id: tokenId || '',
    },
    {
      skip: !tokenId || tokenId === '',
    }
  );

  const token = data?.tezos_tokensCollection?.edges?.[0]?.node;

  return (
    token && (
      <DeleteToken
        tokenKey={token}
        className={styles.modal}
        closeDialog={() => {
          closeDialog();
          onClose?.();
        }}
      />
    )
  );
};
