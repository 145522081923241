/**
 * @generated SignedSource<<06bb8d9837064c299dc05ea251cfc0d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentContentOwnerFragment$data = {
  readonly id: string;
  readonly identities: {
    readonly profilesCollection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly avatar_uri: string | null;
          readonly username: string | null;
        };
      }>;
    } | null;
  };
  readonly nodeId: string;
  readonly " $fragmentSpreads": FragmentRefs<"UserProfileItemFragment">;
  readonly " $fragmentType": "CommentContentOwnerFragment";
};
export type CommentContentOwnerFragment$key = {
  readonly " $data"?: CommentContentOwnerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentContentOwnerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentContentOwnerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nodeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "identities",
      "kind": "LinkedField",
      "name": "identities",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "profilesConnection",
          "kind": "LinkedField",
          "name": "profilesCollection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "profilesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "profiles",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "username",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "avatar_uri",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserProfileItemFragment"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "d622f9e5e836210099909d01cb2af8b5";

export default node;
