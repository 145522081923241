import React from 'react';
import {Command} from 'cmdk';
import styles from '@/view/styles/components/Actions/Actions.module.scss';
import Image from '../Image';
import CMDk from '../CMDk';
import {useActionsMenu} from '@/state/hooks/actionsMenu';
import KeyboardShortcut from '../KeyboardShortcut';
import {ActionType, CMDkActionsType} from '@/view/types/types';
import {getImageUrl} from '@/utils/conversions/conversions';
import clsx from 'clsx';
import {graphql, useLazyLoadQuery} from '@/kits/relay-kit/src';
import ReactionChips from '../ReactionChips';
import {ReactionTo} from '@/controller/hooks/eventRollup';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import type {ActionsTezosTokenQuery as ActionsTezosTokenQueryType} from './__generated__/ActionsTezosTokenQuery.graphql';

const ActionsTezosTokenQuery = graphql`
  query ActionsTezosTokenQuery($id: BigInt!) {
    tezos_tokensCollection(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          nodeId
          tokens_reactionsCollection {
            edges {
              node {
                id
                nodeId
                reaction
                accounts {
                  id
                  nodeId
                  identities {
                    profilesCollection {
                      edges {
                        node {
                          id
                          nodeId
                          username
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function Actions({
  close,
  image,
  title,
  subtitle,
  actions,
  type,
}: {
  close: () => void;
  image?: string | React.ReactNode;
  title: string;
  subtitle?: string | React.ReactNode;
  actions: ActionType[][];
  type: CMDkActionsType;
}) {
  const [actionsPage, setActionsPage] = React.useState(
    () => useActionsMenu.getState().actionsView?.page || 0
  );

  const view = useActionsMenu().actionsView;

  const {data} = useLazyLoadQuery<ActionsTezosTokenQueryType>(
    ActionsTezosTokenQuery,
    {
      id: view?.type === 'token' ? view.id : '',
    },
    {
      skip: view?.type !== 'token',
    }
  );

  const reactionsOnView = React.useMemo(() => {
    if (type === undefined) return undefined;
    if (type !== 'token' && type !== 'comment') {
      return undefined;
    }
    if (
      (type === 'token' && actionsPage !== 3) ||
      (type === 'comment' && actionsPage !== 1)
    ) {
      return undefined;
    }

    return (
      <div className={styles.reactionChips}>
        <ReactionChips
          reactions={
            data?.tezos_tokensCollection?.edges?.[0]?.node?.tokens_reactionsCollection?.edges?.map(
              r => ({
                byUsername:
                  r.node.accounts.identities.profilesCollection?.edges?.[0]
                    ?.node.username || 'Unknown',
                by: r.node.accounts.id || '',
                id: r.node.id || '',
                node: r.node,
                reaction: r.node.reaction,
              })
            ) || []
          }
          id={data?.tezos_tokensCollection?.edges?.[0]?.node?.id || ''}
          nodeId={data?.tezos_tokensCollection?.edges?.[0]?.node?.nodeId || ''}
          type={ReactionTo.Post}
          max={5}
          asModal={false}
          label={''}
          showPicker
        />
      </div>
    );
  }, [actionsPage, data?.tezos_tokensCollection?.edges, type]);

  return (
    <div className={styles.root}>
      <CMDk
        className={styles.cmdk}
        header={
          <div className={styles.header}>
            <div className={styles.details}>
              {image &&
                (typeof image === 'string' ? (
                  <Image
                    src={getImageUrl(image)}
                    alt={title}
                    dynamic
                    onError={e => {
                      e.currentTarget.src = '/poster.svg';
                    }}
                  />
                ) : (
                  image
                ))}
              <div>
                <h4>{title}</h4>
                {subtitle}
              </div>
            </div>
            {view?.type === 'token' ? reactionsOnView : null}
          </div>
        }
        // search={!reactionsOnView}
        // updateSearch={setSearch}
      >
        {actions[actionsPage || 0]?.map((item, i) => {
          if (item.override) {
            return (
              <Command.Item
                className={clsx(styles.item, item.className)}
                key={item.key + '-' + i}
                value={item.key}
                onSelect={() => {
                  if (item.setPage !== undefined) {
                    setActionsPage(item.setPage);
                    return;
                  }
                  if (item.doNotClose !== true) {
                    close();
                  }
                  item.onClick && item.onClick();
                }}
              >
                {item.element}
              </Command.Item>
            );
          }
          return (
            <ActionItem
              key={(item.key || item.title) + '-' + i}
              itemKey={item.key + '-' + i}
              icon={item.icon}
              title={item.title}
              onClick={() => {
                if (item.setPage !== undefined) {
                  setActionsPage(item.setPage);
                  return;
                }
                item.onClick?.();
              }}
              className={item.className}
              onClose={item.doNotClose ? undefined : close}
              shortcut={item.shortcut}
            />
          );
        })}
      </CMDk>
    </div>
  );
}

const ActionItem = ({
  title,
  itemKey,
  icon,
  onClick,
  onClose,
  shortcut,
  className,
}: {
  title: string;
  itemKey?: string;
  icon: React.ReactNode;
  onClick?: (() => void) | undefined;
  onClose?: () => void;
  shortcut?: React.ReactNode;
  className?: string;
}) => {
  const {isMobile} = useBreakpoint();
  return (
    <Command.Item
      className={clsx(styles.item, className)}
      key={itemKey + title}
      value={title + itemKey}
      onSelect={() => {
        onClick && onClick();
        onClose?.();
      }}
    >
      <div>
        {icon}
        <div className={styles.info}>
          <div>{title}</div>
        </div>
        {shortcut && (!isMobile || typeof shortcut !== 'string') && (
          <KeyboardShortcut text={shortcut} />
        )}
      </div>
    </Command.Item>
  );
};
