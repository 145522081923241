/**
 * @generated SignedSource<<e86ed319bb13b41adba2fb71f215e1c7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentContentFragment$data = {
  readonly accounts: {
    readonly id: string;
    readonly nodeId: string;
    readonly " $fragmentSpreads": FragmentRefs<"CommentContentOwnerFragment">;
  };
  readonly comments_reactionsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accounts: {
          readonly id: string;
          readonly identities: {
            readonly profilesCollection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly username: string | null;
                };
              }>;
            } | null;
          };
          readonly " $fragmentSpreads": FragmentRefs<"CommentContentIsReplyToFragment">;
        };
        readonly id: string;
        readonly reaction: string;
      };
    }>;
  } | null;
  readonly content: string | null;
  readonly created_at: string;
  readonly id: string;
  readonly nodeId: string;
  readonly " $fragmentType": "CommentContentFragment";
};
export type CommentContentFragment$key = {
  readonly " $data"?: CommentContentFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentContentFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentContentFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CommentContentOwnerFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "comments_reactionsConnection",
      "kind": "LinkedField",
      "name": "comments_reactionsCollection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "comments_reactionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "comments_reactions",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reaction",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "accounts",
                  "kind": "LinkedField",
                  "name": "accounts",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "identities",
                      "kind": "LinkedField",
                      "name": "identities",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "profilesConnection",
                          "kind": "LinkedField",
                          "name": "profilesCollection",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "profilesEdge",
                              "kind": "LinkedField",
                              "name": "edges",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "profiles",
                                  "kind": "LinkedField",
                                  "name": "node",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "username",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "CommentContentIsReplyToFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "comments",
  "abstractKey": null
};
})();

(node as any).hash = "0f6eb487832193f874b8b502fe431de0";

export default node;
