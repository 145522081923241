import React from 'react';
import styles from '@/view/styles/components/PostActions/PostActions.module.scss';
import PostCollectActions from './blocs/PostCollectActions';
import {tezosTokensFragment$data} from '@/graphql/__generated__/tezosTokensFragment.graphql';
import RemixIcon from '../RemixIcon';
import Tooltip from '../Tooltip';
import ReactionChips from '../ReactionChips';
import {ReactionTo} from '@/controller/hooks/eventRollup';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import ManageItem from '../ManageItem/ManageItem';
import {ActionsMenuActions} from '@/state/hooks/actionsMenu';
import {getImageUrl} from '@/utils/conversions/conversions';
import OwnersDialog from '../CMDk/Owners/OwnersDialog';
import Router from 'next/router';
import {AddToListButton, RepostButton} from './blocs/PostSocialActions';
import clsx from 'clsx';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import {PostActionsStatsTokenFragment$key} from './__generated__/PostActionsStatsTokenFragment.graphql';
import {PostActionsTokenFragment$key} from './__generated__/PostActionsTokenFragment.graphql';

const PostActionsTokenFragment = graphql`
  fragment PostActionsTokenFragment on tezos_tokens {
    id
    nodeId
    allow_comments
    title
    description
    thumbnail_uri
    tokens_reactionsCollection {
      edges {
        node {
          id
          reaction
          accounts {
            id
            identities {
              profilesCollection {
                edges {
                  node {
                    username
                  }
                }
              }
            }
          }
        }
      }
    }
    mime_type
    type
    ...PostCollectActionsFragment
    ...ManageItemTokenFragment
  }
`;

export default function PostActions({
  tokenKey,
  collect,
}: {
  tokenKey: PostActionsTokenFragment$key;
  collect: boolean;
}) {
  const token = useFragment(PostActionsTokenFragment, tokenKey);
  const {isMobile} = useBreakpoint();

  const isTextPost =
    token.type === 'post' && token.mime_type.startsWith('text');

  return (
    <div className={styles.actionsWrapper}>
      <div className={styles.actionsBox}>
        <ReactionChips
          id={token.id}
          nodeId={token.nodeId}
          reactions={
            token.tokens_reactionsCollection?.edges.map(edge => ({
              by: edge.node.accounts.id,
              byUsername:
                edge.node.accounts.identities.profilesCollection?.edges?.[0]
                  ?.node.username || 'Unknown',
              id: edge.node.id,
              node: edge.node,
              reaction: edge.node.reaction,
            })) || []
          }
          type={ReactionTo.Post}
          max={isMobile ? 3 : 5}
        />
        {collect && <PostCollectActions tokenKey={token} />}
      </div>
      {isTextPost && <ManageItem tokenKey={token} />}
    </div>
  );
}

const PostActionsStatsTokenFragment = graphql`
  fragment PostActionsStatsTokenFragment on tezos_tokens {
    id
    nodeId
    allow_comments
    title
    description
    thumbnail_uri
    editions
    editions_minted
    type
    metadata_uri
    artifact_uri
    accounts {
      id
      nodeId
      identities {
        id
        nodeId
        profilesCollection {
          edges {
            node {
              id
              nodeId
              username
            }
          }
        }
      }
    }
    tokens_reactionsCollection {
      edges {
        node {
          id
          reaction
          accounts {
            id
            nodeId
            identities {
              id
              nodeId
              profilesCollection {
                edges {
                  node {
                    id
                    nodeId
                    username
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PostStats = ({
  reactionsCount,
  commentCount,
  repostCount,
  shareCount,
  savedCount,
  editionsText,
  view = 'compact',
  tokenKey,
  setShowComments,
  className,
}: {
  reactionsCount?: number;
  commentCount?: number;
  repostCount?: number;
  shareCount?: number;
  savedCount?: number;
  editionsText?: string;
  view?: 'default' | 'compact';
  tokenKey: PostActionsStatsTokenFragment$key;
  setShowComments?: () => void;
  className?: string;
}) => {
  const token = useFragment(PostActionsStatsTokenFragment, tokenKey);
  return (
    <div className={clsx(styles.stats, className)} data-view={view}>
      {(reactionsCount !== undefined ||
        commentCount !== undefined ||
        repostCount !== undefined ||
        shareCount !== undefined) && (
        <div
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {reactionsCount !== undefined && (
            <ReactionChips
              showPicker
              type={ReactionTo.Post}
              reactions={
                token.tokens_reactionsCollection?.edges?.map(edge => ({
                  node: edge.node,
                  by: edge.node.accounts.id,
                  byUsername:
                    edge.node.accounts.identities.profilesCollection?.edges?.[0]
                      ?.node.username || 'Unknown',
                  id: edge.node.id,
                  reaction: edge.node.reaction,
                })) || []
              }
              id={token.id}
              nodeId={token.nodeId}
              hideChips
              label={reactionsCount}
            />
          )}
          {commentCount !== undefined && (
            <Tooltip text="Comments">
              <div
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (token.allow_comments === false) return;
                  setShowComments && setShowComments();
                }}
                data-disabled={token.allow_comments === false}
              >
                <RemixIcon icon="chat-4-line" size={24} />
                <span>{commentCount || '0'}</span>
              </div>
            </Tooltip>
          )}
          {repostCount !== undefined && (
            <RepostButton
              tokenId={token.id}
              repostCount={repostCount}
              label={repostCount}
            />
          )}
          {shareCount !== undefined && (
            <Tooltip text="Share">
              <div
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  ActionsMenuActions.openActionsMenu({
                    type: 'token',
                    id: token.id,
                    page: 1,
                    title: token.title,
                    description: token.description || undefined,
                    image: getImageUrl(token.thumbnail_uri || ''),
                    shareCount: shareCount || 0,
                    nodeId: token.nodeId,
                    artifactUri: token?.artifact_uri || undefined,
                    creator: {
                      id: token?.accounts.identities.id || '',
                      username:
                        token?.accounts.identities?.profilesCollection
                          ?.edges?.[0]?.node?.username || undefined,
                    },
                    editions: token?.editions || undefined,
                    editionsMinted: token?.editions_minted || undefined,
                    metadataUri: token?.metadata_uri || undefined,
                    tokenType: token?.type || undefined,
                  });
                }}
              >
                <RemixIcon icon="share-forward-line" size={24} />
                <span>{shareCount || '0'}</span>
              </div>
            </Tooltip>
          )}
          {savedCount !== undefined && (
            <AddToListButton
              tokenId={token.id}
              tokenType={token.type.toLowerCase()}
              savedCount={savedCount}
            />
          )}
        </div>
      )}
      {editionsText !== undefined && (
        <div
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <OwnersDialog
            trigger={
              <div>
                <Tooltip text="Owners">
                  <div
                    data-has-editions={true}
                    className={styles.ownersList}
                    onClick={() => {
                      Router.push(
                        {pathname: Router.pathname, query: {...Router.query}},
                        Router.asPath + '#',
                        {
                          shallow: true,
                          scroll: false,
                        }
                      );
                    }}
                  >
                    <span>
                      {editionsText === '0/0 available' ? (
                        'Sold out'
                      ) : (
                        <>
                          <span>{editionsText.split('/')[0]}</span>/
                          {editionsText.split('/')[1]}
                        </>
                      )}
                    </span>
                  </div>
                </Tooltip>
              </div>
            }
            tokenId={token.id}
          />
        </div>
      )}
    </div>
  );
};
