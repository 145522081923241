import React, {useEffect} from 'react';
import Tooltip from './Tooltip';
import {updateTimeSince} from '@/utils/timer';
import {format} from 'timeago.js';

export default function TimestampTooltip({
  date,
  className,
}: {
  date: string;
  className?: string;
}) {
  const [age, setAge] = React.useState('');

  useEffect(() => {
    updateTimeSince(() => {
      setAge(
        format((date ? new Date(date).getTime() : undefined) || Date.now())
      );
    });

    return () => {
      setAge('');
    };
  }, [date]);

  return (
    <Tooltip
      className={className}
      text={
        new Date(date).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }) +
        ' at ' +
        new Date(date).toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        })
      }
      side="top"
    >
      <span>{age}</span>
    </Tooltip>
  );
}
