import React from 'react';
import styles from '@/view/styles/providers/LoginModalWrapper.module.scss';
import {PropsWithChildren} from 'react';
import {
  useLoginModal,
  useLoginModalActions,
} from '@/state/hooks/auth/loginModal';
import BottomSheet from '../components/BottomSheet/BottomSheet';
import {SignInComponent} from '../pages/signin';
import Paper from '../components/Paper';
import {versionHash} from '@/config';

export default function LoginModalWrapper({children}: PropsWithChildren<{}>) {
  const {showLoginModal, loginModalTitle} = useLoginModal();

  return (
    <>
      {children}
      <BottomSheet
        dialogOnDesktop
        trigger={<></>}
        className={styles.wrapper}
        defaultOpen={showLoginModal}
        handleOnClose={() => {
          useLoginModalActions.setShowLoginModal(false);
        }}
        backdropClassName={styles.backdrop}
      >
        <Paper className={styles.loginModal}>
          <SignInComponent title={loginModalTitle} />
        </Paper>
      </BottomSheet>
    </>
  );
}

export const setCookieVersion = async () => {
  await fetch('/api/set-version', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      version: versionHash,
    }),
  });
};
