import React from 'react';
import Button from '../../Button';
import RemixIcon from '../../RemixIcon';
import styles from '@/view/styles/components/FeedCard/blocs/QuoteCardHeader.module.scss';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import {QuoteCardHeaderTokenFragment$key} from './__generated__/QuoteCardHeaderTokenFragment.graphql';
import {UserItem} from '../../UserItem';

const QuoteCardHeaderTokenFragment = graphql`
  fragment QuoteCardHeaderTokenFragment on tezos_tokens {
    id
    nodeId
    title
    type
    accounts {
      id
      nodeId
      ...UserItemFragment
    }
  }
`;

export default function QuoteCardHeader({
  tokenKey,
  onDelete,
}: {
  tokenKey: QuoteCardHeaderTokenFragment$key;
  onDelete?: () => void;
}) {
  const token = useFragment(QuoteCardHeaderTokenFragment, tokenKey);
  return (
    <div className={styles.header}>
      <div>
        <UserItem account={token.accounts} hideText showFollowButton />
        <div>
          <div>
            <UserItem account={token.accounts} hideIcon />
            <span>{token.type === 'post' ? 'posted' : 'posted'}</span>
            <div>{token.type === 'post' ? '' : token.title}</div>
          </div>
        </div>
      </div>
      <div>
        {onDelete && (
          <Button
            icon
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              onDelete();
            }}
            tooltip={'Remove from post'}
            tooltipSide="left"
          >
            <RemixIcon icon="close-fill" size={16} />
          </Button>
        )}
      </div>
    </div>
  );
}
