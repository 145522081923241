import clsx from 'clsx';
import React from 'react';
import styles from '@/view/styles/components/Input/blocs/DefaultInput.module.scss';

export default function DefaultInput<Type extends 'text' | 'number' | 'array'>({
  type,
  id,
  placeholder,
  defaultValue,
  value,
  onChange,
  fullWidth,
  autoComplete,
  autoFocus,
  disabled,
  inputRef,
  onClick,
  onFocus,
  onBlur,
  onEnter,
  onBackspace,
  handleEnter,
  min,
  max,
  step,
}: {
  type: Type;

  id?: string;
  placeholder?: string;
  defaultValue?: string | number;
  value?: string | number;
  onChange: (value: string) => string | void;
  fullWidth?: boolean;
  autoComplete?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
  onBackspace?: () => void;
  handleEnter?: () => void;
  min?: number;
  max?: number;
  step?: number;
}) {
  return (
    <input
      disabled={disabled}
      ref={inputRef}
      onKeyDown={e => {
        if (e.key === 'Enter' && !e.shiftKey) {
          onEnter && handleEnter?.();
        }
        if (e.key === 'Backspace') {
          onBackspace?.();
        }
        if (type === 'number' && ['e', 'E', '+', '-'].includes(e.key)) {
          e.preventDefault();
        }
      }}
      type={type}
      autoFocus={autoFocus}
      autoComplete={autoComplete ? 'on' : 'new-password'}
      id={id}
      placeholder={placeholder}
      value={value}
      defaultValue={value === undefined ? defaultValue : undefined}
      onChange={e => {
        onChange(e.target.value);
      }}
      className={clsx(styles.input, fullWidth && styles.fullWidth)}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      min={min}
      max={max}
      step={step}
    />
  );
}
