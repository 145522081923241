import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation updateSaleMutation(
    $input: salesUpdateInput!
    $filter: salesFilter!
  ) {
    updatesalesCollection(set: $input, filter: $filter) {
      records {
        id
        nodeId
        sale_id
        price
        amount
        tezos_token_id
        valid_from
        valid_until
        created_at
        accounts {
          ...accountsFragment
          identities {
            id
            nodeId
          }
        }
      }
    }
  }
`;
