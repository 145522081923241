// POSTS/NFTS

import {LinkItemType, PostImageGridItemType} from '@/view/types/postTypes';
import {getImageUrl} from './conversions';

export function getImagesFromPostAttributes(
  attributes: {name: string; value: string}[]
): PostImageGridItemType[] {
  let images: PostImageGridItemType[] = [];

  attributes.forEach(attribute => {
    const {alt, display_uri} = JSON.parse(attribute.value.replace(/'/g, '"'));
    images.push({
      alt: alt,
      src: getImageUrl(display_uri),
    });
  });

  return images;
}

export function getLinkDataFromLinkAttributes(
  attributes: {name: string; value: string}[]
): LinkItemType | undefined {
  if (attributes.length === 0) {
    return undefined;
  }
  const altAttribute = attributes.find(attribute => attribute.name === 'alt');
  const descriptionAttribute = attributes.find(
    attribute => attribute.name === 'meta_description'
  );
  const imageAttribute = attributes.find(
    attribute => attribute.name === 'display_uri'
  );
  const titleAttribute = attributes.find(
    attribute => attribute.name === 'meta_title'
  );
  const urlAttribute = attributes.find(attribute => attribute.name === 'url');

  return {
    alt: altAttribute?.value || '',
    description: descriptionAttribute?.value || '',
    image: getImageUrl(imageAttribute?.value) || '',
    title: titleAttribute?.value || '',
    url: urlAttribute?.value || '',
  };
}
