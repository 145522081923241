import * as Tabz from '@radix-ui/react-tabs';
import React from 'react';
import styles from '@/view/styles/components/Tabs.module.scss';
import clsx from 'clsx';

export default function Tabs({
  variant = 'default',
  tabs,
  tab,
  setTab,
  className,
}: {
  variant?: 'default' | 'chips';
  tabs: {
    value: string;
    filter?: ((item: any) => boolean) | undefined;
    label?: React.ReactNode;
    disabled?: boolean;
  }[];
  tab: number;
  setTab: (tab: number) => void;
  className?: string;
}) {
  return (
    <Tabz.Root
      className={clsx(styles.tabs, className)}
      orientation="horizontal"
      value={tabs[tab].value}
      data-variant={variant}
    >
      <Tabz.List>
        {tabs.map((tab, i: number) => {
          return (
            <Trigger
              index={i}
              tab={tab}
              setTab={setTab}
              key={tab.value}
              variant={variant}
              disabled={tab.disabled}
            />
          );
        })}
      </Tabz.List>
    </Tabz.Root>
  );
}

const Trigger = ({
  tab,
  setTab,
  index,
  variant = 'default',
  disabled,
}: {
  tab: {
    value: string;
    filter?: ((item: any) => boolean) | undefined;
    label?: React.ReactNode;
  };
  setTab: (tab: number) => void;
  index: number;
  variant?: 'default' | 'chips';
  disabled?: boolean;
}) => {
  return (
    <Tabz.Trigger
      key={tab.value}
      value={tab.value}
      className={styles.tab}
      data-variant={variant}
      onClick={() => {
        setTab(index);
      }}
      disabled={disabled}
    >
      <span>{tab.label || tab.value}</span>
    </Tabz.Trigger>
  );
};
