/**
 * @generated SignedSource<<756598ada93fdcb0b6eaa43ecc9a5d61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ManageItemTokenFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ManageItemContentFragment">;
  readonly " $fragmentType": "ManageItemTokenFragment";
};
export type ManageItemTokenFragment$key = {
  readonly " $data"?: ManageItemTokenFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ManageItemTokenFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ManageItemTokenFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ManageItemContentFragment"
    }
  ],
  "type": "tezos_tokens",
  "abstractKey": null
};

(node as any).hash = "af61716972d77912132f5fa223daadca";

export default node;
