export const getMediaLink = (
  tokenId: string | undefined,
  username: string | undefined
): string => {
  if (!tokenId) {
    return '';
  }

  if (!!username) {
    return `/${username}/item/${tokenId}`;
  } else {
    return `/item/${tokenId}`;
  }
};
