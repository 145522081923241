import React, {Suspense, useCallback} from 'react';
import styles from '@/view/styles/components/DialogContent.module.scss';
import Actions from './Actions';
import {ActionType} from '@/view/types/types';
import {ActionsView} from '@/state/hooks/actionsMenu';
import BottomSheet from './BottomSheet/BottomSheet';
import Router from 'next/router';
import {getImageUrl} from '@/utils/conversions/conversions';
import RemixIcon from './RemixIcon';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';

export default function DialogContent({
  dialogClose,
  actions,
  view,
  defaultOpen,
}: {
  dialogClose: () => void;
  actions: ActionType[][];
  view: ActionsView | undefined;
  defaultOpen?: boolean;
}) {
  const name = useCallback(() => {
    switch (view?.type) {
      case 'token':
      case 'list':
      case 'track':
      case 'user':
      case 'comment':
        return view.title;
      default:
        return undefined;
    }
  }, [view]);
  const description = useCallback(() => {
    switch (view?.type) {
      case 'token':
      case 'user':
      case 'list':
      case 'track':
      case 'comment':
        return view.description;
      default:
        return undefined;
    }
  }, [view]);
  const image = useCallback(() => {
    switch (view?.type) {
      case 'token':
      case 'list':
      case 'user':
      case 'track':
        return getImageUrl(view.image);
      case 'comment':
        return <RemixIcon icon="chat-4-line" size={24} />;
      default:
        return undefined;
    }
  }, [view]);

  const [open, setOpen] = React.useState(() => defaultOpen);

  const {isMobile} = useBreakpoint();

  React.useEffect(() => {
    setOpen(defaultOpen);
    if (!isMobile) return;
    if (defaultOpen) {
      Router.push(
        {pathname: Router.pathname, query: {...Router.query}},
        Router.asPath + '#',
        {
          shallow: true,
          scroll: false,
        }
      );
    }
  }, [isMobile, defaultOpen]);

  React.useEffect(() => {
    const handlePopState = () => {
      dialogClose?.();
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [dialogClose]);

  return (
    <div>
      <BottomSheet
        dialogOnDesktop
        defaultOpen={open}
        className={styles.bottomSheet}
        handleOnClose={useCallback(() => {
          if (isMobile) {
            Router.back();
          }
          dialogClose && dialogClose();
        }, [dialogClose, isMobile])}
        trigger={<></>}
      >
        {useCallback(
          (closeDialog: () => void) => (
            <Suspense fallback={<></>}>
              <Actions
                title={name() || ''}
                image={image()}
                subtitle={description()}
                actions={actions}
                close={() => {
                  dialogClose();
                  closeDialog();
                }}
                type={view?.type}
              />
            </Suspense>
          ),
          [actions, description, dialogClose, image, name, view?.type]
        )}
      </BottomSheet>
    </div>
  );
}
