/**
 * @generated SignedSource<<af70da21b239f465e4caca7994884484>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ListForSaleOwnedCountQuery$variables = {
  accountId: string;
  tokenIds?: ReadonlyArray<string> | null;
};
export type ListForSaleOwnedCountQuery$data = {
  readonly eventsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly nodeId: string;
        readonly tezos_tokens: {
          readonly id: string;
          readonly nodeId: string;
          readonly salesCollection: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly " $fragmentSpreads": FragmentRefs<"ListForSaleSaleItemFragment">;
              };
            }>;
          } | null;
        } | null;
      };
    }>;
    readonly totalCount: number;
  } | null;
};
export type ListForSaleOwnedCountQuery = {
  response: ListForSaleOwnedCountQuery$data;
  variables: ListForSaleOwnedCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tokenIds"
},
v2 = [
  {
    "fields": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "eq",
                "variableName": "accountId"
              }
            ],
            "kind": "ObjectValue",
            "name": "account_id"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "in",
                "variableName": "tokenIds"
              }
            ],
            "kind": "ObjectValue",
            "name": "tezos_token_id"
          },
          {
            "kind": "Literal",
            "name": "type",
            "value": {
              "in": [
                "mint_tezos_token",
                "pending_mint_tezos_token",
                "purchase_tezos_token",
                "pending_purchase_tezos_token",
                "collect_for_free",
                "mint_for_free"
              ]
            }
          }
        ],
        "kind": "ObjectValue",
        "name": "and"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ListForSaleOwnedCountQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "eventsConnection",
        "kind": "LinkedField",
        "name": "eventsCollection",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "eventsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "events",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "tezos_tokens",
                    "kind": "LinkedField",
                    "name": "tezos_tokens",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "salesConnection",
                        "kind": "LinkedField",
                        "name": "salesCollection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "salesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "sales",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "ListForSaleSaleItemFragment"
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ListForSaleOwnedCountQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "eventsConnection",
        "kind": "LinkedField",
        "name": "eventsCollection",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "eventsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "events",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "tezos_tokens",
                    "kind": "LinkedField",
                    "name": "tezos_tokens",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "salesConnection",
                        "kind": "LinkedField",
                        "name": "salesCollection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "salesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "sales",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "sale_id",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "price",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "created_at",
                                    "storageKey": null
                                  },
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2b84664108bfc4a0e57c9f3447542f72",
    "id": null,
    "metadata": {},
    "name": "ListForSaleOwnedCountQuery",
    "operationKind": "query",
    "text": "query ListForSaleOwnedCountQuery(\n  $tokenIds: [BigInt!]\n  $accountId: BigInt!\n) {\n  eventsCollection(filter: {and: {type: {in: [mint_tezos_token, pending_mint_tezos_token, purchase_tezos_token, pending_purchase_tezos_token, collect_for_free, mint_for_free]}, tezos_token_id: {in: $tokenIds}, account_id: {eq: $accountId}}}) {\n    totalCount\n    edges {\n      node {\n        id\n        nodeId\n        tezos_tokens {\n          id\n          nodeId\n          salesCollection {\n            edges {\n              node {\n                ...ListForSaleSaleItemFragment\n                nodeId\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ListForSaleSaleItemFragment on sales {\n  id\n  sale_id\n  price\n  created_at\n}\n"
  }
};
})();

(node as any).hash = "90e09b1613573f52c57255eb840834ca";

export default node;
