import {Primitive} from '@radix-ui/react-primitive';
import React, {
  ComponentPropsWithoutRef,
  PropsWithChildren,
  SyntheticEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import styles from '@/view/styles/components/Avatar.module.scss';
import clsx from 'clsx';

export const Avatar = React.memo(function Avatar({
  className,
  children,
  observe,
  onLoad: passedOnLoad,
  ...imageProps
}: PropsWithChildren<ComponentPropsWithoutRef<typeof Primitive.img>> & {
  observe?: boolean;
}) {
  const imageRef = useRef<HTMLImageElement>(null);
  const imageCheckedRef = useRef(false);
  const [loaded, setLoaded] = useState(imageRef.current?.complete);
  if (!imageCheckedRef.current && imageRef.current) {
    imageCheckedRef.current = true;
    setLoaded(imageRef.current.complete);
  }
  const onLoad = useCallback(
    (event: SyntheticEvent<HTMLImageElement, Event>) => {
      setLoaded(true);
      passedOnLoad?.(event);
    },
    [passedOnLoad]
  );

  const [hideGif, setHideGif] = React.useState(false);

  React.useEffect(() => {
    if (!observe) return;

    if (imageRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setHideGif(!entry.isIntersecting);
        },
        {threshold: 0.1}
      );

      observer.observe(imageRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [observe, imageRef]);

  return (
    <span
      className={clsx(
        loaded ? styles.imageVisible : styles.imageHidden,
        className
      )}
      data-hide-gif={observe && hideGif ? 'true' : 'false'}
    >
      <Primitive.img
        loading="lazy"
        onLoad={onLoad}
        key={imageProps.alt}
        {...imageProps}
        src={
          imageProps.src !== '' && typeof imageProps.src === 'string'
            ? observe && hideGif
              ? '/poster.svg'
              : imageProps.src
            : undefined
        }
        ref={imageRef}
        data-hide-gif={observe && hideGif ? 'true' : 'false'}
      ></Primitive.img>
      <span className={styles.fallback}>{children}</span>
    </span>
  );
});
