/**
 * @generated SignedSource<<9907b6591dca17a6cca37758a0f9d09a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type enum_account_type = "beacon" | "magic" | "teztok" | "%future added value";
export type enum_denomination_type = "usd" | "xtz" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CollectTokenFragment$data = {
  readonly accounts: {
    readonly beacon_accountsCollection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly public_key_hash: string;
        };
      }>;
    } | null;
    readonly id: string;
    readonly identities: {
      readonly profilesCollection: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly username: string | null;
          };
        }>;
      } | null;
    };
    readonly magic_accountsCollection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly public_key_hash: string;
        };
      }>;
    } | null;
    readonly teztok_accountsCollection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly public_key_hash: string;
        };
      }>;
    } | null;
    readonly type: enum_account_type;
  };
  readonly allow_collection: boolean;
  readonly editions: string;
  readonly editions_minted: string | null;
  readonly id: string;
  readonly is_purchasable: boolean;
  readonly metadata_uri: string;
  readonly nodeId: string;
  readonly price_denomination: enum_denomination_type;
  readonly purchase_price: number | null;
  readonly salesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly amount: string;
        readonly price: number;
        readonly sale_id: string | null;
      };
    }>;
  } | null;
  readonly thumbnail_uri: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"AddedToCartPopupTokenFragment">;
  readonly " $fragmentType": "CollectTokenFragment";
};
export type CollectTokenFragment$key = {
  readonly " $data"?: CollectTokenFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectTokenFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "public_key_hash",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectTokenFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nodeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editions_minted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "purchase_price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price_denomination",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail_uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_purchasable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allow_collection",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metadata_uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "magic_accountsConnection",
          "kind": "LinkedField",
          "name": "magic_accountsCollection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "magic_accountsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "magic_accounts",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "beacon_accountsConnection",
          "kind": "LinkedField",
          "name": "beacon_accountsCollection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "beacon_accountsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "beacon_accounts",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "teztok_accountsConnection",
          "kind": "LinkedField",
          "name": "teztok_accountsCollection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "teztok_accountsEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "teztok_accounts",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "identities",
          "kind": "LinkedField",
          "name": "identities",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "profilesConnection",
              "kind": "LinkedField",
              "name": "profilesCollection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "profilesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "profiles",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "username",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "salesConnection",
      "kind": "LinkedField",
      "name": "salesCollection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "salesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "sales",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sale_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "amount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddedToCartPopupTokenFragment"
    }
  ],
  "type": "tezos_tokens",
  "abstractKey": null
};
})();

(node as any).hash = "f89cddcb9d96c1100986413f51601346";

export default node;
