import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation insertFollowerMutation($input: [followsInsertInput!]!) {
    insertIntofollowsCollection(objects: $input) {
      records {
        follower {
          id
          nodeId
          accountsCollection {
            edges {
              node {
                id
              }
            }
          }
        }
        followee {
          id
          nodeId
          accountsCollection {
            edges {
              node {
                id
              }
            }
          }
        }
        id
      }
    }
  }
`;
