import {placeholderBanners} from '@/view/placeholders/bannerPlaceholders';
import {imperativePromise} from './promises';

export type Size = {width: number; height: number};

export async function getImageSize(
  image: Blob
): Promise<Size & {imgTag: HTMLImageElement; cleanUp: () => void}> {
  const imageUrl = URL.createObjectURL(image);
  const imgTag = new Image();

  const {resolve, promise} = await imperativePromise<void>();
  imgTag.onload = () => resolve();

  imgTag.src = imageUrl;

  await promise;

  return {
    imgTag,
    cleanUp() {
      URL.revokeObjectURL(imageUrl);
    },
    width: imgTag.naturalWidth,
    height: imgTag.naturalHeight,
  };
}

export type ResizeType = 'crop';

function resizeCrop(
  image: HTMLImageElement,
  ctx: CanvasRenderingContext2D,
  resize: Size,
  size: Size
) {
  ctx.canvas.width = resize.width;
  ctx.canvas.height = resize.height;

  const aspectRatio = size.width / size.height;

  const [dw, dh] =
    resize.width - size.width > (resize.height - size.height) * aspectRatio
      ? [resize.width, resize.width / aspectRatio]
      : [resize.height * aspectRatio, resize.height];

  const dx = (resize.width - dw) / 2;
  const dy = (resize.height - dh) / 2;

  ctx.drawImage(image, dx, dy, dw, dh);
}

export async function resizeImage(
  image: Blob,
  resize: Size | number,
  resizeType: ResizeType = 'crop'
): Promise<string> {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('could not load 2d canvas context');
  }

  if (typeof resize === 'number') {
    resize = {
      width: resize,
      height: resize,
    };
  }

  const {imgTag, cleanUp, ...size} = await getImageSize(image);

  switch (resizeType) {
    case 'crop':
      resizeCrop(imgTag, ctx, resize, size);
      break;
  }

  cleanUp();

  return canvas.toDataURL('image/webp', 80); // 80% quality
}

export const randomBanner = (address: string, resX?: number) => {
  if (!address || address === '') return placeholderBanners(resX)[0];
  const matches = address.match(/\d/g);
  if ((matches?.length || 0) < 2) {
    return placeholderBanners(resX)[0];
  }
  const id = `${matches?.[matches.length - 2]}${matches?.[matches.length - 1]}`;

  return placeholderBanners(resX)[parseInt(id)];
};
