import Link from 'next/link';
import React, {PropsWithChildren, Suspense, useCallback} from 'react';
import {Drawer as Vaul} from 'vaul';
import {TokenPageContainer} from '../pages/item';
import styles from '@/view/styles/components/TokenPageLink.module.scss';
import {ItemModalActions} from '@/state/hooks/itemModal';
import InfiniteLoader from './InfiniteLoader';
import {useRouter} from 'next/router';
import {getMediaLink} from '@/state/hooks/mediaPlaylistFunctions';

export default function TokenPageLink({
  tokenId,
  username,
  className,
  children,
}: PropsWithChildren<{
  tokenId: string;
  username?: string;
  className?: string;
}>) {
  const router = useRouter();
  return (
    <Link
      href={{pathname: router.pathname, query: {...router.query}}}
      as={getMediaLink(tokenId, username)}
      scroll={false}
      shallow={true}
      className={className}
      onClick={e => {
        if (e.ctrlKey || e.metaKey || e.shiftKey) {
          return;
        }
        e.preventDefault();
        e.stopPropagation();
        router
          .push(
            {pathname: router.pathname, query: {...router.query}},
            getMediaLink(tokenId, username),
            {shallow: true}
          )
          .then(() => {
            ItemModalActions.setItem(true, tokenId, username);
          });
      }}
    >
      {children}
    </Link>
  );
}

export const ItemModalContent = ({
  isOpen,
  id,
}: {
  isOpen: boolean;
  id: string | null;
}) => {
  const router = useRouter();

  const handleRouteBack = useCallback((isPopState?: boolean) => {
    if (isPopState === false) {
      router.back();
    }
  }, []);

  const handleRelease = useCallback(
    (event: React.PointerEvent<HTMLDivElement>, open: boolean) => {
      if (open === false) {
        handleRouteBack(false);
      }
    },
    [handleRouteBack]
  );

  React.useEffect(() => {
    const handlePopstate = (e: PopStateEvent) => {
      e.preventDefault();
      e.stopPropagation();
      ItemModalActions.setItem(false, null, null);
    };
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);

  React.useEffect(() => {
    return () => {
      ItemModalActions.setItem(false, null, null);
    };
  }, []);

  return (
    <Vaul.Root
      direction="bottom"
      open={isOpen}
      onClose={() => {
        ItemModalActions.setItem(false, null, null);
      }}
      modal={true}
      onRelease={handleRelease}
    >
      <Vaul.Portal>
        <Vaul.Overlay
          className={styles.overlay}
          onClick={() => {
            handleRouteBack(false);
          }}
        />
        <Vaul.Content
          onEscapeKeyDown={ev => {
            ev.preventDefault();
            ev.stopPropagation();
            handleRouteBack(false);
          }}
          className={styles.root}
        >
          <div className={styles.dragHandle} />
          <Suspense
            fallback={<InfiniteLoader height={'80vh'} loaderSize={42} />}
          >
            <TokenPageContainer modalId={id || undefined} />
          </Suspense>
        </Vaul.Content>
      </Vaul.Portal>
    </Vaul.Root>
  );
};
