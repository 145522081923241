import {create} from 'zustand';

type STATUS =
  | 'pending'
  | 'done'
  | 'error'
  | 'error_sold_out'
  | 'error_insufficient_balance'
  | 'error_unknown';

type ACTION_TYPE =
  | 'create'
  | 'collect'
  | 'purchase'
  | 'list'
  | 'cancel'
  | 'transfer'
  | 'burn'
  | 'send-money';

type ACTION = {
  type: ACTION_TYPE;
  id: string;
  status: STATUS;
  thumbnail?: string;
  title: string;
  description?: string;
  action?: () => void;
  actionText?: string;

  retryFunction?: () => void;
};

export type {ACTION as OnchainAction};

type OnchainActionStatusFunctions = {
  add: (actions: ACTION[]) => void;
  remove: (id: string) => void;
  updateAction: (
    id: string,
    status: STATUS,
    title?: string,
    description?: string
  ) => void;
  clear: () => void;
  reset: () => void;
};

type OnchainActionStatusState = {
  actions: ACTION[];
};

const defaultState: OnchainActionStatusState = {
  actions: [],
};

export const useOnchainActionStatus = create<OnchainActionStatusState>(
  (set, get) => {
    return {
      ...defaultState,
    };
  }
);

export const useOnchainActionStatusActions: OnchainActionStatusFunctions = {
  reset() {
    useOnchainActionStatus.setState(defaultState);
  },
  add(actions: ACTION[]) {
    useOnchainActionStatus.setState(state => {
      return {
        ...state,
        actions: state.actions.concat(actions),
      };
    });
  },
  remove(id: string) {
    useOnchainActionStatus.setState(state => {
      return {
        ...state,
        actions: state.actions.filter(action => action.id !== id),
      };
    });
  },
  clear() {
    useOnchainActionStatus.setState(state => {
      return {
        ...state,
        actions: [],
      };
    });
  },
  updateAction(
    id: string,
    status: STATUS,
    title?: string,
    description?: string
  ) {
    useOnchainActionStatus.setState(state => {
      const actions = state.actions.map(action => {
        if (action.id === id) {
          return {
            ...action,
            status,
            title: title || action.title,
            description: description || action.description,
          };
        }
        return action;
      });
      return {
        ...state,
        actions,
      };
    });
  },
};
