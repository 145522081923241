/**
 * @generated SignedSource<<0579562d12ea679db72be0b5f9a1a2d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentIsReplyToFragment$data = {
  readonly id: string;
  readonly identities: {
    readonly profilesCollection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly username: string | null;
        };
      }>;
    } | null;
  };
  readonly nodeId: string;
  readonly " $fragmentSpreads": FragmentRefs<"CommentContentIsReplyToFragment">;
  readonly " $fragmentType": "CommentIsReplyToFragment";
};
export type CommentIsReplyToFragment$key = {
  readonly " $data"?: CommentIsReplyToFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentIsReplyToFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentIsReplyToFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nodeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "identities",
      "kind": "LinkedField",
      "name": "identities",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "profilesConnection",
          "kind": "LinkedField",
          "name": "profilesCollection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "profilesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "profiles",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "username",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentContentIsReplyToFragment"
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "486351a416c95a48d9ee0826b0c32e0a";

export default node;
