/**
 * @generated SignedSource<<46e8313aff904c518b5accc727c278bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NftAssetByCreatorFragment$data = {
  readonly identities: {
    readonly profilesCollection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly username: string | null;
        };
      }>;
    } | null;
  };
  readonly " $fragmentType": "NftAssetByCreatorFragment";
};
export type NftAssetByCreatorFragment$key = {
  readonly " $data"?: NftAssetByCreatorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NftAssetByCreatorFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NftAssetByCreatorFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "identities",
      "kind": "LinkedField",
      "name": "identities",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "profilesConnection",
          "kind": "LinkedField",
          "name": "profilesCollection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "profilesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "profiles",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "username",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "accounts",
  "abstractKey": null
};

(node as any).hash = "1f48a78320ac18b0b030c136db76b9a2";

export default node;
