import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation removeTezosTokenMutation($filter: tezos_tokensFilter!) {
    deleteFromtezos_tokensCollection(filter: $filter) {
      records {
        nodeId
        id
        purchase_price
        price_denomination
        is_purchasable
      }
    }
  }
`;
