/**
 * @generated SignedSource<<a419d51da179bb908c23f75bac341f13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type enum_playlist_visibility_type = "private" | "public" | "unlisted" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AddToCollectionPlaylistsPaginated$data = {
  readonly playlistsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly exists?: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
            };
          }>;
        } | null;
        readonly id: string;
        readonly image_uri: string | null;
        readonly nodeId: string;
        readonly playlists_tokensCollection: {
          readonly totalCount: number;
        } | null;
        readonly title: string;
        readonly visibility: enum_playlist_visibility_type;
      };
    }>;
  } | null;
  readonly " $fragmentType": "AddToCollectionPlaylistsPaginated";
};
export type AddToCollectionPlaylistsPaginated$key = {
  readonly " $data"?: AddToCollectionPlaylistsPaginated$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddToCollectionPlaylistsPaginated">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "playlistsCollection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": 30,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "identityIds"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "loggedIn"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tokenId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./AddToCollectionPlaylistsPaginatedQuery.graphql')
    }
  },
  "name": "AddToCollectionPlaylistsPaginated",
  "selections": [
    {
      "alias": "playlistsCollection",
      "args": [
        {
          "fields": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "in",
                  "variableName": "identityIds"
                }
              ],
              "kind": "ObjectValue",
              "name": "identity_id"
            }
          ],
          "kind": "ObjectValue",
          "name": "filter"
        }
      ],
      "concreteType": "playlistsConnection",
      "kind": "LinkedField",
      "name": "__AddToCollectionPlaylistsPaginated_playlistsCollection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "playlistsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "playlists",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "nodeId",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "image_uri",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "visibility",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "playlists_tokensConnection",
                  "kind": "LinkedField",
                  "name": "playlists_tokensCollection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalCount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "condition": "loggedIn",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": "exists",
                      "args": [
                        {
                          "fields": [
                            {
                              "fields": [
                                {
                                  "kind": "Variable",
                                  "name": "eq",
                                  "variableName": "tokenId"
                                }
                              ],
                              "kind": "ObjectValue",
                              "name": "tezos_token_id"
                            }
                          ],
                          "kind": "ObjectValue",
                          "name": "filter"
                        }
                      ],
                      "concreteType": "playlists_tokensConnection",
                      "kind": "LinkedField",
                      "name": "playlists_tokensCollection",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "playlists_tokensEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "playlists_tokens",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ]
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "85267a5a2dfbc2d3b1ecc8458efdca4f";

export default node;
