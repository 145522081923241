import {useRouter} from 'next/router';
import {PropsWithChildren, Suspense, useEffect} from 'react';
import {useAccount} from '@/kits/account-kit/src';
import {graphql, useLazyLoadQuery} from '@/kits/relay-kit/src';
import type {OnboardingCheckContentOnboardedQuery as OnboardingCheckContentOnboardedQueryType} from './__generated__/OnboardingCheckContentOnboardedQuery.graphql';

export default function OnboardingCheck({children}: PropsWithChildren<{}>) {
  return (
    <>
      {children}
      <Suspense fallback={<></>}>
        <OnboardingCheckContent />
      </Suspense>
    </>
  );
}

type Response = {
  data: {
    identitiesCollection: {
      edges: {
        node: {
          id: string;
          nodeId: string;
          onboarded: boolean;
        };
      }[];
    };
  };
};

const OnboardingCheckContentOnboardedQuery = graphql`
  query OnboardingCheckContentOnboardedQuery($id: BigInt!) {
    identitiesCollection(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          nodeId
          onboarded
        }
      }
    }
  }
`;

const OnboardingCheckContent = () => {
  const {getAccount} = useAccount();

  const account = getAccount();
  const identityId = account.isLoggedIn ? account.identityId : '';

  const router = useRouter();

  const {retry} = useLazyLoadQuery<OnboardingCheckContentOnboardedQueryType>(
    OnboardingCheckContentOnboardedQuery,
    {
      id: identityId || '',
    },
    {
      skip: !account.isLoggedIn || !identityId || identityId === '',
      onResponse(response) {
        const res = response as Response;
        if (
          res.data.identitiesCollection.edges?.[0]?.node?.onboarded === false
        ) {
          router.push('/onboarding');
        }
      },
    }
  );

  useEffect(() => {
    if (!account.isLoggedIn || !identityId || identityId === '') {
      return;
    }
    retry();
  }, [account.isLoggedIn, identityId, retry]);

  return <></>;
};
