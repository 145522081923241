/**
 * @generated SignedSource<<950a1df6d8874b6f9b466722cb0ccc31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ListForSaleSaleItemTokenFragment$data = {
  readonly contract_address: string;
  readonly id: string;
  readonly is_purchasable: boolean;
  readonly purchase_price: number | null;
  readonly thumbnail_uri: string;
  readonly title: string;
  readonly token_id: string | null;
  readonly " $fragmentType": "ListForSaleSaleItemTokenFragment";
};
export type ListForSaleSaleItemTokenFragment$key = {
  readonly " $data"?: ListForSaleSaleItemTokenFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ListForSaleSaleItemTokenFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ListForSaleSaleItemTokenFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "token_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contract_address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail_uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_purchasable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "purchase_price",
      "storageKey": null
    }
  ],
  "type": "tezos_tokens",
  "abstractKey": null
};

(node as any).hash = "0ac5b1145ae1bfee31fc2e88687f7f29";

export default node;
