import {removeFalsyValue} from './mappers';
import type {OmitFalsy} from './types';

export function removeFalsyKeys<T extends object>(obj: T): OmitFalsy<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => removeFalsyValue(value))
  ) as OmitFalsy<T>;
}

export function jsonToBlob(json: object | string): Blob {
  if (json === null) {
    throw new Error('json is null');
  }
  return new Blob([typeof json === 'string' ? json : JSON.stringify(json)], {
    type: typeof json === 'string' ? 'text/plain' : 'application/json',
  });
}
