import React, {PropsWithChildren} from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import Toast from '../components/Toast';
import styles from '@/view/styles/providers/Toast.module.scss';
import {ToastActions} from '@/state/hooks/toasts';

export default function ToastWrapper({children}: PropsWithChildren<{}>) {
  React.useEffect(() => {
    return () => {
      ToastActions.closeToasts();
    };
  }, []);
  return (
    <ToastPrimitive.Provider swipeDirection="left">
      {children}
      <Toast />
      <ToastPrimitive.Viewport className={styles.viewport} />
    </ToastPrimitive.Provider>
  );
}
