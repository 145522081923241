import React, {useCallback} from 'react';
import styles from '@/view/styles/components/ProcessingItems/ProcessingStatus.module.scss';
import Progress from '../Progress';
import confetti from 'canvas-confetti';
import {deviceWidth} from '@/controller/hooks/utils/breakpoint';
import {
  useOnchainActionStatus,
  useOnchainActionStatusActions,
} from '@/state/hooks/onChainActionStatus';
import Button from '../Button';
import RemixIcon from '../RemixIcon';
import {Avatar} from '../Avatar';
import {getImageUrl} from '@/utils/conversions/conversions';

export default function ProcessingStatusCollect({
  relative,
}: {
  relative?: boolean;
}) {
  const showConfetti = useCallback(() => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: {
        y: 0.9,
        x:
          deviceWidth() === 'desktop'
            ? 0.3
            : deviceWidth() === 'tablet'
            ? 0.2
            : 0.5,
      },
    });
  }, []);

  const {actions} = useOnchainActionStatus();
  const oneActionActive = actions.length === 1;

  const completedActions = actions.filter(action => action.status === 'done');
  const pendingActions = actions.filter(action => action.status === 'pending');
  const failedActions = actions.filter(
    action => action.status !== 'done' && action.status !== 'pending'
  );

  const allDone = pendingActions.length === 0 && actions.length > 0;

  const title = oneActionActive
    ? actions[0]?.title
    : allDone
    ? failedActions.length === 0
      ? 'All done!'
      : `${failedActions.length}/${actions.length} failed.`
    : `${completedActions.length}/${actions.length} completed.`;

  const closeAndClear = () => {
    useOnchainActionStatusActions.reset();
  };

  React.useEffect(() => {
    if (allDone && failedActions.length === 0) {
      const yayTimeout = setTimeout(() => {
        showConfetti();
      }, 1000);
      const closeTimeout = setTimeout(() => {
        closeAndClear();
      }, 4000);
      return () => {
        clearTimeout(yayTimeout);
        clearTimeout(closeTimeout);
      };
    }
  }, [allDone, failedActions.length, showConfetti]);

  React.useEffect(() => {
    if (allDone && failedActions.length > 0) {
      const closeTimeout = setTimeout(() => {
        closeAndClear();
      }, 4000);
      return () => {
        clearTimeout(closeTimeout);
      };
    }
  }, [allDone, failedActions.length]);

  const observeRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!relative) return;
    const root = document.documentElement;

    if (observeRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            root.style.setProperty('--fixedNotificationDisplay', 'none');
          } else {
            root.style.setProperty('--fixedNotificationDisplay', 'flex');
          }
        },
        {threshold: 0.5}
      );

      observer.observe(observeRef.current);

      return () => {
        root.style.setProperty('--fixedNotificationDisplay', 'flex');
        observer.disconnect();
      };
    }
  }, [relative, observeRef]);

  return (
    actions.length > 0 && (
      <div
        className={styles.root}
        data-relative={relative ? 'true' : 'false'}
        ref={relative ? observeRef : null}
      >
        <div className={styles.icon}>
          {oneActionActive ? (
            <Avatar
              src={getImageUrl(actions[0]?.thumbnail)}
              className={styles.image}
            >
              <RemixIcon
                icon="loader-4-line"
                size={24}
                className={styles.spinner}
              />
            </Avatar>
          ) : (
            <RemixIcon
              icon="loader-4-line"
              size={24}
              className={styles.spinner}
            />
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <Progress
            value={
              oneActionActive
                ? getProgress(actions[0]?.status)
                : parseFloat(
                    `${
                      ((actions.length - pendingActions.length) /
                        actions.length) *
                      100
                    }`
                  )
            }
            className={styles.progress}
            infinite={oneActionActive && actions[0]?.status === 'pending'}
          />
        </div>
        {oneActionActive ? (
          allDone && failedActions.length > 0 ? (
            <Button
              className={styles.action}
              text
              onClick={() => {
                if (allDone && failedActions.length > 0) {
                  failedActions.forEach(action => {
                    action.retryFunction?.();
                  });
                }
              }}
            >
              <span>RETRY</span>
            </Button>
          ) : actions[0]?.action && actions[0]?.actionText ? (
            <Button
              className={styles.action}
              text
              onClick={() => {
                actions[0].action?.();
              }}
            >
              <span>{actions[0].actionText}</span>
            </Button>
          ) : null
        ) : null}
      </div>
    )
  );
}

function getProgress(status: string) {
  switch (status) {
    case 'done':
      return 100;
    case 'pending':
      return 0;
    default:
      return 1;
  }
}
