import React, {useEffect, useState} from 'react';
import styles from '@/view/styles/components/ShoppingCart/ShoppingCart.module.scss';
import clsx from 'clsx';
import Input from '../Input/Input';
import Button from '../Button';
import RemixIcon from '../RemixIcon';
import Checkbox from '../Checkbox';
import Select from '../Select';
import {nanoid} from 'nanoid';
import {useShoppingCart} from '@/state/hooks/shoppingCart';
import {convertFromMutez} from '@/utils/tezos';
import EmptyProfileCard from '../EmptyProfileCards/EmptyProfileCard';
import {useAccount} from '@/kits/account-kit/src';
import {TezosToolkit} from '@taquito/taquito';
import {getBeaconWallet} from '@/kits/beacon-auth-kit/src';
import {getTezosToolkitWithMagicProvider} from '@/kits/authentication-kit/src';
import {useMutation} from '@/kits/relay-kit/src';
import updatePostMutation from '@/graphql/update-post';
import type {updatePostMutation as PostMutationType} from '@/graphql/__generated__/updatePostMutation.graphql';
import insertEventMutation from '@/graphql/insert-event';
import type {insertEventMutation as EventMutationType} from '@/graphql/__generated__/insertEventMutation.graphql';
import updateSaleMutation from '@/graphql/update-sale';
import type {updateSaleMutation as UpdateSaleMutationType} from '@/graphql/__generated__/updateSaleMutation.graphql';
import removeSaleMutation from '@/graphql/remove-sale';
import type {removeSaleMutation as RemoveSaleMutationType} from '@/graphql/__generated__/removeSaleMutation.graphql';
import {Log} from '@/kits/logging-kit/src';
import {TextLogoutButton} from '../SendToken/SendToken';
import {useLoginModalActions} from '@/state/hooks/auth/loginModal';
import {useTezBalance} from '@/state/hooks/useTezBalance';
import InfiniteLoader from '../InfiniteLoader';
import {getAssetDetail} from '@/kits/coincap-kit/src';
import {
  createInvoice,
  createPayment,
  fulfillInvoice,
  PaymentMethod,
} from '@/kits/marketplace-kit/src';
import {OpKind} from '@taquito/taquito';
import marketplaceConfig from '@/kits/marketplace-kit/config.json';
import {
  OnchainAction,
  useOnchainActionStatusActions,
} from '@/state/hooks/onChainActionStatus';
import {v4 as uuid} from 'uuid';
import {runOnSuccessMutations} from './relayUpdaterFunctions';
import TezSymbol from '../TezSymbol';
import Dialog from '../Dialog';
import Tooltip from '../Tooltip';
import {Item} from './Item';
import {WalletBuyXTZDialog} from './WalletBuyXTZDialog';

export const CoinbaseIcon = ({sizeOverride}: {sizeOverride?: number}) => {
  return (
    <svg
      viewBox="0 0 28 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={sizeOverride}
      height={sizeOverride}
    >
      <path
        d="M14.0346 22.0082C10.1582 22.0082 7.02596 18.8673 7.02596 14.9996C7.02596 11.1319 10.1669 7.99094 14.0346 7.99094C17.5043 7.99094 20.3857 10.5175 20.9394 13.8315H28C27.403 6.63247 21.3807 0.973633 14.026 0.973633C6.28183 0.973633 0 7.25546 0 14.9996C0 22.7437 6.28183 29.0256 14.026 29.0256C21.3807 29.0256 27.403 23.3667 28 16.1677H20.9308C20.377 19.4817 17.5043 22.0082 14.0346 22.0082Z"
        fill="white"
      />
    </svg>
  );
};

enum PAGES {
  CART,
  SELECT_PAYMENT_METHOD,
  PAYMENT_DETAILS,
  SELECT_AMOUNT,
  CONFIRMATION,
}

let paymentMethods = [
  {
    name: 'My Tezos balance',
    icon: <TezSymbol sizeOverride={28} />,
    value: 'tezos',
  },
  {
    name: 'Add funds to your wallet',
    icon: <CoinbaseIcon sizeOverride={28} />,
    value: 'coinbase-onramp',
  },
];

const amountOptions = [5, 10, 25, 50, 100];

export default function ShoppingCart({
  onClose,
  className,
}: {
  onClose?: (isPopState: boolean) => void;
  className?: string;
}) {
  const {getAccount, logoutAccount} = useAccount();
  const user = getAccount();

  const address = user.isLoggedIn ? user.address : undefined;

  const [page, setPage] = React.useState(() => PAGES.CART);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<
    undefined | number
  >(undefined);

  const [saveInfo, setSaveInfo] = React.useState(false);
  const {
    content,
    clear,
    removeItems,
    makeFreeItemsCollectible,
    makeCollectibles,
  } = useShoppingCart();
  const pricedItems = content.filter(c =>
    makeFreeItemsCollectible
      ? true
      : c.createCollectible ||
        (c.token.is_purchasable &&
          !!c.token.purchase_price &&
          c.token.purchase_price > 0)
  );
  const freeItems = content.filter(c =>
    makeFreeItemsCollectible
      ? false
      : c.token.is_purchasable && c.token.purchase_price === 0
  );

  const [usdTotal, setUsdTotal] = React.useState<number>(0);
  const tezTotal = pricedItems.reduce(
    (acc, item) =>
      acc +
      Number(
        item.sale
          ? item.sale.price || 0
          : convertFromMutez(item.token.purchase_price || 0)
      ) *
        item.quantity,
    0
  );

  const [buyingInProgress, setBuyingInProgress] = React.useState(false);
  const [updatePost] = useMutation<PostMutationType>(updatePostMutation);
  const [updateSale] = useMutation<UpdateSaleMutationType>(updateSaleMutation);
  const [removeSale] = useMutation<RemoveSaleMutationType>(removeSaleMutation);
  const [insertEvent] = useMutation<EventMutationType>(insertEventMutation);

  React.useEffect(() => {
    const updateUsdTotal = async () => {
      const tezosInfo = await getAssetDetail('tezos');
      const tezPrice = tezosInfo?.data?.priceUsd || 0;
      setUsdTotal(Number((tezTotal * tezPrice).toFixed(2)));
    };

    updateUsdTotal();
  }, [tezTotal]);

  // Invoice
  const [invoiceId, setInvoiceId] = React.useState<number | undefined>();
  const {balance} = useTezBalance();
  const [error, setError] = React.useState<string | null>(null);
  const showLogoutButton = error === 'Session expired, please log in again.';
  const estimatedTotalFreeItemsGasFee = () => {
    let freeItemToBeMinted = 0;
    content.forEach(item => {
      if (item.createCollectible) {
        freeItemToBeMinted++;
        return;
      }
      if (
        item.token.is_purchasable &&
        item.token.purchase_price === 0 &&
        makeFreeItemsCollectible // only mint if user has selected to mint free items
      ) {
        freeItemToBeMinted++;
        return;
      }
    });

    return parseFloat(convertFromMutez(freeItemToBeMinted * 5000));
  };
  const [tezPrice, setTezPrice] = React.useState<number>();

  React.useEffect(() => {
    const getTezPrice = async () => {
      const tezosInfo = await getAssetDetail('tezos');
      const usdPrice = tezosInfo?.data?.priceUsd;

      const usdConversion = usdPrice ? parseFloat(usdPrice) : 0;
      const value = Math.round(usdConversion * 100) / 100;
      return value;
    };

    getTezPrice().then(price => {
      setTezPrice(price);
    });
  }, []);

  return page === PAGES.CART ? (
    <>
      <Button icon onClick={() => onClose?.(false)} className={styles.closeBtn}>
        <RemixIcon icon="close-line" size={24} />
      </Button>
      <div className={clsx(styles.root, className)}>
        <span>
          <div>
            <span>Shopping cart ({content?.length || 0})</span>
            <span className={styles.balanceText}>Balance: {balance} XTZ</span>
          </div>
          <Button
            text
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              clear();
              onClose?.(false);
            }}
          >
            <span>Clear All</span>
          </Button>
        </span>
        <div className={styles.content}>
          {content.length > 0 && (
            <div>
              <div>ITEM</div>
              <div />
              <div>PRICE</div>
            </div>
          )}
          <div>
            {content?.map(item => (
              <Item
                key={`${item.shoppingCartItemId}-${item.token.id}`}
                shoppingCartItemId={item.shoppingCartItemId}
                item={item.token}
                quantity={item.quantity}
                sale={item.sale}
                makeFreeItemsCollectible={
                  item.token.purchase_price === 0 &&
                  item.token.is_purchasable &&
                  makeFreeItemsCollectible
                }
                price={Number(convertFromMutez(item.token.purchase_price || 0))}
                createCollectible={item.createCollectible}
              />
            ))}
            {content.length > 0 && <hr />}
            {content?.length === 0 && (
              <EmptyProfileCard
                icon={<RemixIcon icon="shopping-cart-2-line" size={48} />}
                title="Your shopping cart is empty"
                styleOverrides={{
                  paddingBlock: '1rem',
                  justifyContent: 'flex-start',
                }}
              />
            )}
          </div>
        </div>
        {content.filter(
          c => c.token.is_purchasable && c.token.purchase_price === 0
        )?.length !== 0 && (
          <div className={styles.makeItACollectible}>
            <span>Create collectibles</span>
            <div>
              <div>
                <Checkbox
                  checked={makeFreeItemsCollectible}
                  onChange={makeCollectibles}
                />
                <span>
                  Turn all your free items into{' '}
                  <Tooltip
                    arrow={false}
                    contentClassName={styles.collectibleTooltip}
                    text={
                      <div>
                        <RemixIcon icon="information-line" size={38} />
                        <span>
                          {`Onchain collectibles are like special items you can\nbuy, trade, and keep safe. You own them, and they\nare decentralized, so nobody can take them away!`}
                        </span>
                      </div>
                    }
                  >
                    <span>onchain collectibles</span>
                    <RemixIcon icon="information-line" size={18} />
                  </Tooltip>
                </span>
              </div>
            </div>
          </div>
        )}
        {content.length > 0 && (
          <>
            <div className={styles.total}>
              <div>Total:</div>
              <div>
                <div>
                  {(
                    parseFloat(tezTotal.toFixed(8)) +
                    parseFloat(estimatedTotalFreeItemsGasFee().toFixed(8))
                  ).toFixed(8)}{' '}
                  XTZ
                </div>
              </div>
            </div>
            <div className={styles.actions}>
              {user.isLoggedIn ? (
                <>
                  <Button
                    accent
                    filled
                    onClick={async () => {
                      // TODO
                      // IF ALL ITEMS ARE FREE, WE DON'T NEED TO SELECT A PAYMENT METHOD - CAN RUN MUTATIONS HERE AND EXIT.
                      if (pricedItems.length === 0 && freeItems.length !== 0) {
                        // when all items are free
                        if (!user.isLoggedIn) {
                          setError('Session expired, please log in again.');
                          return;
                        }
                        setBuyingInProgress(true); // set buying in progress
                        let tezos: TezosToolkit;
                        if (user.walletType === 'beacon') {
                          const {tezosToolkit} = getBeaconWallet();
                          tezos = tezosToolkit;
                        } else if (user.walletType === 'magic') {
                          const t = await getTezosToolkitWithMagicProvider();
                          if (!t) {
                            Log(
                              user.accountId,
                              'ShoppingCart',
                              JSON.stringify({
                                error:
                                  'Failed to get Tezos Toolkit with Magic Provider',
                              })
                            );
                            setError('Session expired, please log in again.');
                            throw new Error(
                              'Unable to get Tezos Toolkit with Magic Provider'
                            );
                          }
                          tezos = t;
                        } else {
                          throw new Error('Unsupported wallet type');
                        }
                        if (!tezos) {
                          Log(
                            user.accountId,
                            'ShoppingCart',
                            JSON.stringify({
                              error: 'Session expired, please log in again.',
                            })
                          );
                          setError('Session expired, please log in again.');
                          console.error('No Tezos Toolkit found');
                          return;
                        }
                        setPage(PAGES.CONFIRMATION);

                        const transactionId = uuid();

                        const onChainActionObject: OnchainAction = {
                          type: 'collect',
                          status: 'pending',
                          title: `Collecting ${freeItems.length} for free`,
                          ...(freeItems[0]?.token?.thumbnail_uri
                            ? {
                                thumbnail: freeItems[0]?.token?.thumbnail_uri,
                              }
                            : {}),
                          id: transactionId,
                        };
                        useOnchainActionStatusActions.add([
                          onChainActionObject,
                        ]);
                        onClose?.(true);

                        let running = false;

                        if (running) return;
                        running = true;
                        useOnchainActionStatusActions.updateAction(
                          transactionId,
                          'done', // Still setting this to 'done' - that's what triggers the confetti
                          'Collect completed!',
                          `${freeItems.length} ${
                            freeItems.length === 1 ? `item` : `items`
                          } for free collected.`
                        );
                        setBuyingInProgress(false);
                        if (!user.isLoggedIn) return; // TODO: Find better fix
                        // THIS FUNCTION BELOW IS ALL THE MUTATIONS: Update sale, post, add event. Also remove items from cart on success.
                        runOnSuccessMutations(
                          [],
                          freeItems,
                          removeSale,
                          user,
                          updateSale,
                          insertEvent,
                          transactionId,
                          0,
                          setBuyingInProgress,
                          removeItems,
                          updatePost
                        );

                        return;
                      }
                      setBuyingInProgress(true);
                      const results = await createInvoice(
                        user.identityId,
                        user.address,
                        pricedItems
                      );
                      setInvoiceId(results[0]?.id);
                      setBuyingInProgress(false);
                      setPage(PAGES.SELECT_PAYMENT_METHOD);
                    }}
                    disabled={
                      buyingInProgress ||
                      !user.isLoggedIn ||
                      !balance ||
                      parseFloat(tezTotal.toFixed(8)) +
                        parseFloat(estimatedTotalFreeItemsGasFee().toFixed(8)) >
                        parseFloat(balance)
                    }
                  >
                    <span>
                      {buyingInProgress ? 'Please wait...' : 'Checkout'}
                    </span>
                    {buyingInProgress && (
                      <RemixIcon
                        icon="loader-4-line"
                        size={16}
                        className={styles.spin}
                      />
                    )}
                  </Button>
                </>
              ) : (
                <Button
                  accent
                  onClick={() => {
                    onClose?.(true);
                    useLoginModalActions.setShowLoginModal(true);
                  }}
                >
                  Sign in
                </Button>
              )}
            </div>
            {error && (
              <div className={styles.error}>
                <RemixIcon icon="error-warning-line" size={16} />
                <span>
                  {error} {showLogoutButton && <TextLogoutButton />}
                </span>
              </div>
            )}
          </>
        )}
      </div>
    </>
  ) : page === PAGES.SELECT_PAYMENT_METHOD ? (
    <div className={clsx(styles.root, className)}>
      <span>
        <div>
          <span>Select payment method</span>
        </div>
      </span>
      <div className={styles.list}>
        {paymentMethods.map((item, i) => {
          return (
            <>
              <div
                key={item.name}
                className={styles.listItem}
                data-available={
                  (balance &&
                    parseFloat(tezTotal.toFixed(8)) +
                      parseFloat(estimatedTotalFreeItemsGasFee().toFixed(8)) <=
                      parseFloat(balance)) ||
                  item.value === 'coinbase-onramp'
                }
                data-selected={selectedPaymentMethod === i}
                onClick={async () => {
                  if (
                    item.value === 'tezos' &&
                    balance &&
                    parseFloat(tezTotal.toFixed(8)) +
                      parseFloat(estimatedTotalFreeItemsGasFee().toFixed(8)) >
                      parseFloat(balance)
                  ) {
                    return;
                  }

                  setSelectedPaymentMethod(i);
                }}
              >
                {item.icon}
                <span>{item.name}</span>
                <div>
                  {item.value === 'tezos' ? `${balance} XTZ` : null}
                  {item.value === 'tezos' &&
                  parseFloat(tezTotal.toFixed(8)) +
                    parseFloat(estimatedTotalFreeItemsGasFee().toFixed(8)) >
                    parseInt(balance || '0') ? (
                    <span>{`You don't have\nenough funds`}</span>
                  ) : null}
                </div>
              </div>
            </>
          );
        })}
        <hr />
      </div>
      <div className={styles.actions}>
        <Button
          text
          onClick={() => {
            setPage(PAGES.CART);
          }}
        >
          <span>Back</span>
        </Button>
        <Button
          disabled={
            selectedPaymentMethod === undefined ||
            !user.isLoggedIn ||
            buyingInProgress ||
            (selectedPaymentMethod === 0 &&
            balance &&
            parseFloat(tezTotal.toFixed(8)) +
              parseFloat(estimatedTotalFreeItemsGasFee().toFixed(8)) >
              parseFloat(balance)
              ? true
              : false)
          }
          accent
          filled
          onClick={async () => {
            if (!user.isLoggedIn) {
              setError('Session expired, please log in again.');
              return;
            }
            if (selectedPaymentMethod === 0) {
              setBuyingInProgress(true);
              if (content.length === 0) return;
              let tezos: TezosToolkit;
              if (user.walletType === 'beacon') {
                const {tezosToolkit} = getBeaconWallet();
                tezos = tezosToolkit;
              } else if (user.walletType === 'magic') {
                const t = await getTezosToolkitWithMagicProvider();
                if (!t) {
                  Log(
                    user.accountId,
                    'ShoppingCart',
                    JSON.stringify({
                      error: 'Failed to get Tezos Toolkit with Magic Provider',
                    })
                  );
                  setError('Session expired, please log in again.');
                  throw new Error(
                    'Unable to get Tezos Toolkit with Magic Provider'
                  );
                }
                tezos = t;
              } else {
                throw new Error('Unsupported wallet type');
              }
              if (!tezos) {
                Log(
                  user.accountId,
                  'ShoppingCart',
                  JSON.stringify({
                    error: 'Session expired, please log in again.',
                  })
                );
                setError('Session expired, please log in again.');
                console.error('No Tezos Toolkit found');
                return;
              }
              if (!invoiceId) {
                console.log(invoiceId);
                Log(
                  user.accountId,
                  'ShoppingCart',
                  JSON.stringify({
                    error:
                      'Failed to create shopping cart invoice, please try again.',
                  })
                );
                setError(
                  'Failed to create shopping cart invoice, please try again.'
                );
                console.error('No invoiceId');
                return;
              }
              let batch;
              let total: number = 0;
              for (let i = 0; i < pricedItems.length; i++) {
                total +=
                  (pricedItems[i].token.purchase_price || 5000) / 1000000;
              }

              setPage(PAGES.CONFIRMATION);

              const transactionId = uuid();

              const onChainActionObject: OnchainAction = {
                type: 'collect',
                status: 'pending',
                title: `Purchasing ${pricedItems.length} for ${total} XTZ`,
                ...(pricedItems[0]?.token?.thumbnail_uri
                  ? {
                      thumbnail: pricedItems[0]?.token?.thumbnail_uri,
                    }
                  : {}),
                id: transactionId,
              };

              useOnchainActionStatusActions.add([onChainActionObject]);

              onClose?.(true);

              let running = false;
              let opHash;
              let op;
              let processed = false;

              if (user.walletType === 'beacon') {
                batch = tezos.wallet.batch([
                  {
                    kind: OpKind.TRANSACTION,
                    to: marketplaceConfig.marketplaceAdminAddress,
                    amount: total,
                  },
                ]);

                op = await batch.send();
                opHash = op.opHash;
              } else {
                batch = tezos.contract.batch([
                  {
                    kind: OpKind.TRANSACTION,
                    to: marketplaceConfig.marketplaceAdminAddress,
                    amount: total,
                  },
                ]);

                op = await batch.send();
                opHash = op.hash;
              }

              const paymentResults = await createPayment(
                Math.ceil(total * 1000000),
                invoiceId,
                PaymentMethod.Tezos,
                opHash
              ); // TODO: Log faillures; email failures; anything we can do as contingency

              const endpoint = 'wss://marketplace.dns.xyz/wss';
              // Create WebSocket connection.
              const socket = new WebSocket(endpoint);

              // Connection opened
              socket.addEventListener('open', _event => {
                const channelId = nanoid();
                socket.send(`subscribe:${user.address}:${channelId}`);
              });

              // Listen for messages
              socket.addEventListener('message', event => {
                console.log('Recieved message:', event.data);
                const decoded = JSON.parse(event.data);

                if (decoded.status === 'confirmed') {
                  if (!processed) {
                    processed = true;
                    processPurchase();
                    fulfillInvoice(invoiceId);
                  }
                }
              });

              function processPurchase() {
                if (running) return;
                running = true;
                useOnchainActionStatusActions.updateAction(
                  transactionId,
                  'done', // Still setting this to 'done' - that's what triggers the confetti
                  'Purchase completed!',
                  `${content.length} ${
                    content.length === 1 ? `item for` : `items totalling to`
                  } ${total} XTZ purchased, you will receive your items shortly.`
                );
                setBuyingInProgress(false);
                if (!user.isLoggedIn) return; // TODO: Find better fix
                // THIS FUNCTION BELOW IS ALL THE MUTATIONS: Update sale, post, add event. Also remove items from cart on success.
                runOnSuccessMutations(
                  pricedItems,
                  freeItems,
                  removeSale,
                  user,
                  updateSale,
                  insertEvent,
                  transactionId,
                  total,
                  setBuyingInProgress,
                  removeItems,
                  updatePost
                );
              }
            } else if (selectedPaymentMethod === 1) {
              setPage(PAGES.SELECT_AMOUNT);
            }
          }}
        >
          <span>
            {selectedPaymentMethod === 0 ? 'Confirm payment' : 'Next'}
          </span>
          {buyingInProgress && (
            <RemixIcon icon="loader-4-line" size={16} className={styles.spin} />
          )}
        </Button>
      </div>
    </div>
  ) : page === PAGES.SELECT_AMOUNT ? (
    <div className={clsx(styles.root, className)}>
      <span>
        <div>
          <span>Add Funds To Your Wallet</span>
          <span className={styles.secondaryText}>
            <CoinbaseIcon />
            <span>Powered by Coinbase</span>
          </span>
        </div>
      </span>
      <span className={styles.yourBalance}>
        Your Tezos Balance: {balance}XTZ
      </span>
      <div className={styles.selectAmount} data-vaul-no-drag="">
        {amountOptions?.map((amt, i) => {
          return (
            <Dialog
              className={styles.dialog}
              backdropClassname={styles.backdrop}
              key={amt}
              trigger={
                <Button
                  fullWidth
                  filled
                  accent
                  disabled={!user.isLoggedIn}
                  onClick={async () => {
                    if (!user.isLoggedIn) {
                      setError('Session expired, please log in again.');
                      return;
                    }
                  }}
                >
                  <span>
                    Add {amt} USD
                    {i === 0 && <span>Minimum Required</span>}
                  </span>
                  {tezPrice ? (
                    <div>~{(amt / tezPrice).toFixed(2)} XTZ</div>
                  ) : (
                    <RemixIcon
                      icon="loader-4-line"
                      size={16}
                      className={styles.spin}
                    />
                  )}
                </Button>
              }
            >
              <WalletBuyXTZDialog amount={amt} address={address || ''} />
            </Dialog>
          );
        })}
      </div>
      <div className={styles.actions}>
        <Button
          text
          onClick={() => {
            setPage(PAGES.SELECT_PAYMENT_METHOD);
          }}
        >
          <span>Back</span>
        </Button>
      </div>
    </div>
  ) : page === PAGES.PAYMENT_DETAILS ? (
    <div className={clsx(styles.root, className)}>
      <span>
        <div>
          <span>Payment details</span>
        </div>
      </span>
      <div className={styles.paymentForm}>
        <div>
          <div className={styles.selectGroup}>
            <span>Payment method</span>
            <Select
              defaultValue={paymentMethods[selectedPaymentMethod || 0]?.name}
              id="payment-methods"
              onChange={v => {
                setSelectedPaymentMethod(
                  paymentMethods.findIndex(m => m.name === v) || 0
                );
              }}
              options={paymentMethods.map((item, i) => item.name)}
              placeholder="Select"
              className={styles.select}
            />
          </div>
        </div>
        {(selectedPaymentMethod || 0) < 4 && (
          <div>
            <div className={clsx(styles.group, styles.groupFill)}>
              <span>Card number</span>
              <Input
                type="text"
                placeholder="0000 0000 0000 0000"
                defaultValue={''}
                onChange={v => {}}
              />
            </div>
            <div className={styles.group}>
              <span>Expiration date</span>
              <div className={styles.exp}>
                <Input
                  type="text"
                  placeholder="XX"
                  defaultValue={''}
                  onChange={v => {}}
                />
                <Input
                  type="text"
                  placeholder="XXXX"
                  defaultValue={''}
                  onChange={v => {}}
                />
              </div>
            </div>
            <div className={clsx(styles.group, styles.secCode)}>
              <span>Security code</span>
              <Input
                type="text"
                placeholder="XXX"
                defaultValue={''}
                onChange={v => {}}
              />
            </div>
          </div>
        )}
        <span>Billing information</span>
        <div>
          <div className={styles.group}>
            <span>First name</span>
            <Input
              type="text"
              placeholder="type something"
              defaultValue={''}
              onChange={v => {}}
            />
          </div>
          <div className={styles.group}>
            <span>Last name</span>
            <Input
              type="text"
              placeholder="type something"
              defaultValue={''}
              onChange={v => {}}
            />
          </div>
          <div className={styles.group}>
            <span>City</span>
            <Input
              type="text"
              placeholder="type something"
              defaultValue={''}
              onChange={v => {}}
            />
          </div>
        </div>
        <div>
          <div className={styles.group}>
            <span>Billing adress</span>
            <Input
              type="text"
              placeholder="type something"
              defaultValue={''}
              onChange={v => {}}
            />
          </div>
          <div className={styles.group}>
            <span>Zip code</span>
            <Input
              type="text"
              placeholder="XXXXXX"
              defaultValue={''}
              onChange={v => {}}
            />
          </div>
        </div>
        <div>
          <div className={styles.group}>
            <span>Country</span>
            <Input
              type="text"
              placeholder="type something"
              defaultValue={''}
              onChange={v => {}}
            />
          </div>
          <div className={styles.group}>
            <span>Phone</span>
            <Input
              type="number"
              placeholder="+X XXXXXXXXXX"
              defaultValue={''}
              onChange={v => {}}
            />
          </div>
        </div>
      </div>
      <div className={styles.confirmation}>
        <Checkbox checked={saveInfo} onChange={setSaveInfo} />
        <span>Save my payment informations for my next purchases</span>
      </div>
      <div className={styles.actions}>
        <Button
          text
          onClick={() => {
            setPage(PAGES.SELECT_PAYMENT_METHOD);
          }}
        >
          <span>Back</span>
        </Button>
        <Button
          accent
          onClick={() => {
            onClose?.(true);
          }}
        >
          <span>Proceed payment</span>
        </Button>
      </div>
    </div>
  ) : (
    <div className={clsx(styles.root, className)}>
      <InfiniteLoader height={350} />
    </div>
  );
}
