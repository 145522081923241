/**
 * @generated SignedSource<<36c1af9aefd8b4b97441412e92caa45e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type nftActionsGetOwnedCountQuery$variables = {
  accountId: string;
  tokenIds?: ReadonlyArray<string> | null;
};
export type nftActionsGetOwnedCountQuery$data = {
  readonly eventsCollection: {
    readonly totalCount: number;
  } | null;
};
export type nftActionsGetOwnedCountQuery = {
  response: nftActionsGetOwnedCountQuery$data;
  variables: nftActionsGetOwnedCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tokenIds"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "eq",
                    "variableName": "accountId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "account_id"
              },
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "in",
                    "variableName": "tokenIds"
                  }
                ],
                "kind": "ObjectValue",
                "name": "tezos_token_id"
              },
              {
                "kind": "Literal",
                "name": "type",
                "value": {
                  "in": [
                    "mint_tezos_token",
                    "pending_mint_tezos_token",
                    "purchase_tezos_token",
                    "pending_purchase_tezos_token",
                    "collect_for_free",
                    "mint_for_free"
                  ]
                }
              }
            ],
            "kind": "ObjectValue",
            "name": "and"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "eventsConnection",
    "kind": "LinkedField",
    "name": "eventsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "nftActionsGetOwnedCountQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "nftActionsGetOwnedCountQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "961c3a3d76f19f8e28723bd44c321033",
    "id": null,
    "metadata": {},
    "name": "nftActionsGetOwnedCountQuery",
    "operationKind": "query",
    "text": "query nftActionsGetOwnedCountQuery(\n  $accountId: BigInt!\n  $tokenIds: [BigInt!]\n) {\n  eventsCollection(filter: {and: {type: {in: [mint_tezos_token, pending_mint_tezos_token, purchase_tezos_token, pending_purchase_tezos_token, collect_for_free, mint_for_free]}, tezos_token_id: {in: $tokenIds}, account_id: {eq: $accountId}}}) {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "084a69f887a1213766126cb1c00dda9e";

export default node;
