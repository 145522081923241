/**
 * @generated SignedSource<<1e997281f1eb9a5baacc7853a0b26980>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DrawerProfileQuery$variables = {
  id: string;
};
export type DrawerProfileQuery$data = {
  readonly identitiesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly nodeId: string;
        readonly profilesCollection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly avatar_uri: string | null;
              readonly id: string;
              readonly nodeId: string;
              readonly username: string | null;
            };
          }>;
        } | null;
      };
    }>;
  } | null;
};
export type DrawerProfileQuery = {
  response: DrawerProfileQuery$data;
  variables: DrawerProfileQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "eq",
                "variableName": "id"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "identitiesConnection",
    "kind": "LinkedField",
    "name": "identitiesCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "identitiesEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "identities",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "profilesConnection",
                "kind": "LinkedField",
                "name": "profilesCollection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "profilesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "profiles",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "avatar_uri",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DrawerProfileQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DrawerProfileQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "6b6a9f9ae4a825b8144c38ce30caae0f",
    "id": null,
    "metadata": {},
    "name": "DrawerProfileQuery",
    "operationKind": "query",
    "text": "query DrawerProfileQuery(\n  $id: BigInt!\n) {\n  identitiesCollection(filter: {id: {eq: $id}}) {\n    edges {\n      node {\n        nodeId\n        id\n        profilesCollection {\n          edges {\n            node {\n              nodeId\n              id\n              username\n              avatar_uri\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7b5bdb1e720f33147f9dd18e096c47eb";

export default node;
