import {QueryClient, QueryClientProvider} from 'react-query';
import {persistQueryClient} from 'react-query/persistQueryClient-experimental';
import {createIDBPersister} from './reactQueryIndexedDbPersistor';
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 10, // 10 minutes
    },
  },
});

const indexedDbPersistor = createIDBPersister();

persistQueryClient({
  queryClient,
  persistor: indexedDbPersistor,
});

export {QueryClientProvider, queryClient};
