import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation removeFollowerMutation($filter: followsFilter!) {
    deleteFromfollowsCollection(filter: $filter) {
      records {
        follower {
          id
          nodeId
        }
        followee {
          id
          nodeId
        }
        id
        nodeId
      }
    }
  }
`;
