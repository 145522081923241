import config from '../config.json';
import {PaymentMethod} from './types';

export const createInvoice = async (identityId: string, purchaserAddress: string, items: any[]) => {
  if(!identityId || !purchaserAddress || !items) return;

  let simplifiedItems = items.map(i => {
    return {
      token_id: i.token.id,
      quantity: i.quantity,
      price: i.token.purchase_price
    };
  });

  const results = await fetch(`${config.marketplaceURI}/createInvoice`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      identityId,
      purchaserAddress,
      items: simplifiedItems
    })
  });

  return results.json();
};

export const createPayment = async (amount: number, invoiceId: number, paymentMethod: PaymentMethod, operationHash?: string) => {
  const results = await fetch(`${config.marketplaceURI}/createPayment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      amount,
      operationHash,
      invoiceId,
      paymentMethod
    })
  });

  return results.json();
};

export const fulfillInvoice = async (invoiceId: number) => {
  const results = await fetch(`${config.marketplaceURI}/fulfill`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      invoiceId,
    })
  });

  return results.json();
};

export {PaymentMethod} from './types';