import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation updateCommentMutation(
    $input: commentsUpdateInput!
    $filter: commentsFilter!
  ) {
    updatecommentsCollection(set: $input, filter: $filter) {
      records {
        id
        content
      }
    }
  }
`;
