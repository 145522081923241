import React from 'react';
import styles from '@/view/styles/components/Paper.module.scss';
import clsx from 'clsx';

export default function Paper({
  children,
  padding,
  className,
  onClick,
}: {
  children: React.ReactNode;
  padding?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent | React.TouchEvent) => void;
}) {
  return (
    <div
      className={clsx(styles.root, className, padding && styles.padding)}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
