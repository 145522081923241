import React, {Suspense} from 'react';
import styles from '@/view/styles/components/ManageItem/ManageItem.module.scss';
import Button from '../Button';
import clsx from 'clsx';
import {EditListingDialog} from './EditListing/EditListingDialog';
import {ListForSaleDialog} from './ListForSale/ListForSaleDialog';
import {graphql, useFragment, useLazyLoadQuery} from '@/kits/relay-kit/src';
import {useAccount} from '@/kits/account-kit/src';
import TezSymbol from '../TezSymbol';
import {ManageItemContentFragment$key} from './__generated__/ManageItemContentFragment.graphql';
import {ManageItemTokenFragment$key} from './__generated__/ManageItemTokenFragment.graphql';
import RemixIcon from '../RemixIcon';
import Tooltip from '../Tooltip';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import {useShoppingCart} from '@/state/hooks/shoppingCart';
import type {ManageItemContentMySalesQuery as ManageItemContentMySalesQueryType} from './__generated__/ManageItemContentMySalesQuery.graphql';
import type {ManageItemContentIsItemOwnedQuery as ManageItemContentIsItemOwnedQueryType} from './__generated__/ManageItemContentIsItemOwnedQuery.graphql';

const ManageItemTokenFragment = graphql`
  fragment ManageItemTokenFragment on tezos_tokens {
    ...ManageItemContentFragment
  }
`;

export default function ManageItem({
  tokenKey,
  className,
}: {
  tokenKey: ManageItemTokenFragment$key;
  className?: string;
}) {
  const {getAccount} = useAccount();
  const user = getAccount();
  const token = useFragment(ManageItemTokenFragment, tokenKey);
  return (
    user.isLoggedIn && (
      <Suspense fallback={<></>}>
        <ManageItemContent token={token} className={className} />
      </Suspense>
    )
  );
}

const ManageItemContentFragment = graphql`
  fragment ManageItemContentFragment on tezos_tokens {
    id
    nodeId
    title
    thumbnail_uri
    editions
    editions_minted
    accounts {
      id
      nodeId
      identities {
        id
        nodeId
        profilesCollection {
          edges {
            node {
              id
              nodeId
              username
            }
          }
        }
      }
      type
      magic_accountsCollection {
        edges {
          node {
            id
            nodeId
            public_key_hash
          }
        }
      }
      beacon_accountsCollection {
        edges {
          node {
            id
            nodeId
            public_key_hash
          }
        }
      }
      teztok_accountsCollection {
        edges {
          node {
            id
            nodeId
            public_key_hash
          }
        }
      }
    }
    is_purchasable
    purchase_price
    price_denomination
    metadata_uri
    ...EditListingDialogTokenFragment
    ...ListForSaleDialogTokenFragment
  }
`;

const ManageItemContentMySalesQuery = graphql`
  query ManageItemContentMySalesQuery($tokenId: BigInt!, $accountId: BigInt) {
    salesCollection(
      filter: {tezos_token_id: {eq: $tokenId}, account_id: {eq: $accountId}}
    ) {
      edges {
        node {
          id
          nodeId
          price
          ...ListForSaleSaleItemFragment
        }
      }
    }
  }
`;

const ManageItemContentIsItemOwnedQuery = graphql`
  query ManageItemContentIsItemOwnedQuery(
    $tokenId: BigInt!
    $accountId: BigInt!
  ) {
    eventsCollection(
      filter: {
        type: {
          in: [
            mint_tezos_token
            pending_mint_tezos_token
            purchase_tezos_token
            pending_purchase_tezos_token
            collect_for_free
            mint_for_free
          ]
        }
        tezos_token_id: {eq: $tokenId}
        account_id: {eq: $accountId}
      }
    ) {
      edges {
        node {
          id
          nodeId
        }
      }
    }
    collectedForFree: eventsCollection(
      filter: {
        type: {eq: collect_for_free}
        tezos_token_id: {eq: $tokenId}
        account_id: {eq: $accountId}
      }
    ) {
      edges {
        node {
          id
          nodeId
        }
      }
    }
    mintedForFree: eventsCollection(
      filter: {
        type: {eq: mint_for_free}
        tezos_token_id: {eq: $tokenId}
        account_id: {eq: $accountId}
      }
    ) {
      edges {
        node {
          id
          nodeId
        }
      }
    }
  }
`;

const ManageItemContent = ({
  token: tokenKey,
  className,
}: {
  token: ManageItemContentFragment$key;
  className?: string;
}) => {
  const token = useFragment(ManageItemContentFragment, tokenKey);
  const {getAccount} = useAccount();
  const user = getAccount();
  const accountId = user.isLoggedIn ? user.accountId : undefined;
  const identityId = user?.isLoggedIn ? user.identityId : undefined;

  const {addItem} = useShoppingCart();

  const {data: mySales, retry: refetch} =
    useLazyLoadQuery<ManageItemContentMySalesQueryType>(
      ManageItemContentMySalesQuery,
      {
        tokenId: token.id,
        accountId: accountId,
      },
      {
        skip:
          !token.id ||
          token.id === '' ||
          !user.isLoggedIn ||
          !accountId ||
          accountId === '',
      }
    );

  const canListItem =
    token.accounts.identities.id === identityId ||
    mySales?.salesCollection?.edges?.length !== 0;
  const primarySale =
    token.is_purchasable &&
    token.purchase_price !== null &&
    token.purchase_price > 0; // TODO: get from listings table

  const secondarySale = mySales?.salesCollection?.edges?.length !== 0;
  const myLowestPrice = mySales?.salesCollection?.edges?.reduce(
    (min, sale) => (sale.node.price < min ? sale.node.price : min),
    Infinity
  );

  const listed = primarySale || secondarySale;

  const {data: eventData} =
    useLazyLoadQuery<ManageItemContentIsItemOwnedQueryType>(
      ManageItemContentIsItemOwnedQuery,
      {
        accountId: accountId || '',
        tokenId: token.id || '',
      },
      {
        skip: !accountId || accountId === '' || !token.id || token.id === '',
      }
    );

  const {isMobile} = useBreakpoint();

  const ownedAndCanBeMinted = eventData?.collectedForFree?.edges?.length !== 0;
  const ownedAndMinted = eventData?.mintedForFree?.edges?.length !== 0;

  if (canListItem && listed) {
    return (
      <div
        className={clsx(styles.root, className)}
        data-listed={listed}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div>You have already listed this item for</div>
        {secondarySale ? (
          <ListForSaleDialog
            tokenKey={token}
            trigger={
              <Button text disabled>
                <span>
                  {myLowestPrice}
                  <TezSymbol sizeOverride={10} />
                </span>
              </Button>
            }
            mySalesList={
              mySales?.salesCollection?.edges?.map(sale => ({
                id: sale.node.id,
                nodeId: sale.node.nodeId,
                node: sale.node,
              })) || []
            }
            refetch={() => {
              refetch();
            }}
          />
        ) : (
          <EditListingDialog
            tokenKey={token}
            trigger={
              <Button text>
                <span>
                  {token.price_denomination === 'usd' && '$'}
                  {(token.purchase_price || 0) / 1000000}
                  {token.price_denomination === 'xtz' && (
                    <TezSymbol sizeOverride={10} />
                  )}
                </span>
              </Button>
            }
          />
        )}
      </div>
    );
  }

  if (canListItem) {
    return (
      <div
        className={clsx(styles.root, className)}
        data-listed={listed}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div>You own this item</div>
        <EditListingDialog
          tokenKey={token}
          trigger={
            <Button text>
              <span>list for sale?</span>
            </Button>
          }
        />
      </div>
    );
  }

  if (ownedAndMinted) {
    return (
      <div
        className={clsx(styles.root, className)}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div>You own this free item.</div>
      </div>
    );
  }

  if (ownedAndCanBeMinted) {
    return (
      <div
        className={clsx(styles.root, className)}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div>You own this free item.</div>
        {!isMobile && <div>Would you like to</div>}
        <div>
          <Button
            text
            onClick={() => {
              addItem({
                quantity: 1,
                type: 'nft',
                token: {
                  id: token.id,
                  nodeId: token.nodeId,
                  title: token.title,
                  editions_minted: token.editions_minted,
                  purchase_price: token.purchase_price,
                  is_purchasable: token.is_purchasable,
                  editions: token.editions,
                  metadata_uri: token.metadata_uri,
                  thumbnail_uri: token.thumbnail_uri,
                  creator: {
                    address:
                      token.accounts.type === 'magic'
                        ? token.accounts.magic_accountsCollection?.edges?.[0]
                            ?.node?.public_key_hash || null
                        : token.accounts.type === 'beacon'
                        ? token.accounts.beacon_accountsCollection?.edges?.[0]
                            ?.node?.public_key_hash || null
                        : token.accounts.type === 'teztok'
                        ? token.accounts.teztok_accountsCollection?.edges?.[0]
                            ?.node?.public_key_hash || null
                        : null,
                    username:
                      token.accounts.identities.profilesCollection?.edges?.[0]
                        ?.node?.username || null,
                  },
                },
                createCollectible: true,
              });
            }}
          >
            <span>
              {isMobile ? 'Create a collectible?' : 'create a collectible?'}
            </span>
          </Button>
          <Tooltip
            arrow={false}
            contentClassName={styles.collectibleTooltip}
            text={
              <div>
                <RemixIcon icon="information-line" size={38} />
                <span>
                  {`Onchain collectibles are like special items you can\nbuy, trade, and keep safe. You own them, and they\nare decentralized, so nobody can take them away!`}
                </span>
              </div>
            }
          >
            <RemixIcon icon="information-line" size={20} />
          </Tooltip>
        </div>
      </div>
    );
  }

  if (eventData?.eventsCollection?.edges?.length !== 0) {
    return (
      <div
        className={clsx(styles.root, className)}
        data-listed={listed}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div>
          You own <span>{token.title}</span>.
        </div>
        {
          <ListForSaleDialog
            tokenKey={token}
            trigger={
              <Button text>
                <span>list for sale?</span>
              </Button>
            }
            refetch={() => {
              refetch();
            }}
          />
        }
      </div>
    );
  }

  return null;
};
