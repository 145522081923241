import clsx from 'clsx';
import React from 'react';
import RemixIcon from '@/view/components/RemixIcon';
import Button from '@/view/components/Button';
import Chip from '@/view/components/Chip';
import styles from '@/view/styles/components/Input/blocs/ArrayInput.module.scss';

const STOP = 'STOP';

export default function ArrayInput<Type extends 'text' | 'number' | 'array'>({
  type,
  id,
  placeholder,
  onChange,
  onEnter,
  fullWidth,
  disabled,
  arrayElements,
  deleteArrayItem,
  inputRef,
  showArrayInput,
  setShowArrayInput,
  newArrayItem,
  setNewArrayItem,
  handleEnter,
}: {
  type: Type;
  id?: string;
  placeholder?: string;
  onChange: (value: string) => void | string;
  onEnter?: () => void;
  fullWidth?: boolean;
  disabled?: boolean;
  arrayElements: string[];
  deleteArrayItem: (index: number) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  showArrayInput: boolean;
  setShowArrayInput: React.Dispatch<React.SetStateAction<boolean>>;
  newArrayItem: string;
  setNewArrayItem: React.Dispatch<React.SetStateAction<string>>;
  handleEnter: () => void;
}) {
  return (
    <div className={styles.arrayInput}>
      {arrayElements.map((text, index) => (
        <Chip
          text={text}
          key={`${index}_${text}`}
          onDelete={() => {
            deleteArrayItem(index);
          }}
        />
      ))}
      {showArrayInput && (
        <input
          disabled={disabled}
          ref={inputRef}
          onKeyDown={e => {
            if (e.key === 'Enter' && !e.shiftKey) {
              onEnter && handleEnter();
            }
          }}
          autoFocus={true}
          type={type}
          id={id}
          placeholder={placeholder}
          onChange={e => {
            setNewArrayItem(e.target.value);
          }}
          className={clsx(styles.input, fullWidth && styles.fullWidth)}
        />
      )}
      <Button
        icon
        filled
        onClick={() => {
          if (showArrayInput) {
            if (newArrayItem) {
              const valueHolder = {value: newArrayItem};
              const newArray = [...arrayElements, valueHolder.value];
              const shouldBeVoid = onChange(newArray.join(','));
              if (typeof shouldBeVoid === 'string') {
                throw new Error(
                  'changing the value of an array using onChange is not supported'
                );
              }
              setNewArrayItem('');
            }
            setShowArrayInput(false);
          } else {
            setShowArrayInput(true);
          }
        }}
        className={styles.arrayInputAdd}
      >
        {showArrayInput ? (
          <RemixIcon icon="check-line" size={16} />
        ) : (
          <RemixIcon icon="add-line" size={16} />
        )}
      </Button>
    </div>
  );
}
