import {PRIMARY_DOMAIN} from '@/config';
import {enum_token_type} from '@/view/components/TokenPageContent/__generated__/TokenPageContentTokenFragment.graphql';

const DEFAULT_TITLE = 'DNS';
const DEFAULT_DESCRIPTION = 'DNS.xyz - Where everyone is a creator.';
const DEFAULT_IMAGE = `https://${PRIMARY_DOMAIN}/dns-avatar.webp`;
const DEFAULT_MEDIA_IMAGE = `https://${PRIMARY_DOMAIN}/audiocover.webp`;

export function generateMetaTags({
  id,
  title,
  description,
  image,
  ogType,
  url,
  mediaType,
  video,
  audio,
  creator,
}: {
  id?: string;
  title?: string;
  description?: string;
  image?: string;
  ogType?: string;
  url?: string;
  mediaType?: enum_token_type;
  video?: string;
  audio?: string;
  creator?: string;
}): Record<string, string | undefined> {
  let op: Record<string, string | undefined> = {};
  // OPEN GRAPH
  op['og:title'] = title ? `DNS.xyz | ${title}` : DEFAULT_TITLE;
  op['og:description'] = description ? `${description}` : DEFAULT_DESCRIPTION;
  op['og:image'] = image?.startsWith('http')
    ? image
    : image
    ? `https://ipfs.dns.xyz/ipfs/${image}`
    : mediaType === 'audio' || mediaType === 'video'
    ? DEFAULT_MEDIA_IMAGE
    : DEFAULT_IMAGE;
  op['og:url'] = url ? `${url}` : `https://${PRIMARY_DOMAIN}`;
  op['og:type'] = ogType ? `${ogType}` : 'website';
  if (mediaType === 'audio') {
    op['og:audio'] = audio ? `https://ipfs.dns.xyz/ipfs/${audio}` : undefined;
  }
  // TWITTER
  op['twitter:card'] = mediaType === 'post' ? 'summary' : 'summary_large_image';
  op['twitter:site'] = '@dns';
  op['twitter:creator'] = '@dns';
  op['twitter:title'] = title ? `DNS.xyz | ${title}` : DEFAULT_TITLE;
  op['twitter:description'] = description
    ? `${description}`
    : DEFAULT_DESCRIPTION;
  op['twitter:image'] = image?.startsWith('http')
    ? image
    : image
    ? `https://ipfs.dns.xyz/ipfs/${image}`
    : mediaType === 'audio'
    ? DEFAULT_MEDIA_IMAGE
    : DEFAULT_IMAGE;
  op['twitter:image:alt'] = title ? `${title}` : '';
  // FARCASTER
  op['fc:frame'] = 'vNext';
  op['fc:frame:image'] = image?.startsWith('http')
    ? image
    : image
    ? `https://ipfs.dns.xyz/ipfs/${image}`
    : mediaType === 'audio' || mediaType === 'video'
    ? DEFAULT_MEDIA_IMAGE
    : DEFAULT_IMAGE;
  op['fc:frame:button:1'] = 'View on DNS';
  op['fc:frame:button:1:action'] = 'link';
  op['fc:frame:button:1:target'] = url ? `${url}` : `https://${PRIMARY_DOMAIN}`;
  if (!!creator) {
    op['fc:frame:button:2'] = 'Profile';
    op['fc:frame:button:2:action'] = 'link';
    op['fc:frame:button:2:target'] = `https://${PRIMARY_DOMAIN}/${creator}`;
  }
  return op;
}
