import {create} from 'zustand';

type ItemModalState = {
  isOpen: boolean;
  id: string | null;
  username?: string | null;
};

type ItemModalFunctions = {
  setItem: (open: boolean, id: string | null, username?: string | null) => void;
};

const defaultState: ItemModalState = {
  isOpen: false,
  id: null,
  username: null,
};

export const useItemModal = create<ItemModalState>((set, get) => {
  return {
    ...defaultState,
  };
});

export const ItemModalActions: ItemModalFunctions = {
  setItem(open, id, username) {
    if (open) {
      useItemModal.setState({isOpen: open, id, username});
    } else {
      useItemModal.setState(defaultState);
    }
  },
};
