/**
 * @generated SignedSource<<4e3fe6a8e95e3dffd3226bb6900b17c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type enum_token_type = "audio" | "image" | "link" | "post" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuoteCardFragmentContainerTokenFragment$data = {
  readonly accounts: {
    readonly identities: {
      readonly profilesCollection: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly username: string | null;
          };
        }>;
      } | null;
    };
  };
  readonly description: string | null;
  readonly id: string;
  readonly mime_type: string;
  readonly title: string;
  readonly tokens_sharesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly quote_post_count: string;
      };
    }>;
  } | null;
  readonly type: enum_token_type;
  readonly " $fragmentSpreads": FragmentRefs<"FeedCardAssetTokenFragment" | "FeedCardDescriptionTokenFragment" | "QuoteCardHeaderTokenFragment">;
  readonly " $fragmentType": "QuoteCardFragmentContainerTokenFragment";
};
export type QuoteCardFragmentContainerTokenFragment$key = {
  readonly " $data"?: QuoteCardFragmentContainerTokenFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuoteCardFragmentContainerTokenFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuoteCardFragmentContainerTokenFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mime_type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "tokens_sharesConnection",
      "kind": "LinkedField",
      "name": "tokens_sharesCollection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "tokens_sharesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "tokens_shares",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "quote_post_count",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "identities",
          "kind": "LinkedField",
          "name": "identities",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "profilesConnection",
              "kind": "LinkedField",
              "name": "profilesCollection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "profilesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "profiles",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "username",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedCardAssetTokenFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedCardDescriptionTokenFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuoteCardHeaderTokenFragment"
    }
  ],
  "type": "tezos_tokens",
  "abstractKey": null
};

(node as any).hash = "ab69adbdb87f8cfb66bd214480247605";

export default node;
