import React from 'react';
import styles from '@/view/styles/components/ManageItem/ListForSale/ListForSale.module.scss';
import clsx from 'clsx';
import Button from '../../Button';
import RemixIcon from '../../RemixIcon';
import Input from '../../Input/Input';
import Select from '../../Select';
import {useAccount} from '@/kits/account-kit/src';
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from '@/kits/relay-kit/src';
import {
  MakeAddMarketplaceOperatorConfig,
  MakeSellConfig,
  MakeCancelConfig,
  SubmitTransactions,
} from '@/kits/tezos-nft-kit/src';
import {BatchOperation, TezosToolkit} from '@taquito/taquito';
import {getBeaconWallet} from '@/kits/beacon-auth-kit/src';
import {getTezosToolkitWithMagicProvider} from '@/kits/authentication-kit/src';
import insertSaleMutation from '@/graphql/insert-sale';
import type {insertSaleMutation as SaleInsertMutationType} from '@/graphql/__generated__/insertSaleMutation.graphql';
import {v4 as uuid} from 'uuid';
import removeSaleMutation from '@/graphql/remove-sale';
import type {removeSaleMutation as RemoveSaleMutationType} from '@/graphql/__generated__/removeSaleMutation.graphql';
import {TextLogoutButton} from '../../SendToken/SendToken';
import {useOnchainActionStatusActions} from '@/state/hooks/onChainActionStatus';
import {Log} from '@/kits/logging-kit/src';
import Router from 'next/router';
import {BatchWalletOperation} from '@taquito/taquito/dist/types/wallet/batch-operation';
import type {ListForSaleOwnedCountQuery as ListForSaleOwnedCountQueryType} from './__generated__/ListForSaleOwnedCountQuery.graphql';
import {ListForSaleSaleItemFragment$key} from './__generated__/ListForSaleSaleItemFragment.graphql';
import {ListForSaleSaleItemTokenFragment$key} from './__generated__/ListForSaleSaleItemTokenFragment.graphql';
import {ListForSaleTokenFragment$key} from './__generated__/ListForSaleTokenFragment.graphql';

enum Currency {
  tez = 'Tezos - XTZ',
  usd = 'USD',
}

const ListForSaleOwnedCountQuery = graphql`
  query ListForSaleOwnedCountQuery($tokenIds: [BigInt!], $accountId: BigInt!) {
    eventsCollection(
      filter: {
        and: {
          type: {
            in: [
              mint_tezos_token
              pending_mint_tezos_token
              purchase_tezos_token
              pending_purchase_tezos_token
              collect_for_free
              mint_for_free
            ]
          }
          tezos_token_id: {in: $tokenIds}
          account_id: {eq: $accountId}
        }
      }
    ) {
      totalCount
      edges {
        node {
          id
          nodeId
          tezos_tokens {
            id
            nodeId
            salesCollection {
              edges {
                node {
                  ...ListForSaleSaleItemFragment
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ListForSaleTokenFragment = graphql`
  fragment ListForSaleTokenFragment on tezos_tokens {
    id
    nodeId
    token_id
    title
    contract_address
    thumbnail_uri
    is_purchasable
    purchase_price
    ...ListForSaleSaleItemTokenFragment
  }
`;

const currencyOptions = ['Tezos - XTZ', 'USD'];
export default function ListForSale({
  tokenKey,
  className,
  mySalesList,
  closeDialog,
  refetch,
}: {
  tokenKey: ListForSaleTokenFragment$key;
  className?: string;
  mySalesList?: {
    id: string;
    nodeId: string;
    node: ListForSaleSaleItemFragment$key | null | undefined;
  }[];
  closeDialog: () => void;
  refetch?: () => void;
}) {
  const {getAccount} = useAccount();
  const user = getAccount();
  const accountId = user.isLoggedIn ? user.accountId : undefined;

  const token = useFragment(ListForSaleTokenFragment, tokenKey);

  const {data: ownedCount} = useLazyLoadQuery<ListForSaleOwnedCountQueryType>(
    ListForSaleOwnedCountQuery,
    {
      accountId: accountId || '',
      tokenIds: [token.id],
    },
    {
      skip: !accountId || accountId === '' || !token.id || token.id === '',
    }
  );

  const [sendingCount, setSendingCount] = React.useState<number>();
  const [price, setPrice] = React.useState(() =>
    token.is_purchasable ? (token.purchase_price || 0) / 1000000 || 0 : 0
  );
  const [currency, setCurrency] = React.useState(Currency.tez);
  const [error, setError] = React.useState<string | null>(null);

  const showLogoutButton = error === 'Session expired, please log in again.';

  const [tezos, setTezos] = React.useState<TezosToolkit>();

  const updateTezosToolkit = React.useCallback(async () => {
    if (!user.isLoggedIn) return;

    if (user.walletType === 'beacon') {
      const {tezosToolkit} = getBeaconWallet();
      setTezos(tezosToolkit);
    } else if (user.walletType === 'magic') {
      const t = await getTezosToolkitWithMagicProvider();
      if (!t) {
        Log(
          user.accountId,
          'ListForSale',
          JSON.stringify({
            error: 'Failed to get Tezos Toolkit with Magic Provider',
          })
        );
        setError('Session expired, please log in again.');
        throw new Error('Unable to get Tezos Toolkit with Magic Provider');
      }
      setTezos(t);
    }
  }, [user]);

  React.useEffect(() => {
    updateTezosToolkit();
  }, [updateTezosToolkit]);

  const [insertSale] = useMutation<SaleInsertMutationType>(insertSaleMutation);

  const [listingForSale, setListingForSale] = React.useState(false);

  const hasSales = mySalesList && mySalesList.length > 0;

  const [showCreateSale, setShowCreateSale] = React.useState(false);

  return (
    <div className={clsx(styles.root, className)}>
      {hasSales && (
        <div className={styles.sales}>
          <span>Your listings</span>
          {mySalesList?.map(sale => {
            return (
              <SaleItem
                key={sale.id}
                saleKey={sale.node}
                tokenKey={token}
                closeDialog={() => {
                  closeDialog();
                  refetch?.();
                }}
                setError={setError}
              />
            );
          })}
        </div>
      )}
      {(showCreateSale || !hasSales) && (
        <>
          <span>List for sale</span>
          <div className={styles.content}>
            <div>
              <div>
                <div>How many do you want to sell?</div>
                <span>
                  You own {ownedCount?.eventsCollection?.totalCount || 0}
                </span>
              </div>
              <Input
                type="number"
                defaultValue={sendingCount}
                onChange={v => setSendingCount(parseInt(v))}
                max={ownedCount?.eventsCollection?.totalCount || 0}
                min={0}
                placeholder="0"
                onBlur={e => {
                  if (
                    Number(e.currentTarget.value) >
                    (ownedCount?.eventsCollection?.totalCount || 0)
                  ) {
                    setError('You do not own that many editions');
                  }
                }}
              />
            </div>
            <hr />
            <div>
              <div>Price</div>
              <div>
                <Input
                  type="number"
                  defaultValue={undefined}
                  onChange={v => setPrice(parseFloat(v))}
                  placeholder={price.toString()}
                  min={0}
                />
                <Select
                  defaultValue={currency}
                  onChange={v => setCurrency(v as Currency)}
                  id="currency"
                  options={currencyOptions}
                  className={styles.currencySelect}
                />
              </div>
            </div>
          </div>
          <Button
            accent
            className={styles.list}
            onClick={async () => {
              if (!user.isLoggedIn) return;
              if (!tezos) {
                Log(
                  user.accountId,
                  'ListForSale',
                  JSON.stringify({
                    error: 'Session expired, please log in again.',
                  })
                );
                setError('Session expired, please log in again.');
                return;
              }
              if (!token.token_id) return;
              if (listingForSale) return;
              setListingForSale(true);
              let transactionConfigs = [];

              transactionConfigs.push(
                await MakeAddMarketplaceOperatorConfig(
                  user.address,
                  Number(token.token_id)
                )
              );

              closeDialog();
              const transactionId1 = uuid();
              useOnchainActionStatusActions.add([
                {
                  type: 'list',
                  status: 'pending',
                  title: `Listing ${sendingCount} of ${token.title} for ${price} XTZ each`,
                  ...(token?.thumbnail_uri
                    ? {thumbnail: token?.thumbnail_uri}
                    : {}),
                  action: () => {
                    Router.push(`/item/${token.id}`);
                  },
                  actionText: 'VIEW',
                  retryFunction: () => {
                    setError(null);
                  },
                  id: transactionId1,
                },
              ]);

              SubmitTransactions(
                // THIS IS ADD MARKETPLACE OPERATOR CALL
                tezos,
                transactionConfigs,
                user.walletType
              ).then(
                async res => {
                  let results: any[] | undefined;

                  await res.confirmation().then(
                    async () => {
                      // ...like this?
                      if (user.walletType === 'beacon') {
                        results = await (
                          res as BatchWalletOperation
                        )?.operationResults();
                      } else if (user.walletType === 'magic') {
                        results = (res as BatchOperation)?.results;
                      }

                      if (!results) {
                        setError(
                          'No results from operation. Please try again.'
                        );
                        console.error(
                          'No results from operation. Please try again.'
                        );
                        return;
                      }

                      if (!token.token_id) {
                        setError('Something went wrong.');
                        setListingForSale(false);
                        return;
                      }

                      if (!sendingCount || sendingCount <= 0) {
                        setError('Please enter a valid amount');
                        setListingForSale(false);
                        return;
                      }

                      let transactionConfigs: any[] = [];

                      transactionConfigs.push(
                        await MakeSellConfig(
                          user.address,
                          sendingCount,
                          token.contract_address,
                          price,
                          Number(token.token_id)
                        )
                      );

                      const transactionId2 = uuid();
                      useOnchainActionStatusActions.remove(transactionId1);
                      useOnchainActionStatusActions.add([
                        {
                          type: 'list',
                          status: 'pending',
                          title: `Listing ${sendingCount} of ${token.title} for ${price} XTZ each`,
                          ...(token?.thumbnail_uri
                            ? {thumbnail: token?.thumbnail_uri}
                            : {}),
                          action: () => {
                            Router.push(`/item/${token.id}`);
                          },
                          actionText: 'VIEW',
                          retryFunction: () => {
                            setError(null);
                            ListToken(transactionId2, transactionConfigs);
                          },
                          id: transactionId2,
                        },
                      ]);

                      await ListToken(transactionId2, transactionConfigs); // THIS IS SELL CALL

                      async function ListToken(
                        transactionId: string,
                        transactionConfigs: any[]
                      ) {
                        if (!user.isLoggedIn) return;
                        if (!tezos) {
                          setError('Session expired, please log in again.');
                          return;
                        }
                        if (!token.token_id) return;

                        await SubmitTransactions(
                          tezos,
                          transactionConfigs,
                          user.walletType
                        ).then(
                          async res => {
                            let results;

                            if (user.walletType === 'beacon') {
                              results = await (
                                res as BatchWalletOperation
                              )?.operationResults();
                            } else if (user.walletType === 'magic') {
                              results = (res as BatchOperation)?.results;
                            }

                            if (!results) {
                              setError(
                                'No results from operation. Please try again.'
                              );
                              console.error(
                                'No results from operation. Please try again.'
                              );
                              return;
                            }
                            const saleId = (results[0] as any)?.metadata
                              .operation_result.lazy_storage_diff[1].diff
                              .updates[0].key.int;
                            if (!saleId) {
                              setError('Something went wrong.');
                              setListingForSale(false);
                              return;
                            }
                            useOnchainActionStatusActions.updateAction(
                              transactionId,
                              'pending',
                              'Listing confirmed, creating sale...'
                            );
                            insertSale({
                              variables: {
                                input: [
                                  {
                                    sale_id: saleId,
                                    created_at: 'now',
                                    updated_at: 'now',
                                    account_id: accountId || '',
                                    amount: sendingCount?.toString(),
                                    price: price,
                                    tezos_token_id: token.id,
                                  },
                                ],
                              },
                              onCompleted(response) {
                                useOnchainActionStatusActions.updateAction(
                                  transactionId,
                                  'done',
                                  'Listing confirmed, sale created'
                                );
                                refetch?.();
                              },
                              onError(error) {
                                useOnchainActionStatusActions.updateAction(
                                  transactionId,
                                  'error',
                                  'Failed to create sale'
                                );
                                console.error(error);
                                setListingForSale(false);
                              },
                            });
                          },
                          e => {
                            useOnchainActionStatusActions.updateAction(
                              transactionId,
                              'error',
                              'Failed to submit transaction'
                            );
                            console.error(JSON.stringify(e));
                            setError(JSON.stringify(e));
                            setListingForSale(false);
                          }
                        );
                      }
                    },
                    e => {
                      console.error(e);
                      setError('Something went wrong.');
                      setListingForSale(false);
                    }
                  );
                },
                e => {
                  useOnchainActionStatusActions.updateAction(
                    transactionId1,
                    'error',
                    'Failed to submit transaction'
                  );
                  console.error(e);
                  setError('Something went wrong.');
                  setListingForSale(false);
                }
              );
            }}
            disabled={
              !user.isLoggedIn ||
              !tezos ||
              (sendingCount && sendingCount <= 0) ||
              (sendingCount &&
                sendingCount >
                  (ownedCount?.eventsCollection?.totalCount || 0)) ||
              price <= 0 ||
              !token.token_id ||
              listingForSale
            }
          >
            <RemixIcon icon="price-tag-3-line" size={18} />
            <span>{listingForSale ? 'Please wait...' : 'List for sale'}</span>
          </Button>
        </>
      )}
      {hasSales && !showCreateSale && (
        <Button
          outlined
          className={styles.list}
          onClick={() => setShowCreateSale(true)}
        >
          <RemixIcon icon="add-line" size={18} />
          <span>Create listing</span>
        </Button>
      )}
      {error && (
        <div className={styles.error}>
          <RemixIcon icon="error-warning-line" size={16} />
          <span>
            {error} {showLogoutButton && <TextLogoutButton />}
          </span>
        </div>
      )}
    </div>
  );
}

const ListForSaleSaleItemTokenFragment = graphql`
  fragment ListForSaleSaleItemTokenFragment on tezos_tokens {
    id
    token_id
    title
    contract_address
    thumbnail_uri
    is_purchasable
    purchase_price
  }
`;

const ListForSaleSaleItemFragment = graphql`
  fragment ListForSaleSaleItemFragment on sales {
    id
    sale_id
    price
    created_at
  }
`;

const SaleItem = ({
  saleKey,
  tokenKey,
  closeDialog,
  setError,
}: {
  saleKey: ListForSaleSaleItemFragment$key | null | undefined;
  tokenKey: ListForSaleSaleItemTokenFragment$key;
  closeDialog: () => void;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
}) => {
  const sale = useFragment(ListForSaleSaleItemFragment, saleKey);
  const token = useFragment(ListForSaleSaleItemTokenFragment, tokenKey);

  const {getAccount} = useAccount();
  const user = getAccount();

  const [confirmCancel, setConfirmCancel] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!confirmCancel) return;
    const timeout = setTimeout(() => {
      setConfirmCancel(false);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [confirmCancel]);

  const [removeSale] = useMutation<RemoveSaleMutationType>(removeSaleMutation);

  const [tezos, setTezos] = React.useState<TezosToolkit>();

  const updateTezosToolkit = React.useCallback(async () => {
    if (!user.isLoggedIn) return;

    if (user.walletType === 'beacon') {
      const {tezosToolkit} = getBeaconWallet();
      setTezos(tezosToolkit);
    } else if (user.walletType === 'magic') {
      const t = await getTezosToolkitWithMagicProvider();
      if (!t) {
        Log(
          user.accountId,
          'ListForSale',
          JSON.stringify({
            error: 'Failed to get Tezos Toolkit with Magic Provider',
          })
        );
        setError('Session expired, please log in again.');
        throw new Error('Unable to get Tezos Toolkit with Magic Provider');
      }
      setTezos(t);
    }
  }, [user, setError]);

  React.useEffect(() => {
    updateTezosToolkit();
  }, [updateTezosToolkit]);

  const [cancelling, setCancelling] = React.useState(false);

  return (
    <div className={styles.sale}>
      <div>
        <div>
          {sale?.price} <span>XTZ</span>
        </div>
        <div>
          Created {new Date(sale?.created_at || '').toLocaleDateString()} at{' '}
          {new Date(sale?.created_at || '').toLocaleTimeString()}
        </div>
      </div>
      {confirmCancel ? (
        <Button
          accent
          onClick={async () => {
            if (!user.isLoggedIn) return;
            if (!tezos) {
              setError('Session expired, please log in again.');
              return;
            }
            if (!sale?.sale_id) return;
            setCancelling(true);
            let transactionConfigs: any[] = [];

            const cancelConfig = await MakeCancelConfig(sale.sale_id);
            transactionConfigs.push(cancelConfig);

            const transactionId = uuid();
            useOnchainActionStatusActions.add([
              {
                type: 'cancel',
                status: 'pending',
                title: `Cancelling listing for ${token.title}`,
                ...(token?.thumbnail_uri
                  ? {thumbnail: token?.thumbnail_uri}
                  : {}),
                retryFunction: () => {
                  setError(null);
                  CancelListing(transactionId);
                },
                id: transactionId,
              },
            ]);

            closeDialog();
            await CancelListing(transactionId);

            async function CancelListing(transactionId: string) {
              if (!user.isLoggedIn) return;
              if (!tezos) {
                setError('Session expired, please log in again.');
                return;
              }
              if (!sale?.sale_id) return;
              await SubmitTransactions(
                tezos,
                transactionConfigs,
                user.walletType
              ).then(
                () => {
                  useOnchainActionStatusActions.updateAction(
                    transactionId,
                    'pending',
                    'Waiting for confirmation...'
                  );
                  removeSale({
                    variables: {
                      filter: {
                        id: {
                          eq: sale.id,
                        },
                      },
                    },
                    onCompleted() {
                      useOnchainActionStatusActions.updateAction(
                        transactionId,
                        'done',
                        'Listing cancelled!'
                      );
                      setCancelling(false);
                      closeDialog();
                    },
                    onError(error) {
                      useOnchainActionStatusActions.updateAction(
                        transactionId,
                        'error',
                        'Failed to cancel listing'
                      );
                      setCancelling(false);
                    },
                    updater(store) {
                      const deleted = store
                        .getRootField('deleteFromsalesCollection')
                        ?.getLinkedRecords('records')?.[0]
                        ?.getValue('nodeId');
                      if (!deleted) return;
                      store.delete(deleted);
                      closeDialog();
                    },
                  });
                },
                e => {
                  useOnchainActionStatusActions.updateAction(
                    transactionId,
                    'error',
                    'Failed to submit transaction'
                  );
                  setCancelling(false);
                }
              );
            }
          }}
          data-confirm="true"
          className={styles.confirm}
          disabled={
            !user.isLoggedIn || cancelling || !tezos || !token.id || !sale?.id
          }
        >
          <span>{cancelling ? 'Please wait...' : 'Yes!'}</span>
        </Button>
      ) : (
        <Button
          accent
          onClick={() => {
            if (!user.isLoggedIn) return;
            setConfirmCancel(true);
          }}
          disabled={!user.isLoggedIn || cancelling || !tezos || !token.id}
        >
          <span>{cancelling ? 'Please wait...' : 'Cancel'}</span>
        </Button>
      )}
    </div>
  );
};
