import {create} from 'zustand';
import {persist} from 'zustand/middleware';

const DEFAULT_DARK_MODE_ENABLED = true;

export const useDarkMode = create(
  persist(
    () => ({
      darkMode: DEFAULT_DARK_MODE_ENABLED,
    }),
    {
      name: 'darkMode',
    }
  )
);

export const DarkModeActions = {
  setDarkMode(darkMode: boolean) {
    useDarkMode.setState({darkMode: darkMode});
  },
};
