import {create} from 'zustand';
import {persist} from 'zustand/middleware';
import {MetadataType} from '@/view/types/mintTypes';
import {RightsEnum} from '@/view/types/mintTypes';

export interface IMint {
  id: string;
  ipfs: boolean;
  name: string;
  description: string;
  type: string;
  size: number;
  tmpThumb?: string;
  dimensions: string;
  editions: number;
  metadata: MetadataType;
  ready: boolean;
  cocreators: string[];
  license: RightsEnum;
  nsfw: boolean;
  photosensitiveWarning: boolean;
  royalty: number;
  royaltySplit: {
    [key: string]: number;
  };
  attributes: {
    name: string;
    value: string;
  }[];
  processing: {
    progress: number;
    completeTasks: string[];
    maxProgress: number;
  };
  uploading: {
    progress: number;
    maxProgress: number;
  };
  tags: string[];
}

export enum ProgressType {
  UPLOADED_ASSET = 'UPLOADED_ASSET',
  RESIZED_COVER = 'RESIZED_COVER',
  RESIZED_THUMB = 'RESIZED_THUMB',
  UPLOADED_COVER = 'UPLOADED_COVER',
  UPLOADED_THUMB = 'UPLOADED_THUMB',
  DONE = 'DONE',
}

type MintStore = {
  currentMint: IMint | null;
  updateMint: (mint: IMint) => void;
  removeMint: () => void;
  getMintMetadata: (wallet: string) => MetadataType;
};

export const mintStore = create<MintStore>()(
  persist(
    (set, get) => ({
      currentMint: null,
      updateMint: (mint: IMint) => {
        set(state => ({
          ...state,
          currentMint: mint,
        }));
      },
      removeMint: () => {
        set(state => ({
          ...state,
          currentMint: null,
        }));
      },
      getMintMetadata: (address: string) => {
        const {currentMint} = get();
        const processedMint: MetadataType = {
          ...currentMint?.metadata,
          name: currentMint?.name || '',
          description: currentMint?.description || '',
          editions: currentMint?.editions || 0,
          creators: [
            address,
            ...(currentMint?.cocreators?.filter(c => c !== address) || []),
          ],
          royalties: {
            decimals: 4,
            shares: Object.fromEntries(
              Object.entries(currentMint?.royaltySplit || []).map(
                ([addr, percent]) => [addr, percent * 10]
              )
            ), //This should be an array of string and percentage (number) in 3 decimal places
          },
          contentRating: currentMint?.nsfw ? 'mature' : undefined,
          ...(currentMint?.photosensitiveWarning
            ? {
                accessibility: {
                  hazards: ['flashing'],
                },
              }
            : {}),
          rights: currentMint?.license || RightsEnum.NONE,
        };

        return processedMint;
      },
    }),
    {
      name: 'mintStore',
      storage: {
        // TODO: check if this breaks hyperminter
        getItem(name) {
          const str = localStorage.getItem(name);
          if (!str) {
            return {state: {currentMints: new Map(), mintsOrder: []}};
          }
          const parsed = JSON.parse(str);
          const currentMints = parsed.currentMints.map((e: [string, IMint]) => {
            delete e[1].tmpThumb;
            return e;
          });
          return {
            state: {
              currentMints: new Map<string, IMint>(currentMints),
              mintsOrder:
                parsed.mintsOrder ||
                currentMints?.map(([id]: [string, IMint]) => id) ||
                [],
            },
          };
        },
        setItem(name, value) {
          const {currentMints, mintsOrder} = value.state;
          const state = {
            currentMints: [...currentMints.entries()],
            mintsOrder,
          };
          localStorage.setItem(name, JSON.stringify(state));
        },
        removeItem(name) {
          localStorage.removeItem(name);
        },
      },
    }
  )
);
