import Axios from 'axios';
import {MINTER_URI} from '@/config';
import {jsonToBlob} from '@/utils/objects';

export async function uploadText(description: string) {
  const formData = new FormData();
  formData.append('file', jsonToBlob(description));
  const {data} = await Axios.post(`${MINTER_URI}/upload/ipfs-simple`, formData);
  return `https://ipfs.dns.xyz/ipfs/${data.cid}`;
}
