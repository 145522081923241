import React from 'react';

export const usePlaceholderIds = (count: number) => {
  // Number of placeholders to show (15 here)
  return [
    React.useId(),
    React.useId(),
    React.useId(),
    React.useId(),
    React.useId(),
    React.useId(),
    React.useId(),
    React.useId(),
    React.useId(),
    React.useId(),
    React.useId(),
    React.useId(),
    React.useId(),
    React.useId(),
    React.useId(),
  ].slice(0, count);
};
