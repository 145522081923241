/**
 * @generated SignedSource<<4ae0886adf08a3272dcdf7bdfe816489>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddToCollectionPlaylistsPaginatedQuery$variables = {
  after?: any | null;
  first?: number | null;
  identityIds?: ReadonlyArray<string> | null;
  loggedIn: boolean;
  tokenId?: string | null;
};
export type AddToCollectionPlaylistsPaginatedQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AddToCollectionPlaylistsPaginated">;
};
export type AddToCollectionPlaylistsPaginatedQuery = {
  response: AddToCollectionPlaylistsPaginatedQuery$data;
  variables: AddToCollectionPlaylistsPaginatedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 30,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "identityIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "loggedIn"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tokenId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = [
  (v1/*: any*/),
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "in",
            "variableName": "identityIds"
          }
        ],
        "kind": "ObjectValue",
        "name": "identity_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddToCollectionPlaylistsPaginatedQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "identityIds",
            "variableName": "identityIds"
          },
          {
            "kind": "Variable",
            "name": "loggedIn",
            "variableName": "loggedIn"
          },
          {
            "kind": "Variable",
            "name": "tokenId",
            "variableName": "tokenId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "AddToCollectionPlaylistsPaginated"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddToCollectionPlaylistsPaginatedQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "playlistsConnection",
        "kind": "LinkedField",
        "name": "playlistsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "playlistsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "playlists",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image_uri",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "visibility",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "playlists_tokensConnection",
                    "kind": "LinkedField",
                    "name": "playlists_tokensCollection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "condition": "loggedIn",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": "exists",
                        "args": [
                          {
                            "fields": [
                              {
                                "fields": [
                                  {
                                    "kind": "Variable",
                                    "name": "eq",
                                    "variableName": "tokenId"
                                  }
                                ],
                                "kind": "ObjectValue",
                                "name": "tezos_token_id"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "filter"
                          }
                        ],
                        "concreteType": "playlists_tokensConnection",
                        "kind": "LinkedField",
                        "name": "playlists_tokensCollection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "playlists_tokensEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "playlists_tokens",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "filter"
        ],
        "handle": "connection",
        "key": "AddToCollectionPlaylistsPaginated_playlistsCollection",
        "kind": "LinkedHandle",
        "name": "playlistsCollection"
      }
    ]
  },
  "params": {
    "cacheID": "3d61d4ec4e7ffd22438614aaab65d987",
    "id": null,
    "metadata": {},
    "name": "AddToCollectionPlaylistsPaginatedQuery",
    "operationKind": "query",
    "text": "query AddToCollectionPlaylistsPaginatedQuery(\n  $after: Cursor\n  $first: Int = 30\n  $identityIds: [BigInt!]\n  $loggedIn: Boolean!\n  $tokenId: BigInt\n) {\n  ...AddToCollectionPlaylistsPaginated_496LHd\n}\n\nfragment AddToCollectionPlaylistsPaginated_496LHd on Query {\n  playlistsCollection(filter: {identity_id: {in: $identityIds}}, first: $first, after: $after) {\n    edges {\n      node {\n        nodeId\n        id\n        title\n        image_uri\n        visibility\n        playlists_tokensCollection {\n          totalCount\n        }\n        exists: playlists_tokensCollection(filter: {tezos_token_id: {eq: $tokenId}}) @include(if: $loggedIn) {\n          edges {\n            node {\n              id\n              nodeId\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "85267a5a2dfbc2d3b1ecc8458efdca4f";

export default node;
