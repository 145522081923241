import React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import styles from '@/view/styles/components/Checkbox.module.scss';
import RemixIcon from './RemixIcon';

export default function Checkbox({
  checked,
  label,
  onChange,
  disabled = false,
}: {
  checked?: boolean;
  label?: string;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
}) {
  const [checkedState, setCheckedState] = React.useState<boolean>(
    checked || false
  );

  React.useEffect(() => {
    setCheckedState(checked || false);
  }, [checked]);
  return (
    <form className={styles.root}>
      <CheckboxPrimitive.Root
        checked={checkedState}
        id="checkbox"
        onCheckedChange={() => {
          setCheckedState(!checkedState);
          if (onChange) {
            onChange(!checkedState);
          }
        }}
        disabled={disabled}
      >
        <CheckboxPrimitive.Indicator>
          <RemixIcon icon="check-line" size={16} />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      {label && <label htmlFor="checkbox">{label}</label>}
    </form>
  );
}
