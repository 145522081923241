/**
 * @generated SignedSource<<d9b9c8ed801ca064f93b20da70fcab07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewPostCardProfileFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"NewPostCardDetailsHeaderFragment">;
  readonly " $fragmentType": "NewPostCardProfileFragment";
};
export type NewPostCardProfileFragment$key = {
  readonly " $data"?: NewPostCardProfileFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewPostCardProfileFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewPostCardProfileFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NewPostCardDetailsHeaderFragment"
    }
  ],
  "type": "identities",
  "abstractKey": null
};

(node as any).hash = "bb32301a0ced5b16025355c2cb6a7b44";

export default node;
