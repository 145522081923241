/**
 * @generated SignedSource<<9dcd10751185bfcf812002361927b596>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FilterIs = "NOT_NULL" | "NULL" | "%future added value";
export type tokens_sharesUpdateInput = {
  count?: string | null;
  created_at?: string | null;
  quote_post_count?: string | null;
  repost_count?: string | null;
  share_count?: string | null;
  tezos_token_id?: string | null;
  updated_at?: string | null;
};
export type tokens_sharesFilter = {
  and?: ReadonlyArray<tokens_sharesFilter> | null;
  count?: BigIntFilter | null;
  created_at?: DatetimeFilter | null;
  id?: BigIntFilter | null;
  nodeId?: IDFilter | null;
  not?: tokens_sharesFilter | null;
  or?: ReadonlyArray<tokens_sharesFilter> | null;
  quote_post_count?: BigIntFilter | null;
  repost_count?: BigIntFilter | null;
  share_count?: BigIntFilter | null;
  tezos_token_id?: BigIntFilter | null;
  updated_at?: DatetimeFilter | null;
};
export type BigIntFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type DatetimeFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type IDFilter = {
  eq?: string | null;
};
export type updateShareMutation$variables = {
  filter: tokens_sharesFilter;
  input: tokens_sharesUpdateInput;
};
export type updateShareMutation$data = {
  readonly updatetokens_sharesCollection: {
    readonly records: ReadonlyArray<{
      readonly id: string;
      readonly quote_post_count: string;
      readonly repost_count: string;
      readonly share_count: string;
    }>;
  };
};
export type updateShareMutation = {
  response: updateShareMutation$data;
  variables: updateShareMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "set",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "share_count",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "repost_count",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "quote_post_count",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateShareMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "tokens_sharesUpdateResponse",
        "kind": "LinkedField",
        "name": "updatetokens_sharesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "tokens_shares",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateShareMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "tokens_sharesUpdateResponse",
        "kind": "LinkedField",
        "name": "updatetokens_sharesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "tokens_shares",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodeId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "242bfe94990864d5b5061fbc32611b8c",
    "id": null,
    "metadata": {},
    "name": "updateShareMutation",
    "operationKind": "mutation",
    "text": "mutation updateShareMutation(\n  $input: tokens_sharesUpdateInput!\n  $filter: tokens_sharesFilter!\n) {\n  updatetokens_sharesCollection(set: $input, filter: $filter) {\n    records {\n      id\n      share_count\n      repost_count\n      quote_post_count\n      nodeId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1c0d9a5310b731157e17da54da68dbf9";

export default node;
