import {UserActions} from '@/state/hooks/actionsMenu';
import {ToastActions} from '@/state/hooks/toasts';
import RemixIcon from '@/view/components/RemixIcon';
import styles from '@/view/styles/providers/Dialog.module.scss';
import {ActionType} from '@/view/types/types';
import {useMemo} from 'react';
import {v4 as uuid} from 'uuid';
import Router from 'next/router';
import Image from '@/view/components/Image';
import WarpcastIcon from '@/view/resources/icons/Warpcast.svg';

const copyLinkText = 'Copy link';

export const useUserViewToActions = ({
  view,
  share,
}: {
  view: UserActions | undefined;
  share?: (
    item: {
      id: string;
      nodeId: string;
    },
    type: 'post' | 'list' | 'user' | 'blog'
  ) => void;
}) => {
  const userActions: ActionType[][] = useMemo(() => {
    const user = view?.type === 'user' ? view.user : undefined;

    if (!user) {
      return [];
    }

    const profileLink = !!user?.username
      ? user.username
      : user?.address
      ? user.address
      : user?.id;

    const explorerAddress = user?.address || '';

    return [
      [
        {
          icon: <RemixIcon icon="user-line" size={22} />,
          onClick: () => {
            Router.push(`/${profileLink}`);
          },
          title: `View profile`,
          override: false as false,
        },
        {
          icon: <RemixIcon icon="node-tree" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://tzkt.io/${explorerAddress}`,
                '_blank',
                'noopener noreferrer'
              );
            }
          },
          title: `View on TzKT`,
          override: false as false,
        },
        {
          icon: <RemixIcon icon="share-forward-line" size={22} />,
          onClick: () => {},
          title: 'Share',
          doNotClose: true,
          setPage: 1,
          override: false as false,
        },
      ],
      [
        {
          icon: <RemixIcon icon="arrow-left-s-line" size={22} />,
          ...((view?.page || 0) === 0
            ? {setPage: 0, doNotClose: true}
            : {
                onClick: () => {},
              }),
          title: 'Back',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="file-copy-line" size={22} />,
          onClick: () => {
            navigator.clipboard.writeText(
              `${window.location.origin}/${profileLink}`
            );
            ToastActions.addToast(
              uuid(),
              'Copied to clipboard!',
              '',
              'success',
              undefined,
              undefined,
              undefined,
              'file-copy-line'
            );
            share?.(
              {
                id: user?.id || '',
                nodeId: user?.nodeId || '',
              },
              'user'
            );
          },
          doNotClose: true,
          title: copyLinkText,
          override: false as false,
        },
        {
          icon: <RemixIcon icon="facebook-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://www.facebook.com/sharer/sharer.php?u=https://dns.xyz/${profileLink}`,
                '_blank',
                'noopener,noreferrer'
              );
              share?.(
                {
                  id: user?.id || '',
                  nodeId: user?.nodeId || '',
                },
                'user'
              );
            }
          },
          title: 'Share on Facebook',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="twitter-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://twitter.com/intent/tweet?url=https://dns.xyz/${profileLink}`,
                '_blank',
                'noopener,noreferrer'
              );
              share?.(
                {
                  id: user?.id || '',
                  nodeId: user?.nodeId || '',
                },
                'user'
              );
            }
          },
          title: 'Share on Twitter',
          override: false as false,
        },
        {
          icon: (
            <Image
              src={WarpcastIcon}
              alt="Warpcast"
              className={styles.customIcon}
            />
          ),
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://warpcast.com/~/compose?embeds[]=https://dns.xyz/${profileLink}`,
                '_blank',
                'noopener,noreferrer'
              );
              share?.(
                {
                  id: user?.id || '',
                  nodeId: user?.nodeId || '',
                },
                'user'
              );
            }
          },
          title: 'Share on Warpcast',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="whatsapp-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://wa.me/?text=https://dns.xyz/${profileLink}`,
                '_blank',
                'noopener,noreferrer'
              );
              share?.(
                {
                  id: user?.id || '',
                  nodeId: user?.nodeId || '',
                },
                'user'
              );
            }
          },
          title: 'Share on WhatsApp',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="telegram-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://t.me/share/url?url=https://dns.xyz/${profileLink}`,
                '_blank',
                'noopener,noreferrer'
              );
              share?.(
                {
                  id: user?.id || '',
                  nodeId: user?.nodeId || '',
                },
                'user'
              );
            }
          },
          title: 'Share on Telegram',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="reddit-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://www.reddit.com/submit?url=https://dns.xyz/${profileLink}`,
                '_blank',
                'noopener,noreferrer'
              );
              share?.(
                {
                  id: user?.id || '',
                  nodeId: user?.nodeId || '',
                },
                'user'
              );
            }
          },
          title: 'Share on Reddit',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="mail-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `mailto:?subject=${user.username} on DNS&body=https://dns.xyz/${profileLink}`,
                '_blank',
                'noopener,noreferrer'
              );
              share?.(
                {
                  id: user?.id || '',
                  nodeId: user?.nodeId || '',
                },
                'user'
              );
            }
          },
          title: 'Share via email',
          override: false as false,
        },
      ],
    ];
  }, [share, view?.page, view?.type, view?.user]);

  return userActions;
};
