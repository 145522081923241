/**
 * @generated SignedSource<<9ae2e7cfe86994e62c08cf2af7f8cc64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ListForSaleTokenFragment$data = {
  readonly contract_address: string;
  readonly id: string;
  readonly is_purchasable: boolean;
  readonly nodeId: string;
  readonly purchase_price: number | null;
  readonly thumbnail_uri: string;
  readonly title: string;
  readonly token_id: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"ListForSaleSaleItemTokenFragment">;
  readonly " $fragmentType": "ListForSaleTokenFragment";
};
export type ListForSaleTokenFragment$key = {
  readonly " $data"?: ListForSaleTokenFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ListForSaleTokenFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ListForSaleTokenFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nodeId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "token_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contract_address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail_uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "is_purchasable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "purchase_price",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ListForSaleSaleItemTokenFragment"
    }
  ],
  "type": "tezos_tokens",
  "abstractKey": null
};

(node as any).hash = "9b291e4f949c8f2fc32a94ff78296705";

export default node;
