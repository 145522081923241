/**
 * @generated SignedSource<<ce6fcb025c89264528ba354f080cb9f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MobileDrawerProfileQuery$variables = {
  id: string;
};
export type MobileDrawerProfileQuery$data = {
  readonly followers: {
    readonly totalCount: number;
  } | null;
  readonly following: {
    readonly totalCount: number;
  } | null;
  readonly identitiesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly profilesCollection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly avatar_uri: string | null;
              readonly banner_uri: string | null;
              readonly id: string;
              readonly username: string | null;
            };
          }>;
        } | null;
      };
    }>;
  } | null;
};
export type MobileDrawerProfileQuery = {
  response: MobileDrawerProfileQuery$data;
  variables: MobileDrawerProfileQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "eq",
    "variableName": "id"
  }
],
v2 = [
  {
    "fields": [
      {
        "fields": (v1/*: any*/),
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar_uri",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "banner_uri",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v8 = {
  "alias": "followers",
  "args": [
    {
      "fields": [
        {
          "fields": (v1/*: any*/),
          "kind": "ObjectValue",
          "name": "followee_id"
        }
      ],
      "kind": "ObjectValue",
      "name": "filter"
    }
  ],
  "concreteType": "followsConnection",
  "kind": "LinkedField",
  "name": "followsCollection",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v9 = {
  "alias": "following",
  "args": [
    {
      "fields": [
        {
          "fields": (v1/*: any*/),
          "kind": "ObjectValue",
          "name": "follower_id"
        }
      ],
      "kind": "ObjectValue",
      "name": "filter"
    }
  ],
  "concreteType": "followsConnection",
  "kind": "LinkedField",
  "name": "followsCollection",
  "plural": false,
  "selections": (v7/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MobileDrawerProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "identitiesConnection",
        "kind": "LinkedField",
        "name": "identitiesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "identitiesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "identities",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "profilesConnection",
                    "kind": "LinkedField",
                    "name": "profilesCollection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "profilesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "profiles",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MobileDrawerProfileQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "identitiesConnection",
        "kind": "LinkedField",
        "name": "identitiesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "identitiesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "identities",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "profilesConnection",
                    "kind": "LinkedField",
                    "name": "profilesCollection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "profilesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "profiles",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v10/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v8/*: any*/),
      (v9/*: any*/)
    ]
  },
  "params": {
    "cacheID": "87442ce45c909c82743b14068af6c543",
    "id": null,
    "metadata": {},
    "name": "MobileDrawerProfileQuery",
    "operationKind": "query",
    "text": "query MobileDrawerProfileQuery(\n  $id: BigInt!\n) {\n  identitiesCollection(filter: {id: {eq: $id}}) {\n    edges {\n      node {\n        id\n        profilesCollection {\n          edges {\n            node {\n              id\n              username\n              avatar_uri\n              banner_uri\n              nodeId\n            }\n          }\n        }\n        nodeId\n      }\n    }\n  }\n  followers: followsCollection(filter: {followee_id: {eq: $id}}) {\n    totalCount\n  }\n  following: followsCollection(filter: {follower_id: {eq: $id}}) {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "27bf1b124b7d9af08db3dcf933572d87";

export default node;
