/**
 * @generated SignedSource<<4133a1457cca9a8bee742ab95b9099e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type salesInsertInput = {
  account_id?: string | null;
  amount?: string | null;
  created_at?: string | null;
  price?: number | null;
  recipients?: ReadonlyArray<string | null> | null;
  sale_id?: string | null;
  tezos_token_id?: string | null;
  updated_at?: string | null;
  valid_from?: string | null;
  valid_until?: string | null;
};
export type insertSaleMutation$variables = {
  input: ReadonlyArray<salesInsertInput>;
};
export type insertSaleMutation$data = {
  readonly insertIntosalesCollection: {
    readonly records: ReadonlyArray<{
      readonly accounts: {
        readonly identities: {
          readonly id: string;
          readonly nodeId: string;
        };
        readonly " $fragmentSpreads": FragmentRefs<"accountsFragment">;
      };
      readonly amount: string;
      readonly created_at: string;
      readonly id: string;
      readonly nodeId: string;
      readonly price: number;
      readonly sale_id: string | null;
      readonly tezos_token_id: string | null;
      readonly valid_from: string | null;
      readonly valid_until: string | null;
    }>;
  } | null;
};
export type insertSaleMutation = {
  response: insertSaleMutation$data;
  variables: insertSaleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sale_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tezos_token_id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "valid_from",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "valid_until",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v11 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "public_key_hash",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "public_key",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "insertSaleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "salesInsertResponse",
        "kind": "LinkedField",
        "name": "insertIntosalesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sales",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "accounts",
                "kind": "LinkedField",
                "name": "accounts",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "accountsFragment"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "identities",
                    "kind": "LinkedField",
                    "name": "identities",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "insertSaleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "salesInsertResponse",
        "kind": "LinkedField",
        "name": "insertIntosalesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sales",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "accounts",
                "kind": "LinkedField",
                "name": "accounts",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "magic_accountsConnection",
                    "kind": "LinkedField",
                    "name": "magic_accountsCollection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "magic_accountsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "magic_accounts",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": (v11/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "beacon_accountsConnection",
                    "kind": "LinkedField",
                    "name": "beacon_accountsCollection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "beacon_accountsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "beacon_accounts",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": (v11/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "teztok_accountsConnection",
                    "kind": "LinkedField",
                    "name": "teztok_accountsCollection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "teztok_accountsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "teztok_accounts",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": (v11/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "identities",
                    "kind": "LinkedField",
                    "name": "identities",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "profilesConnection",
                        "kind": "LinkedField",
                        "name": "profilesCollection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "profilesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "profiles",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "bio",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "username",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "avatar_uri",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "banner_uri",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "website",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "tezos_profilesConnection",
                                    "kind": "LinkedField",
                                    "name": "tezos_profilesCollection",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "tezos_profilesEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "tezos_profiles",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "logo",
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "description",
                                                "storageKey": null
                                              },
                                              (v3/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ae284af7696cba00d1f7e54cb25e9efc",
    "id": null,
    "metadata": {},
    "name": "insertSaleMutation",
    "operationKind": "mutation",
    "text": "mutation insertSaleMutation(\n  $input: [salesInsertInput!]!\n) {\n  insertIntosalesCollection(objects: $input) {\n    records {\n      id\n      nodeId\n      sale_id\n      price\n      amount\n      tezos_token_id\n      valid_from\n      valid_until\n      created_at\n      accounts {\n        ...accountsFragment\n        identities {\n          id\n          nodeId\n        }\n        nodeId\n      }\n    }\n  }\n}\n\nfragment accountsFragment on accounts {\n  nodeId\n  id\n  type\n  magic_accountsCollection {\n    edges {\n      node {\n        id\n        nodeId\n        public_key_hash\n        public_key\n      }\n    }\n  }\n  beacon_accountsCollection {\n    edges {\n      node {\n        id\n        nodeId\n        public_key_hash\n        public_key\n      }\n    }\n  }\n  teztok_accountsCollection {\n    edges {\n      node {\n        id\n        nodeId\n        public_key_hash\n        public_key\n      }\n    }\n  }\n  identities {\n    id\n    nodeId\n    profilesCollection {\n      edges {\n        node {\n          nodeId\n          id\n          bio\n          username\n          avatar_uri\n          banner_uri\n          name\n          website\n          tezos_profilesCollection {\n            edges {\n              node {\n                logo\n                description\n                nodeId\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9787fa7c1f3fc1b8a0e8e35bc8070d1";

export default node;
