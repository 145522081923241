import {JWTActions} from '@/state/hooks/jwt';
import {useState} from 'react';

export type Account =
  | {
      isLoggedIn: false;
    }
  | {
      accountId: string;
      identityId: string;
      address: string;
      blockchain: string;
      email: string;
      isLoggedIn: true;
      publicKey: string;
      walletType: string;
    };

export function useAccount() {
  const [account, setAccount] = useState<Account>({
    isLoggedIn: false,
  });

  const updateAccountAndCookies = (account: Account) => {
    setAccount(account);
    if (typeof window !== 'undefined') {
      if (account.isLoggedIn) {
        window.document.cookie = `account=${JSON.stringify(
          account
        )}; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
      } else {
        window.document.cookie = `account=; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
        window.document.cookie = `version=; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
      }
    }
  };

  const getAccountFromCookie: () => Account = () => {
    if (typeof window === 'undefined') {
      return account;
    } else {
      const cookie = window.document.cookie
        .split('; ')
        .find(row => row.startsWith('account='));
      if (cookie && cookie.split('=')[1]) {
        const cookieString = cookie.split('account=')[1] as string; // NOTE: The split string might change if the type for Account or the data passed to it changes, in case any of it includes the string 'account='
        try {
          const cookieAccount = JSON.parse(cookieString);
          setAccount(cookieAccount);
          return cookieAccount;
        } catch (e) {
          console.error(e);
          window.document.cookie = `account=; path=/; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
          return account;
        }
      } else {
        return account;
      }
    }
  };

  const getAccount = () => {
    if (account.isLoggedIn) {
      return account;
    }

    const accountFromCookie = getAccountFromCookie();
    if (accountFromCookie?.isLoggedIn) {
      return accountFromCookie;
    }

    return account;
  };

  const logoutAccount = () => {
    updateAccountAndCookies({
      isLoggedIn: false,
    });
    JWTActions.removeToken();
  };

  return {
    getAccount,
    setAccount: updateAccountAndCookies,
    logoutAccount,
  };
}

export const getBlockchainId = (blockchain: string) => {
  return '1';
};
