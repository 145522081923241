import NextImage, {StaticImageData} from "next/image";
import React, {forwardRef} from 'react';

export default forwardRef(function Image(
  {
    dynamic,
    src,
    alt,
    quality,
    className,
    style,
    onLoad,
    onError,
    onContextMenu,
    observe,
  }: {
    src: string | StaticImageData;
    alt?: string;
    quality?: number;
    className?: string;
    style?: React.CSSProperties;
    onError?: React.ReactEventHandler<HTMLImageElement>;
    onContextMenu?: React.ReactEventHandler<HTMLImageElement>;
    observe?: boolean;
  } & (
    | {
        dynamic: true;
        onLoad?: React.ReactEventHandler<HTMLImageElement>;
      }
    | {
        dynamic?: false;
        onLoad?: (dimensions: {
          naturalWidth: number;
          naturalHeight: number;
        }) => void;
      }
  ),
  ref: React.ForwardedRef<HTMLImageElement>
) {
  const imageRef = React.useRef<HTMLImageElement>(null);

  React.useImperativeHandle(
    ref,
    () => imageRef.current as HTMLImageElement,
    []
  );

  const [hideGif, setHideGif] = React.useState(false);

  React.useEffect(() => {
    if (!dynamic) return;
    if (!observe) return;

    if (imageRef.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setHideGif(!entry.isIntersecting);
        },
        {threshold: 0.1}
      );

      observer.observe(imageRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [dynamic, observe, imageRef]);

  return !dynamic ? (
    <NextImage
      onLoadingComplete={onLoad}
      onError={onError}
      src={src}
      alt={alt ?? ''}
      quality={quality}
      className={className}
      draggable={false}
      onContextMenu={onContextMenu}
      fill
      sizes="100vw" />
  ) : (
    // eslint-disable-next-line @next/next/no-img-element
    <img
      ref={imageRef}
      onLoad={onLoad}
      onError={onError}
      src={
        src !== '' && typeof src === 'string'
          ? observe && hideGif
            ? '/poster.svg'
            : src
          : undefined
      }
      alt={alt ?? ''}
      className={className}
      loading="lazy"
      draggable={false}
      style={style}
      onContextMenu={onContextMenu}
      data-hide-gif={observe && hideGif ? 'true' : 'false'}
    />
  );
});
