/**
 * @generated SignedSource<<0e5867fd2a2535547cc99bc0d034fb0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type signinFindBeaconAccountQuery$variables = {
  address: string;
};
export type signinFindBeaconAccountQuery$data = {
  readonly beacon_accountsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accounts: {
          readonly id: string;
          readonly identities: {
            readonly id: string;
          };
        };
        readonly public_key: string;
      };
    }>;
  } | null;
};
export type signinFindBeaconAccountQuery = {
  response: signinFindBeaconAccountQuery$data;
  variables: signinFindBeaconAccountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "address"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "address"
          }
        ],
        "kind": "ObjectValue",
        "name": "public_key_hash"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "public_key",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "signinFindBeaconAccountQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "beacon_accountsConnection",
        "kind": "LinkedField",
        "name": "beacon_accountsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "beacon_accountsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "beacon_accounts",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "accounts",
                    "kind": "LinkedField",
                    "name": "accounts",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "identities",
                        "kind": "LinkedField",
                        "name": "identities",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "signinFindBeaconAccountQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "beacon_accountsConnection",
        "kind": "LinkedField",
        "name": "beacon_accountsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "beacon_accountsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "beacon_accounts",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "accounts",
                    "kind": "LinkedField",
                    "name": "accounts",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "identities",
                        "kind": "LinkedField",
                        "name": "identities",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5ebeb59ebb9158ca6b124f04e16e345c",
    "id": null,
    "metadata": {},
    "name": "signinFindBeaconAccountQuery",
    "operationKind": "query",
    "text": "query signinFindBeaconAccountQuery(\n  $address: String!\n) {\n  beacon_accountsCollection(filter: {public_key_hash: {eq: $address}}) {\n    edges {\n      node {\n        public_key\n        accounts {\n          id\n          identities {\n            id\n            nodeId\n          }\n          nodeId\n        }\n        nodeId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d61fad6fd3a3a7f3c1538b083b071975";

export default node;
