import * as config from '../config.json';

const {loggerUri} = config;

export async function Log(accountId: string, message: string, data: any = {}) {
  await fetch(loggerUri, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "account_id": Number(accountId),
      "message": message,
      "data": data,
    })
  });
}