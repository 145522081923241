import clsx from 'clsx';
import React from 'react';
import styles from '@/view/styles/components/Input/blocs/MultiLineInput.module.scss';

export default function MultiLineInput({
  id,
  placeholder,
  defaultValue,
  onChange,
  fullWidth,
  autoComplete,
  autoFocus,
  disabled,
  textareaRef,
  rows,
  onClick,
  onFocus,
  onBlur,
  onEnter,
  handleEnter,
}: {
  id?: string;
  placeholder?: string;
  defaultValue?: string | number;
  onChange: (value: string) => string | void;
  fullWidth?: boolean;
  autoComplete?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  textareaRef?: React.RefObject<HTMLTextAreaElement>;
  rows?: number;
  onClick?: (e: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onEnter?: () => void;
  handleEnter?: () => void;
}) {
  return (
    <textarea
      disabled={disabled}
      ref={textareaRef}
      onKeyDown={e => {
        if (e.key === 'Enter' && !e.shiftKey) {
          onEnter && handleEnter?.();
        }
      }}
      autoFocus={autoFocus}
      autoComplete={autoComplete ? 'on' : 'new-password'}
      id={id}
      placeholder={placeholder}
      defaultValue={defaultValue}
      onChange={e => {
        const newValue = onChange(e.target.value);
        if (typeof newValue === 'string') {
          e.target.value = newValue;
        }
      }}
      className={clsx(styles.textarea, fullWidth && styles.fullWidth)}
      rows={rows || 4}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}
