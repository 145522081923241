import {ActionsView} from '@/state/hooks/actionsMenu';
import {useNftViewToActions} from './actionsViews/nftActions';
import {useUserViewToActions} from './actionsViews/userActions';
import {useListViewToActions} from './actionsViews/listActions';
import {useTrackViewToActions} from './actionsViews/trackActions';
import {useCommentViewToActions} from './actionsViews/commentActions';

export function useConvertViewToActions({
  view,
  loadingShares,
  share,
}: {
  view: ActionsView | undefined;
  loadingShares?: boolean;
  share?: (
    post: {
      id: string;
      nodeId: string;
    },
    type: string
  ) => void;
}) {
  const nftActions = useNftViewToActions({
    view: view?.type === 'token' ? view : undefined,
    loadingShares,
    share,
  });

  const userActions = useUserViewToActions({
    view: view?.type === 'user' ? view : undefined,
  });

  const listActions = useListViewToActions({
    view: view?.type === 'list' ? view : undefined,
    share,
  });

  const trackActions = useTrackViewToActions({
    view: view?.type === 'track' ? view : undefined,
    loadingShares,
    share,
  });

  const commentActions = useCommentViewToActions({
    view: view?.type === 'comment' ? view : undefined,
  });

  switch (view?.type) {
    case 'token': {
      return nftActions;
    }
    case 'user': {
      return userActions;
    }
    case 'list': {
      return listActions;
    }
    case 'track': {
      return trackActions;
    }
    case 'comment': {
      return commentActions;
    }
    default:
      return [];
  }
}
