/**
 * @generated SignedSource<<0a6fb25a47a4d2c16b5a79966f4e7c22>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type enum_account_type = "beacon" | "magic" | "teztok" | "%future added value";
export type NewPostCardDetailsUsernameSuggestionsQuery$variables = {
  search?: string | null;
};
export type NewPostCardDetailsUsernameSuggestionsQuery$data = {
  readonly profilesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly avatar_uri: string | null;
        readonly id: string;
        readonly identities: {
          readonly accountsCollection: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly beacon_accountsCollection: {
                  readonly edges: ReadonlyArray<{
                    readonly node: {
                      readonly id: string;
                      readonly nodeId: string;
                      readonly public_key_hash: string;
                    };
                  }>;
                } | null;
                readonly id: string;
                readonly magic_accountsCollection: {
                  readonly edges: ReadonlyArray<{
                    readonly node: {
                      readonly id: string;
                      readonly nodeId: string;
                      readonly public_key_hash: string;
                    };
                  }>;
                } | null;
                readonly nodeId: string;
                readonly teztok_accountsCollection: {
                  readonly edges: ReadonlyArray<{
                    readonly node: {
                      readonly id: string;
                      readonly nodeId: string;
                      readonly public_key_hash: string;
                    };
                  }>;
                } | null;
                readonly type: enum_account_type;
              };
            }>;
          } | null;
          readonly id: string;
          readonly nodeId: string;
        };
        readonly name: string | null;
        readonly username: string | null;
      };
    }>;
  } | null;
};
export type NewPostCardDetailsUsernameSuggestionsQuery = {
  response: NewPostCardDetailsUsernameSuggestionsQuery$data;
  variables: NewPostCardDetailsUsernameSuggestionsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "like",
            "variableName": "search"
          }
        ],
        "kind": "ObjectValue",
        "name": "username"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "avatar_uri",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "public_key_hash",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "identities",
  "kind": "LinkedField",
  "name": "identities",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "accountsConnection",
      "kind": "LinkedField",
      "name": "accountsCollection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "accountsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "accounts",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "magic_accountsConnection",
                  "kind": "LinkedField",
                  "name": "magic_accountsCollection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "magic_accountsEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "magic_accounts",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v7/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "beacon_accountsConnection",
                  "kind": "LinkedField",
                  "name": "beacon_accountsCollection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "beacon_accountsEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "beacon_accounts",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v7/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "teztok_accountsConnection",
                  "kind": "LinkedField",
                  "name": "teztok_accountsCollection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "teztok_accountsEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "teztok_accounts",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": (v7/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewPostCardDetailsUsernameSuggestionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "profilesConnection",
        "kind": "LinkedField",
        "name": "profilesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "profilesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "profiles",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewPostCardDetailsUsernameSuggestionsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "profilesConnection",
        "kind": "LinkedField",
        "name": "profilesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "profilesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "profiles",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v8/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f007329df74a85358ca9e068e164d060",
    "id": null,
    "metadata": {},
    "name": "NewPostCardDetailsUsernameSuggestionsQuery",
    "operationKind": "query",
    "text": "query NewPostCardDetailsUsernameSuggestionsQuery(\n  $search: String\n) {\n  profilesCollection(filter: {username: {like: $search}}) {\n    edges {\n      node {\n        id\n        username\n        name\n        avatar_uri\n        identities {\n          id\n          nodeId\n          accountsCollection {\n            edges {\n              node {\n                id\n                nodeId\n                type\n                magic_accountsCollection {\n                  edges {\n                    node {\n                      id\n                      nodeId\n                      public_key_hash\n                    }\n                  }\n                }\n                beacon_accountsCollection {\n                  edges {\n                    node {\n                      id\n                      nodeId\n                      public_key_hash\n                    }\n                  }\n                }\n                teztok_accountsCollection {\n                  edges {\n                    node {\n                      id\n                      nodeId\n                      public_key_hash\n                    }\n                  }\n                }\n              }\n            }\n          }\n        }\n        nodeId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "426fa04f608534942afcfca854d02d55";

export default node;
