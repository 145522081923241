import React from 'react';
import styles from '@/view/styles/components/TezSymbol.module.scss';

export default function TezSymbol({sizeOverride}: {sizeOverride?: number}) {
  return (
    <svg
      viewBox="0 0 20 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.root}
      width={sizeOverride}
      height={sizeOverride}
    >
      <path d="M19.511 20.0484C19.3935 17.9676 18.2649 16.1101 16.1488 14.5348C15.4787 14.0411 14.3971 13.3357 12.9159 12.7244L18.8409 6.77579V5.94111C18.8409 5.61194 18.5588 5.32979 18.2296 5.32979H7.20242V0.345215L2.15906 1.40326V2.77872H2.80565C2.99375 2.77872 3.42872 2.84926 3.42872 3.4018V5.30628H0.571993C0.289846 5.30628 0.0429688 5.55316 0.0429688 5.83531V7.71628H3.44048V15.2872C3.44048 16.6744 3.91072 17.8735 4.76891 18.6612C5.63886 19.4606 6.87325 19.825 8.3075 19.7075C8.91881 19.6605 9.53013 19.4371 10.1297 19.0491C10.4589 18.8258 10.6587 18.4731 10.6587 18.0851V16.5333L10.0239 16.9566C9.24798 17.4738 8.57788 17.4621 8.57788 17.4621H8.55437C8.21345 17.4621 7.94306 17.3563 7.73145 17.1447C7.21418 16.6156 7.21418 15.6164 7.21418 15.6046V7.71628H14.0445L8.69545 13.1006V13.2652C8.68369 13.8765 8.67193 14.382 8.67193 14.5818C8.67193 14.6406 8.67193 14.6759 8.67193 14.6876C8.67193 14.9228 8.83652 15.1109 9.07164 15.1344C14.4794 16.0513 15.608 19.5311 15.608 20.1777V20.2248C15.608 20.2483 15.8314 22.2938 14.6558 23.7163C13.9739 24.5275 12.9511 24.9977 11.6227 25.08C11.611 25.08 10.8233 25.1153 9.98862 24.986C9.21271 24.8684 8.64842 24.6686 8.35452 24.3982C8.87179 24.0337 9.16569 23.4812 9.16569 22.8346C9.16569 21.5179 8.28398 20.6715 6.93203 20.6715C5.7094 20.6715 4.71013 21.6355 4.69838 22.8111C4.68662 23.0227 4.62784 24.3159 5.67413 25.468C6.7792 26.6906 8.68369 27.3019 11.3288 27.3019C11.4229 27.3019 11.5287 27.3019 11.6227 27.3019C14.3266 27.2549 16.3957 26.4672 17.7711 24.9625C19.6756 22.8934 19.5228 20.213 19.511 20.0484Z" />
    </svg>
  );
}
