import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation insertMessageReactionMutation(
    $input: [messages_reactionsInsertInput!]!
  ) {
    insertIntomessages_reactionsCollection(objects: $input) {
      records {
        nodeId
        id
        reaction
        accounts {
          id
          nodeId
          identities {
            profilesCollection {
              edges {
                node {
                  username
                  avatar_uri
                }
              }
            }
          }
          ...accountsFragment
        }
        ...messagesReactionsFragment
      }
    }
  }
`;
