import React from 'react';
import styles from '@/view/styles/components/ShoppingCart/ShoppingCart.module.scss';
import Image from '../Image';
import Button from '../Button';
import RemixIcon from '../RemixIcon';
import Select from '../Select';
import {useShoppingCart} from '@/state/hooks/shoppingCart';
import {getImageUrl} from '@/utils/conversions/conversions';
import {trim} from '@/utils/trim';

export const Item = ({
  shoppingCartItemId,
  item,
  createCollectible,
  quantity,
  sale,
  price: defaultPrice,
  makeFreeItemsCollectible,
}: {
  shoppingCartItemId: string;
  item: {
    id: string;
    nodeId: string;
    title: string;
    editions_minted: string | null;
    purchase_price: number | null;
    is_purchasable: boolean;
    thumbnail_uri: string | null;
    creator: {
      address: string | null;
      username: string | null;
    };
    editions?: string;
  };
  createCollectible?: boolean;
  quantity: number;
  sale:
    | {
        saleId: string | null | undefined;
        price: number | undefined;
        amount: string | undefined;
      }
    | undefined;
  price: number | null;
  makeFreeItemsCollectible?: boolean;
}) => {
  const creatorAddress = item.creator.address || undefined;

  const creatorUsername = !!item.creator.username
    ? item.creator.username
    : trim(creatorAddress || 'Unknown');

  const {removeItem, editItem} = useShoppingCart();

  const editionsRemaining =
    parseInt(item.editions || '0') - parseInt(item.editions_minted || '0');

  const maxQuantity = Math.min(9, editionsRemaining);
  return (
    <>
      <hr />
      <div className={styles.item}>
        <div>
          <Image
            dynamic
            src={
              !!item.thumbnail_uri
                ? getImageUrl(item.thumbnail_uri)
                : '/poster.svg'
            }
            alt={item.title}
            onError={e => {
              e.currentTarget.src = '/poster.svg';
            }}
          />
          <div>
            <div
              data-is-free={item.is_purchasable && item.purchase_price === 0}
            >
              <div>{item.title}</div>
              <span>by {creatorUsername}</span>
            </div>
            {createCollectible ? (
              <div>&bull; Will create collectible</div>
            ) : (
              ((item.is_purchasable && item.purchase_price !== 0) ||
                makeFreeItemsCollectible) && (
                <div>
                  &bull; Includes{' '}
                  {item.is_purchasable && item.purchase_price !== 0
                    ? `free`
                    : ''}{' '}
                  collectible
                </div>
              )
            )}
          </div>
        </div>
        <div>
          <Select
            defaultValue={quantity.toString()}
            id={`quantity-${shoppingCartItemId}`}
            onChange={q => {
              if (!q || q === '') return;

              if (parseInt(q) > editionsRemaining) {
                return;
              }

              editItem(shoppingCartItemId, {
                token: item,
                type: 'nft',
                quantity: parseInt(q),
                sale,
              });
            }}
            label="Qty:"
            options={
              createCollectible ||
              (item.is_purchasable && item.purchase_price === 0)
                ? ['1']
                : maxQuantity > 1
                ? Array.from(
                    {
                      length: maxQuantity,
                    },
                    (_, i) => (i + 1).toString()
                  )
                : ['1']
            }
            disabled={
              createCollectible ||
              maxQuantity === 1 ||
              (item.is_purchasable && item.purchase_price === 0)
            }
          />
        </div>
        {(item.is_purchasable && item.purchase_price === 0) ||
        createCollectible ? (
          <div
            data-incurs-gas-fee={
              !!makeFreeItemsCollectible || !!createCollectible
            }
          >
            <div>
              {makeFreeItemsCollectible
                ? '0 XTZ'
                : createCollectible
                ? '0 XTZ'
                : 'Free'}
            </div>
            {(makeFreeItemsCollectible || createCollectible) && (
              <span>{`+ Gas Fee`}</span>
            )}
          </div>
        ) : (
          <div>
            <div>{sale ? sale.price : defaultPrice}</div>
            <span>
              XTZ
              {/* <TezSymbol /> */}
            </span>
          </div>
        )}
        <Button
          icon
          onClick={() => {
            removeItem(shoppingCartItemId);
          }}
        >
          <RemixIcon icon="close-line" size={24} />
        </Button>
      </div>
    </>
  );
};
