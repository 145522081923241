import React from 'react';
import Dialog from '../Dialog';
import Paper from '../Paper';
import styles from '@/view/styles/components/ShoppingCart/AddedToCartPopup.module.scss';
import Image from '../Image';
import {getImageUrl} from '@/utils/conversions/conversions';
import {convertFromMutez} from '@/utils/tezos';
import Button from '../Button';
import RemixIcon from '../RemixIcon';
import {useShoppingCart} from '@/state/hooks/shoppingCart';
import {AddedToCartPopupTokenFragment$key} from './__generated__/AddedToCartPopupTokenFragment.graphql';
import {graphql, useFragment} from '@/kits/relay-kit/src';

const AddedToCartPopupTokenFragment = graphql`
  fragment AddedToCartPopupTokenFragment on tezos_tokens {
    id
    nodeId
    title
    description
    thumbnail_uri
    purchase_price
    accounts {
      id
      nodeId
      identities {
        profilesCollection {
          edges {
            node {
              username
            }
          }
        }
      }
    }
  }
`;

export default function AddedToCartPopup({
  defaultOpen,
  onClose,
  token: tokenKey,
}: {
  defaultOpen?: boolean;
  onClose?: () => void;
  token: AddedToCartPopupTokenFragment$key;
}) {
  const token = useFragment(AddedToCartPopupTokenFragment, tokenKey);
  const {content, open, removeItem} = useShoppingCart();
  return (
    <Dialog defaultOpen={defaultOpen} trigger={null} handleOnClose={onClose}>
      <Paper className={styles.root}>
        <div className={styles.title}>Added to your Cart!</div>
        <div className={styles.content}>
          <hr />
          <div className={styles.item}>
            <Image
              dynamic
              src={getImageUrl(token.thumbnail_uri)}
              alt={token.title}
            />
            <div className={styles.details}>
              <span>{token.title}</span>
              <span>
                By{' '}
                {token.accounts.identities?.profilesCollection?.edges?.[0]?.node
                  ?.username || 'User'}
              </span>
            </div>
            <span>
              {token.purchase_price
                ? `${convertFromMutez(token.purchase_price)}`
                : `Not for sale`}
              {token.purchase_price ? <span>XTZ</span> : null}
            </span>
            <Button
              icon
              onClick={() => {
                removeItem(content[content.length - 1].shoppingCartItemId);
                onClose?.();
              }}
            >
              <RemixIcon icon="close-line" size={24} />
            </Button>
          </div>
          <hr />
        </div>
        <div className={styles.actions}>
          <Button
            filled
            fullWidth
            onClick={() => {
              onClose?.();
            }}
          >
            <span>Continue Browsing</span>
          </Button>
          <Button
            filled
            accent
            fullWidth
            onClick={() => {
              onClose?.();
              open();
            }}
          >
            <span>View Cart ({content.length})</span>
          </Button>
        </div>
      </Paper>
    </Dialog>
  );
}
