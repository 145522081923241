/**
 * @generated SignedSource<<a698a6d7187df131dc3ffcb9a2e9fdb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ActionsTezosTokenQuery$variables = {
  id: string;
};
export type ActionsTezosTokenQuery$data = {
  readonly tezos_tokensCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly nodeId: string;
        readonly tokens_reactionsCollection: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly accounts: {
                readonly id: string;
                readonly identities: {
                  readonly profilesCollection: {
                    readonly edges: ReadonlyArray<{
                      readonly node: {
                        readonly id: string;
                        readonly nodeId: string;
                        readonly username: string | null;
                      };
                    }>;
                  } | null;
                };
                readonly nodeId: string;
              };
              readonly id: string;
              readonly nodeId: string;
              readonly reaction: string;
            };
          }>;
        } | null;
      };
    }>;
  } | null;
};
export type ActionsTezosTokenQuery = {
  response: ActionsTezosTokenQuery$data;
  variables: ActionsTezosTokenQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reaction",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "profilesConnection",
  "kind": "LinkedField",
  "name": "profilesCollection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "profilesEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "profiles",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "username",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ActionsTezosTokenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "tezos_tokensConnection",
        "kind": "LinkedField",
        "name": "tezos_tokensCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "tezos_tokensEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "tezos_tokens",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "tokens_reactionsConnection",
                    "kind": "LinkedField",
                    "name": "tokens_reactionsCollection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "tokens_reactionsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "tokens_reactions",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "accounts",
                                "kind": "LinkedField",
                                "name": "accounts",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "identities",
                                    "kind": "LinkedField",
                                    "name": "identities",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ActionsTezosTokenQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "tezos_tokensConnection",
        "kind": "LinkedField",
        "name": "tezos_tokensCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "tezos_tokensEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "tezos_tokens",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "tokens_reactionsConnection",
                    "kind": "LinkedField",
                    "name": "tokens_reactionsCollection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "tokens_reactionsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "tokens_reactions",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "accounts",
                                "kind": "LinkedField",
                                "name": "accounts",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "identities",
                                    "kind": "LinkedField",
                                    "name": "identities",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5f9255a378048d57882b943e7463266f",
    "id": null,
    "metadata": {},
    "name": "ActionsTezosTokenQuery",
    "operationKind": "query",
    "text": "query ActionsTezosTokenQuery(\n  $id: BigInt!\n) {\n  tezos_tokensCollection(filter: {id: {eq: $id}}) {\n    edges {\n      node {\n        id\n        nodeId\n        tokens_reactionsCollection {\n          edges {\n            node {\n              id\n              nodeId\n              reaction\n              accounts {\n                id\n                nodeId\n                identities {\n                  profilesCollection {\n                    edges {\n                      node {\n                        id\n                        nodeId\n                        username\n                      }\n                    }\n                  }\n                  nodeId\n                }\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f23ee0b4cebdc8160d2e36a9b0e5b027";

export default node;
