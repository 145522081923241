import React, {Suspense} from 'react';
import styles from '@/view/styles/components/PostActions/blocs/PostCollectActions.module.scss';
import Collect, {
  CollectButtonSkeleton,
} from '@/view/components/CollectDialog/Collect';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import {PostCollectActionsFragment$key} from './__generated__/PostCollectActionsFragment.graphql';

const PostCollectActionsFragment = graphql`
  fragment PostCollectActionsFragment on tezos_tokens {
    ...CollectTokenFragment
    salesCollection {
      edges {
        node {
          sale_id
          price
          amount
        }
      }
    }
  }
`;

export default function PostCollectActions({
  tokenKey,
  showAddedToCartPopup,
}: {
  tokenKey: PostCollectActionsFragment$key;
  showAddedToCartPopup?: boolean;
}) {
  const token = useFragment(PostCollectActionsFragment, tokenKey);
  return (
    <>
      <div className={styles.right}>
        <span
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Suspense fallback={<CollectButtonSkeleton />}>
            <Collect
              token={token}
              showAddedToCartPopup={showAddedToCartPopup}
            />
          </Suspense>
        </span>
      </div>
    </>
  );
}
