import React, {Suspense} from 'react';
import styles from '@/view/styles/components/FeedCard/QuoteCard.module.scss';
import FeedCardDescription from './blocs/FeedCardDescription';
import FeedCardAsset from './blocs/FeedCardAsset';
import {graphql, useFragment, useLazyLoadQuery} from '@/kits/relay-kit/src';
import TokenPageLink from '../TokenPageLink';
import QuoteCardHeader from './blocs/QuoteCardHeader';
import Skeleton from '../Skeleton';
import clsx from 'clsx';
import {QuoteCardFragmentContainerTokenFragment$key} from './__generated__/QuoteCardFragmentContainerTokenFragment.graphql';
import type {QuoteCardWrapperTokenQuery as QuoteCardWrapperTokenQueryType} from './__generated__/QuoteCardWrapperTokenQuery.graphql';

export default function QuoteCard({
  tokenId,
  onDelete,
  parentHasAsset,
  setQuotedItemRepostCount,
  className,
}: {
  tokenId: string;
  onDelete?: () => void;
  parentHasAsset?: boolean;
  setQuotedItemRepostCount?: (repostCount: number) => void;
  className?: string;
}) {
  if (!tokenId) return 'no token';
  return (
    <div className={clsx(styles.cardWrapper, className)}>
      <Suspense
        fallback={
          <Skeleton
            variant="rect"
            animation="wave"
            borderRadius={0}
            styles={{
              aspectRatio: '1',
            }}
          />
        }
      >
        <QuoteCardWrapper
          tokenId={tokenId}
          onDelete={onDelete}
          parentHasAsset={parentHasAsset}
          setQuotedItemRepostCount={setQuotedItemRepostCount}
        />
      </Suspense>
    </div>
  );
}

const QuoteCardWrapperTokenQuery = graphql`
  query QuoteCardWrapperTokenQuery($id: BigInt!) {
    tezos_tokensCollection(filter: {id: {eq: $id}}, first: 1) {
      edges {
        node {
          id
          nodeId
          ...QuoteCardFragmentContainerTokenFragment
        }
      }
    }
  }
`;

const QuoteCardWrapper = ({
  tokenId,
  onDelete,
  parentHasAsset,
  setQuotedItemRepostCount,
}: {
  tokenId: string;
  onDelete?: () => void;
  parentHasAsset?: boolean;
  setQuotedItemRepostCount?: (repostCount: number) => void;
}) => {
  const {data} = useLazyLoadQuery<QuoteCardWrapperTokenQueryType>(
    QuoteCardWrapperTokenQuery,
    {
      id: tokenId,
    },
    {
      skip: !tokenId || tokenId === '',
    }
  );

  const token = data?.tezos_tokensCollection?.edges?.[0]?.node;

  return (
    token && (
      <QuoteCardFragmentContainer
        tokenKey={token}
        onDelete={onDelete}
        parentHasAsset={parentHasAsset}
        setQuotedItemRepostCount={setQuotedItemRepostCount}
      />
    )
  );
};

const QuoteCardFragmentContainerTokenFragment = graphql`
  fragment QuoteCardFragmentContainerTokenFragment on tezos_tokens {
    id
    type
    mime_type
    title
    description
    tokens_sharesCollection {
      edges {
        node {
          quote_post_count
        }
      }
    }
    accounts {
      identities {
        profilesCollection {
          edges {
            node {
              username
            }
          }
        }
      }
    }
    ...FeedCardAssetTokenFragment
    ...FeedCardDescriptionTokenFragment
    ...QuoteCardHeaderTokenFragment
  }
`;

const QuoteCardFragmentContainer = ({
  tokenKey,
  onDelete,
  parentHasAsset,
  setQuotedItemRepostCount,
}: {
  tokenKey: QuoteCardFragmentContainerTokenFragment$key;
  onDelete?: () => void;
  parentHasAsset?: boolean;
  setQuotedItemRepostCount?: (repostCount: number) => void;
}) => {
  const token = useFragment(
    QuoteCardFragmentContainerTokenFragment, // The imported graphql fragment query
    tokenKey // The props
  );
  const isPostType = token.type === 'post';
  const isTextPost = isPostType && token?.mime_type === 'text/plain';

  const hasAsset =
    token.type !== 'post' || (token.type === 'post' && !isTextPost);

  const hasAudio = token.type === 'audio';

  React.useEffect(() => {
    if (token) {
      setQuotedItemRepostCount?.(
        parseInt(
          token.tokens_sharesCollection?.edges?.[0]?.node?.quote_post_count ||
            '0'
        )
      );
    }
  }, [token]);
  return (
    <div className={styles.card} data-has-asset={hasAsset}>
      <QuoteCardHeader tokenKey={token} onDelete={onDelete} />
      {!isPostType && (
        <div data-parent-has-asset={parentHasAsset} data-has-audio={hasAudio}>
          <FeedCardAsset tokenKey={token} />
          {parentHasAsset && (
            <TokenPageLink
              tokenId={token.id}
              username={
                token?.accounts.identities.profilesCollection?.edges?.[0]?.node
                  .username || undefined
              }
              className={styles.quoteCardTextGroup}
            >
              <span>{token.title}</span>
              <span>{token.description}</span>
            </TokenPageLink>
          )}
        </div>
      )}
      {isPostType && !parentHasAsset && (
        <FeedCardDescription tokenKey={token} showDescription={isPostType} />
      )}
      {isPostType && (
        <div data-parent-has-asset={parentHasAsset}>
          {!isTextPost && <FeedCardAsset tokenKey={token} />}
          {parentHasAsset && (
            <TokenPageLink
              tokenId={token.id}
              username={
                token?.accounts.identities.profilesCollection?.edges?.[0]?.node
                  .username || undefined
              }
              className={styles.quoteCardTextGroup}
            >
              <span>{token.title}</span>
              <span>{token.description}</span>
            </TokenPageLink>
          )}
        </div>
      )}
    </div>
  );
};
