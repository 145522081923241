// General
export const versionHash = '64435ef1-0def-410c-a574-f96ade8039a3';

export const PRIMARY_DOMAIN = 'dns.xyz';
export const ENVIRONMENT =
  process.env.NODE_ENV || process.env.ENVIRONMENT || 'development';

// Event Rollup
export const EVENT_ROLLUP_URI = process.env.NEXT_PUBLIC_EVENT_ROLLUP_URI;

// Minter
export const MINTER_URI =
  process.env.NEXT_PUBLIC_MINTER_URI || 'https://mint.dns.xyz';
export const MINTER_CONTRACT = 'KT1KBUqY5zzV3CaXcS6mNLUDBRnpDcgj2t5X';
export const ADMIN_SIGNER = process.env.NEXT_PUBLIC_ADMIN_SIGNER || '';

// IPFS
export const IPFS_URI = process.env.NEXT_PUBLIC_IPFS_URI || 'https://ipfs.io';

// Feature Flags
export const MEDIA_PLAYLIST_DEBUG: boolean = false;
export const EVENTS: boolean = false;
export const ALBUMS: boolean = false;
export const PAGES: boolean = false;
export const THEME_TOGGLE: boolean = false;
export const BATCH_MINTER: boolean = false;
export const NOTIFICATIONS: boolean = true;
export const MESSAGING: boolean = false;
export const SUBSCRIPTIONS: boolean = false;
export const SHOPPING_CART: boolean = true;
export const SECONDARY_MARKET_BUYING: boolean = true;
export const MESSAGE_REACTIONS: boolean = false;
export const MESSAGE_REPLIES: boolean = false;
export const GROUP_CHATS: boolean = false;

// TEAM ACCOUNT IDS
export const TEAM_MEMBERS = [
  '1', // tylerdmace
  '4', // illsn
  '8', // Shokunin
  '48', // Administrator
  '59', // username <--- what/who is this?
];

export const ANNOUNCEMENTS_CHANNEL_ID: string = '51';
export const REPORTS_CHANNEL_ID: string = '2';
export const MUSIC_PLAYLIST_ID: string = '';
export const VIDEOS_PLAYLIST_ID: string = '';
export const IMAGES_PLAYLIST_ID: string = '';
export const reservedNames = [
  'admin',
  'administrator',
  'moderator',
  'mod',
  'owner',
  'root',
  'dns',
  'official',
  'support',
  'help',
  'blog',
  'team',
  'staff',
  'contact',
  'guest',
  'undefined',
  'unknown',
  'null',
  'anon',
  'settings',
  'wallet',
  'inbox',
  'notifications',
  'messages',
  'about',
  'privacy',
  'terms',
  'faq',
  'login',
  'register',
  'signup',
  'signin',
  'sign-in',
  'sign-up',
  'verify',
];
