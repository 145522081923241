import styles from '@/view/styles/pages/signin/SignIn.module.scss';
import React from 'react';
import type {FunctionComponent} from 'react';
import Image from '@/view/components/Image';
import Button from '@/view/components/Button';
import DiscordLogo from '@/view/resources/logos/login-discord.svg';
import GoogleLogo from '@/view/resources/logos/login-google.svg';
import TwitterLogo from '@/view/resources/logos/login-twitter.svg';
import TezosLogo from '@/view/resources/icons/TezosIcon.svg';
import signInBackground from '@/view/resources/sign-in.webp';
import {useRouter} from 'next/router';
import {login} from '@/kits/authentication-kit/src';
import {useAccount} from '@/kits/account-kit/src';
import BackButton from '@/view/components/BackButton';
import {useLoginModalActions} from '@/state/hooks/auth/loginModal';
import {graphql, useMutation} from '@/kits/relay-kit/src';
import insertIdentityMutation from '@/graphql/insert-identity';
import type {insertIdentityMutation as IdentityInsertMutationType} from '@/graphql/__generated__/insertIdentityMutation.graphql';
import insertAccountMutation from '@/graphql/insert-account';
import type {insertAccountMutation as AccountInsertMutationType} from '@/graphql/__generated__/insertAccountMutation.graphql';
import insertBeaconAccountMutation from '@/graphql/insert-beacon-account';
import type {insertBeaconAccountMutation as BeaconAccountInsertMutationType} from '@/graphql/__generated__/insertBeaconAccountMutation.graphql';
import {AccountInfo} from '@airgap/beacon-dapp';
import {setCookieVersion} from '@/view/providers/LoginModal';
import {NextRouter} from 'next/router';
import {GraphQLResponse, loadQuery, relay} from '@/kits/relay-kit/src';
import {JWTActions} from '@/state/hooks/jwt';

function ensureRedirectUri() {
  if (!window.history.state?.redirectURI) {
    window.history.replaceState(
      {
        ...(window.history.state || {}),
        redirectURI: window.location.href.slice(
          0,
          window.location.href.length - window.location.pathname.length
        ),
      },
      ''
    );
  }
}

export type WalletResponse = {
  data: {
    beacon_accountsCollection: {
      edges: {
        node: {
          public_key: string;
          accounts: {
            id: string;
            identities: {
              id: string;
            };
          };
        };
      }[];
    };
  };
};

const signinFindBeaconAccountQuery = graphql`
  query signinFindBeaconAccountQuery($address: String!) {
    beacon_accountsCollection(filter: {public_key_hash: {eq: $address}}) {
      edges {
        node {
          public_key
          accounts {
            id
            identities {
              id
            }
          }
        }
      }
    }
  }
`;

const handleBeaconResponse = (
  account: any,
  setAccount: Function,
  insertIdentity: Function,
  insertAccount: Function,
  insertWallet: Function,
  router: NextRouter,
  results: {
    accountInfo: Promise<AccountInfo | undefined>;
    signature: string;
    payloadBytes: string;
    walletPublicKey: string;
    walletPublicKeyHash: string;
  }
) => {
  const load = loadQuery();

  const pkh = !!account.address ? account.address : results.walletPublicKeyHash;

  const pk = !!account.publicKey ? account.publicKey : results.walletPublicKey;

  load.next(
    relay,
    signinFindBeaconAccountQuery,
    {
      address: pkh,
    },
    {
      fetchPolicy: 'network-only',
      fetchKey: 'get',
      async onResponse(response: GraphQLResponse) {
        const res = response as WalletResponse;
        const walletFound =
          res.data.beacon_accountsCollection.edges?.length !== 0;
        if (walletFound) {
          const accountId =
            res.data.beacon_accountsCollection.edges?.[0]?.node?.accounts.id;
          const identityId =
            res.data.beacon_accountsCollection.edges?.[0]?.node?.accounts
              .identities.id;

          // And verify
          const rawResults = await fetch('/api/beacon-authenticate', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              payloadBytes: results.payloadBytes,
              publicKey: results.walletPublicKey,
              signature: results.signature,
            }),
          });

          const beaconAuthResults = await rawResults.json();

          if (beaconAuthResults.success) {
            const {token} = beaconAuthResults;

            JWTActions.setToken(token);
          } else {
            // TODO: Handle a signin error
          }

          setAccount({
            accountId: accountId,
            identityId: identityId,
            address: pkh,
            blockchain: 'tezos',
            email: '',
            isLoggedIn: true,
            publicKey: pk,
            walletType: 'beacon',
          });
          setCookieVersion().then(() => {
            router.push('/');
          });
        } else {
          insertIdentity({
            variables: {
              input: [
                {
                  created_at: 'now',
                  updated_at: 'now',
                  onboarded: false,
                },
              ],
            },
            onCompleted(response: any) {
              const identityId =
                response.insertIntoidentitiesCollection?.records?.[0]?.id;
              if (!identityId) {
                throw new Error('Failed to create identity');
              }
              insertAccount({
                variables: {
                  input: [
                    {
                      identity_id: identityId,
                      created_at: 'now',
                      type: 'beacon',
                    },
                  ],
                },
                onCompleted(response: any) {
                  const accountId =
                    response?.insertIntoaccountsCollection?.records?.[0].id;
                  if (!accountId) return;
                  insertWallet({
                    variables: {
                      input: [
                        {
                          account_id: accountId,
                          public_key_hash: pkh,
                          public_key: pk,
                          created_at: 'now',
                          updated_at: 'now',
                        },
                      ],
                    },
                    async onCompleted() {
                      // And verify
                      const rawResults = await fetch(
                        '/api/beacon-authenticate',
                        {
                          method: 'POST',
                          headers: {
                            'Content-Type': 'application/json',
                          },
                          body: JSON.stringify({
                            payloadBytes: results.payloadBytes,
                            publicKey: results.walletPublicKey,
                            signature: results.signature,
                          }),
                        }
                      );

                      const beaconAuthResults = await rawResults.json();

                      if (beaconAuthResults.success) {
                        const {token} = beaconAuthResults;

                        JWTActions.setToken(token);
                      }

                      setAccount({
                        accountId: accountId,
                        identityId: identityId,
                        address: account,
                        blockchain: 'tezos',
                        email: '',
                        isLoggedIn: true,
                        publicKey: pk,
                        walletType: 'beacon',
                      });
                      load.next(
                        relay,
                        signinFindBeaconAccountQuery,
                        {
                          address: pkh,
                        },
                        {
                          fetchPolicy: 'network-only',
                          fetchKey: 'retry',
                          onResponse(response: GraphQLResponse) {
                            const res = response as WalletResponse;
                            const accountId =
                              res.data.beacon_accountsCollection.edges?.[0]
                                ?.node?.accounts.id;
                            const identityId =
                              res.data.beacon_accountsCollection.edges?.[0]
                                ?.node?.accounts.identities.id;
                            setAccount({
                              accountId: accountId,
                              identityId: identityId,
                              address: pkh,
                              blockchain: 'tezos',
                              email: '',
                              isLoggedIn: true,
                              publicKey: pk,
                              walletType: 'beacon',
                            });
                            setCookieVersion().then(() => {
                              router.push('/onboarding');
                            });
                          },
                        }
                      );
                    },
                  });
                },
              });
            },
          });
        }
      },
    }
  );
};

const socialLoginProviders: {
  name: string;
  label: string;
  icon: string | string[];
  onClick: () =>
    | Promise<void>
    | Promise<{
        accountInfo: Promise<AccountInfo | undefined>;
        signature: string;
        payloadBytes: string;
        walletPublicKey: string;
        walletPublicKeyHash: string;
      }>;
  disabled?: boolean;
}[] = [
  {
    name: 'google',
    label: 'Continue on Google',
    icon: GoogleLogo,
    onClick() {
      ensureRedirectUri();
      return login({strategy: 'magic', provider: 'google'});
    },
  },
  {
    name: 'twitter',
    label: 'Continue on Twitter',
    icon: TwitterLogo,
    onClick() {
      ensureRedirectUri();
      return login({strategy: 'magic', provider: 'twitter'});
    },
  },
  {
    name: 'discord',
    label: 'Continue on Discord',
    icon: DiscordLogo,
    onClick() {
      ensureRedirectUri();
      return login({strategy: 'magic', provider: 'discord'});
    },
  },
  {
    name: 'tezos',
    label: 'Sign in with Tezos',
    icon: TezosLogo,
    onClick() {
      ensureRedirectUri();
      useLoginModalActions.setShowLoginModal(false);
      return login({strategy: 'beacon', provider: undefined});
    },
  },
];

const SignIn: FunctionComponent = () => {
  const router = useRouter();
  const {getAccount} = useAccount();
  const user = getAccount();

  const close = React.useCallback(() => {
    router.push(new URL(window.history.state?.redirectURI).pathname || '/');
  }, [router]);

  React.useEffect(() => {
    if (user.isLoggedIn) {
      ensureRedirectUri();
      close();
    }
  }, [close, user.isLoggedIn]);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.content}>
          <BackButton
            className={styles.back}
            onBack={() => {
              router.push('/');
            }}
          />
          <SignInComponent />
        </div>
        <div className={styles.backgroundImage}>
          <Image src={signInBackground} alt="" />
        </div>
      </div>
    </>
  );
};

export const SignInComponent = ({title}: {title?: string}) => {
  const router = useRouter();
  const {setAccount} = useAccount();
  const [insertIdentity] = useMutation<IdentityInsertMutationType>(
    insertIdentityMutation
  );
  const [insertAccount] = useMutation<AccountInsertMutationType>(
    insertAccountMutation
  );
  const [insertBeaconAccount] = useMutation<BeaconAccountInsertMutationType>(
    insertBeaconAccountMutation
  );

  return (
    <>
      <div className={styles.heading}>{title || `LOGIN`}</div>
      <div className={styles.authSocials}>
        {socialLoginProviders.map(provider => (
          <Button
            key={provider.name}
            outlined
            onClick={async () => {
              if (provider.disabled) return;
              let results = await provider.onClick();

              if (!results) return;

              if (provider.name === 'tezos' && results) {
                handleBeaconResponse(
                  results,
                  setAccount,
                  insertIdentity,
                  insertAccount,
                  insertBeaconAccount,
                  router,
                  results
                );
              }
            }}
            className={styles.signInButton}
            disabled={provider.disabled}
          >
            <Image src={provider.icon as string} alt={provider.label} />
            <span>{provider.label}</span>
          </Button>
        ))}
      </div>
    </>
  );
};

export default SignIn;
