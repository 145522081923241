/**
 * @generated SignedSource<<e5f37107489874e79cc66798ff4ea9fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FilterIs = "NOT_NULL" | "NULL" | "%future added value";
export type comments_reactionsFilter = {
  account_id?: BigIntFilter | null;
  and?: ReadonlyArray<comments_reactionsFilter> | null;
  comment_id?: BigIntFilter | null;
  created_at?: DatetimeFilter | null;
  id?: BigIntFilter | null;
  nodeId?: IDFilter | null;
  not?: comments_reactionsFilter | null;
  or?: ReadonlyArray<comments_reactionsFilter> | null;
  reaction?: StringFilter | null;
  updated_at?: DatetimeFilter | null;
};
export type BigIntFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type DatetimeFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type StringFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  ilike?: string | null;
  in?: ReadonlyArray<string> | null;
  iregex?: string | null;
  is?: FilterIs | null;
  like?: string | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
  regex?: string | null;
  startsWith?: string | null;
};
export type IDFilter = {
  eq?: string | null;
};
export type removeCommentReactionMutation$variables = {
  filter: comments_reactionsFilter;
};
export type removeCommentReactionMutation$data = {
  readonly deleteFromcomments_reactionsCollection: {
    readonly records: ReadonlyArray<{
      readonly id: string;
      readonly nodeId: string;
    }>;
  };
};
export type removeCommentReactionMutation = {
  response: removeCommentReactionMutation$data;
  variables: removeCommentReactionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "comments_reactionsDeleteResponse",
    "kind": "LinkedField",
    "name": "deleteFromcomments_reactionsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "comments_reactions",
        "kind": "LinkedField",
        "name": "records",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeCommentReactionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeCommentReactionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e70c0ba7456362360036d63c1aaf7d92",
    "id": null,
    "metadata": {},
    "name": "removeCommentReactionMutation",
    "operationKind": "mutation",
    "text": "mutation removeCommentReactionMutation(\n  $filter: comments_reactionsFilter!\n) {\n  deleteFromcomments_reactionsCollection(filter: $filter) {\n    records {\n      id\n      nodeId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "74adc5a5bf7042a55244fcfa8db5da35";

export default node;
