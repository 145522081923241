import React, {useCallback} from 'react';
import styles from '@/view/styles/components/ProcessingItems/ProcessingStatus.module.scss';
import Progress from '../Progress';
import Link from 'next/link';
import confetti from 'canvas-confetti';
import {deviceWidth} from '@/controller/hooks/utils/breakpoint';
import {useRouter} from 'next/router';
import {useCreateStatusActions} from '@/state/hooks/createStatus';
import Button from '../Button';
import RemixIcon from '../RemixIcon';

export default function ProcessingStatus({
  type = 'post',
  progress,
  status,
  icon,
  url,
}: {
  type: 'post' | 'video' | 'image' | 'audio';
  progress: number;
  status?: string;
  icon?: React.ReactNode;
  url: string;
}) {
  const itemTitle = () => {
    if (progress === 100) {
      switch (type) {
        case 'post':
          return 'Post published!';
        case 'video':
          return 'Video published!';
        case 'image':
          return 'Image published!';
        case 'audio':
          return 'Audio published!';
      }
    } else if (progress > 50) {
      switch (type) {
        case 'post':
          return 'Preparing your post...';
        case 'video':
          return 'Preparing your video...';
        case 'image':
          return 'Preparing your image...';
        case 'audio':
          return 'Preparing your audio...';
      }
    } else if (progress > 0) {
      switch (type) {
        case 'post':
          return 'Uploading your post...';
        case 'video':
          return 'Uploading your video...';
        case 'image':
          return 'Uploading your image...';
        case 'audio':
          return 'Uploading your audio...';
      }
    } else {
      return 'Failed to publish';
    }
  };

  const router = useRouter();

  const showConfetti = useCallback(() => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: {
        y: 0.9,
        x: deviceWidth() === 'mobile' ? 0.5 : 0.3,
      },
    });
  }, []);

  React.useEffect(() => {
    if (router.pathname === '/') return;
    if (progress === 100) {
      const timeout = setTimeout(() => {
        confetti.reset();
      }, 4000);
      showConfetti();
      return () => clearTimeout(timeout);
    }
  }, [progress, showConfetti, router.pathname]);

  React.useEffect(() => {
    if (status === 'error') {
      const timeout = setTimeout(() => {
        useCreateStatusActions.reset();
      }, 4000);
      return () => clearTimeout(timeout);
    }
  }, [status]);

  const closeAndClear = () => {
    useCreateStatusActions.reset();
  };

  React.useEffect(() => {
    if (status === 'done') {
      setTimeout(() => {
        closeAndClear();
      }, 4000);
    }
  }, [status]);

  return url ? (
    <div className={styles.root}>
      {progress === -1 && (
        <Button
          icon
          className={styles.close}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            closeAndClear();
          }}
        >
          <RemixIcon icon="close-line" size={16} />
        </Button>
      )}
      <div className={styles.icon}>
        {icon}
        {type === 'video' && (
          <div className={styles.iconOverlay}>
            <RemixIcon icon="vidicon-line" size={24} />
          </div>
        )}
        {type === 'post' && (
          <div className={styles.iconOverlay}>
            <RemixIcon icon="article-line" size={24} />
          </div>
        )}
        {type === 'post' && (
          <div className={styles.iconOverlay}>
            <RemixIcon icon="music-2-line" size={24} />
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{itemTitle()}</div>
        <Progress
          value={progress}
          className={styles.progress}
          status={status}
          infinite={progress > 0 && progress < 100}
        />
      </div>
    </div>
  ) : (
    <Link href={'/'} className={styles.root}>
      {progress === -1 && (
        <Button
          icon
          className={styles.close}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            closeAndClear();
          }}
        >
          <RemixIcon icon="close-line" size={16} />
        </Button>
      )}
      <div className={styles.icon}>
        {icon}
        {type === 'video' && (
          <div className={styles.iconOverlay}>
            <RemixIcon icon="vidicon-line" size={24} />
          </div>
        )}
        {type === 'post' && (
          <div className={styles.iconOverlay}>
            <RemixIcon icon="article-line" size={24} />
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.title}>{itemTitle()}</div>
        <Progress
          value={progress}
          className={styles.progress}
          status={status}
          infinite={progress > 0 && progress < 100}
        />
      </div>
    </Link>
  );
}
