import React from 'react';

export const DNSLogo = () => {
  return (
    <svg
      width="300"
      height="100"
      viewBox="0 0 300 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_93_2)">
        <path
          id="D"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.73993 99.3342L88.6052 54.5454C92.1848 52.5674 92.1848 47.4334 88.6052 45.4491L7.73993 0.660237C4.26769 -1.26109 0 1.23978 0 5.20841V39.1119L15.4546 49.9972L0 60.8889V94.7923C0 98.7547 4.26769 101.262 7.73993 99.3342Z"
          fill="currentColor"
        />
        <path
          id="N"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M197.425 91.521L148.144 2.93868C145.966 -0.97956 140.316 -0.97956 138.138 2.93868L88.8573 91.521C86.7424 95.3258 89.4949 100 93.8573 100H131.155L143.138 83.0735L155.12 100H192.418C196.781 100 199.54 95.3258 197.425 91.521Z"
          fill="currentColor"
        />
        <path
          id="S"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188.629 0L267.916 0.0503868C271.325 0.0503868 273.471 3.70404 271.83 6.67736L259.352 29.0704L299.318 93.1437C301.174 96.1233 299.028 99.9723 295.511 99.9723L216.073 99.9219C212.657 99.9219 210.505 96.2619 212.165 93.2886L224.715 70.7584L184.834 6.81595C182.978 3.84263 185.118 0 188.629 0ZM192.158 6.00963L204.462 25.1598L216.76 6.00963H192.158ZM291.97 93.95L279.646 74.7683L267.323 93.95H291.97Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_93_2">
          <rect width="300" height="100" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
};
