import {
  BeaconEvent,
  NetworkType,
  PermissionResponseOutput,
  DAppClientOptions,
} from '@airgap/beacon-dapp';
import type {PairingAlertInfo} from '@airgap/beacon-ui/dist/esm/components/pairing';
import {BlockchainClient} from './types';
import {TezosToolkit} from '@taquito/taquito';
import {BeaconWallet} from '@taquito/beacon-wallet';
import {PRIMARY_DOMAIN, ENVIRONMENT} from '@/config';
import {getConfig} from '@/kits/authentication-kit/src';

export function networkTypeOrDefault(networkType?: string): NetworkType {
  switch (networkType) {
    case 'mainnet':
      return NetworkType.MAINNET;
    case 'testnet':
      return NetworkType.GHOSTNET;
    default:
      if (ENVIRONMENT === 'production') {
        return NetworkType.MAINNET;
      }
      return NetworkType.GHOSTNET;
  }
}

export {
  isValidTezosAddress,
  isValidTezosContractAddress,
} from './utils/tezosAddressValidation';

/**
 * Encode message to the micheline format
 *
 * @param {string} text - the message to Encode
 * @returns {string} the message encoded to the micheline format
 *
 * References:
 * - https://github.com/Cryptonomic/ConseilJS/blob/fb718632d6ce47718dad5aa77c67fc514afaa0b9/src/chain/tezos/lexer/Micheline.ts#L62L71
 * - https://tezos.gitlab.io/shell/micheline.html
 */
export interface Tezos extends BlockchainClient {
  blockchain: 'Tezos';
  beaconClient?: BeaconWallet;
  tezosToolkit: TezosToolkit;
  uiDescription?: PairingAlertInfo & {qrData: string};
  permissionsPromise?: Promise<PermissionResponseOutput>;
}

const authenticationKitConfig = getConfig();
const tezosObject: Tezos = {
  blockchain: 'Tezos',
  tezosToolkit: new TezosToolkit(
    authenticationKitConfig.tezosConfig.tezosRpcUri
  ),
};

const TEMP_FOR_TYPES: DAppClientOptions = {name: ''};
type EventHandlers = typeof TEMP_FOR_TYPES.eventHandlers & {};

export async function init(
  tezos: Tezos,
  network: NetworkType,
  overrides?: EventHandlers,
  additions?: EventHandlers
) {
  const options: DAppClientOptions = {
    name: 'DNS',
    featuredWallets: ['kukai', 'temple', 'naan', 'trust'],
    preferredNetwork: network,
    appUrl: PRIMARY_DOMAIN,
  };

  tezos.beaconClient = new BeaconWallet(options);
  if (additions !== undefined) {
    const promises: Promise<any>[] = [];
    for (const [event, {handler}] of Object.entries(additions)) {
      if (!BeaconEvent.hasOwnProperty(event)) {
        continue;
      }
      promises.push(
        tezos.beaconClient.client.subscribeToEvent(
          event as BeaconEvent,
          handler as any
        )
      );
    }
    await Promise.all(promises);
  }
  tezos.tezosToolkit = new TezosToolkit(
    authenticationKitConfig.tezosConfig.tezosRpcUri
  );
  //tezos.tezosToolkit.setWalletProvider(tezos.beaconClient);
  //tezos.tezosToolkit.setSignerProvider(tezos.beaconClient);
}

export async function cleanUp(tezos: Tezos) {
  if (tezos.beaconClient !== undefined) {
    await tezos.beaconClient.client.clearActiveAccount();
    tezos.permissionsPromise = undefined;
    tezos.uiDescription = undefined;
  }
}

export default tezosObject;
