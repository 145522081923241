import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation insertEventMutation($input: [eventsInsertInput!]!) {
    insertIntoeventsCollection(objects: $input) {
      records {
        nodeId
        id
        type
        created_at
        account_id
        accounts {
          id
          nodeId
          identities {
            profilesCollection {
              edges {
                node {
                  username
                }
              }
            }
          }
          ...accountsFragment
        }
        tezos_tokens {
          id
          nodeId
          mime_type
          artifact_uri
          thumbnail_uri
          contract_address
          token_id
          title
          metadata_uri
          accounts {
            id
            nodeId
            identities {
              profilesCollection {
                edges {
                  node {
                    username
                  }
                }
              }
            }
          }
          tokens_reactionsCollection {
            edges {
              node {
                id
                nodeId
                reaction
                accounts {
                  id
                  nodeId
                  identities {
                    profilesCollection {
                      edges {
                        node {
                          username
                        }
                      }
                    }
                  }
                  ...accountsFragment
                }
                ...tokensReactionsFragment
              }
            }
          }
          ...tezosTokensFragment
        }
        ...eventFragment
      }
    }
  }
`;
