import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation removeTokenReactionMutation($filter: tokens_reactionsFilter!) {
    deleteFromtokens_reactionsCollection(filter: $filter) {
      records {
        id
        nodeId
      }
    }
  }
`;
