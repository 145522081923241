import clsx from 'clsx';
import {MenuOptionType} from '@/view/types/types';
import styles from '@/view/styles/components/Comment.module.scss';
import React, {useEffect, useState} from 'react';
import {updateTimeSince} from '@/utils/timer';
import {format} from 'timeago.js';
import RemixIcon from './RemixIcon';
import {graphql, useFragment, useMutation} from '@/kits/relay-kit/src';
import {useAccount} from '@/kits/account-kit/src';
import removeCommentMutation from '@/graphql/remove-comment';
import type {removeCommentMutation as RemoveCommentMutationType} from '@/graphql/__generated__/removeCommentMutation.graphql';
import {ToastActions} from '@/state/hooks/toasts';
import {v4 as uuid} from 'uuid';
import {CommentFragment$key} from './__generated__/CommentFragment.graphql';
import {CommentIsReplyToFragment$key} from './__generated__/CommentIsReplyToFragment.graphql';
import {CommentContent} from './CommentContent';

const CommentFragment = graphql`
  fragment CommentFragment on comments {
    id
    nodeId
    content
    created_at
    accounts {
      id
      nodeId
      ...CommentContentOwnerFragment
    }
    comments_reactionsCollection {
      edges {
        node {
          id
          reaction
          accounts {
            id
            identities {
              profilesCollection {
                edges {
                  node {
                    username
                  }
                }
              }
            }
            ...CommentContentIsReplyToFragment
          }
        }
      }
    }
    ...CommentContentFragment
  }
`;

const CommentIsReplyToFragment = graphql`
  fragment CommentIsReplyToFragment on accounts {
    id
    nodeId
    identities {
      profilesCollection {
        edges {
          node {
            username
          }
        }
      }
    }
    ...CommentContentIsReplyToFragment
  }
`;

export const Comment = ({
  commentKey,
  parentId,
  className,
  hideAvatar,
  setReplyToCommentId,
  setReplyToUsername,
  isAReplyToKey,
  onEdit,
  editingComment,
}: {
  commentKey: CommentFragment$key;
  parentId?: string;
  className?: string;
  hideAvatar?: boolean;
  setReplyToCommentId?: (id: string | null) => void;
  setReplyToUsername?: (username: string | null) => void;
  isAReplyToKey?: CommentIsReplyToFragment$key | null | undefined;
  onEdit?: (
    commentContent: string,
    commentId: string,
    commentNodeId: string,
    replyId?: string
  ) => void;
  editingComment?: boolean;
}) => {
  const comment = useFragment(CommentFragment, commentKey);

  const isAReplyTo = useFragment(CommentIsReplyToFragment, isAReplyToKey);

  const {getAccount} = useAccount();

  const user = getAccount();
  const accountId = user?.isLoggedIn ? user.accountId : undefined;

  const [age, setAge] = useState('');

  useEffect(() => {
    return updateTimeSince(() => {
      const formatted = format(
        (comment?.created_at
          ? new Date(comment.created_at).getTime()
          : undefined) || Date.now()
      );
      const age = formatted
        .replace(' ago', '')
        .replace(
          /(\d+) (second|minute|hour|day|week|month|year)s?/g,
          (_, count, unit) => {
            return `${count}${unit === 'month' ? 'mo' : unit[0]}`;
          }
        );
      setAge(age);
    });
  }, [comment?.created_at]);

  const commentOwner = comment?.accounts;

  const [justAdded, setJustAdded] = useState(false);

  React.useEffect(() => {
    if (
      comment?.created_at &&
      new Date(comment.created_at).getTime() > Date.now() - 1000
    ) {
      setJustAdded(true);
      setTimeout(() => {
        setJustAdded(false);
      }, 1000);
    }
  }, [comment?.created_at]);

  const [removeComment, {loading: removingComment}] =
    useMutation<RemoveCommentMutationType>(removeCommentMutation);

  const menuOptions: MenuOptionType[] = [
    // {
    //   icon: <RemixIcon icon="error-warning-line" size={24} />,
    //   label: 'Report comment',
    //   value: 'report-comment',
    //   onClick: () => {},
    // },
    ...(commentOwner?.id === accountId
      ? [
          {
            icon: <RemixIcon icon="delete-bin-7-line" size={24} />,
            label: 'Remove comment',
            value: 'remove-comment',
            onClick: () => {
              if (removingComment) return;
              removeComment({
                variables: {
                  filter: {
                    id: {
                      eq: comment?.id,
                    },
                  },
                },
                onError(error) {
                  ToastActions.addToast(
                    uuid(),
                    'Something went wrong',
                    'There was a problem removing your comment',
                    'failure'
                  );
                },
                updater: store => {
                  store.delete(comment?.nodeId);
                },
              });
            },
          },
          {
            icon: <RemixIcon icon="pencil-line" size={24} />,
            label: 'Edit comment',
            value: 'edit-comment',
            onClick: () => {
              if (editingComment) return;
              onEdit?.(
                comment.content || '',
                comment.id,
                comment.nodeId,
                parentId
              );
            },
          },
        ]
      : []),
  ];

  return (
    comment && (
      <div
        className={clsx(styles.root, className)}
        data-is-a-reply={!!isAReplyTo}
        data-just-added={justAdded}
        data-avatar-visible={!hideAvatar}
      >
        <CommentContent
          hideAvatar={hideAvatar}
          commentOwner={commentOwner}
          isAReplyTo={isAReplyTo}
          setReplyToCommentId={setReplyToCommentId}
          commentContent={comment?.content || ''}
          setReplyToUsername={setReplyToUsername}
          commentContentKey={comment}
          justAdded={justAdded}
          age={age}
          menuOptions={menuOptions}
        />
      </div>
    )
  );
};
