import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation insertTokenReactionMutation(
    $input: [tokens_reactionsInsertInput!]!
  ) {
    insertIntotokens_reactionsCollection(objects: $input) {
      records {
        nodeId
        id
        created_at
        updated_at
        reaction
        accounts {
          id
          nodeId
        }
        tezos_token_id
      }
    }
  }
`;
