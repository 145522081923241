import React, {Suspense, useEffect, useRef, useState} from 'react';
import styles from '@/view/styles/pages/Nft.module.scss';
import {useMemo} from 'react';
import {useRouter} from 'next/router';
import {MimeType} from '@/view/types/types';
import TokenAsset from '../NftAsset';
import {PostStats} from '../PostActions/PostActions';
import Button from '../Button';
import NFTCommentBox from '../NFTCommentBox';
import {Comment} from '../Comment';
import {getImageUrl} from '@/utils/conversions/conversions';
import {trim} from '@/utils/trim';
import TextClamp from '../TextClamp';
import UserProfileItem from '../UserProfileItem';
import EmptyProfileCard from '../EmptyProfileCards/EmptyProfileCard';
import Tooltip from '../Tooltip';
import ReactionChips from '../ReactionChips';
import {ReactionTo} from '@/controller/hooks/eventRollup';
import {ActionsMenuActions} from '@/state/hooks/actionsMenu';
import RemixIcon from '../RemixIcon';
import {graphql, useFragment, useMutation} from '@/kits/relay-kit/src';
import {convertGqlMimeTypetoMimeType} from '@/utils/conversions/mimeTypeConversions';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import PostCollectActions from '../PostActions/blocs/PostCollectActions';
import FollowButton from '../FollowButton';
import {useAccount} from '@/kits/account-kit/src';
import updateCommentMutation from '@/graphql/update-comment';
import type {updateCommentMutation as UpdateCommentMutationType} from '@/graphql/__generated__/updateCommentMutation.graphql';
import {TokenPageContentTokenFragment$key} from './__generated__/TokenPageContentTokenFragment.graphql';
import {CommentIsReplyToFragment$key} from '../__generated__/CommentIsReplyToFragment.graphql';
import {CommentFragment$key} from '../__generated__/CommentFragment.graphql';
import TimestampTooltip from '../TimestampTooltip';

const TokenPageContentTokenFragment = graphql`
  fragment TokenPageContentTokenFragment on tezos_tokens {
    id
    nodeId
    type
    mime_type
    created_at
    artifact_uri
    thumbnail_uri
    contract_address
    token_id
    title
    description
    metadata_uri
    allow_collection
    allow_comments
    editions
    editions_minted
    accounts {
      id
      nodeId
      identities {
        id
        nodeId
        profilesCollection {
          edges {
            node {
              id
              nodeId
              username
            }
          }
        }
      }
      ...UserProfileItemFragment
    }
    commentsCollection {
      totalCount
      edges {
        node {
          id
          nodeId
          accounts {
            id
            nodeId
            ...UserProfileItemFragment
          }
          parent_comment_id
          ...CommentFragment
        }
      }
    }
    playlists_tokensCollection {
      totalCount
    }
    tokens_reactionsCollection {
      edges {
        node {
          id
          nodeId
          reaction
          accounts {
            id
            nodeId
            identities {
              id
              nodeId
              profilesCollection {
                edges {
                  node {
                    username
                  }
                }
              }
            }
          }
        }
      }
    }
    tokens_sharesCollection {
      edges {
        node {
          share_count
          repost_count
          quote_post_count
        }
      }
    }
    ...NftAssetTokenFragment
    ...PostActionsStatsTokenFragment
    ...PostCollectActionsFragment
  }
`;

export const TokenPageContent = ({
  modalId,
  tokenKey,
  comments: eventComments,
  close,
}: {
  modalId: string | undefined;
  tokenKey: TokenPageContentTokenFragment$key;
  comments: {
    id: string;
    account: CommentIsReplyToFragment$key | null | undefined;
    node: CommentFragment$key;
    parent_id: string | null;
  }[];
  close: () => void;
}) => {
  const router = useRouter();

  const token = useFragment(TokenPageContentTokenFragment, tokenKey);

  const {getAccount} = useAccount();

  const user = getAccount();

  const rootRef = React.useRef<HTMLDivElement>(null);
  const commentsListRef = React.useRef<HTMLDivElement>(null);

  const scrollToLastComment = () => {
    const current = rootRef.current;
    if (current) {
      setTimeout(() => {
        current.scrollTo({
          top: current.scrollHeight,
          behavior: 'smooth',
        });
      }, 200);
    }
  };

  const commentBoxRef = useRef<HTMLInputElement>(null);

  const focusInput = () => {
    const input = commentBoxRef.current;
    if (!input) return;
    input.focus();
  };

  const isTextPost =
    convertGqlMimeTypetoMimeType(token.mime_type || undefined) ===
    MimeType.TEXT;

  const [replyToCommentId, setReplyToCommentId] = useState<string | null>(null);
  const [replyToUsername, setReplyToUsername] = useState<string | null>(null);
  const [replyingTo, setReplyingTo] = useState<string | null>(null);
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null);
  const [editingCommentNodeId, setEditingCommentNodeId] = useState<
    string | null
  >(null);
  const {isMobile, breakpoint} = useBreakpoint();

  React.useEffect(() => {
    if (replyToCommentId) {
      setReplyingTo('@' + trim(replyToUsername || ''));
    } else {
      setReplyingTo(null);
    }
  }, [replyToCommentId, replyToUsername]);

  const arrangeComments = useMemo(() => {
    let comments: {
      node: CommentFragment$key;
      id: string;
      account: CommentIsReplyToFragment$key | null | undefined;
      isAReplyTo: CommentIsReplyToFragment$key | null | undefined;
    }[] = [];
    eventComments?.forEach(comment => {
      if (comment.parent_id) {
        // a reply to another comment
        const parentComment = comments.find(c => c.id === comment.parent_id);
        if (parentComment) {
          // will add after the comment it is a reply to, TODO: update logic if we want them to be arranged by timestamp (or nested reply to + timestamp)
          comments.splice(comments.indexOf(parentComment) + 1, 0, {
            node: comment.node,
            id: comment.id,
            account: comment.account,
            isAReplyTo: parentComment.account,
          });
        } else {
          // nah, add as a comment
          comments.push({
            node: comment.node,
            id: comment.id,
            account: comment.account,
            isAReplyTo: null,
          });
        }
      } else {
        // add as a comment
        comments.push({
          node: comment.node,
          id: comment.id,
          account: comment.account,
          isAReplyTo: null,
        });
      }
    });

    return comments;
  }, [eventComments]);

  const isPostType = token.type === 'post';

  const [hasHistory, setHasHistory] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasHistory(window.history.length > 2);
    }
  }, []);

  const [editComment, {loading: editingComment}] =
    useMutation<UpdateCommentMutationType>(updateCommentMutation);

  const updateComment = () => {
    if (!editingCommentId) return;
    editComment({
      variables: {
        filter: {
          id: {
            eq: editingCommentId,
          },
        },
        input: {
          content: commentBoxRef?.current?.value,
        },
      },
      updater: store => {
        const updatedData = store.getRootField('updatecommentsCollection');
        const newContent = updatedData
          ?.getLinkedRecords('records')?.[0]
          ?.getValue('content');
        if (!editingCommentNodeId) return;
        const newComment = store.get(editingCommentNodeId);
        if (newComment) {
          newComment.setValue(newContent, 'content');
        }
      },
      onCompleted() {
        setEditingCommentId(null);
        setEditingCommentNodeId(null);
        setReplyToCommentId(null);
        setReplyToUsername(null);
      },
    });
  };

  return (
    <>
      <div className={styles.mobileHeader}>
        <Button icon onClick={close}>
          <RemixIcon icon="arrow-down-s-line" size={22} />
        </Button>
        <div className={styles.eventTitle}>
          <div>{token.title}</div>
        </div>
        <Button
          icon
          onClick={() => {
            if (!token) return;
            ActionsMenuActions.openActionsMenu({
              type: 'token',
              id: token.id,
              title: token.title,
              description: token.description || undefined,
              image: getImageUrl(token.thumbnail_uri || ''),
              shareCount:
                parseInt(
                  token.tokens_sharesCollection?.edges?.[0]?.node
                    ?.share_count || '0'
                ) || 0,
              nodeId: token.nodeId,
              artifactUri: token?.artifact_uri || undefined,
              creator: {
                id: token?.accounts.identities.id || '',
                username:
                  token?.accounts.identities?.profilesCollection?.edges?.[0]
                    ?.node?.username || undefined,
              },
              editions: token?.editions || undefined,
              editionsMinted: token?.editions_minted || undefined,
              metadataUri: token?.metadata_uri || undefined,
              tokenType: token?.type || undefined,
            });
          }}
        >
          <RemixIcon icon="more-line" size={22} />
        </Button>
      </div>
      <div className={styles.root} ref={rootRef} data-is-text-post={isTextPost}>
        <div className={styles.container}>
          <div className={styles.desktopNavActions}>
            <div className={styles.eventTitle}>
              <div data-vaul-no-drag="">{token.title}</div>
            </div>
            <Button
              icon
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                ActionsMenuActions.openActionsMenu({
                  type: 'token',
                  id: token.id,
                  page: 0,
                  title: token.title,
                  description: token.description || undefined,
                  image: getImageUrl(token.thumbnail_uri || ''),
                  shareCount:
                    parseInt(
                      token.tokens_sharesCollection?.edges?.[0].node
                        ?.share_count || '0'
                    ) || 0,
                  nodeId: token.nodeId,
                  artifactUri: token?.artifact_uri || undefined,
                  creator: {
                    id: token?.accounts.identities.id || '',
                    username:
                      token?.accounts.identities?.profilesCollection?.edges?.[0]
                        ?.node?.username || undefined,
                  },
                  editions: token?.editions || undefined,
                  editionsMinted: token?.editions_minted || undefined,
                  metadataUri: token?.metadata_uri || undefined,
                  tokenType: token?.type || undefined,
                });
              }}
              tooltip="Actions"
            >
              <RemixIcon icon="more-fill" size={24} />
            </Button>
            <Button
              icon
              onClick={e => {
                if (!hasHistory) {
                  router.push('/');
                  return;
                }
                router.back();
              }}
            >
              <Tooltip text="Close">
                <RemixIcon icon="close-line" size={24} />
              </Tooltip>
            </Button>
          </div>
          <TokenAsset tokenKey={token} modalId={modalId} />
          <div
            className={styles.infoAndActions}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
            data-is-text-post={isTextPost}
          >
            <div>
              <PostStats
                reactionsCount={token.tokens_reactionsCollection?.edges.length}
                commentCount={token.commentsCollection?.totalCount || 0}
                editionsText={
                  isMobile
                    ? undefined
                    : `${
                        parseInt(token.editions || '0') -
                        parseInt(token.editions_minted || '0')
                      }/${parseInt(token.editions || '0')} available`
                }
                shareCount={
                  parseInt(
                    token.tokens_sharesCollection?.edges?.[0]?.node
                      ?.share_count || '0'
                  ) || 0
                }
                savedCount={token.playlists_tokensCollection?.totalCount || 0}
                tokenKey={token}
                setShowComments={() => {
                  setTimeout(() => {
                    focusInput();
                  }, 100);
                }}
                repostCount={
                  parseInt(
                    token.tokens_sharesCollection?.edges?.[0]?.node
                      ?.repost_count || '0'
                  ) +
                  parseInt(
                    token.tokens_sharesCollection?.edges?.[0]?.node
                      ?.quote_post_count || '0'
                  )
                }
              />
              {/* <EditOfferDialog
                trigger={
                  <Button filled gradient>
                    Offer
                  </Button>
                }
                token={token}
              /> */}
              {!isMobile && (
                <PostCollectActions tokenKey={token} showAddedToCartPopup />
              )}
            </div>
            <div className={styles.desktopPostActions}>
              <div>
                <ReactionChips
                  id={token?.id || ''}
                  nodeId={token?.nodeId || ''}
                  reactions={
                    token.tokens_reactionsCollection?.edges.map(edge => ({
                      by: edge.node.accounts.id,
                      byUsername:
                        edge.node.accounts.identities.profilesCollection
                          ?.edges?.[0]?.node.username || 'Unknown',
                      id: edge.node.id,
                      reaction: edge.node.reaction,
                    })) || []
                  }
                  type={ReactionTo.Post}
                  max={isMobile ? (breakpoint === 'mobile' ? 2 : 3) : 5}
                />
              </div>
            </div>
            {isMobile && (
              <div>
                <PostStats
                  editionsText={`${
                    parseInt(token.editions || '0') -
                    parseInt(token.editions_minted || '0')
                  }/${parseInt(token.editions || '0')} available`}
                  tokenKey={token}
                />
                {/* <EditOfferDialog
                      trigger={
                        <Button filled gradient>
                          Offer
                        </Button>
                      }
                      token={token}
                    /> */}
                <PostCollectActions tokenKey={token} showAddedToCartPopup />
              </div>
            )}
            {!isTextPost && !isPostType && !isMobile && (
              <div className={styles.by}>
                <UserProfileItem
                  user={token.accounts}
                  hideUsername={true}
                  showFollowButton
                />
                <FollowButton
                  id={token.accounts?.identities.id || ''}
                  username={
                    token.accounts?.identities.profilesCollection?.edges?.[0]
                      .node.username || ''
                  }
                />
              </div>
            )}
            <div>
              {!isPostType ? (
                <>
                  {token.description && (
                    <TextClamp
                      maxChars={200}
                      text={token.description}
                      className={styles.description}
                      prefix={null}
                      enableUrls
                      enableMentions
                      enableHashtags
                    />
                  )}
                  {token.created_at && (
                    <TimestampTooltip
                      date={token.created_at}
                      className={styles.timestamp}
                    />
                  )}
                </>
              ) : null}
            </div>
            {isMobile && (
              <div className={styles.by}>
                <UserProfileItem
                  user={token.accounts}
                  hideUsername={true}
                  showFollowButton
                />
              </div>
            )}
            {/* <div className={styles.shareToEarn}>
              <div>
                <div>
                  <RemixIcon icon="share-forward-line" size={32} />
                  <span>Share to Earn</span>
                </div>
                <div>
                  <span>
                    Earn 6.9% of the sale price if someone places the winning
                    bid using your wallet-based share link. Connect wallet to
                    generate yours below.
                  </span>
                </div>
              </div>
              <div>
                <Button outlined>
                  <span>Copy 6.9% link</span>
                  <RemixIcon icon="link-m" size={24} />
                </Button>
                <Button outlined>
                  <span>Tweet 6.9% link</span>
                  <RemixIcon icon="twitter-x-fill" size={24} />
                </Button>
              </div>
            </div> */}
          </div>
        </div>
        <div className={styles.commentsWrapper}>
          <div className={styles.commentsList} ref={commentsListRef}>
            {arrangeComments.map(comment => {
              return (
                <Suspense key={comment.id}>
                  <Comment
                    commentKey={comment.node}
                    hideAvatar={isMobile}
                    setReplyToCommentId={id => {
                      setReplyToCommentId(id);
                      setTimeout(() => {
                        focusInput();
                      }, 100);
                    }}
                    setReplyToUsername={setReplyToUsername}
                    isAReplyToKey={comment.isAReplyTo}
                    onEdit={(
                      commentContent: string,
                      commentId: string,
                      commentNodeId: string,
                      parentId?: string
                    ) => {
                      setEditingCommentId(commentId);
                      setEditingCommentNodeId(commentNodeId);
                      if (parentId) {
                        setReplyToCommentId(parentId);
                      }
                      setTimeout(() => {
                        if (commentBoxRef.current) {
                          commentBoxRef.current.value = commentContent;
                        }
                        focusInput();
                      }, 100);
                    }}
                    editingComment={editingComment}
                  />
                </Suspense>
              );
            })}
          </div>
          {token.commentsCollection?.edges?.length === 0 &&
            token.allow_comments && (
              <EmptyProfileCard
                className={styles.empty}
                icon={<RemixIcon icon="message-2-line" size={54} />}
                title={`There are no comments yet, be the first to comment!`}
              />
            )}
          <div
            className={styles.commentBox}
            data-comments-disabled={token?.allow_comments === false}
            data-has-blockchain-actions={!!user.isLoggedIn}
          >
            <NFTCommentBox
              replyToCommentId={replyToCommentId}
              replyingTo={replyingTo}
              resetReplyTo={() => {
                setReplyToCommentId(null);
                setReplyToUsername(null);
                setEditingCommentId(null);
                setEditingCommentNodeId(null);
              }}
              onAdd={() => {
                setReplyToCommentId(null);
                setReplyToUsername(null);
                if (!replyToCommentId) {
                  scrollToLastComment();
                }
              }}
              allowComments={token.allow_comments || false}
              tokenId={token?.id || ''}
              tokenNodeId={token?.nodeId || ''}
              ref={commentBoxRef}
              editing={!!editingCommentId}
              onUpdate={updateComment}
            />
          </div>
        </div>
        {!modalId && (
          <div
            className={styles.goBack}
            onClick={() => {
              if (!hasHistory) {
                router.push('/');
                return;
              }
              router.back();
            }}
          />
        )}
      </div>
    </>
  );
};
