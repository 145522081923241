import React, {useCallback, useMemo} from 'react';
import {Drawer as DialogPrimitive} from 'vaul';
import styles from '@/view/styles/components/Dialog/Dialog.module.scss';
import clsx from 'clsx';

export default function Dialog({
  defaultOpen,
  disabled,
  trigger,
  children,
  className,
  backdropClassname,
  handleOnClose,
}: {
  defaultOpen?: boolean;
  disabled?: boolean;
  trigger: React.ReactNode;
  children: React.ReactNode | ((closeDialog: () => void) => React.ReactNode);
  className?: string;
  backdropClassname?: string;
  handleOnClose?: () => void;
}) {
  const [open, setOpen] = React.useState(defaultOpen || false);

  React.useEffect(() => {
    if (defaultOpen !== undefined) {
      setOpen(defaultOpen);
    }
  }, [defaultOpen]);

  return (
    <DialogPrimitive.NestedRoot
      nested
      modal
      open={open}
      onOpenChange={useCallback(
        (open: boolean) => {
          if (!open) {
            handleOnClose?.();
          }
          setOpen(open);
        },
        [handleOnClose]
      )}
    >
      {disabled ? (
        trigger
      ) : (
        <DialogPrimitive.Trigger
          asChild
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            setOpen(true);
          }}
        >
          {trigger}
        </DialogPrimitive.Trigger>
      )}
      <DialogPrimitive.Portal>
        <DialogPrimitive.Close asChild>
          <DialogPrimitive.Overlay
            className={clsx(styles.backdrop, backdropClassname)}
          />
        </DialogPrimitive.Close>
        <DialogPrimitive.Content className={clsx(styles.content, className)}>
          {useMemo(
            () =>
              typeof children === 'function'
                ? children(() => {
                    setOpen(false);
                  })
                : children,
            [children]
          )}
        </DialogPrimitive.Content>
      </DialogPrimitive.Portal>
    </DialogPrimitive.NestedRoot>
  );
}
