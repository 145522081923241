import {create} from 'zustand';

type NotificationsState = {
  unreadCount: number;
  unreadMessageCount: number;
};

type NotificationsFunctions = {
  updateUnreadCount: (count: number) => void;
  updateUnreadMessageCount: (count: number) => void;
  reset: () => void;
};

const defaultState: NotificationsState = {
  unreadCount: 0,
  unreadMessageCount: 0,
};

export const useNotifications = create<NotificationsState>((set, get) => {
  return {
    ...defaultState,
  };
});

export const NotificationsActions: NotificationsFunctions = {
  updateUnreadCount: (count: number) => {
    useNotifications.setState({unreadCount: count});
  },
  updateUnreadMessageCount: (count: number) => {
    useNotifications.setState({unreadMessageCount: count});
  },
  reset: () => {
    useNotifications.setState({...defaultState});
  },
};
