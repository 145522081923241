import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation insertIdentityMutation($input: [identitiesInsertInput!]!) {
    insertIntoidentitiesCollection(objects: $input) {
      records {
        id
        nodeId
      }
    }
  }
`;
