import {
  PublicMagic,
  getConfig as getMagicAuthKitConfig,
} from '@/kits/magic-auth-kit/src';
import {getBeaconWallet} from '@/kits/beacon-auth-kit/src';
import {OAuthRedirectConfiguration} from '@magic-ext/oauth';
import {OAuthProvider} from '@magic-ext/oauth';
import {TezosToolkit} from '@taquito/taquito';
import {
  RequestSignPayloadInput,
  SigningType,
  BeaconEvent,
} from '@airgap/beacon-dapp';
import config from '../config.json';
import {stringToBytes} from '@taquito/utils';

export type LoginOptions =
  | {
      strategy: string;
      provider: string;
    }
  | {
      strategy: 'beacon';
    };

export async function getTezosToolkitWithMagicProvider() {
  if (!PublicMagic) {
    return;
  }

  const magicSigner = await PublicMagic.taquito.createMagicSigner();
  const tezos = new TezosToolkit(config.tezosConfig.tezosRpcUri);

  tezos.setProvider({
    config: {
      confirmationPollingTimeoutSecond: 3000,
    },
    rpc: config.tezosConfig.tezosRpcUri,
    signer: magicSigner,
  });

  return tezos;
}

async function loginWithBeacon() {
  const {wallet} = getBeaconWallet();

  wallet.client.subscribeToEvent(
    BeaconEvent.CHANNEL_CLOSED,
    async (event: any) => {
      wallet.client.getActiveAccount();
    }
  );

  await wallet.requestPermissions();

  const dappUrl = 'dns.xyz';
  const timestampAsNonce = new Date().toISOString();
  const walletAddress = await wallet.getPKH();
  const walletPublicKey = await wallet.getPK();
  const walletPublicKeyHash = await wallet.getPKH();
  const message = `Identity verification for ${walletAddress}`;
  const formattedInput: string = [
    'Tezos Signed Message:',
    dappUrl,
    timestampAsNonce,
    message,
  ].join(' ');

  // Put it all together (https://taquito.io/docs/signing/#generating-a-signature-with-beacon-sdk)
  const bytes = stringToBytes(formattedInput);
  const bytesLength = (bytes.length / 2).toString(16);
  const addPadding = `00000000${bytesLength}`;
  const paddedBytesLength = addPadding.slice(addPadding.length - 8);
  const payloadBytes = '05' + '01' + paddedBytesLength + bytes;
  const payload: RequestSignPayloadInput = {
    signingType: SigningType.MICHELINE,
    payload: payloadBytes,
    sourceAddress: walletAddress,
  };

  // Sign
  const signedPayload = await wallet.client.requestSignPayload(payload);
  const {signature} = signedPayload;

  return {
    accountInfo: wallet.client.getActiveAccount(),
    signature,
    payloadBytes,
    walletPublicKey,
    walletPublicKeyHash,
  };
}

async function loginWithMagic(provider: string) {
  if (!PublicMagic) {
    return;
  }

  const magicAuthKitConfig = getMagicAuthKitConfig();
  const providerConfigRaw = magicAuthKitConfig.providers.find(
    config => config.provider === provider
  );

  if (!providerConfigRaw) {
    throw new Error('provider not found');
  }

  const providerConfig: OAuthRedirectConfiguration = {
    provider: providerConfigRaw.provider as OAuthProvider,
    redirectURI:
      window.history.state?.redirectURI + '/magic' || window.location.href,
    scope: providerConfigRaw.scope || [],
  };

  return await PublicMagic.oauth.loginWithRedirect(providerConfig);
}

export async function handleMagicAuthRedirect() {
  if (!PublicMagic) {
    return;
  }

  return await PublicMagic.oauth.getRedirectResult();
}

export function login(options: LoginOptions) {
  if (!config.authStrategies.includes(options.strategy)) {
    throw new Error('strategy not found');
  }

  switch (options.strategy) {
    case 'beacon':
      return loginWithBeacon();
    case 'magic':
      return loginWithMagic(options.provider);
    default:
      throw new Error('strategy not found');
  }
}

export function getConfig() {
  return config;
}
