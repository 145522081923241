import {MimeType} from '@/view/types/types';

// MIMETYPE

export const convertGqlMimeTypetoMimeType = (type: string | undefined) => {
  let mimeType = MimeType.IMAGE;

  if (type === undefined) {
    return mimeType;
  }
  switch (type) {
    case 'image/png':
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/gif':
    case 'image/svg+xml':
      mimeType = MimeType.IMAGE;
      break;
    case 'audio/mp3':
    case 'audio/mpeg':
    case 'audio/ogg':
    case 'audio/wav':
    case 'audio/x-wav':
      mimeType = MimeType.AUDIO;
      break;
    case 'video/mp4':
    case 'video/quicktime':
      mimeType = MimeType.VIDEO;
      break;
    case 'application/pdf':
    case 'text/html':
    case 'application/x-directory':
      mimeType = MimeType.APPLICATION;
      break;
    case 'text/plain':
      mimeType = MimeType.TEXT;
      break;
    case 'model/gltf-binary':
      mimeType = MimeType['MODEL-VIEWER'];
      break;
  }
  return mimeType;
};

export const convertMimeTypestoGqlMimeType = (
  types: string[] | undefined
): string[] => {
  let mimeTypes: string[] = [];

  if (types === undefined) {
    return [];
  }
  if (types.includes('Image')) {
    mimeTypes.push('image/png', 'image/jpeg', 'image/jpg', 'image/svg+xml');
  }
  if (types.includes('GIF')) {
    mimeTypes.push('image/gif');
  }
  if (types.includes('Video')) {
    mimeTypes.push('video/mp4', 'video/quicktime');
  }
  if (types.includes('Music')) {
    mimeTypes.push(
      'audio/mp3',
      'audio/mpeg',
      'audio/ogg',
      'audio/wav',
      'audio/x-wav'
    );
  }
  if (types.includes('HTML')) {
    mimeTypes.push('application/pdf', 'text/html', 'application/x-directory');
  }
  if (types.includes('Text')) {
    mimeTypes.push('text/plain');
  }
  if (types.includes('3D')) {
    mimeTypes.push('model/gltf-binary');
  }
  return mimeTypes;
};
