import {BeaconWallet} from '@taquito/beacon-wallet';
import {TezosToolkit} from '@taquito/taquito';
import {DAppClientOptions, NetworkType} from '@airgap/beacon-dapp';
import authConfig from '@/kits/authentication-kit/config.json';
import config from '../config.json';

let wallet: BeaconWallet;
const tezosToolkit = new TezosToolkit(authConfig.tezosConfig.tezosRpcUri);

if (typeof window !== 'undefined') {
  const options: DAppClientOptions = {
    name: config.dapp.name,
    appUrl: config.dapp.url,
    network: {
      type: authConfig.tezosConfig.networkType as NetworkType,
    },
  };

  wallet = new BeaconWallet(options);
  tezosToolkit.setProvider({
    config: {
      confirmationPollingTimeoutSecond: 3000
    },
    wallet: wallet
  });
}

export const getBeaconWallet = function () {
  return {
    wallet,
    tezosToolkit,
  };
};

export const getBalance = async function (pkh: string) {
  const balance = await tezosToolkit.tz.getBalance(pkh);
  return balance.dividedBy(1000000).toString();
}

export function getConfig() {
  return config;
}
