import config from '../config.json';

export function ConvertToMutez(amount: number) {
  return Math.floor(amount * 1_000_000).toFixed(0);
}

export function ConvertFromMutez(mutez: string | number) {
  return (Number(mutez) / 1_000_000)
    .toFixed(6)
    .replace(/0*$/, '')
    .replace(/\.$/, '');
}

export function GetConfig() {
  return config;
}

export function ToHex(str: string) {
  var result = '';
  for (var i = 0; i < str.length; i++) {
    result += str.charCodeAt(i).toString(16);
  }
  return result;
}

