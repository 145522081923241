import {create} from 'zustand';
import {persist} from 'zustand/middleware';

type Draft = {
  type: 'comment'; // | 'post' | 'message'; // other potential types
  id: string;
  content: string;
};

type DraftsState = {
  drafts: Draft[];
};

type DraftsFunctions = {
  addDraft: (draft: Draft) => void;
  addOrUpdateDraft: (draft: Draft) => void;
  removeDraft: (id: string) => void;
  reset: () => void;
};

const defaultState: DraftsState = {
  drafts: [],
};

// export const useDrafts = create( // persisted store - if we want to keep drafts between sessions
//   persist<DraftsState>(
//     (set, get) => {
//       return {
//         ...defaultState,
//       };
//     },
//     {
//       name: 'drafts',
//     }
//   )
// );

export const useDrafts = create<DraftsState>((set, get) => {
  return {
    ...defaultState,
  };
});

export const DraftsActions: DraftsFunctions = {
  addDraft: (draft: Draft) => {
    useDrafts.setState(state => {
      return {
        drafts: [...state.drafts, draft],
      };
    });
  },
  addOrUpdateDraft(draft: Draft) {
    const index = useDrafts.getState().drafts.findIndex(d => d.id === draft.id);
    if (index !== -1) {
      useDrafts.setState(state => {
        const drafts = [...state.drafts];
        drafts[index] = draft;
        return {
          drafts,
        };
      });
    } else {
      DraftsActions.addDraft(draft);
    }
  },
  removeDraft: (id: string) => {
    useDrafts.setState(state => {
      return {
        drafts: state.drafts.filter(draft => draft.id !== id),
      };
    });
  },
  reset: () => {
    useDrafts.setState({...defaultState});
  },
};
