import React, {PropsWithChildren, Suspense, useMemo} from 'react';
import styles from '@/view/styles/providers/Nav/Nav.module.scss';
import Image from '../../components/Image';
import {useRouter} from 'next/router';
import ProcessingStatus from '../../components/ProcessingItems/ProcessingStatus';
import Create from '@/view/pages/create';
import {
  useCreateStatus,
  useCreateStatusActions,
} from '@/state/hooks/createStatus';
import Drawer, {DrawerLoader} from '@/view/providers/Nav/Drawer';
import MobileNav from '@/view/components/MobileNav/MobileNav';
import SearchDialog from '@/view/components/CMDk/Search/SearchDialog';
import {useActionsMenu} from '@/state/hooks/actionsMenu';
import {useLoginModalActions} from '@/state/hooks/auth/loginModal';
import dynamic from 'next/dynamic';
import {CREATE_LOGIN_MESSAGE, PROFILE_LOGIN_MESSAGE} from '@/content';
import {useAccount} from '@/kits/account-kit/src';
import {useOnchainActionStatus} from '@/state/hooks/onChainActionStatus';
import ProcessingStatusCollect from '@/view/components/ProcessingItems/ProcessingStatusCollect';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import {ShoppingCartDialog} from '@/view/components/ShoppingCart/ShoppingCartDialog';
import {useShoppingCart} from '@/state/hooks/shoppingCart';
import {TezBalanceActions, useTezBalance} from '@/state/hooks/useTezBalance';
import Button from '@/view/components/Button';
import RemixIcon from '@/view/components/RemixIcon';
import {useItemModal} from '@/state/hooks/itemModal';
import {ItemModalContent} from '@/view/components/TokenPageLink';
import {ErrorBoundary} from 'react-error-boundary';
import AppWideErrorBoundary from '@/view/components/AppWideErrorBoundary';
const NotificationsWs = dynamic(
  () => import('@/view/components/WebsocketSubscribers/NotificationsWs'),
  {
    ssr: false,
  }
);
const MessagingWs = dynamic(
  () => import('@/view/components/WebsocketSubscribers/MessagingWs'),
  {
    ssr: false,
  }
);
const AccountsGroupsWs = dynamic(
  () => import('@/view/components/WebsocketSubscribers/AccountsGroupsWs'),
  {
    ssr: false,
  }
);

export default function NavProvider({children}: PropsWithChildren<{}>) {
  return (
    <ErrorBoundary
      fallback={<AppWideErrorBoundary />}
      onError={e => {
        console.error(e);
      }}
    >
      <Suspense
        fallback={
          <div
            className="globalLoader"
            style={{
              position: 'fixed',
              zIndex: '9999',
              top: '0',
              left: '0',
              backgroundColor: '#000',
              width: '100vw',
              height: '100vh',
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              alt="DNS"
              src="/anim.gif"
              style={{
                width: '200px',
                maxWidth: '60vw',
                height: '200px',
                margin: 'auto',
                objectFit: 'contain',
              }}
            />
          </div>
        }
      >
        <NavContent>{children}</NavContent>
      </Suspense>
    </ErrorBoundary>
  );
}

const NavContent = ({children}: PropsWithChildren<{}>) => {
  const {getAccount} = useAccount();

  const account = getAccount();

  const accountId = account.isLoggedIn ? account.accountId : undefined;
  const identityId = account.isLoggedIn ? account.identityId : undefined;

  const router = useRouter();

  const {status, type, thumbnail, url, showCreateModal, previousRoute} =
    useCreateStatus();
  const {actions} = useOnchainActionStatus();

  const isNavHidden = useMemo(() => {
    return (
      router.pathname.includes('item/') ||
      router.pathname.startsWith('/onboarding') ||
      router.pathname.startsWith('/landing') ||
      router.pathname.startsWith('/magic') ||
      router.pathname.startsWith('/sign-in') ||
      router.asPath.split('#')[1] === 'manage'
    );
  }, [router]);

  const {open: isActionsMenuOpen} = useActionsMenu();
  const [searchOpen, setSearchOpen] = React.useState(false);

  const {isMobile} = useBreakpoint();
  const {isOpen, close: closeCart} = useShoppingCart();

  React.useEffect(() => {
    if (isActionsMenuOpen) {
      setSearchOpen(false);
    }
  }, [isActionsMenuOpen]);

  const {balance, loading} = useTezBalance();

  React.useEffect(() => {
    if (!balance && account.isLoggedIn) {
      TezBalanceActions.updateBalance(account.address);
    }
  }, [account, balance]);

  const [showTopBanner, setShowTopBanner] = React.useState(false);

  React.useEffect(() => {
    // TODO: enable when we launch the beta
    const topBanner = localStorage.getItem('topBannerv4');
    if (topBanner === 'false') {
      setShowTopBanner(false);
    } else {
      setShowTopBanner(true);
    }
  }, []);

  const {isOpen: itemModalOpen, id: itemModalId} = useItemModal();

  return (
    <div className={styles.base}>
      <div className={styles.root}>
        <Suspense fallback={!isNavHidden ? <DrawerLoader /> : null}>
          <Drawer
            isNavHidden={isNavHidden}
            showCreateModal={showCreateModal}
            identityId={identityId || ''}
            loggedIn={account.isLoggedIn}
          />
        </Suspense>
        {status !== 'idle' && (
          <ProcessingStatus
            type={type || 'post'}
            progress={
              status === 'uploading'
                ? 30
                : status === 'processing'
                ? 60
                : status === 'done'
                ? 100
                : -1
            }
            icon={
              !!thumbnail ? <Image dynamic src={thumbnail} alt="test" /> : null
            }
            url={url || ''}
          />
        )}
        {actions.length > 0 && <ProcessingStatusCollect />}
        {children}
      </div>
      <Suspense fallback={<></>}>
        <NotificationsWs accountId={accountId || ''} />
      </Suspense>
      <Suspense fallback={<></>}>
        <MessagingWs accountId={accountId || ''} />
      </Suspense>
      <Suspense fallback={<></>}>
        <AccountsGroupsWs accountId={accountId || ''} />
      </Suspense>
      <SearchDialog
        defaultOpen={searchOpen}
        onClose={() => {
          setSearchOpen(false);
        }}
        trigger={<></>}
      />
      <ShoppingCartDialog
        defaultOpen={isOpen}
        onClose={() => {
          closeCart();
        }}
        trigger={<></>}
      />
      {showTopBanner && !isMobile ? (
        <div className={styles.floatyBanner}>
          <div>
            <span>
              DNS is in public beta! We are experiencing a ton of users, and
              working on scalability issues.
            </span>
            <span>
              Most are fixed. Please click Retry if there are issues, and reach
              us on Discord for help.
            </span>
          </div>
          <Button
            icon
            onClick={() => {
              setShowTopBanner(false);
              localStorage.setItem('topBannerv4', 'false');
            }}
          >
            <RemixIcon icon="close-line" size={16} />
          </Button>
        </div>
      ) : null}
      {(showCreateModal || status !== 'idle') && (
        <div
          className={styles.createPage}
          data-hidden={!showCreateModal}
          data-nav-hidden={isNavHidden}
        >
          <Suspense fallback={<></>}>
            <Create
              onClose={(isPopState: boolean) => {
                if (previousRoute && !isPopState) {
                  router.push(previousRoute);
                  useCreateStatusActions.setShowCreateModal(false);
                  useCreateStatusActions.setPreviousRoute(undefined);
                  return;
                }
                useCreateStatusActions.setShowCreateModal(false);
                // router.back();
                useCreateStatusActions.setPreviousRoute(undefined);
              }}
            />
          </Suspense>
        </div>
      )}
      <ItemModalContent id={itemModalId} isOpen={itemModalOpen} />
      {isMobile && (
        <MobileNav
          loggedIn={account.isLoggedIn}
          identityId={account.isLoggedIn ? account.identityId : ''}
        />
      )}
    </div>
  );
};
