import axios from 'axios';
import {MINTER_URI} from '@/config';
import {RightsEnum} from '@/view/types/mintTypes';
import {metadataImportType} from '@/view/types/mintTypes';
import {MetadataType} from '@/view/types/mintTypes';
import {IMetadata} from '@/view/types/mintTypes';

export const buildMetadata: (data: metadataImportType) => MetadataType = ({
  name = '',
  description = '',
  tags = [],
  editions = 1,

  thumbnailUri = '',
  artifactUri = '',
  displayUri = '',
  image = '',
  formats = [],

  minter = '',
  creators,
  royalties = {
    decimals: 0,
    shares: {},
  },

  rights = RightsEnum.NONE,
  nsfw = false,
}: metadataImportType) => {
  const result: MetadataType = {
    name,
    description,
    tags,
    editions,

    thumbnailUri,
    artifactUri,
    displayUri,
    image,
    formats,

    mintingTool: 'https://dns.xyz',
    mintingToolVersion: 'v1',
    minter,
    creators,
    royalties,

    rights,
    date: new Date().toISOString(),

    symbol: 'DNS', // OBJKT Collections // TODO @tyler: do we change this?
    decimals: 0,
    shouldPreferSymbol: false,
    isBooleanAmount: false,

    contentRating: nsfw ? 'mature' : undefined,
  };
  return result;
};

export const uploadMetadata = async ({
  mintMetadata,
}: {
  mintMetadata: MetadataType;
}) => {
  const upload = new Blob([JSON.stringify(mintMetadata)]);
  const formData = new FormData();
  formData.append('files', upload);
  const {data: {cids = []} = {}} = await axios.post(
    `${MINTER_URI}/upload/ipfs/metadata`,
    formData
  );

  const metadata: IMetadata[] = [];

  for await (const cid of cids) {
    metadata.push({
      cid: `ipfs://${cid}`
        .split('')
        .reduce(
          (hex, char) =>
            (hex += char.charCodeAt(0).toString(16).padStart(2, '0')),
          ''
        ),
      editions: mintMetadata.editions ?? mintMetadata.editions ?? 1,
    });
  }

  return metadata;
};
