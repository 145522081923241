import React, {useCallback, useMemo} from 'react';
import {Drawer as BottomSheetPrimitive} from 'vaul';
import styles from '@/view/styles/components/BottomSheet/BottomSheet.module.scss';
import clsx from 'clsx';
import Button from '../Button';
import RemixIcon from '../RemixIcon';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';

export default function BottomSheet({
  trigger,
  children,
  className,
  backdropClassName,
  pauseEvents,
  closeButton,
  handleOnClose,
  defaultOpen,
  keepOpen,
  dialogOnDesktop,
  draggable = true,
  asModal = true,
}: {
  trigger: React.ReactNode;
  children: React.ReactNode | ((closeDialog: () => void) => React.ReactNode);
  className?: string;
  backdropClassName?: string;
  pauseEvents?: boolean;
  closeButton?: boolean;
  handleOnClose?: (isPopState?: boolean) => void;
  defaultOpen?: boolean;
  keepOpen?: boolean;
  dialogOnDesktop?: boolean;
  draggable?: boolean;
  asModal?: boolean;
}) {
  const [open, setOpen] = React.useState(defaultOpen || false);

  React.useEffect(() => {
    if (defaultOpen !== undefined) {
      setOpen(defaultOpen);
    }
  }, [defaultOpen]);

  const handleClose = useCallback(() => {
    const timeout = setTimeout(() => {
      setOpen(false);
      handleOnClose?.(false);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [handleOnClose]);

  React.useEffect(() => {
    const handlePopState = () => {
      setOpen(false);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const {isMobile} = useBreakpoint();

  return (
    <BottomSheetPrimitive.NestedRoot
      nested
      open={keepOpen !== undefined ? keepOpen : open}
      onOpenChange={useCallback(
        (open: boolean) => {
          if (!open) {
            handleOnClose?.(false);
          }
          setOpen(open);
        },
        [handleOnClose]
      )}
      modal={asModal === false ? false : true}
    >
      <BottomSheetPrimitive.Trigger asChild>
        <span>{trigger}</span>
      </BottomSheetPrimitive.Trigger>
      <BottomSheetPrimitive.Portal>
        <BottomSheetPrimitive.Overlay
          className={clsx(
            styles.backdrop,
            backdropClassName,
            !isMobile && dialogOnDesktop && styles.desktopDialogPauseEvents
          )}
          onPointerDown={() => {
            if (pauseEvents) {
              return;
            }
            handleClose();
          }}
        />
        <BottomSheetPrimitive.Content
          className={clsx(
            styles.root,
            dialogOnDesktop && styles.dialogOnDesktop,
            className
          )}
        >
          <BottomSheetPrimitive.Close asChild>
            <div className={styles.backdropCloseHandle}>
              {draggable !== false && (
                <span className={styles.backdropCloseHandleLine} />
              )}
              {closeButton && (
                <Button
                  icon
                  filled
                  className={styles.backdropCloseHandleButton}
                  tooltip="Close"
                  tooltipSide="right"
                >
                  <RemixIcon icon="close-line" size={24} />
                </Button>
              )}
            </div>
          </BottomSheetPrimitive.Close>
          {useMemo(
            () =>
              typeof children === 'function'
                ? children(() => {
                    setOpen(false);
                    handleOnClose?.(false);
                  })
                : children,
            [children, handleOnClose]
          )}
        </BottomSheetPrimitive.Content>
      </BottomSheetPrimitive.Portal>
    </BottomSheetPrimitive.NestedRoot>
  );
}
