import React from 'react';
import RemixIcon from '../RemixIcon';
import Link from 'next/link';
import {Avatar} from '../Avatar';
import {useRouter} from 'next/router';
import styles from '@/view/styles/components/MobileNav/MobileDrawer.module.scss';
import {trim} from '@/utils/trim';
import {MemoIcon} from '../utils/MemoChildren';
import Menu from '../Menu';
import clsx from 'clsx';
import {MenuOptionType} from '@/view/types/types';
import {useLoginModalActions} from '@/state/hooks/auth/loginModal';
import {PROFILE_LOGIN_MESSAGE} from '@/content';
import {BATCH_MINTER, NOTIFICATIONS, THEME_TOGGLE} from '@/config';
import {DarkModeActions, useDarkMode} from '@/controller/hooks/darkMode';
import {getImageUrl} from '@/utils/conversions/conversions';
import {useAccount} from '@/kits/account-kit/src';
import {graphql, useLazyLoadQuery} from '@/kits/relay-kit/src';
import {getBeaconWallet} from '@/kits/beacon-auth-kit/src';
import {PublicMagic} from '@/kits/magic-auth-kit/src';
import Button from '../Button';
import {CreateDialog} from '../CMDk/Create/CreateDialog';
import {TezBalanceActions, useTezBalance} from '@/state/hooks/useTezBalance';
import {useShoppingCart} from '@/state/hooks/shoppingCart';
import Skeleton from '../Skeleton';
import type {MobileDrawerProfileQuery as MobileDrawerProfileQueryType} from './__generated__/MobileDrawerProfileQuery.graphql';

const drawerLinks: {
  icon: (active?: boolean) => React.ReactNode;
  value: string;
  label: string;
  url?: string | ((wallet: string) => string);
}[] = [
  {
    icon: (active?: boolean) => (
      <MemoIcon Component={'home-7-line'} size={22} data-active={active} />
    ),
    value: 'home',
    label: 'Home',
    url: '/',
  },
  {
    label: 'Profile',
    value: 'profile',
    icon: (active?: boolean) => (
      <MemoIcon Component={'user-line'} size={22} data-active={active} />
    ),
    url: wallet => (wallet ? `/${wallet}` : 'not-signed-in'),
  },
  {
    label: 'Wallet',
    value: 'wallet',
    icon: (active?: boolean) => (
      <MemoIcon Component={'wallet-line'} size={22} data-active={active} />
    ),
    url: wallet => (wallet ? `/wallet` : 'not-signed-in'),
  },
  // ...(NOTIFICATIONS
  //   ? [
  //       {
  //         label: 'Inbox',
  //         value: 'inbox',
  //         icon: (active?: boolean) => (
  //           <MemoIcon Component={'mail-line'} size={22} data-active={active} />
  //         ),
  //         url: (wallet?: string) => (wallet ? `/inbox` : 'not-signed-in'),
  //       },
  //     ]
  //   : []),
  {
    label: 'Collections',
    value: 'collections',
    icon: (active?: boolean) => (
      <MemoIcon Component={'stack-line'} size={22} data-active={active} />
    ),
    url: wallet => (wallet ? `/collections` : 'not-signed-in'),
  },
];

const MobileDrawerProfileQuery = graphql`
  query MobileDrawerProfileQuery($id: BigInt!) {
    identitiesCollection(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          profilesCollection {
            edges {
              node {
                id
                username
                avatar_uri
                banner_uri
              }
            }
          }
        }
      }
    }
    followers: followsCollection(filter: {followee_id: {eq: $id}}) {
      totalCount
    }
    following: followsCollection(filter: {follower_id: {eq: $id}}) {
      totalCount
    }
  }
`;

export default function MobileDrawer({identityId}: {identityId: string}) {
  const router = useRouter();
  const {getAccount, logoutAccount} = useAccount();
  const {clear} = useShoppingCart();

  const user = getAccount();

  const loggedIn = user.isLoggedIn;
  const address = user.isLoggedIn ? user.address : undefined;

  const {darkMode} = useDarkMode();

  const [addressText, setAddressText] = React.useState<string | undefined>(
    trim(address || 'Copy address')
  );
  const {balance} = useTezBalance();

  const options: MenuOptionType[] = [
    ...(address
      ? [
          {
            noHighlight: true,
            icon: null,
            value: 'balance',
            label: (
              <div className={styles.balance}>
                <div>
                  <div>Balance:</div>
                  <div>
                    {balance} {balance && 'xtz'}
                  </div>
                </div>
                <Button
                  text
                  onMouseEnter={() => {
                    setAddressText('Copy address');
                  }}
                  onMouseLeave={() => {
                    setAddressText(address ? trim(address) : undefined);
                  }}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (address) {
                      navigator.clipboard.writeText(address);
                      setAddressText('Copied!');
                      setTimeout(() => {
                        setAddressText(address ? trim(address) : undefined);
                      }, 1000);
                    }
                  }}
                  className={styles.addressCopy}
                >
                  <span>{addressText}</span>
                  <RemixIcon
                    icon={
                      addressText === 'Copied!'
                        ? 'check-line'
                        : 'file-copy-2-line'
                    }
                    size={18}
                  />
                </Button>
              </div>
            ),
          },
        ]
      : []),
    ...(THEME_TOGGLE
      ? [
          {
            icon: <RemixIcon icon="moon-line" size={22} />,
            label: 'Dark mode',
            value: 'dark-mode',
            onClick: () => {
              DarkModeActions.setDarkMode(!darkMode);
            },
          },
        ]
      : []),
    ...(BATCH_MINTER
      ? [
          {
            icon: <RemixIcon icon="add-circle-line" size={22} />,
            label: 'Batch minter',
            value: 'batch-minter',
            onClick: () => {},
          },
        ]
      : []),
    ...(loggedIn
      ? [
          {
            icon: <RemixIcon icon="settings-4-line" size={22} />,
            label: 'Settings',
            value: 'settings',
            onClick: () => {
              closeDrawer();
              router.push('/settings');
            },
          },
        ]
      : []),
    {
      icon: <RemixIcon icon="play-line" size={22} />,
      label: 'DNS Studio',
      value: 'dns-studio',
      onClick: () => {
        window.open('https://studio.dns.xyz', '_blank', 'noopener noreferrer');
      },
    },
    ...(identityId
      ? [
          {
            icon: <RemixIcon icon="logout-circle-line" size={22} />,
            label: 'Logout',
            value: 'logout',
            onClick: () => {
              const account = getAccount();
              const {wallet: beaconWallet} = getBeaconWallet();

              if (account.isLoggedIn && account.walletType === 'beacon') {
                console.log('Disconnecting beacon wallet.');
                beaconWallet.client.clearActiveAccount();
              } else if (account.isLoggedIn && account.walletType === 'magic') {
                if (PublicMagic) {
                  console.log('Disconnecting magic wallet.');
                  PublicMagic.user.logout();
                }
              }

              console.log('Logging out of DNS.');
              logoutAccount();
              clear();
              closeDrawer();
              router.reload();
            },
          },
        ]
      : [
          {
            icon: <RemixIcon icon="login-circle-line" size={22} />,
            label: 'Sign in',
            value: 'signin',
            onClick: () => {
              closeDrawer();
              useLoginModalActions.setShowLoginModal(true);
            },
          },
        ]),
  ];

  const [activeTab, setActiveTab] = React.useState<string>('');

  React.useEffect(() => {
    const route = router.pathname.split('/')[1];
    const subRoute = router.pathname.split('/')[2];
    const currentTab = () => {
      switch (route) {
        case '':
          return 'home';
        case 'wallet':
          return 'wallet';
        case 'explore':
          switch (subRoute) {
            case 'music':
              return 'music';
            case 'video':
              return 'videos';
            case 'creators':
              return 'creators';
            case 'art':
              return 'artwork';
            default:
              return route;
          }
        case '[id]':
          return 'profile';
        default:
          return route;
      }
    };
    setActiveTab(currentTab);
  }, [router.pathname]);

  const closeDrawer = () => {
    const root = document.documentElement;
    const body = document.body;
    body.classList.remove('disableBodyScroll');
    root.style.setProperty('--mobileDrawerTranslate', '-110%');
    root.style.setProperty('--mobileDrawerOpacity', '0');
    setTimeout(() => {
      root.style.setProperty('--mobileDrawerVisibility', 'hidden');
    }, 100);
  };

  const [menuOpen, setMenuOpen] = React.useState<boolean>(false);

  const {data} = useLazyLoadQuery<MobileDrawerProfileQueryType>(
    MobileDrawerProfileQuery,
    {
      id: identityId || '',
    },
    {
      skip: !identityId || identityId === '',
    }
  );

  const myProfile = data?.identitiesCollection?.edges?.[0]?.node;

  return (
    <div className={styles.mobileDrawer} onClick={closeDrawer}>
      <div
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {user.isLoggedIn && myProfile ? (
          <div className={styles.drawerHeader}>
            <div className={styles.headerInfo}>
              <div
                onClick={() => {
                  router.push(
                    `/${
                      myProfile.profilesCollection?.edges?.[0]?.node
                        ?.username || myProfile?.id
                    }`
                  );
                  closeDrawer();
                }}
              >
                <Avatar
                  src={getImageUrl(
                    myProfile?.profilesCollection?.edges?.[0].node.avatar_uri ||
                      ''
                  )}
                />
                <div>
                  {trim(
                    myProfile?.profilesCollection?.edges?.[0].node.username ||
                      myProfile?.id ||
                      ''
                  )}
                </div>
              </div>
              <div>
                <div>
                  <div>Balance:</div>
                  <div>
                    {balance} {balance && <span>xtz</span>}
                  </div>
                </div>
                <div>
                  <div>
                    <span>{data?.followers?.totalCount || 0}</span> Followers
                  </div>
                  <div>
                    <span>{data?.following?.totalCount || 0}</span> Following
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        <div className={styles.menuItems}>
          <ul className={styles.links}>
            {drawerLinks.map(item => {
              const link =
                typeof item.url === 'string'
                  ? item.url
                  : item.url?.(
                      myProfile?.profilesCollection?.edges?.[0]?.node
                        ?.username ||
                        myProfile?.id ||
                        ''
                    ) || '';

              return item.value === 'profile' && !user.isLoggedIn ? (
                <div
                  className={styles.link}
                  key={item.value}
                  onClick={() => {
                    useLoginModalActions.setShowLoginModal(
                      true,
                      PROFILE_LOGIN_MESSAGE
                    );
                    closeDrawer();
                  }}
                  data-state={activeTab === item.value ? 'active' : undefined}
                >
                  {item.icon(activeTab === item.value)}
                  <div>{item.label}</div>
                </div>
              ) : (
                <Link
                  href={link}
                  key={item.value}
                  className={styles.link}
                  onClick={closeDrawer}
                  data-state={activeTab === item.value ? 'active' : undefined}
                >
                  {item.icon(activeTab === item.value)}
                  <div>{item.label}</div>
                </Link>
              );
            })}
          </ul>
          {!loggedIn ? (
            <Button
              filled
              accent
              onClick={() => {
                closeDrawer();
                useLoginModalActions.setShowLoginModal(true);
              }}
              className={styles.signInButton}
              key={'sign-in'}
            >
              <span>Sign in</span>
            </Button>
          ) : (
            <CreateDialog
              trigger={
                <Button
                  tooltip={'Create'}
                  tooltipSide="right"
                  tooltipOffset={42}
                  gradient
                  onClick={() => {
                    closeDrawer();
                    router.push(
                      {pathname: router.pathname, query: {...router.query}},
                      router.asPath + '#',
                      {
                        shallow: true,
                      }
                    );
                  }}
                  className={styles.createButton}
                  key={'create'}
                >
                  <RemixIcon icon="add-circle-fill" size={18} />
                  <span>Create</span>
                </Button>
              }
            />
          )}
        </div>
        <Menu
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          options={options}
          offset={24}
          arrow={false}
          className={styles.moreMenuDrawer}
          key="more"
          asModal
          onOpen={() => {
            address && TezBalanceActions.updateBalance(address);
          }}
        >
          <div className={clsx(styles.link, styles.moreLink)} key="more">
            <MemoIcon Component={'menu-line'} size={22} />
            <div>More</div>
          </div>
        </Menu>
      </div>
    </div>
  );
}

export const MobileDrawerLoader = () => {
  return (
    <div
      className={styles.mobileDrawer}
      style={{
        background: 'none',
      }}
    >
      <Skeleton variant="rect" borderRadius={0} animation="wave" />
    </div>
  );
};
