import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation updatePostMutation(
    $input: tezos_tokensUpdateInput!
    $filter: tezos_tokensFilter!
  ) {
    updatetezos_tokensCollection(set: $input, filter: $filter) {
      records {
        nodeId
        id
        created_at
        updated_at
        token_id
        contract_address
        metadata_uri
        minter_address
        artist_address
        symbol
        editions
        editions_burned
        minted_at
        title
        description
        artifact_uri
        display_uri
        thumbnail_uri
        contract_address
        token_id
        mime_type
        attributes
        price
        minting_platform
        editions_minted
        editions_burned
        allow_comments
        allow_collection
        account_id
        is_purchasable
        price_denomination
        purchase_price
      }
    }
  }
`;
