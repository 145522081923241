import React, {useEffect} from 'react';
import styles from '@/view/styles/components/AddToCollection/AddToCollection.module.scss';
import clsx from 'clsx';
import Button from '../Button';
import CreateCollectionDialog from '../CMDk/CreateCollection/CreateCollectionDialog';
import RemixIcon from '../RemixIcon';
import {
  graphql,
  useLazyLoadQuery,
  useMutation,
  usePagination,
} from '@/kits/relay-kit/src';
import {useAccount} from '@/kits/account-kit/src';
import insertPlaylistTokenMutation from '@/graphql/insert-playlist-token';
import type {insertPlaylistTokenMutation as InsertPlaylistTokenMutationType} from '@/graphql/__generated__/insertPlaylistTokenMutation.graphql';
import removePlaylistTokenMutation from '@/graphql/remove-playlist-token';
import type {removePlaylistTokenMutation as RemovePlaylistTokenMutationType} from '@/graphql/__generated__/removePlaylistTokenMutation.graphql';
import Router from 'next/router';
import {ToastActions} from '@/state/hooks/toasts';
import {v4 as uuid} from 'uuid';
import Input from '../Input/Input';
import type {AddToCollectionPlaylistsQuery as AddToCollectionPlaylistsQueryType} from './__generated__/AddToCollectionPlaylistsQuery.graphql';
import type {AddToCollectionCollabPlaylistsQuery as AddToCollectionCollabPlaylistsQueryType} from './__generated__/AddToCollectionCollabPlaylistsQuery.graphql';
import {AddToCollectionPlaylistsPaginated$key} from './__generated__/AddToCollectionPlaylistsPaginated.graphql';
import {AddToCollectionCollabPlaylistsPaginated$key} from './__generated__/AddToCollectionCollabPlaylistsPaginated.graphql';
import {AddToCollectionPlaylistsPaginatedQuery} from './__generated__/AddToCollectionPlaylistsPaginatedQuery.graphql';
import {AddToCollectionCollabPlaylistsPaginatedQuery} from './__generated__/AddToCollectionCollabPlaylistsPaginatedQuery.graphql';

const AddToCollectionPlaylistsQuery = graphql`
  query AddToCollectionPlaylistsQuery(
    $identityIds: [BigInt!]
    $tokenId: BigInt
    $loggedIn: Boolean!
  ) {
    ...AddToCollectionPlaylistsPaginated
      @arguments(
        identityIds: $identityIds
        tokenId: $tokenId
        loggedIn: $loggedIn
      )
  }
`;

const AddToCollectionCollabPlaylistsQuery = graphql`
  query AddToCollectionCollabPlaylistsQuery(
    $accountIds: [BigInt!]
    $tokenId: BigInt
    $loggedIn: Boolean!
  ) {
    ...AddToCollectionCollabPlaylistsPaginated
      @arguments(
        accountIds: $accountIds
        tokenId: $tokenId
        loggedIn: $loggedIn
      )
  }
`;

export default function AddToCollection({
  className,
  asModal,
  onClose,
  tokenId,
  tokenType,
}: {
  className?: string;
  asModal?: boolean;
  onClose?: (isPopState: boolean) => void;
  tokenId: string;
  tokenType: string;
}) {
  const {getAccount} = useAccount();

  const user = getAccount();
  const identityId = user?.isLoggedIn ? user.identityId : undefined;
  const accountId = user?.isLoggedIn ? user.accountId : undefined;

  const {
    data: playlists,
    isLoading: loadingPlaylists,
    retry,
  } = useLazyLoadQuery<AddToCollectionPlaylistsQueryType>(
    AddToCollectionPlaylistsQuery,
    {
      identityIds: [identityId || ''],
      tokenId: tokenId,
      loggedIn: user.isLoggedIn,
    },
    {
      skip: !identityId || identityId === '',
    }
  );

  const {
    data: collabPlaylists,
    isLoading: loadingCollabPlaylists,
    retry: retryCollabPlaylists,
  } = useLazyLoadQuery<AddToCollectionCollabPlaylistsQueryType>(
    AddToCollectionCollabPlaylistsQuery,
    {
      accountIds: [accountId || ''],
      tokenId: tokenId,
      loggedIn: user.isLoggedIn,
    },
    {
      skip: !accountId || accountId === '',
    }
  );

  React.useEffect(() => {
    const handlePopState = () => {
      onClose?.(true);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [onClose]);

  const [createOpen, setCreateOpen] = React.useState(false);

  const [search, setSearch] = React.useState('');

  return (
    <>
      <CreateCollectionDialog
        trigger={<></>}
        defaultOpen={createOpen}
        onClose={created => {
          setCreateOpen(false);
          if (created) {
            retry();
            retryCollabPlaylists();
          }
        }}
        tokenId={tokenId}
        asModal
      />
      <div className={clsx(styles.root, className)}>
        <div className={styles.sticky}>
          <div className={styles.header}>
            <span>Save {tokenType} to...</span>
            <Button
              accent
              text
              onClick={() => {
                setCreateOpen(true);
              }}
            >
              <RemixIcon icon="add-line" size={24} />
              <span>New collection</span>
            </Button>
          </div>
          <div className={styles.search}>
            <Input
              type="text"
              defaultValue={search}
              onChange={v => setSearch(v)}
              simple
              fullWidth
              placeholder="Search playlists..."
            />
          </div>
        </div>
        <List
          playlists={playlists}
          collabPlaylists={collabPlaylists}
          tokenId={tokenId}
          retry={() => {
            retry();
            retryCollabPlaylists();
          }}
          search={search}
        />
      </div>
    </>
  );
}

const AddToCollectionPlaylistsPaginated = graphql`
  fragment AddToCollectionPlaylistsPaginated on Query
  @argumentDefinitions(
    identityIds: {type: "[BigInt!]"}
    tokenId: {type: "BigInt"}
    loggedIn: {type: "Boolean!"}
    first: {type: "Int", defaultValue: 30}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "AddToCollectionPlaylistsPaginatedQuery") {
    playlistsCollection(
      filter: {identity_id: {in: $identityIds}}
      first: $first
      after: $after
    )
      @connection(
        key: "AddToCollectionPlaylistsPaginated_playlistsCollection"
      ) {
      edges {
        node {
          nodeId
          id
          title
          image_uri
          visibility
          playlists_tokensCollection {
            totalCount
          }
          exists: playlists_tokensCollection(
            filter: {tezos_token_id: {eq: $tokenId}}
          ) @include(if: $loggedIn) {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;

const AddToCollectionCollabPlaylistsPaginated = graphql`
  fragment AddToCollectionCollabPlaylistsPaginated on Query
  @argumentDefinitions(
    accountIds: {type: "[BigInt!]"}
    tokenId: {type: "BigInt"}
    loggedIn: {type: "Boolean!"}
    first: {type: "Int", defaultValue: 30}
    after: {type: "Cursor"}
  )
  @refetchable(queryName: "AddToCollectionCollabPlaylistsPaginatedQuery") {
    playlists_accountsCollection(
      filter: {account_id: {in: $accountIds}}
      first: $first
      after: $after
    )
      @connection(
        key: "AddToCollectionCollabPlaylistsPaginated_playlists_accountsCollection"
      ) {
      edges {
        node {
          nodeId
          id
          playlists {
            nodeId
            id
            title
            image_uri
            visibility
            playlists_tokensCollection {
              totalCount
            }
            exists: playlists_tokensCollection(
              filter: {tezos_token_id: {eq: $tokenId}}
            ) @include(if: $loggedIn) {
              edges {
                node {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

const List = ({
  playlists,
  collabPlaylists,
  tokenId,
  retry,
  search,
}: {
  playlists: AddToCollectionPlaylistsPaginated$key | null | undefined;
  collabPlaylists:
    | AddToCollectionCollabPlaylistsPaginated$key
    | null
    | undefined;
  tokenId: string;
  retry: () => void;
  search: string;
}) => {
  const {
    data: paginatedPlaylists,
    hasNext,
    loadNext,
    isLoadingNext,
    isLoading,
  } = usePagination<
    AddToCollectionPlaylistsPaginatedQuery,
    AddToCollectionPlaylistsPaginated$key
  >(AddToCollectionPlaylistsPaginated, playlists);
  const {
    data: paginatedCollabPlaylists,
    hasNext: collabHasNext,
    loadNext: collabLoadNext,
    isLoadingNext: collabIsLoadingNext,
    isLoading: collabIsLoading,
  } = usePagination<
    AddToCollectionCollabPlaylistsPaginatedQuery,
    AddToCollectionCollabPlaylistsPaginated$key
  >(AddToCollectionCollabPlaylistsPaginated, collabPlaylists);

  useEffect(() => {
    if (hasNext && !isLoadingNext && !isLoading) {
      loadNext(30);
    }
  }, [hasNext, isLoadingNext, isLoading, loadNext]);
  useEffect(() => {
    if (collabHasNext && !collabIsLoadingNext && !collabIsLoading) {
      collabLoadNext(30);
    }
  }, [collabHasNext, collabIsLoadingNext, collabIsLoading, collabLoadNext]);

  const [addToPlaylist] = useMutation<InsertPlaylistTokenMutationType>(
    insertPlaylistTokenMutation
  );

  const [removeFromPlaylist] = useMutation<RemovePlaylistTokenMutationType>(
    removePlaylistTokenMutation
  );

  const lists: {
    id: string;
    nodeId: string;
    title: string;
    visibility: string;
    exists: boolean;
  }[] = React.useMemo(() => {
    return [
      ...(paginatedPlaylists?.playlistsCollection?.edges || []).map(
        playlist => ({
          id: playlist.node.id,
          nodeId: playlist.node.nodeId,
          title: playlist.node.title,
          visibility: playlist.node.visibility,
          exists: playlist.node.exists?.edges?.length !== 0,
        })
      ),
      ...(
        paginatedCollabPlaylists?.playlists_accountsCollection?.edges || []
      ).map(account => ({
        id: account.node.playlists.id,
        nodeId: account.node.playlists.nodeId,
        title: account.node.playlists.title,
        visibility: account.node.playlists.visibility,
        exists: account.node.playlists.exists?.edges?.length !== 0,
      })),
    ];
  }, [paginatedPlaylists, paginatedCollabPlaylists]);

  return (
    <>
      {lists
        ?.sort((a, b) => {
          if (a.exists && !b.exists) return -1;
          if (!a.exists && b.exists) return 1;
          return 0;
        })
        ?.filter(
          playlist =>
            playlist.title.toLowerCase().includes(search.toLowerCase()) ||
            search === ''
        )
        ?.map((playlist, index) => {
          return (
            <ListItem
              key={playlist.id}
              playlistId={playlist.id}
              playlistNodeId={playlist.nodeId}
              playlistTitle={playlist.title}
              playlistVisibility={playlist.visibility}
              exists={playlist.exists}
              remove={() => {
                removeFromPlaylist({
                  variables: {
                    filter: {
                      playlist_id: {
                        eq: playlist.id,
                      },
                      tezos_token_id: {
                        eq: tokenId,
                      },
                    },
                  },
                  updater: store => {
                    const affectedMutation = store.getRootField(
                      'deleteFromplaylists_tokensCollection'
                    );
                    const newAddedRecords =
                      affectedMutation?.getLinkedRecords('records');
                    const newAddedRecord = newAddedRecords?.[0];
                    const tokenNodeId = newAddedRecord
                      .getLinkedRecord('tezos_tokens')
                      ?.getValue('nodeId');
                    const updatedSavedCount = newAddedRecord
                      .getLinkedRecord('tezos_tokens')
                      ?.getLinkedRecord('playlists_tokensCollection')
                      ?.getValue('totalCount');
                    store
                      .get(tokenNodeId)
                      ?.getLinkedRecord('playlists_tokensCollection')
                      ?.setValue(updatedSavedCount - 1 || 0, 'totalCount');
                  },
                }).then(() => {
                  ToastActions.addToast(
                    uuid(),
                    `Removed from collection`,
                    '',
                    'success',
                    undefined,
                    undefined,
                    undefined,
                    'checkbox-circle-line'
                  );
                  retry();
                });
              }}
              add={() => {
                addToPlaylist({
                  variables: {
                    input: [
                      {
                        playlist_id: playlist.id,
                        tezos_token_id: tokenId,
                        created_at: 'now',
                        updated_at: 'now',
                      },
                    ],
                  },
                  updater: store => {
                    const affectedMutation = store.getRootField(
                      'insertIntoplaylists_tokensCollection'
                    );
                    const newAddedRecords =
                      affectedMutation?.getLinkedRecords('records');
                    const newAddedRecord = newAddedRecords?.[0];
                    const tokenNodeId = newAddedRecord
                      .getLinkedRecord('tezos_tokens')
                      ?.getValue('nodeId');
                    const updatedSavedCount = newAddedRecord
                      .getLinkedRecord('tezos_tokens')
                      ?.getLinkedRecord('playlists_tokensCollection')
                      ?.getValue('totalCount');
                    store
                      .get(tokenNodeId)
                      ?.getLinkedRecord('playlists_tokensCollection')
                      ?.setValue(updatedSavedCount + 1 || 0, 'totalCount');
                  },
                }).then(res => {
                  const playlistTitle =
                    res.insertIntoplaylists_tokensCollection?.records?.[0]
                      ?.playlists.title;
                  const playlistId =
                    res.insertIntoplaylists_tokensCollection?.records?.[0]
                      ?.playlist_id;
                  ToastActions.addToast(
                    uuid(),
                    `Added to ${playlistTitle}`,
                    '',
                    'success',
                    () => {
                      Router.push(`/collection/${playlistId}`);
                    },
                    'View',
                    undefined,
                    'checkbox-circle-line'
                  );
                  retry();
                });
              }}
            />
          );
        })}
    </>
  );
};

const ListItem = ({
  playlistId,
  playlistNodeId,
  playlistTitle,
  playlistVisibility,
  exists,
  add,
  remove,
}: {
  playlistId: string;
  playlistNodeId: string;
  playlistTitle: string;
  playlistVisibility: string;
  exists: boolean;
  add: () => void;
  remove: () => void;
}) => {
  return (
    <div
      key={playlistId}
      className={styles.playlistItem}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (exists) {
          remove();
        } else {
          add();
        }
      }}
    >
      <div>
        <div>
          <div className={styles.check} data-checked={exists}>
            {exists ? <RemixIcon icon="check-line" size={20} /> : null}
          </div>
          <span>{playlistTitle}</span>
        </div>
        <RemixIcon
          icon={
            playlistVisibility === 'private'
              ? 'lock-2-fill'
              : playlistVisibility === 'unlisted'
              ? 'link'
              : 'earth-fill'
          }
          size={20}
        />
      </div>
    </div>
  );
};
