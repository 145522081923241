import {commentsFragment$data} from '@/graphql/__generated__/commentsFragment.graphql';
import {create} from 'zustand';

export type NftActions = {
  type: 'token';
  id: string;
  nodeId: string;
  page?: number;
  image?: string;
  description?: string;
  title?: string;
  tokenType?: string;
  artifactUri?: string;
  metadataUri?: string;

  creator?: {
    id?: string;
    username?: string;
  };
  shareCount: number;
  editions?: string;
  editionsMinted?: string;
};

export type UserActions = {
  type: 'user';
  page?: number;
  image?: string;
  description?: string;
  title?: string;

  user?: {
    id?: string;
    nodeId?: string;
    username?: string;
    address?: string;
  };
};

export type ListActions = {
  type: 'list';
  list: string;
  page?: number;
  image?: string;
  description?: string;
  title?: string;
};

export type TrackActions = {
  type: 'track';
  id: string;
  nodeId: string;
  page?: number;
  image?: string;
  description?: string;
  title?: string;
  tokenType?: string;
  artifactUri?: string;
  metadataUri?: string;

  creator?: {
    id?: string;
    username?: string;
  };
  shareCount: number;
  editions?: string;
  editionsMinted?: string;
};

export type CommentActions = {
  type: 'comment';
  comment: commentsFragment$data;
  page?: number;
  description?: string;
  title?: string;
};

export type ActionsView =
  | NftActions
  | UserActions
  | ListActions
  | TrackActions
  | CommentActions;

type ActionsMenuState = {
  open: boolean;
  actionsView: ActionsView | undefined;
};

type ActionsMenuFunctions = {
  openActionsMenu: (actionsView: ActionsView) => void;
  closeActionsMenu: () => void;
  setPage: (page: number) => void;
};

export const useActionsMenu = create<ActionsMenuState>((get, set) => {
  const initialState: ActionsMenuState = {
    open: false,
    actionsView: undefined,
  };

  return {
    ...initialState,
  };
});

export const ActionsMenuActions: ActionsMenuFunctions = {
  openActionsMenu(actionsView) {
    useActionsMenu.setState({open: true, actionsView});
  },
  closeActionsMenu() {
    useActionsMenu.setState({open: false, actionsView: undefined});
  },
  setPage(page) {
    const {actionsView} = useActionsMenu.getState();
    if (actionsView) {
      useActionsMenu.setState({
        open: true,
        actionsView: {...actionsView, page: 0},
      });
    }
  },
};
