import {
  isValidTezosAddress,
  isValidTezosContractAddress,
} from '@/state/clients/tezos';

export function trim(text: string, padding?: number): string {
  return isValidTezosAddress(text) || isValidTezosContractAddress(text)
    ? `${text.slice(0, padding ? padding : 4)}...${text.slice(
        padding ? -padding : -4
      )}`
    : text;
}
