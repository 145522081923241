// use breakpoint hook to get the current breakpoint

import {useEffect, useMemo, useState} from 'react';

export const useBreakpoint = () => {
  const {width} = useWindowSize();
  const breakpoint = useMemo(() => {
    if (width < 500) {
      return 'mobile';
    } else if (width < 1152) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  }, [width]);

  const widerThan = (w: number) => {
    return width > w;
  };

  return {
    breakpoint,
    isMobile: breakpoint === 'mobile' || breakpoint === 'tablet' ? true : false,
    widerThan,
  };
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const deviceWidth = () => {
  if (typeof window === 'undefined') return false;
  const windowWidth = window.innerWidth;

  if (windowWidth < 500) {
    return 'mobile';
  } else if (windowWidth < 1152) {
    return 'tablet';
  } else {
    return 'desktop';
  }
};
