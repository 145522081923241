import React from 'react';
import styles from '@/view/styles/components/NewPostCard/blocs/PlaylistsSelector.module.scss';
import {useAccount} from '@/kits/account-kit/src';

import {graphql, useLazyLoadQuery} from '@/kits/relay-kit/src';
import Button from '../../Button';
import RemixIcon from '../../RemixIcon';
import {Avatar} from '../../Avatar';
import {getImageUrl} from '@/utils/conversions/conversions';
import clsx from 'clsx';
import CreateCollectionDialog from '../../CMDk/CreateCollection/CreateCollectionDialog';
import type {PlaylistsSelectorPlaylistsQuery as PlaylistsSelectorPlaylistsQueryType} from './__generated__/PlaylistsSelectorPlaylistsQuery.graphql';

const PlaylistsSelectorPlaylistsQuery = graphql`
  query PlaylistsSelectorPlaylistsQuery(
    $identityId: BigInt!
    $accountId: BigInt!
  ) {
    created: playlistsCollection(
      filter: {identity_id: {eq: $identityId}}
      first: 5
      orderBy: {updated_at: DescNullsLast}
    ) {
      edges {
        node {
          id
          nodeId
          title
          image_uri
        }
      }
    }
    collabs: playlists_accountsCollection(
      filter: {account_id: {eq: $accountId}}
      first: 5
      orderBy: {updated_at: DescNullsLast}
    ) {
      edges {
        node {
          id
          nodeId
          playlists {
            id
            nodeId
            title
            image_uri
          }
        }
      }
    }
  }
`;

export default function PlaylistsSelector({
  onSelect,
  selected,
}: {
  onSelect: (playlistId: string, playlistTitle: string) => void;
  selected: string;
}) {
  const {getAccount} = useAccount();
  const user = getAccount();

  const identityId = user.isLoggedIn ? user.identityId : undefined;
  const accountId = user.isLoggedIn ? user.accountId : undefined;

  const {
    data: playlists,
    isLoading: loadingPlaylists,
    retry,
  } = useLazyLoadQuery<PlaylistsSelectorPlaylistsQueryType>(
    PlaylistsSelectorPlaylistsQuery,
    {
      identityId: identityId || '',
      accountId: accountId || '',
    },
    {
      skip: !identityId || identityId === '' || !accountId || accountId === '',
    }
  );

  const [createCollectionDialogOpen, setCreateCollectionDialogOpen] =
    React.useState<boolean>(false);

  return (
    <>
      <div className={styles.root}>
        <Button
          outlined
          accent
          icon
          onClick={() => setCreateCollectionDialogOpen(true)}
        >
          <RemixIcon icon="add-fill" size={32} />
        </Button>
        {playlists?.created?.edges?.map(edge => {
          return (
            <div
              key={edge.node.id}
              className={styles.playlist}
              data-selected={selected === edge.node.id}
              onClick={() => onSelect(edge.node.id, edge.node.title)}
              title={edge.node.title}
            >
              {!!edge.node.image_uri ? (
                <Avatar
                  src={getImageUrl(edge.node.image_uri)}
                  alt={edge.node.title}
                  className={styles.avatar}
                />
              ) : (
                <span className={clsx(styles.avatar, styles.fallback)}>
                  <span>{edge.node.title[0].toUpperCase()}</span>
                </span>
              )}
            </div>
          );
        })}
        {playlists?.collabs?.edges?.map(edge => {
          return (
            <div
              key={edge.node.playlists.id}
              className={styles.playlist}
              data-selected={selected === edge.node.playlists.id}
              onClick={() =>
                onSelect(edge.node.playlists.id, edge.node.playlists.title)
              }
              title={edge.node.playlists.title}
            >
              {!!edge.node.playlists.image_uri ? (
                <Avatar
                  src={getImageUrl(edge.node.playlists.image_uri)}
                  alt={edge.node.playlists.title}
                  className={styles.avatar}
                />
              ) : (
                <span className={clsx(styles.avatar, styles.fallback)}>
                  <span>{edge.node.playlists.title[0].toUpperCase()}</span>
                </span>
              )}
            </div>
          );
        })}
      </div>
      <CreateCollectionDialog
        trigger={<></>}
        defaultOpen={createCollectionDialogOpen}
        onClose={created => {
          setCreateCollectionDialogOpen(false);
          if (created) {
            retry();
          }
        }}
      />
    </>
  );
}
