import React, {PropsWithChildren} from 'react';
import * as MenuPrimitive from '@radix-ui/react-dropdown-menu';
import styles from '@/view/styles/components/Menu.module.scss';
import Link from 'next/link';
import clsx from 'clsx';
import Tooltip from './Tooltip';
import {MenuOptionType} from '../types/types';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';

const Menu = ({
  options,
  element,
  side,
  open,
  onClose,
  onOpen,
  children,
  className,
  arrow = true,
  offset,
  asModal = false,
  disabled,
  removePadding = false,
}: PropsWithChildren<{
  options?: MenuOptionType[] | undefined;
  element?: (close?: () => void) => React.ReactNode;
  side?: 'left' | 'right' | 'top' | 'bottom';
  open?: boolean | undefined;
  onClose?: (() => void) | undefined;
  onOpen?: (() => void) | undefined;
  className?: string | undefined;
  arrow?: boolean;
  offset?: number;
  asModal?: boolean;
  disabled?: boolean;
  removePadding?: boolean;
}>) => {
  const [isOpen, setIsOpen] = React.useState(() => open);

  React.useEffect(() => {
    setIsOpen(open);
  }, [open]);
  const {isMobile} = useBreakpoint();

  return (
    <MenuPrimitive.Root
      open={isOpen}
      onOpenChange={open => {
        setIsOpen(open);
        if (!open) {
          onClose?.();
        } else {
          onOpen?.();
        }
      }}
      modal={asModal}
    >
      <MenuPrimitive.Trigger
        asChild
        onPointerUp={event => {
          if (event.pointerType === 'touch' || event.pointerType === 'pen') {
            setIsOpen(true);
          }
        }}
        onPointerDown={event => {
          if (event.pointerType === 'touch' || event.pointerType === 'pen') {
            event.preventDefault();
          }
        }}
        disabled={disabled}
      >
        {children}
      </MenuPrimitive.Trigger>
      <MenuPrimitive.Portal>
        <MenuPrimitive.Content
          className={clsx(
            styles.actionsMenu,
            removePadding && styles.noVerticalPadding,
            className
          )}
          sideOffset={offset || 8}
          side={side}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {element
            ? element(() => {
                setIsOpen(false);
                onClose?.();
              })
            : options
            ? options?.map(option => {
                const isCritical =
                  option.value === 'logout' ||
                  option.value === 'remove' ||
                  option.value === 'delete' ||
                  option.value === 'remove' ||
                  option.value === 'block' ||
                  option.value === 'report';
                return (
                  <MenuPrimitive.Item
                    className={styles.actionsMenuItem}
                    key={option.value}
                    onClick={e => {
                      option.onClick?.(e);
                    }}
                    data-has-function={!!option.onClick}
                    data-is-critical={isCritical}
                    data-arrow={arrow}
                    data-disabled={option.disabled}
                    data-no-highlight={option.noHighlight}
                  >
                    {option.href ? (
                      <Link
                        href={option.href}
                        target={
                          option.href.startsWith('http') ? '_blank' : undefined
                        }
                        rel={
                          option.href.startsWith('http')
                            ? 'noopener noreferrer'
                            : undefined
                        }
                        onClick={e => {
                          if (option.disabled) {
                            return;
                          }
                          e.stopPropagation();
                          setIsOpen(false);
                          onClose?.();
                        }}
                      >
                        {option.icon}
                        <span>{option.label}</span>
                      </Link>
                    ) : (
                      <div
                        onClick={e => {
                          if (option.disabled) {
                            e.stopPropagation();
                            e.preventDefault();
                            return;
                          }
                          setIsOpen(false);
                          onClose?.();
                        }}
                      >
                        <Tooltip
                          text={
                            option.disabled && option.disabledTooltip
                              ? option.disabledTooltip
                              : option.tooltip
                          }
                          hidden={
                            !option.disabled &&
                            !option.disabledTooltip &&
                            !option.tooltip
                          }
                        >
                          {option.icon}
                          <span>{option.label}</span>
                        </Tooltip>
                      </div>
                    )}
                  </MenuPrimitive.Item>
                );
              })
            : null}
          {arrow && <MenuPrimitive.Arrow className={styles.arrow} />}
        </MenuPrimitive.Content>
      </MenuPrimitive.Portal>
    </MenuPrimitive.Root>
  );
};

export default Menu;
