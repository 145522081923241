import React, {Suspense} from 'react';
import styles from '@/view/styles/components/NewPostCard/NewPostCard.module.scss';
import Image from '@/view/components/Image';
import NewPostCardDetails, {
  NewPostCardHeader,
} from '@/view/components/NewPostCard/NewPostCardDetails';
import Button from '../Button';
import clsx from 'clsx';
import NewPostCardActions from './NewPostCardActions';
import {ToastActions} from '@/state/hooks/toasts';
import {v4 as uuid} from 'uuid';
import RemixIcon from '../RemixIcon';
import {CreateView} from '@/state/hooks/createStatus';
import {ItemDataType} from '@/view/types/types';
import {
  ACCEPTED_ARTWORK_FILE_TYPES,
  ACCEPTED_AUDIO_FILE_TYPES,
  ACCEPTED_FILE_TYPES,
  ACCEPTED_VIDEO_FILE_TYPES,
} from '@/view/types/mintTypes';
import QuoteCard from '../FeedCard/QuoteCard';
import PlaylistsSelector from './blocs/PlaylistsSelector';
import {FrozenGif} from '../FrozenGif';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import {NewPostCardProfileFragment$key} from './__generated__/NewPostCardProfileFragment.graphql';

const MAX_FILE_SIZE = 200000000; // 200MB

const NewPostCardProfileFragment = graphql`
  fragment NewPostCardProfileFragment on identities {
    ...NewPostCardDetailsHeaderFragment
  }
`;

export default function NewPostCard({
  identityKey,
  view,
  itemData,
  setItemData,
  processNFT,
  uploading,
  disabled,
  onClose,
  quoteItem,
  setQuoteItem,
  setQuotedItemRepostCount,
  selectedCollection,
  selectedCollectionTitle,
  setSelectedCollection,
}: {
  identityKey: NewPostCardProfileFragment$key | undefined | null;
  view: CreateView;
  itemData: ItemDataType;
  setItemData: React.Dispatch<React.SetStateAction<ItemDataType>>;
  processNFT: () => void;
  uploading: string | undefined;
  disabled: boolean;
  onClose?: (isPopState: boolean) => void;
  quoteItem?: string | null;
  setQuoteItem?: (quoteItem: string | null) => void;
  setQuotedItemRepostCount?: (repostCount: number) => void;
  selectedCollection: string;
  selectedCollectionTitle: string | null;
  setSelectedCollection: (collection: string, title: string) => void;
}) {
  const identity = useFragment(NewPostCardProfileFragment, identityKey);
  const actionsContainerRef = React.useRef<HTMLDivElement>(null);
  const fileUploadRef = React.useRef<HTMLInputElement>(null);

  const audioThumbInput = React.useRef<HTMLInputElement>(null);
  const audioElementRef = React.useRef<HTMLAudioElement>(null);

  const [audioPlaying, setAudioPlaying] = React.useState(false);

  React.useEffect(() => {
    if (audioElementRef.current) {
      audioElementRef.current.onplay = () => {
        setAudioPlaying(true);
      };
      audioElementRef.current.onpause = () => {
        setAudioPlaying(false);
      };
    }
  }, []);

  const getAcceptedFileTypes = (type: CreateView) => {
    switch (type) {
      case 'image':
        return ACCEPTED_ARTWORK_FILE_TYPES;
      case 'video':
        return ACCEPTED_VIDEO_FILE_TYPES;
      case 'audio':
        return ACCEPTED_AUDIO_FILE_TYPES;
      case 'post':
        return ACCEPTED_FILE_TYPES;
      default:
        return [];
    }
  };

  const formatTime = (seconds: number) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.round(seconds % 60);
    return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s]
      .filter(a => a)
      .join(':');
  };

  const [showImageUploader, setShowImageUploader] = React.useState(false);

  const [itemDataFileURL, setItemDataFileURL] = React.useState<string>();
  const [itemDataCoverURL, setItemDataCoverURL] = React.useState<string>();
  React.useEffect(() => {
    const handlePaste = async (e: ClipboardEvent) => {
      if (e.target instanceof HTMLInputElement) return;

      if (e.clipboardData?.files?.length) {
        const file = e.clipboardData.files[0];
        const fileType = file.type;
        if (ACCEPTED_FILE_TYPES.indexOf(fileType) === -1) {
          ToastActions.addToast(
            uuid(),
            'Invalid file type!',
            'We currently do not support this file type.',
            'failure'
          );
          return;
        }
        if (file.size > MAX_FILE_SIZE) {
          ToastActions.addToast(
            uuid(),
            'File too large!',
            'The maximum file size is 200MB.',
            'failure'
          );
          return;
        }
        switch (view) {
          case 'image':
            if (ACCEPTED_ARTWORK_FILE_TYPES.indexOf(fileType) === -1) {
              ToastActions.addToast(
                uuid(),
                'Invalid file type!',
                'We currently do not support this file type.',
                'failure'
              );
              return;
            }
            setItemData(d => ({...d, file}));
            setItemDataFileURL(URL.createObjectURL(file));
            break;
          case 'video':
            if (ACCEPTED_VIDEO_FILE_TYPES.indexOf(fileType) === -1) {
              ToastActions.addToast(
                uuid(),
                'Invalid file type!',
                'We currently do not support this file type.',
                'failure'
              );
              return;
            }
            setItemData(d => ({...d, file}));
            setItemDataFileURL(URL.createObjectURL(file));
            break;
          case 'audio':
            if (fileType.startsWith('image')) {
              setItemData(d => ({...d, cover: file}));
              setItemDataCoverURL(URL.createObjectURL(file));
            } else if (ACCEPTED_AUDIO_FILE_TYPES.indexOf(fileType) === -1) {
              ToastActions.addToast(
                uuid(),
                'Invalid file type!',
                'We currently do not support this file type.',
                'failure'
              );
              return;
            } else {
              setItemData(d => ({...d, file}));
              setItemDataFileURL(URL.createObjectURL(file));
            }
            break;
          case 'post':
            if (ACCEPTED_FILE_TYPES.indexOf(fileType) === -1) {
              ToastActions.addToast(
                uuid(),
                'Invalid file type!',
                'We currently do not support this file type.',
                'failure'
              );
              return;
            }
            if (fileType.startsWith('audio')) {
              ToastActions.addToast(
                uuid(),
                'Unsupported file type!',
                'Audio files are not supported yet.',
                'failure'
              );
              return;
            }
            setItemData(d => ({...d, file}));
            setItemDataFileURL(URL.createObjectURL(file));
            break;
          default:
            break;
        }
      }
    };
    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [view, setItemData, setItemDataFileURL, itemData.file, itemDataCoverURL]);

  const audioCanvasRef = React.useRef<HTMLCanvasElement | null>(null);

  return (
    <div className={styles.card}>
      <Button
        icon
        className={styles.back}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onClose?.(false);
        }}
        tooltip="Close"
        tooltipSide="right"
        tooltipOffset={20}
      >
        <RemixIcon icon="close-line" size={24} />
      </Button>
      <div>
        {identity && (
          <Suspense fallback={<></>}>
            <NewPostCardHeader identityKey={identity} />
          </Suspense>
        )}
        <span className={styles.sectionTitle}>
          {view === 'audio' ? 'Album' : 'Add to collection'}{' '}
          <span>{selectedCollectionTitle}</span>
        </span>
        <Suspense fallback={<></>}>
          <PlaylistsSelector
            selected={selectedCollection}
            onSelect={setSelectedCollection}
          />
        </Suspense>
        <span className={styles.sectionTitle}>Info</span>
        {view === 'post' && (
          <Suspense>
            <NewPostCardDetails
              title={itemData.title}
              setTitle={t => setItemData(d => ({...d, title: t}))}
              description={itemData.description}
              setDescription={description =>
                setItemData(d => ({...d, description}))
              }
              view={view}
              quoteItem={quoteItem}
              setQuoteItem={setQuoteItem}
            />
          </Suspense>
        )}
        {!showImageUploader && (
          <div
            data-is-audio={view === 'audio' ? 'true' : 'false'}
            className={styles.audioFlexContainer}
          >
            {view === 'post' && !itemData.file ? (
              <div className={styles.uploadFileButton}>
                <Button
                  outlined
                  onClick={() => {
                    fileUploadRef.current?.click();
                  }}
                >
                  <RemixIcon icon="upload-2-fill" size={16} />
                  <span>Upload file</span>
                </Button>
                <span>or paste here. Max {MAX_FILE_SIZE / 1000000}MB</span>
              </div>
            ) : null}
            <div
              className={styles.imageContainer}
              data-hidden={view === 'post' && !itemData.file}
              data-is-audio={view === 'audio' ? 'true' : 'false'}
            >
              <div
                className={clsx(
                  styles.image,
                  !itemData.file && styles.noFile,
                  !itemData.file && itemDataCoverURL && styles.hasCoverAttached
                )}
                data-has-file={!!itemData.file ? 'true' : 'false'}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (!itemData.file) {
                    return;
                  }
                }}
              >
                {(view === 'post' && itemData.file?.type.startsWith('audio')) ||
                view === 'audio' ? (
                  <div className={styles.audioContainer}>
                    <FrozenGif
                      componentProps={{
                        imgProps: {
                          src: itemDataCoverURL || '/poster.svg',
                          alt: itemData.cover ? itemData.cover.name : '',
                          onError: e => {
                            e.currentTarget.src = '/poster.svg';
                          },
                          onLoad: async e => {
                            const img = e.currentTarget;
                            const w = img.naturalWidth;
                            const h = img.naturalHeight;
                            const canvas = audioCanvasRef.current;
                            if (canvas) {
                              canvas.width = w;
                              canvas.height = h;
                              const ctx = canvas.getContext('2d');
                              if (ctx) {
                                ctx.drawImage(img, 0, 0, w, h);
                              }
                            }
                          },
                          hidden: true,
                        },
                        containerProps: {
                          className: styles.audioPlayer,
                        },
                        canvasProps: {
                          ref: audioCanvasRef,
                          className: styles.canvasImage,
                        },
                      }}
                      src={itemDataCoverURL || '/poster.svg'}
                      fill
                    />
                    <audio
                      controls
                      src={itemDataFileURL || ''}
                      loop
                      muted
                      playsInline
                      ref={audioElementRef}
                      hidden
                      onLoadedData={e => {
                        const duration = e.currentTarget.duration;
                        const formattedDuration = formatTime(duration);
                        setItemData(d => ({...d, duration: formattedDuration}));
                      }}
                      crossOrigin="anonymous"
                    />
                    <div className={styles.audioThumbOverlay}>
                      <Button
                        icon
                        onClick={() => {
                          if (audioElementRef.current) {
                            if (audioPlaying) {
                              audioElementRef.current.pause();
                              setAudioPlaying(false);
                            } else {
                              audioElementRef.current.play();
                              audioElementRef.current.muted = false;
                              setAudioPlaying(true);
                            }
                          }
                        }}
                      >
                        <RemixIcon
                          icon={
                            audioElementRef.current?.paused
                              ? 'play-fill'
                              : 'pause-fill'
                          }
                          size={42}
                        />
                      </Button>
                    </div>
                  </div>
                ) : (view === 'post' &&
                    itemData.file?.type.startsWith('video')) ||
                  view === 'video' ? (
                  <div className={styles.videoContainer}>
                    <video
                      disablePictureInPicture
                      src={itemDataFileURL || ''}
                      autoPlay
                      loop
                      muted
                      poster="/poster.svg"
                      onLoadedData={e => {
                        const duration = e.currentTarget.duration;
                        const formattedDuration = formatTime(duration);
                        setItemData(d => ({...d, duration: formattedDuration}));
                      }}
                      controls
                      controlsList="nodownload noremoteplayback noplaybackrate"
                      crossOrigin="anonymous"
                    />
                  </div>
                ) : (view === 'post' &&
                    itemData.file?.type.startsWith('image')) ||
                  view === 'image' ? (
                  <Image
                    dynamic
                    src={itemDataFileURL || ''}
                    alt={''}
                    quality={10}
                  />
                ) : null}
              </div>
              <div
                className={styles.actionsContainer}
                data-has-file={itemData.file ? 'true' : 'false'}
                ref={actionsContainerRef}
              >
                <input
                  name="post-input"
                  type="file"
                  ref={fileUploadRef}
                  accept={getAcceptedFileTypes(view)?.join(',')}
                  // multiple={view === 'post' ? true : false} // TODO
                  onChange={e => {
                    if (view === 'post') {
                      if (!e.target.files) return;
                      if (e.target.files.length > 4) {
                        ToastActions.addToast(
                          uuid(),
                          'Too many files!',
                          'You can only upload up to 4 files on a post.',
                          'failure'
                        );
                        return;
                      }
                      const fileTypes = [...(e.target.files || [])]?.map(
                        f => f.type
                      );
                      if (fileTypes?.find(type => type.startsWith('audio'))) {
                        ToastActions.addToast(
                          uuid(),
                          'Unsupported file type!',
                          'Audio files are not supported yet.',
                          'failure'
                        );
                        return;
                      }
                      if (
                        fileTypes?.every(type => type.startsWith('video')) &&
                        e.target.files?.length > 1
                      ) {
                        ToastActions.addToast(
                          uuid(),
                          'Oops!',
                          'You can only upload one video at a time.',
                          'failure'
                        );
                        return;
                      }
                      if (
                        e.target.files?.length > 1 &&
                        fileTypes?.every(type => type.startsWith('image')) ===
                          false
                      ) {
                        ToastActions.addToast(
                          uuid(),
                          'Uh oh!',
                          'Mixed file types are not supported yet.',
                          'failure'
                        );
                        return;
                      }
                      if (e.target.files?.[0]) {
                        const file = e.target.files?.[0];
                        if (file.size > MAX_FILE_SIZE) {
                          ToastActions.addToast(
                            uuid(),
                            'File too large!',
                            'The maximum file size is 200MB.',
                            'failure'
                          );
                          return;
                        }
                        if (
                          file.type.startsWith('image') ||
                          file.type.startsWith('video')
                        ) {
                          setItemData(d => ({...d, file}));
                          setItemDataFileURL(URL.createObjectURL(file));
                        } else {
                          setItemData(d => ({...d, file: undefined}));
                          return;
                        }
                      }
                      if (
                        [...(e.target.files || [])].find(
                          f => f.size > MAX_FILE_SIZE
                        )
                      ) {
                        ToastActions.addToast(
                          uuid(),
                          'File too large!',
                          'The maximum file size is 200MB.',
                          'failure'
                        );
                        setItemData(d => ({...d, files: []}));
                        return;
                      }
                      setItemData(fs => ({
                        ...fs,
                        files: [...(e.target.files || [])],
                      }));
                      return;
                    }
                    // END OF POST TYPE
                    const file = e.target.files?.[0];
                    if (
                      file?.type &&
                      getAcceptedFileTypes(view)?.indexOf(file.type) === -1 &&
                      ACCEPTED_FILE_TYPES.indexOf(file?.type) !== -1
                    ) {
                      ToastActions.addToast(
                        uuid(),
                        'Invalid file type!',
                        'This file type is not supported for this view.',
                        'failure'
                      );
                      return;
                    }
                    if (
                      (file?.type &&
                        getAcceptedFileTypes(view)?.indexOf(file.type) ===
                          -1) ||
                      !file?.type
                    ) {
                      ToastActions.addToast(
                        uuid(),
                        'Invalid file type!',
                        'We currently do not support this file type.',
                        'failure'
                      );
                      return;
                    }
                    if (file?.size > MAX_FILE_SIZE) {
                      ToastActions.addToast(
                        uuid(),
                        'File too large!',
                        'The maximum file size is 200MB.',
                        'failure'
                      );
                      return;
                    }
                    if (file) {
                      if (ACCEPTED_FILE_TYPES.indexOf(file.type) !== -1) {
                        setItemData(d => ({...d, file}));
                        setItemDataFileURL(URL.createObjectURL(file));
                      } else {
                        setItemData(d => ({...d, file: undefined}));
                        return;
                      }
                    }
                  }}
                  onBlur={e => {
                    e.target.value = '';
                  }}
                />
                {!itemData.file && (
                  <span
                    className={clsx(styles.hint)}
                    data-has-cover-no-file={
                      !itemData.file && itemDataCoverURL ? 'true' : 'false'
                    }
                  >
                    <RemixIcon
                      icon={
                        view === 'image'
                          ? 'image-add-fill'
                          : view === 'video'
                          ? 'video-add-line'
                          : view === 'audio'
                          ? 'file-music-line'
                          : 'file-add-line'
                      }
                      size={view === 'audio' ? 42 : 64}
                    />
                    {view !== 'audio' && (
                      <div>
                        <div>
                          Add{' '}
                          {view === 'post'
                            ? 'an image or a video'
                            : view.charAt(0).toUpperCase() + view.slice(1)}
                        </div>
                        <span className={styles.desktopHint}>
                          <span>drag and drop, or paste here</span>
                          <br />
                          (Max {MAX_FILE_SIZE / 1000000}MB)
                        </span>
                      </div>
                    )}
                  </span>
                )}
              </div>
              {(view === 'post' || itemData.file) && (
                <Button
                  className={clsx(styles.actionBtn, styles.topRight)}
                  onClick={e => {
                    if (!itemData.file) {
                      setShowImageUploader(false);
                      return;
                    }
                    e.stopPropagation();
                    e.preventDefault();
                    if (view === 'audio' && itemData.cover) {
                      setItemData(d => ({...d, cover: undefined}));
                      setItemDataCoverURL('');
                      if (audioThumbInput.current) {
                        audioThumbInput.current.src = '';
                        audioThumbInput.current.value = '';
                      }
                      return;
                    }
                    setItemData(d => ({...d, file: undefined}));
                  }}
                  icon
                  tooltip={
                    view === 'audio' && itemData.cover
                      ? 'Remove Cover'
                      : view === 'audio'
                      ? 'Remove Audio'
                      : view === 'video'
                      ? 'Remove Video'
                      : 'Remove Image'
                  }
                  tooltipSide="right"
                  tooltipOffset={25}
                  data-is-audio={view === 'audio' ? 'true' : 'false'}
                >
                  <RemixIcon icon="close-line" size={20} />
                </Button>
              )}
            </div>
            {view === 'audio' && (
              <Suspense>
                <NewPostCardDetails
                  title={itemData.title}
                  setTitle={title => setItemData(d => ({...d, title}))}
                  description={itemData.description}
                  setDescription={description =>
                    setItemData(d => ({...d, description}))
                  }
                  view={view}
                  quoteItem={quoteItem}
                  setQuoteItem={setQuoteItem}
                />
              </Suspense>
            )}
          </div>
        )}
        {view === 'audio' && (
          <Button
            outlined
            onClick={async () => {
              if (itemData.cover) {
                setItemData(prev => ({...prev, cover: undefined}));
                setItemDataCoverURL('');
                if (audioThumbInput.current) {
                  audioThumbInput.current.src = '';
                  audioThumbInput.current.value = '';
                }
                return;
              }
              if (audioThumbInput.current) {
                audioThumbInput.current.click();
              }
            }}
            className={styles.coverBtn}
          >
            {itemData.cover ? 'Remove' : 'Add'} Cover
          </Button>
        )}
        {view !== 'post' && view !== 'audio' && (
          <Suspense>
            <NewPostCardDetails
              title={itemData.title}
              setTitle={title => setItemData(d => ({...d, title}))}
              description={itemData.description}
              setDescription={description =>
                setItemData(d => ({...d, description}))
              }
              view={view}
              quoteItem={quoteItem}
              setQuoteItem={setQuoteItem}
            />
          </Suspense>
        )}
        {quoteItem && (
          <div className={styles.quoteContainer}>
            <QuoteCard
              tokenId={quoteItem}
              onDelete={() => {
                setQuoteItem?.(null);
              }}
              parentHasAsset={itemData.file !== undefined}
              setQuotedItemRepostCount={setQuotedItemRepostCount}
            />
          </div>
        )}
        <NewPostCardActions
          processNFT={() => {
            if (view === 'image' && !itemData.file) {
              ToastActions.addToast(
                uuid(),
                'No image!',
                'Please add an image to create an artwork.',
                'failure'
              );
              return;
            } else if (view === 'video' && !itemData.file) {
              ToastActions.addToast(
                uuid(),
                'No video!',
                'Please add a video to create a video.',
                'failure'
              );
              return;
            } else if (view === 'audio' && !itemData.file) {
              ToastActions.addToast(
                uuid(),
                'No audio!',
                'Please add an audio file to create a music track.',
                'failure'
              );
              return;
            }
            processNFT();
          }}
          disabled={disabled}
          itemData={itemData}
          setItemData={setItemData}
        />
      </div>
      <input
        ref={audioThumbInput}
        type="file"
        accept="image/*"
        hidden
        onChange={e => {
          const file = e.target.files?.[0];
          if (file) {
            if (file.size > MAX_FILE_SIZE) {
              ToastActions.addToast(
                uuid(),
                'File too large!',
                'The maximum file size is 200MB.',
                'failure'
              );
              return;
            }
            if (file.type.startsWith('image')) {
              setItemData(d => ({...d, cover: file}));
              setItemDataCoverURL(URL.createObjectURL(file));
            }
          }
        }}
      />
    </div>
  );
}
