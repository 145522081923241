import React from 'react';
import * as ProgressPrimitive from '@radix-ui/react-progress';
import styles from '@/view/styles/components/Progress.module.scss';
import clsx from 'clsx';

export default function Progress({
  value,
  status,
  infinite = false,
  className,
}: {
  value: number;
  status?: string;
  infinite?: boolean;
  className?: string;
}) {
  const [progressValue, setProgressValue] = React.useState(value || 0);

  React.useEffect(() => {
    setProgressValue(value);
  }, [value]);
  return (
    <ProgressPrimitive.Root
      value={progressValue}
      className={clsx(styles.progress, className)}
      data-status={status}
    >
      <ProgressPrimitive.Indicator
        style={{transform: `translateX(-${100 - progressValue}%)`}}
        className={styles.indicator}
        data-infinite={infinite}
      />
    </ProgressPrimitive.Root>
  );
}
