/**
 * @generated SignedSource<<acfaa9ab3c6dce9e4ef58d16f27a1955>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FilterIs = "NOT_NULL" | "NULL" | "%future added value";
export type enum_event_type = "add_tezos_token_to_playlist" | "block" | "collect_for_free" | "create_tezos_token" | "follow" | "mint_for_free" | "mint_tezos_token" | "pending_mint_tezos_token" | "pending_purchase_tezos_token" | "purchase_tezos_token" | "repost" | "%future added value";
export type eventsFilter = {
  account_id?: BigIntFilter | null;
  and?: ReadonlyArray<eventsFilter> | null;
  created_at?: DatetimeFilter | null;
  follow_id?: BigIntFilter | null;
  id?: BigIntFilter | null;
  nodeId?: IDFilter | null;
  not?: eventsFilter | null;
  or?: ReadonlyArray<eventsFilter> | null;
  playlist_id?: BigIntFilter | null;
  tezos_token_id?: BigIntFilter | null;
  type?: enum_event_typeFilter | null;
  updated_at?: DatetimeFilter | null;
};
export type BigIntFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type DatetimeFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type enum_event_typeFilter = {
  eq?: enum_event_type | null;
  in?: ReadonlyArray<enum_event_type> | null;
  is?: FilterIs | null;
  neq?: enum_event_type | null;
};
export type IDFilter = {
  eq?: string | null;
};
export type removeEventMutation$variables = {
  filter: eventsFilter;
};
export type removeEventMutation$data = {
  readonly deleteFromeventsCollection: {
    readonly records: ReadonlyArray<{
      readonly account_id: string;
      readonly created_at: string;
      readonly follow_id: string | null;
      readonly id: string;
      readonly nodeId: string;
      readonly playlist_id: string | null;
      readonly tezos_token_id: string | null;
      readonly type: enum_event_type;
      readonly updated_at: string;
    }>;
  };
};
export type removeEventMutation = {
  response: removeEventMutation$data;
  variables: removeEventMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "eventsDeleteResponse",
    "kind": "LinkedField",
    "name": "deleteFromeventsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "events",
        "kind": "LinkedField",
        "name": "records",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created_at",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updated_at",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tezos_token_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "follow_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "playlist_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "account_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeEventMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeEventMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3e46f339cfcc09d1b4913541b80da20e",
    "id": null,
    "metadata": {},
    "name": "removeEventMutation",
    "operationKind": "mutation",
    "text": "mutation removeEventMutation(\n  $filter: eventsFilter!\n) {\n  deleteFromeventsCollection(filter: $filter) {\n    records {\n      id\n      nodeId\n      created_at\n      updated_at\n      type\n      tezos_token_id\n      follow_id\n      playlist_id\n      account_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fcb6cfaef0521209a8cacd1689c84f71";

export default node;
