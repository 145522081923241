import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation insertPlaylistTokenMutation(
    $input: [playlists_tokensInsertInput!]!
  ) {
    insertIntoplaylists_tokensCollection(objects: $input) {
      records {
        id
        nodeId
        playlist_id
        order
        playlists {
          title
        }
        tezos_tokens {
          playlists_tokensCollection {
            totalCount
          }
          id
          nodeId
          mime_type
          ...tezosTokensFragment
        }
      }
    }
  }
`;
