import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation removeEventMutation($filter: eventsFilter!) {
    deleteFromeventsCollection(filter: $filter) {
      records {
        id
        nodeId
        created_at
        updated_at
        type
        tezos_token_id
        follow_id
        playlist_id
        account_id
      }
    }
  }
`;
