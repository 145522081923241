import React from 'react';
import styles from '@/view/styles/components/Chip.module.scss';
import Button from './Button';
import clsx from 'clsx';
import RemixIcon from './RemixIcon';

export default function Chip({
  text,
  icon,
  onDelete,
  onClick,
  active = false,
  disabled = false,
  className,
}: {
  text: string | React.ReactNode;
  icon?: React.ReactNode;
  onDelete?: () => void;
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
  className?: string;
}) {
  return (
    <div
      className={clsx(
        styles.root,
        !onDelete && styles.noDelete,
        active && styles.active,
        className
      )}
      data-has-icon={icon ? true : undefined}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (disabled) return;
        onClick?.();
      }}
    >
      {icon}
      <span>{text}</span>
      {onDelete && (
        <Button icon onClick={onDelete}>
          <RemixIcon icon="close-line" />
        </Button>
      )}
    </div>
  );
}
