/**
 * @generated SignedSource<<78d2abb0ae961677b0fa8c1f1d30ec92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CollectGetFreeCollectStatusQuery$variables = {
  accountId: string;
  tokenId: string;
};
export type CollectGetFreeCollectStatusQuery$data = {
  readonly eventsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly nodeId: string;
      };
    }>;
  } | null;
};
export type CollectGetFreeCollectStatusQuery = {
  response: CollectGetFreeCollectStatusQuery$data;
  variables: CollectGetFreeCollectStatusQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tokenId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "eq",
                "variableName": "accountId"
              }
            ],
            "kind": "ObjectValue",
            "name": "account_id"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "eq",
                "variableName": "tokenId"
              }
            ],
            "kind": "ObjectValue",
            "name": "tezos_token_id"
          },
          {
            "kind": "Literal",
            "name": "type",
            "value": {
              "in": [
                "collect_for_free",
                "mint_for_free"
              ]
            }
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "eventsConnection",
    "kind": "LinkedField",
    "name": "eventsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "eventsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "events",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodeId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CollectGetFreeCollectStatusQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CollectGetFreeCollectStatusQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a2ffae197bc2094e68965e064e7ec1a0",
    "id": null,
    "metadata": {},
    "name": "CollectGetFreeCollectStatusQuery",
    "operationKind": "query",
    "text": "query CollectGetFreeCollectStatusQuery(\n  $tokenId: BigInt!\n  $accountId: BigInt!\n) {\n  eventsCollection(filter: {type: {in: [collect_for_free, mint_for_free]}, tezos_token_id: {eq: $tokenId}, account_id: {eq: $accountId}}) {\n    edges {\n      node {\n        id\n        nodeId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "08aee2b89e3610d5feb4f00ac7ae9dd3";

export default node;
