import {
  Categories,
  CategoryConfig,
} from 'emoji-picker-react/dist/config/categoryConfig';

// override type for emoji picker which excludes flags (because most of them are broken)
export const emojiCategories: CategoryConfig[] = [
  {
    category: 'suggested' as Categories.SUGGESTED,
    name: 'Frequently used',
  },
  {
    category: 'smileys_people' as Categories.SMILEYS_PEOPLE,
    name: 'Faces',
  },
  {
    category: 'animals_nature' as Categories.ANIMALS_NATURE,
    name: 'Animals',
  },
  {
    category: 'food_drink' as Categories.FOOD_DRINK,
    name: 'Food',
  },
  {
    category: 'travel_places' as Categories.TRAVEL_PLACES,
    name: 'Travel',
  },
  {
    category: 'activities' as Categories.ACTIVITIES,
    name: 'Activities',
  },
  {
    category: 'objects' as Categories.OBJECTS,
    name: 'Objects',
  },
  {
    category: 'symbols' as Categories.SYMBOLS,
    name: 'Symbols',
  },
];

export const ftueAvatars = [
  'QmP4BDM4iwuSP44doTu9rdmwa6oT2FfR4M9NnwgvUPMHG5',
  'Qmc55nQVcV38R9j3MXdGXeYWEvaw3SVhRgTGb5t2FXSEXh',
  'QmY7LYC5z77tbxHL6XpzpWqrPfHoe4oK3M8C1dZR4GrB4c',
  'Qmeg4gX5wH2uvzgNAAjsAmRnU9tz8z7a65PrrbxuEARcRD',
  'QmYgpMJm8aM7N34cGkuUZFWfomN4MAjvRpoFKXEMUje8Dc',
  'QmVKEmw7umsnnm9EP7uCeF9FxidFCD9ptjaD9tJd1VETE3',
  'QmahY9K23NqVnpV7W1ETW3FGKQ9bR4bDb2R7VgeQXbGL34',
];

export const ftueBanners = [
  'QmTkqzPs7N1hYPHusZ8PZRYgM55DWh14RSQTw4Cw9QkLLn',
  'QmaSSqhtMLLjQbW8kGfbBbc6LQAi4meAb7Ns98q5ivqsXz',
  'QmVGewrSjpSE55osN19GWp6LdmTNeS6CwbgYTRWmnqmNV4',
  'QmcX1umUKZXGdncP7rszH5FmDALziojp1yWkECLDUP3kCw',
  'QmRg8DkvDdK2gksMELdyHGVF62iAz77ssk4LcQvzzVso5R',
  'QmcNvp476f4HuNgh5drs6EHgwuU6sksHdPA2xUaqHqdUXX',
];
