import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation updateTezosTokenMutation(
    $input: tezos_tokensUpdateInput!
    $filter: tezos_tokensFilter!
  ) {
    updatetezos_tokensCollection(set: $input, filter: $filter) {
      records {
        nodeId
        id
        purchase_price
        price_denomination
        is_purchasable
        editions
        editions_minted
        editions_burned
      }
    }
  }
`;
