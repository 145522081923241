import {ActionsMenuActions, NftActions} from '@/state/hooks/actionsMenu';
import {ToastActions} from '@/state/hooks/toasts';
import RemixIcon from '@/view/components/RemixIcon';
import {ActionType} from '@/view/types/types';
import {Suspense, useEffect, useMemo, useState} from 'react';
import {v4 as uuid} from 'uuid';
import {getImageUrl} from '../conversions/conversions';
import styles from '@/view/styles/providers/Dialog.module.scss';
import {graphql, useLazyLoadQuery} from '@/kits/relay-kit/src';
import {Account, useAccount} from '@/kits/account-kit/src';
import {SendTokenDialog} from '@/view/components/SendToken/SendTokenDialog';
import {DeleteTokenDialog} from '@/view/components/ManageItem/DeleteToken/DeleteTokenDialog';
import {PRIMARY_DOMAIN} from '@/config';
import Image from '@/view/components/Image';
import WarpcastIcon from '@/view/resources/icons/Warpcast.svg';
import type {nftActionsGetOwnedCountQuery as nftActionsGetOwnedCountQueryType} from './__generated__/nftActionsGetOwnedCountQuery.graphql';

const copyLinkText = 'Copy link';

export const useNftViewToActions = ({
  view,
  loadingShares,
  share,
}: {
  view: NftActions | undefined;
  loadingShares?: boolean;
  share?: (
    item: {
      id: string;
      nodeId: string;
    },
    type: 'post' | 'list' | 'user' | 'blog'
  ) => void;
}) => {
  const {getAccount} = useAccount();

  const user = getAccount();

  const isDeletable =
    Number(view?.editionsMinted) === 0 &&
    user.isLoggedIn &&
    user.accountId === view?.creator?.id;

  const [sendTokenOpen, setSendTokenOpen] = useState(false);
  const [deleteTokenOpen, setDeleteTokenOpen] = useState(false);
  const [burnTokenOpen, setBurnTokenOpen] = useState(false);
  const [ownedCount, setOwnedCount] = useState<number>();

  useEffect(() => {
    setSendTokenOpen(false);
    setBurnTokenOpen(false);
    setDeleteTokenOpen(false);
    setOwnedCount(undefined);
  }, [view?.id]);

  const nftActions: ActionType[][] = useMemo(() => {
    const token = view?.type === 'token' ? view : undefined;
    if (!token) return [];

    const tokenCreatorUsername = token.creator?.username;

    const nftUrl =
      typeof window !== 'undefined'
        ? !!tokenCreatorUsername
          ? `https://${PRIMARY_DOMAIN}/${tokenCreatorUsername}/item/${token?.id}`
          : `https://${PRIMARY_DOMAIN}/item/${token?.id}`
        : '';

    return [
      [
        ...(ownedCount && ownedCount > 0 && user.isLoggedIn
          ? [
              {
                element: (
                  <SendTokenDialog
                    defaultOpen={sendTokenOpen}
                    onClose={(initiated: boolean) => {
                      setSendTokenOpen(false);
                      if (initiated) {
                        ActionsMenuActions.closeActionsMenu();
                      }
                    }}
                    trigger={
                      <div>
                        <RemixIcon icon="send-plane-2-line" size={22} />
                        <div>
                          <div>
                            Send {token.tokenType === 'post' ? 'post' : 'item'}
                          </div>
                        </div>
                      </div>
                    }
                    tokenId={token.id}
                  />
                ),
                onClick: () => {
                  setSendTokenOpen(true);
                },
                doNotClose: true,
                override: true as true,
                className: styles.sendToken,
              },
              {
                element: (
                  <SendTokenDialog
                    defaultOpen={burnTokenOpen}
                    onClose={(initiated: boolean) => {
                      setBurnTokenOpen(false);
                      if (initiated) {
                        ActionsMenuActions.closeActionsMenu();
                      }
                    }}
                    trigger={
                      <div>
                        <RemixIcon icon="fire-line" size={22} />
                        <div>
                          <div>
                            Burn {token.tokenType === 'post' ? 'post' : 'item'}
                          </div>
                        </div>
                      </div>
                    }
                    tokenId={token.id}
                    burn
                  />
                ),
                onClick: () => {
                  setBurnTokenOpen(true);
                },
                doNotClose: true,
                override: true as true,
                className: styles.sendToken,
              },
            ]
          : ownedCount === undefined
          ? [
              {
                element: (
                  <Suspense
                    fallback={
                      <div>
                        <RemixIcon icon="hourglass-2-line" size={22} />
                        <div>
                          <div>Please wait...</div>
                        </div>
                      </div>
                    }
                  >
                    <GetOwnedCount
                      user={user}
                      tokenId={token.id}
                      setOwnedCount={setOwnedCount}
                    />
                  </Suspense>
                ),
                doNotClose: true,
                override: true as true,
                className: styles.sendToken,
              },
            ]
          : []),
        ...(isDeletable
          ? [
              {
                element: (
                  <DeleteTokenDialog
                    defaultOpen={deleteTokenOpen}
                    onClose={() => {
                      setDeleteTokenOpen(false);
                    }}
                    trigger={
                      <div>
                        <RemixIcon icon="delete-bin-7-line" size={22} />
                        <div>
                          <div>
                            Delete{' '}
                            {token.tokenType === 'post' ? 'post' : 'item'}
                          </div>
                        </div>
                      </div>
                    }
                    tokenId={token.id}
                  />
                ),
                onClick: () => {
                  setDeleteTokenOpen(true);
                },
                doNotClose: true,
                override: true as true,
                className: styles.sendToken,
              },
            ]
          : []),
        {
          icon: <RemixIcon icon="external-link-line" size={22} />,
          onClick: () => {
            window.open(
              getImageUrl(token.artifactUri || ''),
              '_blank',
              'noopener,noreferrer'
            );
          },
          title: 'View on IPFS',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="code-line" size={22} />,
          onClick: () => {
            if (!token.metadataUri) return;
            window.open(
              getImageUrl(token.metadataUri),
              '_blank',
              'noopener,noreferrer'
            );
          },
          title: 'View metadata',
          override: false as false,
        },
      ],
      [
        {
          icon: <RemixIcon icon="arrow-left-s-line" size={22} />,
          ...((view?.page || 0) === 0
            ? {setPage: 0, doNotClose: true}
            : {
                onClick: () => {},
              }),
          title: 'Back',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="file-copy-line" size={22} />,
          onClick: () => {
            navigator.clipboard.writeText(nftUrl);
            ToastActions.addToast(
              uuid(),
              'Copied to clipboard!',
              '',
              'success',
              undefined,
              undefined,
              undefined,
              'copy-line'
            );
            if (loadingShares) return;
            share?.(
              {
                id: token.id,
                nodeId: token.nodeId,
              },
              'post'
            );
          },
          title: copyLinkText,
          override: false as false,
        },
        {
          icon: <RemixIcon icon="facebook-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${nftUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              if (loadingShares) return;
              share?.(
                {
                  id: token.id,
                  nodeId: token.nodeId,
                },
                'post'
              );
            }
          },
          title: 'Share on Facebook',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="twitter-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://twitter.com/intent/tweet?url=${nftUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              if (loadingShares) return;
              share?.(
                {
                  id: token.id,
                  nodeId: token.nodeId,
                },
                'post'
              );
            }
          },
          title: 'Share on X',
          override: false as false,
        },
        {
          icon: (
            <Image
              src={WarpcastIcon}
              alt="Warpcast"
              className={styles.customIcon}
            />
          ),
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://warpcast.com/~/compose?embeds[]=${nftUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              if (loadingShares) return;
              share?.(
                {
                  id: token.id,
                  nodeId: token.nodeId,
                },
                'post'
              );
            }
          },
          title: 'Share on Warpcast',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="whatsapp-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://wa.me/?text=${nftUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              if (loadingShares) return;
              share?.(
                {
                  id: token.id,
                  nodeId: token.nodeId,
                },
                'post'
              );
            }
          },
          title: 'Share on WhatsApp',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="telegram-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://t.me/share/url?url=${nftUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              if (loadingShares) return;
              share?.(
                {
                  id: token.id,
                  nodeId: token.nodeId,
                },
                'post'
              );
            }
          },
          title: 'Share on Telegram',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="reddit-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://www.reddit.com/submit?url=${nftUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              if (loadingShares) return;
              share?.(
                {
                  id: token.id,
                  nodeId: token.nodeId,
                },
                'post'
              );
            }
          },
          title: 'Share on Reddit',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="mail-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `mailto:?subject=${token.title}&body=${nftUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              if (loadingShares) return;
              share?.(
                {
                  id: token.id,
                  nodeId: token.nodeId,
                },
                'post'
              );
            }
          },
          title: 'Share via email',
          override: false as false,
        },
      ],
      [
        {
          icon: <RemixIcon icon="arrow-left-s-line" size={22} />,
          ...((view?.page || 0) === 0
            ? {setPage: 0, doNotClose: true}
            : {
                onClick: () => {},
              }),
          title: 'Back',
          override: false as false,
        },
      ],
      [
        ...((view?.page || 0) === 0
          ? [
              {
                icon: <RemixIcon icon="arrow-left-s-line" size={22} />,
                setPage: 0,
                doNotClose: true,
                title: 'Back',
                override: false as false,
              },
            ]
          : []),
      ],
    ];
  }, [
    burnTokenOpen,
    deleteTokenOpen,
    isDeletable,
    loadingShares,
    ownedCount,
    sendTokenOpen,
    share,
    view,
    user,
  ]);

  return nftActions;
};

const nftActionsGetOwnedCountQuery = graphql`
  query nftActionsGetOwnedCountQuery(
    $accountId: BigInt!
    $tokenIds: [BigInt!]
  ) {
    eventsCollection(
      filter: {
        and: {
          type: {
            in: [
              mint_tezos_token
              pending_mint_tezos_token
              purchase_tezos_token
              pending_purchase_tezos_token
              collect_for_free
              mint_for_free
            ]
          }
          tezos_token_id: {in: $tokenIds}
          account_id: {eq: $accountId}
        }
      }
    ) {
      totalCount
    }
  }
`;

const GetOwnedCount = ({
  user,
  tokenId,
  setOwnedCount,
}: {
  user: Account;
  tokenId: string;
  setOwnedCount: (ownedCount: number) => void;
}) => {
  const {data: ownedCount} = useLazyLoadQuery<nftActionsGetOwnedCountQueryType>(
    nftActionsGetOwnedCountQuery,
    {
      accountId: user.isLoggedIn ? user.accountId : '',
      tokenIds: [tokenId || ''],
    },
    {
      skip:
        !user.isLoggedIn ||
        !user.accountId ||
        user.accountId === '' ||
        !tokenId ||
        tokenId === '',
    }
  );

  useEffect(() => {
    setOwnedCount(ownedCount?.eventsCollection?.totalCount || 0);
  }, [ownedCount?.eventsCollection?.totalCount, setOwnedCount, tokenId]);

  return null;
};
