import React from 'react';
import styles from '@/view/styles/components/TabPanel.module.scss';
import clsx from 'clsx';

export default function TabPanel({
  show,
  tab,
  children,
  className,
  scrollToTopOnChange,
}: {
  show: number;
  tab: number;
  children?: React.ReactNode;
  className?: string;
  scrollToTopOnChange?: boolean;
}) {
  const panelRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (scrollToTopOnChange) return;
    if (panelRef.current && panelRef.current.getBoundingClientRect().top < 0) {
      panelRef.current?.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    }
  }, [scrollToTopOnChange, tab]);

  return show === tab ? (
    <div ref={panelRef} className={clsx(styles.root, className)}>
      {children}
    </div>
  ) : null;
}
