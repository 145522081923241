/**
 * @generated SignedSource<<1d4bf02cee591c444d976b8799cafd5a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type enum_token_type = "audio" | "image" | "link" | "post" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuoteCardHeaderTokenFragment$data = {
  readonly accounts: {
    readonly id: string;
    readonly nodeId: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserItemFragment">;
  };
  readonly id: string;
  readonly nodeId: string;
  readonly title: string;
  readonly type: enum_token_type;
  readonly " $fragmentType": "QuoteCardHeaderTokenFragment";
};
export type QuoteCardHeaderTokenFragment$key = {
  readonly " $data"?: QuoteCardHeaderTokenFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuoteCardHeaderTokenFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuoteCardHeaderTokenFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserItemFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "tezos_tokens",
  "abstractKey": null
};
})();

(node as any).hash = "3ab32a2e7d1de5f8992d7d9047e92eee";

export default node;
