import {RelayEnvironmentProvider, relay} from '@/kits/relay-kit/src';
import {PropsWithChildren, Suspense} from 'react';

export default function RelayProvider({children}: PropsWithChildren<{}>) {
  return (
    <RelayEnvironmentProvider environment={relay}>
      <Suspense>{children}</Suspense>
    </RelayEnvironmentProvider>
  );
}
