/**
 * @generated SignedSource<<0017f905cb4220282b377372fc387a3b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FilterIs = "NOT_NULL" | "NULL" | "%future added value";
export type commentsUpdateInput = {
  account_id?: string | null;
  content?: string | null;
  created_at?: string | null;
  parent_comment_id?: string | null;
  tezos_token_id?: string | null;
  updated_at?: string | null;
};
export type commentsFilter = {
  account_id?: BigIntFilter | null;
  and?: ReadonlyArray<commentsFilter> | null;
  content?: StringFilter | null;
  created_at?: DatetimeFilter | null;
  id?: BigIntFilter | null;
  nodeId?: IDFilter | null;
  not?: commentsFilter | null;
  or?: ReadonlyArray<commentsFilter> | null;
  parent_comment_id?: BigIntFilter | null;
  tezos_token_id?: BigIntFilter | null;
  updated_at?: DatetimeFilter | null;
};
export type BigIntFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type DatetimeFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type StringFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  ilike?: string | null;
  in?: ReadonlyArray<string> | null;
  iregex?: string | null;
  is?: FilterIs | null;
  like?: string | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
  regex?: string | null;
  startsWith?: string | null;
};
export type IDFilter = {
  eq?: string | null;
};
export type updateCommentMutation$variables = {
  filter: commentsFilter;
  input: commentsUpdateInput;
};
export type updateCommentMutation$data = {
  readonly updatecommentsCollection: {
    readonly records: ReadonlyArray<{
      readonly content: string | null;
      readonly id: string;
    }>;
  };
};
export type updateCommentMutation = {
  response: updateCommentMutation$data;
  variables: updateCommentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "set",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "commentsUpdateResponse",
        "kind": "LinkedField",
        "name": "updatecommentsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "comments",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateCommentMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "commentsUpdateResponse",
        "kind": "LinkedField",
        "name": "updatecommentsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "comments",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodeId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cb5c15d21ac865f7acfafc5a4c0e5135",
    "id": null,
    "metadata": {},
    "name": "updateCommentMutation",
    "operationKind": "mutation",
    "text": "mutation updateCommentMutation(\n  $input: commentsUpdateInput!\n  $filter: commentsFilter!\n) {\n  updatecommentsCollection(set: $input, filter: $filter) {\n    records {\n      id\n      content\n      nodeId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2295a8f42322629d9adb410bd1b292e7";

export default node;
