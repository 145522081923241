import React, {ForwardedRef} from 'react';
import styles from '@/view/styles/components/Input/Input.module.scss';
import Button from '@/view/components/Button';
import clsx from 'clsx';
import RemixIcon from '@/view/components/RemixIcon';
import MultiLineInput from './blocs/MultiLineInput';
import ArrayInput from './blocs/ArrayInput';
import DefaultInput from './blocs/DefaultInput';

const STOP = 'STOP';

export default React.forwardRef(function Input<
  Type extends 'text' | 'number' | 'array'
>(
  {
    simple,
    type,
    id,
    placeholder,
    defaultValue,
    value,
    validate,
    onChange,
    clearInput,
    onEnter,
    onBackspace,
    fullWidth,
    label,
    disabled,
    multiline,
    rows,
    autoFocus,
    min,
    max,
    step,
    integer,
    onClick,
    onFocus,
    onBlur,
    autoComplete,
  }: {
    simple?: boolean;
    type: Type;
    id?: string;
    placeholder?: string;
    defaultValue: string | number | undefined;
    value?: string | number | undefined;
    // return a string with an error message when there's an error otherwise return undefined
    validate?: (value: string) => string | undefined;
    onChange: (value: string) => void | string;
    clearInput?: (e?: React.MouseEvent) => void;
    onEnter?: () => void;
    onBackspace?: () => void;
    fullWidth?: boolean;
    label?: string;
    disabled?: boolean;
    multiline?: boolean;
    rows?: number;
    autoFocus?: boolean;
    autoComplete?: boolean;
    min?: number;
    max?: number;
    step?: number;
    integer?: Type extends 'number' ? boolean : false | undefined;
    onClick?: (
      e: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    onFocus?: (
      e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
    onBlur?: (
      e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void;
  },
  ref: ForwardedRef<HTMLInputElement | HTMLTextAreaElement>
) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);

  if (ref) {
    if (typeof ref === 'function') {
      ref(inputRef.current || textareaRef.current);
    } else if (inputRef.current) {
      ref.current = inputRef.current;
    } else if (textareaRef.current) {
      ref.current = textareaRef.current;
    } else {
      ref.current = null;
    }
  }

  const handleClear = React.useCallback(
    (e?: React.MouseEvent<Element, MouseEvent>) => {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      clearInput && clearInput(e);
    },
    [clearInput]
  );

  const handleEnter = () => {
    if (inputRef.current) {
      if (type !== 'array') {
        onChange(inputRef.current.value);
      } else {
        const newArray = [...arrayElements, inputRef.current.value];
        const shouldBeVoid = onChange(newArray.join(','));
        if (typeof shouldBeVoid === 'string') {
          throw new Error(
            'changing the value of an array using onChange is not supported'
          );
        }
        setNewArrayItem('');
      }
      inputRef.current.value = '';
    }
    onEnter?.();
  };

  const [arrayElements, setArrayElements] = React.useState<string[]>([]);
  const [showArrayInput, setShowArrayInput] = React.useState<boolean>(false);
  const [newArrayItem, setNewArrayItem] = React.useState<string>('');

  React.useEffect(() => {
    if (type !== 'array') return;
    if (defaultValue === undefined) {
      setArrayElements([]);
      setShowArrayInput(false);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      if (textareaRef.current) {
        textareaRef.current.value = '';
      }
    } else {
      if (Array.isArray(defaultValue)) {
        setArrayElements(defaultValue);
      } else {
        const val =
          typeof defaultValue === 'number'
            ? integer
              ? defaultValue.toFixed(0)
              : defaultValue.toFixed(1)
            : defaultValue;
        if (type === 'array') {
          setArrayElements(val === '' ? [] : val.split(','));
        } else {
          setArrayElements([val]);
          if (inputRef.current) {
            inputRef.current.value = val;
          }
          if (textareaRef.current) {
            textareaRef.current.value = val;
          }
        }
      }
    }
    // only run when defaultValue changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, type]);

  const deleteArrayItem = (index: number) => {
    const newElements = [...arrayElements];
    newElements.splice(index, 1);
    setArrayElements(newElements);
    onChange(newElements.join(', ')); // all elements are valid already
  };

  React.useEffect(() => {
    if (defaultValue === '') {
      handleClear();
    }
  }, [defaultValue, handleClear]);

  // prevent moving the viewport on ios when focusing on input
  React.useEffect(() => {
    const isIOS =
      typeof navigator !== 'undefined'
        ? navigator.userAgent.match(/iPad|iPhone|iPod/i)
        : false;

    if (!isIOS) return;

    if (typeof window === 'undefined') return;

    const current = inputRef.current || textareaRef.current;

    if (current) {
      const onFocus = () => {
        const scrollY = window.scrollY;
        const onBlur = () => {
          window.scrollTo(0, scrollY);
          current.removeEventListener('blur', onBlur);
        };
        current.addEventListener('blur', onBlur);
      };

      current.addEventListener('focus', onFocus);
      return () => {
        current.removeEventListener('focus', onFocus);
      };
    }
  }, []);

  return (
    <div
      className={clsx(
        styles.root,
        type === 'array' && styles.arrayInputRoot,
        multiline && styles.textarea
      )}
      onClick={e => {
        inputRef.current?.focus();
      }}
    >
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      )}
      {multiline ? (
        <MultiLineInput
          id={id}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChange}
          fullWidth={fullWidth}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          disabled={disabled}
          textareaRef={textareaRef}
          rows={rows}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          onEnter={onEnter}
          handleEnter={handleEnter}
        />
      ) : type === 'array' ? (
        <ArrayInput
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          fullWidth={fullWidth}
          disabled={disabled}
          inputRef={inputRef}
          onEnter={onEnter}
          handleEnter={handleEnter}
          arrayElements={arrayElements}
          deleteArrayItem={deleteArrayItem}
          showArrayInput={showArrayInput}
          setShowArrayInput={setShowArrayInput}
          newArrayItem={newArrayItem}
          setNewArrayItem={setNewArrayItem}
          type={type}
        />
      ) : (
        <DefaultInput
          type={type}
          id={id}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          fullWidth={fullWidth}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          disabled={disabled}
          inputRef={inputRef}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          onEnter={onEnter}
          onBackspace={onBackspace}
          handleEnter={handleEnter}
          min={min}
          max={max}
          step={step}
        />
      )}
      {(clearInput || onEnter) && (
        <Button
          icon
          className={styles.reset}
          onClick={
            clearInput
              ? e => {
                  handleClear(e);
                }
              : onEnter
              ? () => {
                  handleEnter();
                }
              : () => {}
          }
        >
          {clearInput ? (
            <RemixIcon icon="close-circle-fill" size={16} />
          ) : (
            <RemixIcon icon="checkbox-circle-fill" size={16} />
          )}
        </Button>
      )}
    </div>
  );
});
