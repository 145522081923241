import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation removeMessageReactionMutation($filter: messages_reactionsFilter!) {
    deleteFrommessages_reactionsCollection(filter: $filter) {
      records {
        id
        nodeId
      }
    }
  }
`;
