import {memo} from 'react';
import RemixIcon from '../RemixIcon';

export const MemoIcon = memo(function MemoChildren({
  Component,
  size,
  color,
  opacity,
  className,
}: {
  Component: string;
  size?: string | number;
  color?: string;
  opacity?: number;
  className?: string;
}) {
  return (
    <RemixIcon
      icon={Component}
      color={color}
      size={size}
      opacity={opacity}
      className={className}
    />
  );
});
