import {enum_token_type} from '@/graphql/__generated__/tezosTokensFragment.graphql';
import {create} from 'zustand';

export type CreateView = enum_token_type;

type CreateStatusFunctions = {
  setStatus: (
    status: 'idle' | 'uploading' | 'processing' | 'done' | 'error'
  ) => void;
  setCanCancel: (canCancel: boolean) => void;
  setType: (type: 'image' | 'video' | 'audio' | 'post') => void;
  setThumbnail: (thumbnail: string) => void;
  setUrl: (url: string) => void;
  setShowCreateModal: (showCreateModal: boolean, view?: CreateView) => void;
  setPreviousRoute: (previousRoute: string | undefined) => void;
  reset: () => void;
};

type CreateStatusState = {
  status: 'idle' | 'uploading' | 'processing' | 'done' | 'error';
  canCancel: boolean;
  type: 'image' | 'video' | 'audio' | 'post';
  thumbnail?: string;
  url: string;
  showCreateModal: boolean;
  view: CreateView;
  previousRoute?: string;
};

const defaultState: CreateStatusState = {
  status: 'idle',
  canCancel: false,
  type: 'post',
  thumbnail: undefined,
  url: '',
  showCreateModal: false,
  view: 'post',
  previousRoute: undefined,
};

export const useCreateStatus = create<CreateStatusState>((set, get) => {
  return {
    ...defaultState,
  };
});

export const useCreateStatusActions: CreateStatusFunctions = {
  reset() {
    useCreateStatus.setState(defaultState);
  },
  setStatus(status) {
    useCreateStatus.setState({status});
  },
  setCanCancel(canCancel) {
    useCreateStatus.setState({canCancel});
  },
  setType(type) {
    useCreateStatus.setState({type});
  },
  setThumbnail(thumbnail) {
    useCreateStatus.setState({thumbnail});
  },
  setUrl(url) {
    useCreateStatus.setState({url});
  },
  setShowCreateModal(showCreateModal, view) {
    useCreateStatus.setState({
      showCreateModal,
      view: view || 'post',
    });
  },
  setPreviousRoute(previousRoute) {
    useCreateStatus.setState({
      previousRoute,
    });
  },
};
