import React, {Suspense, useCallback} from 'react';
import styles from '@/view/styles/components/AddToCollection/AddToCollectionDialog.module.scss';
import {LabelsPosition} from '@/view/types/types';
import Tooltip from '@/view/components/Tooltip';
import {useRouter} from 'next/router';
import clsx from 'clsx';
import BottomSheet from '@/view/components/BottomSheet/BottomSheet';
import RemixIcon from '../RemixIcon';
import AddToCollection from './AddToCollection';
import InfiniteLoader from '../InfiniteLoader';

const AddToCollectionDialog = ({
  iconSize,
  tokenId,
  tokenType,
  trigger,
  labels,
  tooltipPosition,
}: {
  iconSize?: number;
  tokenId: string;
  tokenType: string;
  trigger?: React.ReactNode;
  labels?: LabelsPosition;
  tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
}) => {
  const router = useRouter();

  return (
    <BottomSheet
      dialogOnDesktop
      trigger={
        trigger ? (
          trigger
        ) : (
          <div
            className={clsx(
              labels === 'right'
                ? styles.rightLabels
                : labels === 'bottom'
                ? styles.bottomLabels
                : null
            )}
            onClick={() => {
              router.push(
                {pathname: router.pathname, query: {...router.query}},
                router.asPath + '#',
                {
                  shallow: true,
                  scroll: false,
                }
              );
            }}
          >
            <Tooltip text="Collectors" side={tooltipPosition || 'top'}>
              <RemixIcon icon="add-line" size={iconSize || 24} />
              {labels !== 'off' && <span>Save</span>}
            </Tooltip>
          </div>
        )
      }
      className={styles.bottomSheet}
    >
      {useCallback(
        (closeDialog: () => void) => (
          <Suspense
            fallback={
              <div className={styles.root}>
                <InfiniteLoader />
              </div>
            }
          >
            <AddToCollection
              className={styles.root}
              onClose={(isPopState: boolean) => {
                closeDialog();
              }}
              tokenId={tokenId}
              tokenType={tokenType}
            />
          </Suspense>
        ),
        [tokenId, tokenType]
      )}
    </BottomSheet>
  );
};

export default AddToCollectionDialog;
