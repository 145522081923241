/**
 * @generated SignedSource<<f35f310fcb33283f3bf0bf78dea78b96>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NewPostCardDetailsHeaderFragment$data = {
  readonly id: string;
  readonly nodeId: string;
  readonly profilesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly avatar_uri: string | null;
        readonly id: string;
        readonly nodeId: string;
        readonly username: string | null;
      };
    }>;
  } | null;
  readonly " $fragmentType": "NewPostCardDetailsHeaderFragment";
};
export type NewPostCardDetailsHeaderFragment$key = {
  readonly " $data"?: NewPostCardDetailsHeaderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NewPostCardDetailsHeaderFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewPostCardDetailsHeaderFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "profilesConnection",
      "kind": "LinkedField",
      "name": "profilesCollection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "profilesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "profiles",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "username",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "avatar_uri",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "identities",
  "abstractKey": null
};
})();

(node as any).hash = "5f197d6317d9ff3144f8ab42e02b0272";

export default node;
