/**
 * @generated SignedSource<<af67868ecd05b71ee0d15ec3e25cfdb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilterIs = "NOT_NULL" | "NULL" | "%future added value";
export type salesUpdateInput = {
  account_id?: string | null;
  amount?: string | null;
  created_at?: string | null;
  price?: number | null;
  recipients?: ReadonlyArray<string | null> | null;
  sale_id?: string | null;
  tezos_token_id?: string | null;
  updated_at?: string | null;
  valid_from?: string | null;
  valid_until?: string | null;
};
export type salesFilter = {
  account_id?: BigIntFilter | null;
  amount?: BigIntFilter | null;
  and?: ReadonlyArray<salesFilter> | null;
  created_at?: DatetimeFilter | null;
  id?: BigIntFilter | null;
  nodeId?: IDFilter | null;
  not?: salesFilter | null;
  or?: ReadonlyArray<salesFilter> | null;
  price?: FloatFilter | null;
  sale_id?: BigIntFilter | null;
  tezos_token_id?: BigIntFilter | null;
  updated_at?: DatetimeFilter | null;
  valid_from?: DatetimeFilter | null;
  valid_until?: DatetimeFilter | null;
};
export type BigIntFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type DatetimeFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type FloatFilter = {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number> | null;
  is?: FilterIs | null;
  lt?: number | null;
  lte?: number | null;
  neq?: number | null;
};
export type IDFilter = {
  eq?: string | null;
};
export type updateSaleMutation$variables = {
  filter: salesFilter;
  input: salesUpdateInput;
};
export type updateSaleMutation$data = {
  readonly updatesalesCollection: {
    readonly records: ReadonlyArray<{
      readonly accounts: {
        readonly identities: {
          readonly id: string;
          readonly nodeId: string;
        };
        readonly " $fragmentSpreads": FragmentRefs<"accountsFragment">;
      };
      readonly amount: string;
      readonly created_at: string;
      readonly id: string;
      readonly nodeId: string;
      readonly price: number;
      readonly sale_id: string | null;
      readonly tezos_token_id: string | null;
      readonly valid_from: string | null;
      readonly valid_until: string | null;
    }>;
  };
};
export type updateSaleMutation = {
  response: updateSaleMutation$data;
  variables: updateSaleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "set",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sale_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tezos_token_id",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "valid_from",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "valid_until",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v12 = [
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "public_key_hash",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "public_key",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateSaleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "salesUpdateResponse",
        "kind": "LinkedField",
        "name": "updatesalesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sales",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "accounts",
                "kind": "LinkedField",
                "name": "accounts",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "accountsFragment"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "identities",
                    "kind": "LinkedField",
                    "name": "identities",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateSaleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "salesUpdateResponse",
        "kind": "LinkedField",
        "name": "updatesalesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "sales",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "accounts",
                "kind": "LinkedField",
                "name": "accounts",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "magic_accountsConnection",
                    "kind": "LinkedField",
                    "name": "magic_accountsCollection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "magic_accountsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "magic_accounts",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": (v12/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "beacon_accountsConnection",
                    "kind": "LinkedField",
                    "name": "beacon_accountsCollection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "beacon_accountsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "beacon_accounts",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": (v12/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "teztok_accountsConnection",
                    "kind": "LinkedField",
                    "name": "teztok_accountsCollection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "teztok_accountsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "teztok_accounts",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": (v12/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "identities",
                    "kind": "LinkedField",
                    "name": "identities",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "profilesConnection",
                        "kind": "LinkedField",
                        "name": "profilesCollection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "profilesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "profiles",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "bio",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "username",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "avatar_uri",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "banner_uri",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "website",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "tezos_profilesConnection",
                                    "kind": "LinkedField",
                                    "name": "tezos_profilesCollection",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "tezos_profilesEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "tezos_profiles",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "logo",
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "description",
                                                "storageKey": null
                                              },
                                              (v4/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a903b6f740f0bf8887f96bcce7196de",
    "id": null,
    "metadata": {},
    "name": "updateSaleMutation",
    "operationKind": "mutation",
    "text": "mutation updateSaleMutation(\n  $input: salesUpdateInput!\n  $filter: salesFilter!\n) {\n  updatesalesCollection(set: $input, filter: $filter) {\n    records {\n      id\n      nodeId\n      sale_id\n      price\n      amount\n      tezos_token_id\n      valid_from\n      valid_until\n      created_at\n      accounts {\n        ...accountsFragment\n        identities {\n          id\n          nodeId\n        }\n        nodeId\n      }\n    }\n  }\n}\n\nfragment accountsFragment on accounts {\n  nodeId\n  id\n  type\n  magic_accountsCollection {\n    edges {\n      node {\n        id\n        nodeId\n        public_key_hash\n        public_key\n      }\n    }\n  }\n  beacon_accountsCollection {\n    edges {\n      node {\n        id\n        nodeId\n        public_key_hash\n        public_key\n      }\n    }\n  }\n  teztok_accountsCollection {\n    edges {\n      node {\n        id\n        nodeId\n        public_key_hash\n        public_key\n      }\n    }\n  }\n  identities {\n    id\n    nodeId\n    profilesCollection {\n      edges {\n        node {\n          nodeId\n          id\n          bio\n          username\n          avatar_uri\n          banner_uri\n          name\n          website\n          tezos_profilesCollection {\n            edges {\n              node {\n                logo\n                description\n                nodeId\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f684d1770b143acb5848b65ec11dfee0";

export default node;
