import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation insertPlaylistMutation($input: [playlistsInsertInput!]!) {
    insertIntoplaylistsCollection(objects: $input) {
      records {
        nodeId
        id
        title
        image_uri
        visibility
        playlists_tokensCollection {
          edges {
            node {
              tezos_tokens {
                nodeId
                id
                mime_type
                title
                thumbnail_uri
                artifact_uri
                contract_address
                token_id
                accounts {
                  id
                  nodeId
                  identities {
                    profilesCollection {
                      edges {
                        node {
                          username
                        }
                      }
                    }
                  }
                }
                ...tezosTokensFragment
              }
            }
          }
        }
      }
    }
  }
`;
