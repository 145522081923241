import {setAuthTokenInRelay} from '@/kits/relay-kit/src';
import {create} from 'zustand';
import {persist} from 'zustand/middleware';

type JWTState = {
  token: string | null;
};

type JWTFunctions = {
  setToken: (token: string) => void;
  removeToken: () => void;
};

const defaultState: JWTState = {
  token: null,
};

export const useJWT = create(
  persist<JWTState>(
    (set, get) => {
      return {
        ...defaultState,
      };
    },
    {
      name: 'token-store',
    }
  )
);

export const JWTActions: JWTFunctions = {
  setToken: (token: string) => {
    useJWT.setState(state => {
      return {
        token,
      };
    });
    setAuthTokenInRelay(token);
  },
  removeToken: () => {
    useJWT.setState(defaultState);
    setAuthTokenInRelay(null);
  },
};
