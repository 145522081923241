/**
 * @generated SignedSource<<ee0bfd95bdbba60047c5b224e9d608fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type enum_token_type = "audio" | "image" | "link" | "post" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PostActionsStatsTokenFragment$data = {
  readonly accounts: {
    readonly id: string;
    readonly identities: {
      readonly id: string;
      readonly nodeId: string;
      readonly profilesCollection: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly nodeId: string;
            readonly username: string | null;
          };
        }>;
      } | null;
    };
    readonly nodeId: string;
  };
  readonly allow_comments: boolean;
  readonly artifact_uri: string;
  readonly description: string | null;
  readonly editions: string;
  readonly editions_minted: string | null;
  readonly id: string;
  readonly metadata_uri: string;
  readonly nodeId: string;
  readonly thumbnail_uri: string;
  readonly title: string;
  readonly tokens_reactionsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accounts: {
          readonly id: string;
          readonly identities: {
            readonly id: string;
            readonly nodeId: string;
            readonly profilesCollection: {
              readonly edges: ReadonlyArray<{
                readonly node: {
                  readonly id: string;
                  readonly nodeId: string;
                  readonly username: string | null;
                };
              }>;
            } | null;
          };
          readonly nodeId: string;
        };
        readonly id: string;
        readonly reaction: string;
      };
    }>;
  } | null;
  readonly type: enum_token_type;
  readonly " $fragmentType": "PostActionsStatsTokenFragment";
};
export type PostActionsStatsTokenFragment$key = {
  readonly " $data"?: PostActionsStatsTokenFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PostActionsStatsTokenFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "accounts",
  "kind": "LinkedField",
  "name": "accounts",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "identities",
      "kind": "LinkedField",
      "name": "identities",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "profilesConnection",
          "kind": "LinkedField",
          "name": "profilesCollection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "profilesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "profiles",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "username",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PostActionsStatsTokenFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allow_comments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail_uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editions_minted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "metadata_uri",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "artifact_uri",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "tokens_reactionsConnection",
      "kind": "LinkedField",
      "name": "tokens_reactionsCollection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "tokens_reactionsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "tokens_reactions",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reaction",
                  "storageKey": null
                },
                (v2/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "tezos_tokens",
  "abstractKey": null
};
})();

(node as any).hash = "2a31060942f03c920ae7654d92e59f5f";

export default node;
