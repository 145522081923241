import React, {Suspense} from 'react';
import styles from '@/view/styles/components/MobileNav/MobileNav.module.scss';
import MobileDrawer, {MobileDrawerLoader} from './MobileDrawer';
import Button from '../Button';
import RemixIcon from '../RemixIcon';
import {CreateDialog} from '../CMDk/Create/CreateDialog';
import {useRouter} from 'next/router';
import clsx from 'clsx';
import Link from 'next/link';
import {useNotifications} from '@/state/hooks/notifications';
import {PROFILE_LOGIN_MESSAGE} from '@/content';
import {useLoginModalActions} from '@/state/hooks/auth/loginModal';
import {useAccount} from '@/kits/account-kit/src';
import {graphql, useLazyLoadQuery} from '@/kits/relay-kit/src';
import {Avatar} from '../Avatar';
import {getImageUrl} from '@/utils/conversions/conversions';
import SearchDialog from '../CMDk/Search/SearchDialog';
import {useOnchainActionStatus} from '@/state/hooks/onChainActionStatus';
import Skeleton from '../Skeleton';
import type {MobileNavProfileButtonQuery as MobileNavProfileButtonQueryType} from './__generated__/MobileNavProfileButtonQuery.graphql';

export default function MobileNav({
  loggedIn,
  identityId,
}: {
  loggedIn: boolean;
  identityId: string;
}) {
  const router = useRouter();
  const currentRoute = router.asPath;

  const hideCreateFAB = () => {
    if (
      router.asPath === '/create' ||
      router.asPath.startsWith('/inbox') ||
      router.asPath === '/onboarding' ||
      router.asPath === '/settings' ||
      router.pathname === '/item/[itemId]' ||
      router.pathname === '/[id]/item/[itemId]'
    ) {
      return true;
    }
    return false;
  };

  const {unreadMessageCount} = useNotifications();

  const {actions} = useOnchainActionStatus();

  return (
    <>
      <div
        className={styles.bottomNav}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        // data-has-top-banner={showTopBanner}
      >
        <div>
          <Button icon onClick={() => {}}>
            <Link href="/">
              <RemixIcon
                icon={`home-${
                  currentRoute === '/' || currentRoute.startsWith('/#')
                    ? 'fill'
                    : 'line'
                }`}
                size={24}
              />
            </Link>
          </Button>
          <SearchDialog
            trigger={
              <Button
                icon
                onClick={() => {
                  router.push(
                    {pathname: router.pathname, query: {...router.query}},
                    router.asPath + '#',
                    {
                      shallow: true,
                    }
                  );
                }}
              >
                <RemixIcon icon="search-2-line" size={24} />
              </Button>
            }
            onClose={() => {}}
          />
          <Button icon className={styles.withUnreadCount}>
            <Link href="/collections">
              <>
                <RemixIcon icon="stack-line" size={24} />
              </>
            </Link>
          </Button>
          <Button icon onClick={() => {}}>
            <Link href="/wallet">
              <RemixIcon
                icon={`wallet-${
                  currentRoute.startsWith('/wallet') ? 'fill' : 'line'
                }`}
                size={24}
              />
            </Link>
          </Button>
          <Suspense
            fallback={
              <Skeleton
                variant="circle"
                animation="wave"
                width={32}
                height={32}
              />
            }
          >
            <ProfileButton
              identityId={identityId}
              currentRoute={currentRoute}
            />
          </Suspense>
        </div>
      </div>
      <Suspense fallback={<MobileDrawerLoader />}>
        <MobileDrawer identityId={identityId} />
      </Suspense>
      {loggedIn && (
        <CreateDialog
          trigger={
            <Button
              onClick={() => {
                router.push(
                  {pathname: router.pathname, query: {...router.query}},
                  router.asPath + '#',
                  {
                    shallow: true,
                  }
                );
              }}
              accent
              icon
              className={clsx(
                styles.fab,
                hideCreateFAB() && styles.hideFab,
                actions.length > 0 && styles.translateFab
              )}
            >
              <RemixIcon icon="add-line" size={40} />
            </Button>
          }
        />
      )}
    </>
  );
}

const MobileNavProfileButtonQuery = graphql`
  query MobileNavProfileButtonQuery($id: BigInt!) {
    identitiesCollection(filter: {id: {eq: $id}}) {
      edges {
        node {
          profilesCollection {
            edges {
              node {
                username
                avatar_uri
              }
            }
          }
        }
      }
    }
  }
`;

const ProfileButton = ({
  identityId,
  currentRoute,
}: {
  identityId?: string;
  currentRoute: string;
}) => {
  const {getAccount} = useAccount();
  const user = getAccount();

  const {data} = useLazyLoadQuery<MobileNavProfileButtonQueryType>(
    MobileNavProfileButtonQuery,
    {
      id: identityId || '',
    },
    {
      skip: !identityId || identityId === '',
    }
  );

  return (
    <Button
      icon
      onClick={e => {
        if (!user.isLoggedIn) {
          e.preventDefault();
          e.stopPropagation();
          useLoginModalActions.setShowLoginModal(true, PROFILE_LOGIN_MESSAGE);
        }
      }}
    >
      <Link
        href={`/${
          user.isLoggedIn
            ? data?.identitiesCollection?.edges?.[0]?.node?.profilesCollection
                ?.edges?.[0]?.node?.username || user.identityId
            : '#'
        }`}
      >
        {!!data?.identitiesCollection?.edges?.[0]?.node?.profilesCollection
          ?.edges?.[0]?.node?.avatar_uri ? (
          <Avatar
            src={getImageUrl(
              data?.identitiesCollection?.edges?.[0]?.node?.profilesCollection
                ?.edges?.[0]?.node?.avatar_uri
            )}
            alt=""
            className={styles.avatar}
          >
            <RemixIcon
              icon={`user-3-${
                currentRoute ===
                `/${data?.identitiesCollection?.edges?.[0]?.node?.profilesCollection?.edges?.[0]?.node?.username}`
                  ? 'fill'
                  : 'line'
              }`}
              size={24}
            />
          </Avatar>
        ) : (
          <RemixIcon
            icon={`user-3-${
              currentRoute ===
              `/${data?.identitiesCollection?.edges?.[0]?.node?.profilesCollection?.edges?.[0]?.node?.username}`
                ? 'fill'
                : 'line'
            }`}
            size={24}
          />
        )}
      </Link>
    </Button>
  );
};
