/**
 * @generated SignedSource<<a1ddef1d264d1c1d5f01ca3d78261bdd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NFTPageItemHeaderTokenFragment$data = {
  readonly accounts: {
    readonly id: string;
    readonly identities: {
      readonly id: string;
      readonly nodeId: string;
      readonly profilesCollection: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly id: string;
            readonly nodeId: string;
            readonly username: string | null;
          };
        }>;
      } | null;
    };
    readonly nodeId: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserProfileItemFragment">;
  };
  readonly " $fragmentType": "NFTPageItemHeaderTokenFragment";
};
export type NFTPageItemHeaderTokenFragment$key = {
  readonly " $data"?: NFTPageItemHeaderTokenFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NFTPageItemHeaderTokenFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NFTPageItemHeaderTokenFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "identities",
          "kind": "LinkedField",
          "name": "identities",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "profilesConnection",
              "kind": "LinkedField",
              "name": "profilesCollection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "profilesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "profiles",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/),
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "username",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserProfileItemFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "tezos_tokens",
  "abstractKey": null
};
})();

(node as any).hash = "997c4231bf99e47e52275f98f86a0d43";

export default node;
