import {enum_denomination_type} from '@/graphql/__generated__/tezosTokensFragment.graphql';
import {RightsEnum} from './mintTypes';

type Link = string;

export type TabType = {
  label?: React.ReactNode;
  value: string;
  filter?: (item: any) => boolean;
};

export type ActionType = {
  key?: string;
  onClick?: (() => void) | undefined;
  href?: string | undefined;
  doNotClose?: boolean | undefined;
  setPage?: number | undefined;
  shortcut?: React.ReactNode;
  className?: string;
  type?: string;
  override: boolean;
} & (
  | {
      override: false;
      icon: React.ReactNode;
      title: string;
    }
  | {
      override: true;
      element: React.ReactNode;
    }
);

export enum MimeType {
  'IMAGE' = 1,
  'AUDIO',
  'VIDEO',
  'APPLICATION',
  'MODEL-VIEWER',
  'TEXT',
}

export type CMDkActionsType =
  | 'token'
  | 'tokens'
  | 'user'
  | 'list'
  | 'blog'
  | 'track'
  | 'comment'
  | undefined;

export interface MenuOptionType {
  label: React.ReactNode;
  value: string;
  icon: React.ReactNode;
  onClick?: ((e: React.MouseEvent<HTMLDivElement>) => void) | undefined;
  href?: string | undefined;
  shortcut?: string | undefined;
  separator?: string | undefined;
  disabled?: boolean | undefined;
  disabledTooltip?: string | undefined;
  tooltip?: string | undefined;
  noHighlight?: boolean | undefined;
}

export interface LoginProviderType {
  name: string;
  icon: React.ReactNode;
  onClick: (connected: boolean) => any | void;
}

// CARD TYPES

export type HeaderActionType =
  | 'actions'
  | 'scroll'
  | 'more'
  | 'activity'
  | 'none';

export type CardHeaderType<HeaderType extends HeaderActionType> = {
  title: string | React.ReactNode;
  onTitleClick?: Link | (() => void);
  // more?: boolean;
  action?: HeaderType;
  loading?: boolean;
  fullWidth?: boolean;
  fullWidthPadding?: 'sm' | 'md' | 'lg';
} & (HeaderType extends 'actions'
  ? {
      onActionClick?: () => void;
    }
  : HeaderType extends 'scroll'
  ? {
      onScrollLeft: () => void;
      onScrollRight: () => void;
      scrollStart: boolean;
      scrollEnd: boolean;
    }
  : HeaderType extends 'more'
  ? {
      onMore: Link | (() => void);
      moreText?: string | 'View all';
    }
  : HeaderType extends 'activity'
  ? {
      date: string;
      transactionHash: string;
    }
  : {});

export type LabelsPosition = 'off' | 'bottom' | 'right';

export type ItemDataType = {
  title: string;
  description: string;
  file: File | undefined;
  fileDuration: string | undefined;
  multipleFiles: File[] | undefined;
  cover: File | undefined;
  editions: number;
  allowComments: boolean;
  allowCollecting: boolean;
  royalty: number;
  royaltySplits: {
    user: {
      address: string;
      username: string;
      avatar: string;
    };
    split: number;
  }[];
  coCreators: {
    address: string;
    username: string;
    avatar: string;
  }[];
  attributes: {
    name: string;
    value: string;
  }[];
  tags: string[];
  license: RightsEnum;
  photosensitiveWarning: boolean;
  nsfw: boolean;
  price: number | undefined;
  price_denomination: enum_denomination_type;
};
