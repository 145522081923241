/**
 * @generated SignedSource<<abb77305202f28661a33aedd1581d363>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useCreateNotificationsForMentionsGetAccountsFromUsernamesQuery$variables = {
  usernames: ReadonlyArray<string>;
};
export type useCreateNotificationsForMentionsGetAccountsFromUsernamesQuery$data = {
  readonly profilesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly identities: {
          readonly accountsCollection: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly id: string;
              };
            }>;
          } | null;
        };
        readonly nodeId: string;
      };
    }>;
  } | null;
};
export type useCreateNotificationsForMentionsGetAccountsFromUsernamesQuery = {
  response: useCreateNotificationsForMentionsGetAccountsFromUsernamesQuery$data;
  variables: useCreateNotificationsForMentionsGetAccountsFromUsernamesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "usernames"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "in",
            "variableName": "usernames"
          }
        ],
        "kind": "ObjectValue",
        "name": "username"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateNotificationsForMentionsGetAccountsFromUsernamesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "profilesConnection",
        "kind": "LinkedField",
        "name": "profilesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "profilesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "profiles",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "identities",
                    "kind": "LinkedField",
                    "name": "identities",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "accountsConnection",
                        "kind": "LinkedField",
                        "name": "accountsCollection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "accountsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "accounts",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateNotificationsForMentionsGetAccountsFromUsernamesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "profilesConnection",
        "kind": "LinkedField",
        "name": "profilesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "profilesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "profiles",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "identities",
                    "kind": "LinkedField",
                    "name": "identities",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "accountsConnection",
                        "kind": "LinkedField",
                        "name": "accountsCollection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "accountsEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "accounts",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c52a04bee1789b608507caed4e4a02e4",
    "id": null,
    "metadata": {},
    "name": "useCreateNotificationsForMentionsGetAccountsFromUsernamesQuery",
    "operationKind": "query",
    "text": "query useCreateNotificationsForMentionsGetAccountsFromUsernamesQuery(\n  $usernames: [String!]!\n) {\n  profilesCollection(filter: {username: {in: $usernames}}) {\n    edges {\n      node {\n        id\n        nodeId\n        identities {\n          accountsCollection {\n            edges {\n              node {\n                id\n                nodeId\n              }\n            }\n          }\n          nodeId\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "443bc53a89753f9866973c069b323136";

export default node;
