import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation insertAccountMutation($input: [accountsInsertInput!]!) {
    insertIntoaccountsCollection(objects: $input) {
      records {
        id
      }
    }
  }
`;
