import {
  packDataBytes,
  Parser,
  assertMichelsonData,
  assertMichelsonType,
} from '@taquito/michel-codec';
import {
  OpKind,
  OperationBatch,
  TezosToolkit,
  WalletOperationBatch,
} from '@taquito/taquito';
import config from '../config.json';
import {PublicKey, PublicKeyHash} from './types';
import {ConvertFromMutez, ConvertToMutez, ToHex} from './utils';

const EXPIRATION = 31536000000;

export async function MakeAcceptOfferConfig(
  address: PublicKeyHash,
  offerId: string
) {
  if (!address || !offerId) throw new Error('missing or invalid parameters');

  const p = new Parser();
  const params = p.parseMichelineExpression(
    `(Pair
      ${offerId}
      { Elt "${address}" 1})`
  );

  if (!params) return;

  return {
    kind: OpKind.TRANSACTION,
    to: config.marketPlaceContract,
    amount: 0,
    fee: 5000,
    parameter: {
      entrypoint: 'accept_offer',
      value: params,
    },
  };
}

export async function MakeAddMarketplaceOperatorConfig(
  address: PublicKeyHash,
  tokenId: number
) {
  if (!address || tokenId === undefined)
    throw new Error('missing or invalid parameters');

  const p = new Parser();
  const params = p.parseMichelineExpression(
    `{Left
      (Pair "${address}"
        (Pair "${config.marketPlaceContract}" ${tokenId}))}`
  );

  if (!params) return;

  return {
    kind: OpKind.TRANSACTION,
    to: config.mintingContract,
    amount: 0,
    fee: 5000,
    parameter: {
      entrypoint: 'update_operators',
      value: params,
    },
  };
}

export function MakeAdminTranscript(
  author: PublicKey,
  beneficiary: PublicKeyHash,
  ipfsCid: string,
  listPriceMutez: number,
  maxEditions: number
) {
  const p = new Parser();
  const michelsonType = p.parseMichelineExpression(
    `(pair address (map string bytes) key mutez address nat timestamp)`
  );
  const michelsonData = p.parseMichelineExpression(
    `(Pair
       "${config.mintingContract}"
       { Elt "" 0x${ToHex(ipfsCid)} }
       "${author}"
       ${listPriceMutez} 
       "${beneficiary}"
       ${maxEditions.toString()} 
       ${EXPIRATION.toString()} 
       )`
  );

  if (
    !assertMichelsonData(michelsonData!) ||
    !assertMichelsonType(michelsonType!)
  )
    throw new Error('missing or invalid parameters');

  return packDataBytes(michelsonData, michelsonType).bytes;
}

export async function MakeBuyConfig(amount: number, saleId: string) {
  if (amount === undefined || !saleId)
    throw new Error('missing or invalid parameters');

  const p = new Parser();
  const params = p.parseMichelineExpression(
    `(Pair
      ${saleId}
      None
      None)`
  );

  if (!params) return;

  return {
    kind: OpKind.TRANSACTION,
    to: config.marketPlaceContract,
    amount: amount,
    fee: 5000,
    parameter: {
      entrypoint: 'buy',
      value: params,
    },
  };
}

export async function MakeCancelOfferConfig(offerId: string) {
  if (!offerId) throw new Error('missing or invalid parameters');

  const p = new Parser();
  const params = p.parseMichelineExpression(`${offerId}`);

  if (!params) return;

  return {
    kind: OpKind.TRANSACTION,
    to: config.marketPlaceContract,
    amount: 0,
    fee: 5000,
    parameter: {
      entrypoint: 'cancel_offer',
      value: params,
    },
  };
}

export async function MakeCancelConfig(saleId: string) {
  if (!saleId) throw new Error('missing or invalid parameters');

  const p = new Parser();
  const params = p.parseMichelineExpression(`${saleId}`);

  if (!params) return;

  return {
    kind: OpKind.TRANSACTION,
    to: config.marketPlaceContract,
    amount: 0,
    fee: 5000,
    parameter: {
      entrypoint: 'cancel',
      value: params,
    },
  };
}

export async function MakeCreateOfferConfig(
  contractAddress: string,
  tokenId: number
) {
  if (!contractAddress || tokenId === undefined)
    throw new Error('missing or invalid parameters');

  const p = new Parser();
  const params = p.parseMichelineExpression(
    `(Pair
      (Pair "${contractAddress}" ${tokenId})
      None)`
  );

  if (!params) return;

  return {
    kind: OpKind.TRANSACTION,
    to: config.marketPlaceContract,
    amount: 0,
    fee: 5000,
    parameter: {
      entrypoint: 'create_offer',
      value: params,
    },
  };

  /*
  const transactionResponseWithOfferId: TransactionResponseWithOfferId = {
    operation: transactionResponse.operation,
    operationHash: transactionResponse.operationHash,
    offerId: transactionResponse.operation.operationResults[0].metadata.operation_result.lazy_storage_diff[0].diff.updates[0].key.int
  };
  */
}

export async function MakeRemoveMarketplaceOperatorConfig(
  address: PublicKeyHash,
  tokenId: number
) {
  if (!address || tokenId === undefined)
    throw new Error('missing or invalid parameters');

  const p = new Parser();
  const params = p.parseMichelineExpression(
    `{Left
      (Pair "${address}"
        (Pair "${config.marketPlaceContract}" ${tokenId}))}`
  );

  if (!params) return;

  return {
    kind: OpKind.TRANSACTION,
    to: config.mintingContract,
    amount: 0,
    fee: 5000,
    parameter: {
      entrypoint: 'update_operators',
      value: params,
    },
  };
}

export async function MakeSellConfig(
  address: PublicKeyHash,
  amount: number,
  contractAddress: string,
  price: number,
  tokenId: number
) {
  if (
    !address ||
    !amount ||
    !contractAddress ||
    !price ||
    tokenId === undefined
  )
    throw new Error('missing or invalid parameters');

  // Note: This needs to be extended to fully support royalties,
  // referrals, and other features offered by the marketplace contract.
  const p = new Parser();
  const params = p.parseMichelineExpression(
    `(Pair
      (Pair "${contractAddress}" ${tokenId})
      ${ConvertToMutez(price)}
      ${amount}
      0
      None
      (Some (Pair 10000 { Elt "${address}" 1000}))
      None
      None)`
  );

  if (!params) return;

  return {
    kind: OpKind.TRANSACTION,
    to: config.marketPlaceContract,
    amount: 0,
    fee: 5000,
    parameter: {
      entrypoint: 'sell',
      value: params,
    },
  };
}

export async function MakeLazyMintConfig(
  author: PublicKey,
  beneficiary: PublicKeyHash,
  dbTokenId: string,
  ipfsCid: string,
  listPriceMutez: number,
  maxEditions: number,
  recipient: PublicKeyHash
) {
  const signatureResults = await fetch('/api/mint/sign', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({dbTokenId: dbTokenId}),
  }).then(res => res.json());

  if (!signatureResults.success) {
    throw new Error(signatureResults.error);
  }

  const signature = signatureResults.data.signature;
  const fullIpfsCid = `ipfs://${ipfsCid}`;
  const p = new Parser();
  const contractParams = p.parseMichelineExpression(
    `(Pair { Elt "" 0x${ToHex(fullIpfsCid)}}
                 "${author}"
                 "${beneficiary}"
                 "${recipient}"
                 ${maxEditions.toString()}
                 "${EXPIRATION.toString()}"
                 "${signature}")`
  );

  if (!contractParams)
    throw new Error('missing or invalid contract parameters');

  return {
    kind: OpKind.TRANSACTION,
    to: config.mintingContract,
    amount: ConvertFromMutez(listPriceMutez),
    fee: 5000,
    parameter: {
      entrypoint: 'materialize',
      value: contractParams,
    },
  };
}

export async function SubmitTransactions(
  tezos: TezosToolkit,
  transactionConfigs: any[],
  walletType: string
) {
  if (!tezos || !transactionConfigs || !walletType)
    throw new Error('missing or invalid parameters');

  let batch: WalletOperationBatch | OperationBatch;

  switch (walletType) {
    case 'beacon':
      batch = tezos.wallet.batch(transactionConfigs);
      break;
    case 'magic':
      batch = tezos.contract.batch(transactionConfigs);
      break;
    default:
      throw new Error('invalid wallet type');
  }

  return batch.send();
}

export async function TransferTokenToAddress(
  tezos: TezosToolkit,
  address: string,
  amount: number,
  tokenId: string,
  burn: boolean = false
) {
  const transferParams = [
    {
      from_: await tezos.wallet.pkh(),
      txs: [
        {
          to_: burn ? 'tz1burnburnburnburnburnburnburjAYjjX' : address,
          token_id: tokenId,
          amount: amount,
        },
      ],
    },
  ];

  const contract = await tezos.wallet.at(config.mintingContract);
  return contract.methods.transfer(transferParams).send();
}

export async function MakeTransferTokenToAddressConfig(
  address: PublicKeyHash,
  toAddress: PublicKeyHash,
  tokenId: number,
  amount: number = 1,
  burn: boolean = false
) {
  if (!address || tokenId === undefined || amount === undefined)
    throw new Error('missing or invalid parameters');

  const p = new Parser();
  const params = p.parseMichelineExpression(
    `(Pair "${address}"
      (Pair "${
        burn ? "tz1burnburnburnburnburnburnburjAYjjX" : toAddress
      }" (Pair ${tokenId} ${amount})))`
  );

  if (!params) return;

  return {
    kind: OpKind.TRANSACTION,
    to: config.mintingContract,
    fee: 5000,
    parameter: {
      entrypoint: 'transfer',
      value: params,
    },
  };
}

export async function MakeSendTezosToAddressConfig(
  address: PublicKeyHash,
  amount: number
) {
  if (!address || amount === undefined)
    throw new Error('missing or invalid parameters');

  return {
    kind: OpKind.TRANSACTION,
    to: address,
    amount: amount,
  };
}

export namespace Blockchain {
  const api = 'https://api.tzkt.io';

  export async function getTokenBalancesByAddress(address: PublicKeyHash, contract: PublicKeyHash): Promise<any> {
    if (!address || !contract) throw new Error('missing or invalid parameters');

    const response = await fetch(`${api}/v1/tokens/balances?account=${address}&token.contract=${contract}`);

    return response.json();
  }

  export async function getTransfersByAddress(address: PublicKeyHash): Promise<any> {
    if (!address) throw new Error('missing or invalid parameters');

    const response = await fetch(`${api}/v1/operations/transactions?anyof.sender.target=${address}`);

    return response.json();
  }
}
