import React, {Suspense, useCallback} from 'react';
import styles from '@/view/styles/components/ManageItem/EditListing/EditListingDialog.module.scss';
import BottomSheet from '@/view/components/BottomSheet/BottomSheet';
import EditListing from './EditListing';
import {EditListingDialogTokenFragment$key} from './__generated__/EditListingDialogTokenFragment.graphql';
import {graphql, useFragment} from '@/kits/relay-kit/src';

const EditListingDialogTokenFragment = graphql`
  fragment EditListingDialogTokenFragment on tezos_tokens {
    ...EditListingTokenFragment
  }
`;
export const EditListingDialog = ({
  trigger,
  tokenKey,
}: {
  trigger: React.ReactNode;
  tokenKey: EditListingDialogTokenFragment$key;
}) => {
  const token = useFragment(EditListingDialogTokenFragment, tokenKey);
  return (
    <div>
      <BottomSheet
        dialogOnDesktop
        trigger={trigger}
        className={styles.bottomSheet}
        handleOnClose={(isPopState?: boolean) => {}}
      >
        {useCallback(
          (closeDialog: () => void) => (
            <Suspense>
              <EditListing
                tokenKey={token}
                className={styles.modal}
                closeDialog={closeDialog}
              />
            </Suspense>
          ),
          [token]
        )}
      </BottomSheet>
    </div>
  );
};
