/**
 * @generated SignedSource<<70a0c82702d5a4fdebd3c559bd043c9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type beacon_accountsInsertInput = {
  account_id?: string | null;
  created_at?: string | null;
  public_key?: string | null;
  public_key_hash?: string | null;
  updated_at?: string | null;
};
export type insertBeaconAccountMutation$variables = {
  input: ReadonlyArray<beacon_accountsInsertInput>;
};
export type insertBeaconAccountMutation$data = {
  readonly insertIntobeacon_accountsCollection: {
    readonly records: ReadonlyArray<{
      readonly account_id: string;
      readonly public_key_hash: string;
    }>;
  } | null;
};
export type insertBeaconAccountMutation = {
  response: insertBeaconAccountMutation$data;
  variables: insertBeaconAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "public_key_hash",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "account_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "insertBeaconAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "beacon_accountsInsertResponse",
        "kind": "LinkedField",
        "name": "insertIntobeacon_accountsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "beacon_accounts",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "insertBeaconAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "beacon_accountsInsertResponse",
        "kind": "LinkedField",
        "name": "insertIntobeacon_accountsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "beacon_accounts",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodeId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "072856cb84257ba87b755cf3b14885da",
    "id": null,
    "metadata": {},
    "name": "insertBeaconAccountMutation",
    "operationKind": "mutation",
    "text": "mutation insertBeaconAccountMutation(\n  $input: [beacon_accountsInsertInput!]!\n) {\n  insertIntobeacon_accountsCollection(objects: $input) {\n    records {\n      public_key_hash\n      account_id\n      nodeId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "10a6d711ed516091007f8d5c5ab063bb";

export default node;
