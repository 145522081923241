import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation insertCommentMutation($input: [commentsInsertInput!]!) {
    insertIntocommentsCollection(objects: $input) {
      records {
        nodeId
        id
        created_at
        updated_at
        content
        accounts {
          nodeId
          id
          ...accountsFragment
        }
        parent_comment_id
        tezos_token_id
        comments_reactionsCollection {
          edges {
            node {
              nodeId
              id
              reaction
              accounts {
                id
                nodeId
                identities {
                  profilesCollection {
                    edges {
                      node {
                        username
                        avatar_uri
                      }
                    }
                  }
                }
                ...accountsFragment
              }
              ...commentsReactionsFragment
            }
          }
        }
      }
    }
  }
`;
