/**
 * @generated SignedSource<<2ad34215a9a77871f3360f77987ce8c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SendTokenOwnedCountQuery$variables = {
  accountId: string;
  tokenIds: ReadonlyArray<string>;
};
export type SendTokenOwnedCountQuery$data = {
  readonly eventsCollection: {
    readonly totalCount: number;
  } | null;
};
export type SendTokenOwnedCountQuery = {
  response: SendTokenOwnedCountQuery$data;
  variables: SendTokenOwnedCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tokenIds"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "eq",
                    "variableName": "accountId"
                  }
                ],
                "kind": "ObjectValue",
                "name": "account_id"
              },
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "in",
                    "variableName": "tokenIds"
                  }
                ],
                "kind": "ObjectValue",
                "name": "tezos_token_id"
              },
              {
                "kind": "Literal",
                "name": "type",
                "value": {
                  "in": [
                    "mint_tezos_token",
                    "pending_mint_tezos_token",
                    "purchase_tezos_token",
                    "pending_purchase_tezos_token",
                    "collect_for_free",
                    "mint_for_free"
                  ]
                }
              }
            ],
            "kind": "ObjectValue",
            "name": "and"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "eventsConnection",
    "kind": "LinkedField",
    "name": "eventsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendTokenOwnedCountQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendTokenOwnedCountQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ff5411e3ba0ac450ff72f62b5d730013",
    "id": null,
    "metadata": {},
    "name": "SendTokenOwnedCountQuery",
    "operationKind": "query",
    "text": "query SendTokenOwnedCountQuery(\n  $accountId: BigInt!\n  $tokenIds: [BigInt!]!\n) {\n  eventsCollection(filter: {and: {type: {in: [mint_tezos_token, pending_mint_tezos_token, purchase_tezos_token, pending_purchase_tezos_token, collect_for_free, mint_for_free]}, tezos_token_id: {in: $tokenIds}, account_id: {eq: $accountId}}}) {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d59dde9e3077f3719dc2b74e44e4f25";

export default node;
