/**
 * @generated SignedSource<<da2c72661029b3813890567479eed262>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NFTPageItemPostViewTokenFragment$data = {
  readonly accounts: {
    readonly id: string;
    readonly nodeId: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserProfileItemFragment">;
  };
  readonly created_at: string;
  readonly description: string | null;
  readonly id: string;
  readonly mime_type: string;
  readonly nodeId: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"ManageItemTokenFragment" | "NFTPageItemHeaderTokenFragment">;
  readonly " $fragmentType": "NFTPageItemPostViewTokenFragment";
};
export type NFTPageItemPostViewTokenFragment$key = {
  readonly " $data"?: NFTPageItemPostViewTokenFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NFTPageItemPostViewTokenFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NFTPageItemPostViewTokenFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mime_type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "accounts",
      "kind": "LinkedField",
      "name": "accounts",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserProfileItemFragment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "NFTPageItemHeaderTokenFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ManageItemTokenFragment"
    }
  ],
  "type": "tezos_tokens",
  "abstractKey": null
};
})();

(node as any).hash = "fd24b0bf955f51b8924ddb81889a7e37";

export default node;
