/**
 * @generated SignedSource<<9921f64061b5ce656b2b50636dd7403a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FilterIs = "NOT_NULL" | "NULL" | "%future added value";
export type followsFilter = {
  and?: ReadonlyArray<followsFilter> | null;
  created_at?: DatetimeFilter | null;
  followee_id?: BigIntFilter | null;
  follower_id?: BigIntFilter | null;
  id?: BigIntFilter | null;
  nodeId?: IDFilter | null;
  not?: followsFilter | null;
  or?: ReadonlyArray<followsFilter> | null;
  updated_at?: DatetimeFilter | null;
};
export type BigIntFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type DatetimeFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type IDFilter = {
  eq?: string | null;
};
export type removeFollowerMutation$variables = {
  filter: followsFilter;
};
export type removeFollowerMutation$data = {
  readonly deleteFromfollowsCollection: {
    readonly records: ReadonlyArray<{
      readonly followee: {
        readonly id: string;
        readonly nodeId: string;
      };
      readonly follower: {
        readonly id: string;
        readonly nodeId: string;
      };
      readonly id: string;
      readonly nodeId: string;
    }>;
  };
};
export type removeFollowerMutation = {
  response: removeFollowerMutation$data;
  variables: removeFollowerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "followsDeleteResponse",
    "kind": "LinkedField",
    "name": "deleteFromfollowsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "follows",
        "kind": "LinkedField",
        "name": "records",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "identities",
            "kind": "LinkedField",
            "name": "follower",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "identities",
            "kind": "LinkedField",
            "name": "followee",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeFollowerMutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeFollowerMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "aba381d5c01b936fa732ae44dffe21d6",
    "id": null,
    "metadata": {},
    "name": "removeFollowerMutation",
    "operationKind": "mutation",
    "text": "mutation removeFollowerMutation(\n  $filter: followsFilter!\n) {\n  deleteFromfollowsCollection(filter: $filter) {\n    records {\n      follower {\n        id\n        nodeId\n      }\n      followee {\n        id\n        nodeId\n      }\n      id\n      nodeId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "44f4ec06539fb83ab9ef47cf55b80dd6";

export default node;
