import React from 'react';
import styles from '@/view/styles/components/FeedCard/blocs/FeedCardAsset.module.scss';
import Image from '@/view/components/Image';
import {MimeType} from '@/view/types/types';
import clsx from 'clsx';
import {getImageUrl} from '@/utils/conversions/conversions';
import Button from '../../Button';
import {MemoIcon} from '../../utils/MemoChildren';
import {convertGqlMimeTypetoMimeType} from '@/utils/conversions/mimeTypeConversions';
import {tezosTokensFragment$data} from '@/graphql/__generated__/tezosTokensFragment.graphql';
import {
  getImagesFromPostAttributes,
  getLinkDataFromLinkAttributes,
} from '@/utils/conversions/postConversions';
import {LinkItemType, PostImageGridItemType} from '@/view/types/postTypes';
import {FrozenGif} from '../../FrozenGif';
import {trim} from '@/utils/trim';
import TokenPageLink from '../../TokenPageLink';
import {FeedCardAssetTokenFragment$key} from './__generated__/FeedCardAssetTokenFragment.graphql';
import {graphql, useFragment} from '@/kits/relay-kit/src';

const FeedCardAssetTokenFragment = graphql`
  fragment FeedCardAssetTokenFragment on tezos_tokens {
    id
    nodeId
    title
    description
    type
    mime_type
    display_uri
    thumbnail_uri
    attributes
    accounts {
      id
      nodeId
      type
      identities {
        profilesCollection {
          edges {
            node {
              username
            }
          }
        }
      }
      beacon_accountsCollection {
        edges {
          node {
            public_key_hash
          }
        }
      }
      magic_accountsCollection {
        edges {
          node {
            public_key_hash
          }
        }
      }
      teztok_accountsCollection {
        edges {
          node {
            public_key_hash
          }
        }
      }
    }
  }
`;

export default function FeedCardAsset({
  tokenKey,
}: {
  tokenKey?: FeedCardAssetTokenFragment$key;
}) {
  const token = useFragment(FeedCardAssetTokenFragment, tokenKey);

  const isPostType = token?.type === 'post';
  const hasMultipleImages =
    (isPostType &&
      token?.attributes &&
      Boolean((JSON.parse(token.attributes)?.length || 0) > 1)) ||
    false;
  const imagesToShow: PostImageGridItemType[] | undefined = hasMultipleImages
    ? token?.attributes &&
      token.attributes !== '[]' &&
      token.attributes !== '{}'
      ? getImagesFromPostAttributes(JSON.parse(token.attributes))
      : undefined
    : undefined;

  const isLinkType = token?.type === 'link';
  const linkRawData = !isLinkType
    ? undefined
    : JSON.parse(token?.attributes || '[]');
  const linkData: LinkItemType | undefined = !isLinkType
    ? undefined
    : getLinkDataFromLinkAttributes(linkRawData);

  const isAudioType =
    (token && // TODO: REMOVE THIS ONCE TYPE IS IMPLEMENTED FOR TEZTOK DATA
      convertGqlMimeTypetoMimeType(token.mime_type || undefined) === // TODO: REMOVE THIS ONCE TYPE IS IMPLEMENTED FOR TEZTOK DATA
        MimeType.AUDIO) || // TODO: REMOVE THIS ONCE TYPE IS IMPLEMENTED FOR TEZTOK DATA
    token?.type === 'audio';

  const isVideoType =
    (token && // TODO: REMOVE THIS ONCE TYPE IS IMPLEMENTED FOR TEZTOK DATA
      convertGqlMimeTypetoMimeType(token.mime_type || undefined) === // TODO: REMOVE THIS ONCE TYPE IS IMPLEMENTED FOR TEZTOK DATA
        MimeType.VIDEO) || // TODO: REMOVE THIS ONCE TYPE IS IMPLEMENTED FOR TEZTOK DATA
    token?.type === 'video';

  const videoContainerRef = React.useRef<HTMLDivElement | null>(null);
  const videoCanvasRef = React.useRef<HTMLCanvasElement | null>(null);

  if (isAudioType) {
    return (
      <TokenPageLink
        tokenId={token?.id || ''}
        username={
          token?.accounts.identities.profilesCollection?.edges?.[0]?.node
            .username || undefined
        }
        className={styles.musicCard}
      >
        <div className={styles.audioAsset}>
          <Image
            dynamic
            src={getImageUrl(token?.thumbnail_uri || '')}
            alt={token?.title}
            onLoad={e => {
              if (e.currentTarget.parentElement) {
                e.currentTarget.parentElement.style.aspectRatio = `${e.currentTarget.naturalWidth}/${e.currentTarget.naturalHeight}`;
              }
            }}
            onError={e => {
              e.currentTarget.src = '/poster.svg';
            }}
            observe
          />
        </div>
        <div className={styles.musicInfoAndControls}>
          <div>
            <Button icon className={clsx(styles.audioPlayButton)}>
              <MemoIcon Component={'play-circle-fill'} size={68} />
            </Button>
            <div>
              <span>
                {token.accounts.identities?.profilesCollection?.edges?.[0]?.node
                  ?.username ||
                  trim(
                    (token.accounts.type === 'beacon'
                      ? token.accounts.beacon_accountsCollection?.edges?.[0]
                          ?.node?.public_key_hash
                      : token.accounts.type === 'magic'
                      ? token.accounts.magic_accountsCollection?.edges?.[0]
                          ?.node?.public_key_hash
                      : token.accounts.type === 'teztok'
                      ? token.accounts.teztok_accountsCollection?.edges?.[0]
                          ?.node?.public_key_hash
                      : 'Unknown') || 'Unknown'
                  )}
              </span>
              <div>{token.title}</div>
            </div>
          </div>
        </div>
      </TokenPageLink>
    );
  }
  return (
    <TokenPageLink
      tokenId={token?.id || ''}
      username={
        token?.accounts.identities.profilesCollection?.edges?.[0]?.node
          .username || undefined
      }
      className={clsx(
        styles.asset,
        isLinkType && styles.linkAsset,
        convertGqlMimeTypetoMimeType(token?.mime_type || undefined) ===
          MimeType.AUDIO && styles.audioAsset
      )}
      data-has-2-images={imagesToShow?.length === 2}
    >
      <>
        {hasMultipleImages ? (
          <div className={styles.imageGrid} data-count={imagesToShow?.length}>
            {imagesToShow?.map(
              (image: PostImageGridItemType, index: number) => (
                <Image
                  dynamic
                  key={index}
                  src={image.src}
                  alt={image.alt}
                  className={styles.gridImage}
                  onError={e => {
                    e.currentTarget.src = '/poster.svg';
                  }}
                />
              )
            )}
          </div>
        ) : (
          <>
            {isVideoType ? (
              <FrozenGif
                componentProps={{
                  imgProps: {
                    src: getImageUrl(token?.thumbnail_uri || ''),
                    alt: token?.title,
                    onError: e => {
                      e.currentTarget.src = '/poster.svg';
                    },
                    onLoad: async e => {
                      const img = e.currentTarget;
                      const w = img.naturalWidth;
                      const h = img.naturalHeight;
                      const container = videoContainerRef.current;
                      if (container) {
                        container.style.aspectRatio = `${w}/${h}`;
                      }
                      const canvas = videoCanvasRef.current;
                      if (canvas) {
                        canvas.width = w;
                        canvas.height = h;
                        const ctx = canvas.getContext('2d');
                        if (ctx) {
                          ctx.drawImage(img, 0, 0, w, h);
                        }
                      }
                    },
                    hidden: true,
                  },
                  containerProps: {
                    ref: videoContainerRef,
                    className: styles.audioPlayer,
                  },
                  canvasProps: {
                    ref: videoCanvasRef,
                    className: styles.image,
                  },
                }}
                src={getImageUrl(token?.display_uri || '')}
                fill
              />
            ) : (
              <Image
                dynamic
                src={getImageUrl(token?.display_uri || '')}
                alt={token?.title}
                onLoad={e => {
                  if (e.currentTarget.src.includes('poster')) {
                    return;
                  }
                  if (e.currentTarget.parentElement) {
                    e.currentTarget.parentElement.style.aspectRatio = `${e.currentTarget.naturalWidth}/${e.currentTarget.naturalHeight}`;
                  }
                }}
                onError={e => {
                  e.currentTarget.src = '/poster.svg';
                }}
                observe
              />
            )}
            {isLinkType && (
              <div className={styles.linkInfo}>
                <div>
                  <span>{linkData?.url.split('/')[2]}</span>
                  <div>{linkData?.title}</div>
                </div>
                <div>{linkData?.description}</div>
              </div>
            )}
          </>
        )}
      </>
      {isVideoType && (
        <Button
          icon
          className={clsx(styles.playButton, styles.playButtonCentered)}
        >
          <MemoIcon Component={'play-fill'} size={120} />
        </Button>
      )}
    </TokenPageLink>
  );
}
