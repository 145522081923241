/**
 * @generated SignedSource<<93c08a8a93cc9190e173cdfebcee3f87>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PlaylistsSelectorPlaylistsQuery$variables = {
  accountId: string;
  identityId: string;
};
export type PlaylistsSelectorPlaylistsQuery$data = {
  readonly collabs: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly nodeId: string;
        readonly playlists: {
          readonly id: string;
          readonly image_uri: string | null;
          readonly nodeId: string;
          readonly title: string;
        };
      };
    }>;
  } | null;
  readonly created: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly image_uri: string | null;
        readonly nodeId: string;
        readonly title: string;
      };
    }>;
  } | null;
};
export type PlaylistsSelectorPlaylistsQuery = {
  response: PlaylistsSelectorPlaylistsQuery$data;
  variables: PlaylistsSelectorPlaylistsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "identityId"
},
v2 = {
  "kind": "Literal",
  "name": "first",
  "value": 5
},
v3 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": {
    "updated_at": "DescNullsLast"
  }
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "image_uri",
    "storageKey": null
  }
],
v7 = [
  {
    "alias": "created",
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "eq",
                "variableName": "identityId"
              }
            ],
            "kind": "ObjectValue",
            "name": "identity_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      },
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "concreteType": "playlistsConnection",
    "kind": "LinkedField",
    "name": "playlistsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "playlistsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "playlists",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": "collabs",
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "eq",
                "variableName": "accountId"
              }
            ],
            "kind": "ObjectValue",
            "name": "account_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      },
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "concreteType": "playlists_accountsConnection",
    "kind": "LinkedField",
    "name": "playlists_accountsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "playlists_accountsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "playlists_accounts",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "playlists",
                "kind": "LinkedField",
                "name": "playlists",
                "plural": false,
                "selections": (v6/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PlaylistsSelectorPlaylistsQuery",
    "selections": (v7/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PlaylistsSelectorPlaylistsQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "02c65f94dc4e7bef1c7d331244587041",
    "id": null,
    "metadata": {},
    "name": "PlaylistsSelectorPlaylistsQuery",
    "operationKind": "query",
    "text": "query PlaylistsSelectorPlaylistsQuery(\n  $identityId: BigInt!\n  $accountId: BigInt!\n) {\n  created: playlistsCollection(filter: {identity_id: {eq: $identityId}}, first: 5, orderBy: {updated_at: DescNullsLast}) {\n    edges {\n      node {\n        id\n        nodeId\n        title\n        image_uri\n      }\n    }\n  }\n  collabs: playlists_accountsCollection(filter: {account_id: {eq: $accountId}}, first: 5, orderBy: {updated_at: DescNullsLast}) {\n    edges {\n      node {\n        id\n        nodeId\n        playlists {\n          id\n          nodeId\n          title\n          image_uri\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "85724d6e3cf8c8ce2a6c529f197bb336";

export default node;
