import React, {Suspense} from 'react';
import Input from '../Input/Input';
import styles from '@/view/styles/components/NewPostCard/NewPostCardDetails.module.scss';
import {Avatar} from '../Avatar';
import {trim} from '@/utils/trim';
import {MemoIcon} from '../utils/MemoChildren';
import {graphql, useFragment, useLazyLoadQuery} from '@/kits/relay-kit/src';
import {getImageUrl} from '@/utils/conversions/conversions';
import {CreateView} from '@/state/hooks/createStatus';
import RemixIcon from '../RemixIcon';
import FollowButton from '../FollowButton';
import {useRouter} from 'next/router';
import {EmojiStyle, SuggestionMode, Theme} from 'emoji-picker-react';
import {emojiCategories} from '@/view/placeholders/placeholderData';
import dynamic from 'next/dynamic';
import Button from '../Button';
import Menu from '../Menu';
import {useDarkMode} from '@/controller/hooks/darkMode';
import {useBreakpoint} from '@/controller/hooks/utils/breakpoint';
import {NewPostCardDetailsHeaderFragment$key} from './__generated__/NewPostCardDetailsHeaderFragment.graphql';
import type {NewPostCardDetailsUsernameSuggestionsQuery as NewPostCardDetailsUsernameSuggestionsQueryType} from './__generated__/NewPostCardDetailsUsernameSuggestionsQuery.graphql';

const EmojiPickerComponent = dynamic(() => import('emoji-picker-react'), {
  ssr: false,
});

export default function NewPostCardDetails({
  title,
  setTitle,
  description,
  setDescription,
  view,
  quoteItem,
  setQuoteItem,
}: {
  title: string;
  setTitle: (title: string) => void;
  description: string;
  setDescription: (description: string) => void;
  view: CreateView;
  quoteItem?: string | null;
  setQuoteItem?: (quoteItem: string | null) => void;
}) {
  const router = useRouter();
  const quote = router.query.quote;
  const tokenIdFromQuery = typeof quote === 'string' ? quote : quote?.[0];

  React.useEffect(() => {
    if (!tokenIdFromQuery) return;
    setQuoteItem?.(tokenIdFromQuery || null);
    router.replace(
      {pathname: router.pathname, query: {...router.query}},
      undefined,
      {shallow: true}
    );
  }, [quote, tokenIdFromQuery]);

  const descriptionRef = React.useRef<HTMLDivElement>(null);

  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!descriptionRef.current) return;
    let currentInput = descriptionRef.current
      .querySelector('div')
      ?.querySelector('textarea');
    let currentDesc = descriptionRef.current;
    if (currentInput) {
      currentInput.style.height = `0px`;
      currentDesc = descriptionRef.current;
      const inputScrollHeight = currentInput.scrollHeight;
      const inputHeight = currentInput.offsetHeight;
      const height =
        inputHeight < inputScrollHeight ? inputScrollHeight : inputHeight;
      currentDesc.style.height = `0px`;
      currentDesc.style.height = `${height + 4}px`;
      currentInput.style.height = `${height + 4}px`;
    }

    return () => {
      if (currentInput) {
        currentInput.style.height = `0px`;
      }
    };
  }, [description, setDescription]);

  const [triggerText, setTriggerText] = React.useState<string>('');

  React.useEffect(() => {
    const current = inputRef.current;
    if (!current) return;

    // if user is typing a username starting with @, we want to trigger the username suggestions, if the word breaks, we want to stop the suggestions
    const handleInput = (e: Event) => {
      const target = e.target as HTMLInputElement;
      const value = target.value;
      const currentWord = value
        .slice(0, target.selectionStart || 0)
        .split(' ')
        .pop();
      const word = currentWord?.split('\n').pop();
      if (word && word.includes('@')) {
        setTriggerText(word);
      } else {
        setTriggerText('');
      }
    };

    current.addEventListener('input', handleInput);

    return () => {
      current.removeEventListener('input', handleInput);
    };
  }, [description, setDescription]);

  const [showEmojiPicker, setShowEmojiPicker] = React.useState<boolean>(false);
  const {darkMode} = useDarkMode();
  const {isMobile} = useBreakpoint();

  return (
    <>
      <div
        className={styles.root}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div>
          {view !== 'post' && (
            <div className={styles.title}>
              <Input
                defaultValue={title}
                id="title"
                placeholder="Enter a title"
                fullWidth
                onChange={val => {
                  setTitle(val);
                }}
                type="text"
              />
            </div>
          )}
          <div className={styles.description} ref={descriptionRef}>
            <Input
              ref={inputRef}
              defaultValue={
                description.split(`https://dns.xyz/item/${tokenIdFromQuery}`)[0]
              }
              id="comment"
              placeholder="What's on your mind?"
              fullWidth
              onChange={val => {
                setDescription(val);
              }}
              multiline
              type="text"
              simple
              rows={2}
            />
            {!isMobile && (
              <Button
                icon
                onClick={() => {
                  setShowEmojiPicker(true);
                }}
                className={styles.emojiPickerToggle}
                tooltip="Emoji"
                tooltipSide="top"
              >
                <Menu
                  side="top"
                  open={showEmojiPicker}
                  removePadding
                  element={close => (
                    <div className={styles.emojiPicker}>
                      <EmojiPickerComponent
                        theme={
                          darkMode ? Theme.DARK : Theme.LIGHT || Theme.AUTO
                        }
                        onEmojiClick={(emojiObject, e) => {
                          const emoji = emojiObject.emoji;
                          const cursorPosition =
                            inputRef.current?.selectionStart;
                          if (cursorPosition) {
                            const newDescription =
                              description.slice(0, cursorPosition) +
                              emoji +
                              description.slice(cursorPosition);
                            setDescription(newDescription);
                            inputRef.current.value = newDescription;
                          } else {
                            setDescription(description + emoji);
                            if (inputRef.current) {
                              inputRef.current.value = description + emoji;
                            }
                          }
                          if (inputRef.current) {
                            inputRef.current.focus();
                            inputRef.current.selectionStart =
                              (cursorPosition || 0) + emoji.length;
                            inputRef.current.selectionEnd =
                              (cursorPosition || 0) + emoji.length;
                          }
                        }}
                        lazyLoadEmojis
                        suggestedEmojisMode={SuggestionMode.FREQUENT}
                        height={400}
                        width={300}
                        previewConfig={{
                          showPreview: false,
                        }}
                        categories={emojiCategories}
                        searchDisabled={false}
                        autoFocusSearch={false}
                        emojiVersion={'12.1'}
                        skinTonesDisabled
                        emojiStyle={EmojiStyle.NATIVE}
                      />
                    </div>
                  )}
                  arrow={false}
                  className={styles.emojiPickerPopper}
                >
                  <div>
                    <RemixIcon icon="chat-smile-3-line" size={16} />
                  </div>
                </Menu>
              </Button>
            )}
          </div>
          {triggerText && triggerText.length > 3 && (
            <Suspense fallback={null}>
              <UsernameSuggestions
                triggerText={triggerText}
                addUsername={(username: string) => {
                  if (inputRef.current) {
                    const cursorPos = inputRef.current.selectionStart || 0;
                    const textBeforeAll = description.slice(0, cursorPos);
                    const textBefore = textBeforeAll.slice(
                      0,
                      textBeforeAll.length - triggerText.length
                    );
                    const textAfter = description.slice(cursorPos);
                    const newText = `${textBefore}${username} ${textAfter}`;
                    inputRef.current.value = newText;
                    setDescription(newText);
                    inputRef.current.focus();
                  }
                  setTriggerText('');
                }}
              />
            </Suspense>
          )}
        </div>
      </div>
    </>
  );
}

const NewPostCardDetailsUsernameSuggestionsQuery = graphql`
  query NewPostCardDetailsUsernameSuggestionsQuery($search: String) {
    profilesCollection(filter: {username: {like: $search}}) {
      edges {
        node {
          id
          username
          name
          avatar_uri
          identities {
            id
            nodeId
            accountsCollection {
              edges {
                node {
                  id
                  nodeId
                  type
                  magic_accountsCollection {
                    edges {
                      node {
                        id
                        nodeId
                        public_key_hash
                      }
                    }
                  }
                  beacon_accountsCollection {
                    edges {
                      node {
                        id
                        nodeId
                        public_key_hash
                      }
                    }
                  }
                  teztok_accountsCollection {
                    edges {
                      node {
                        id
                        nodeId
                        public_key_hash
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UsernameSuggestions = ({
  triggerText,
  addUsername,
  addAddress,
  addIdentityId,
  addAccountId,
  hideMenu,
  setResults,
}: {
  triggerText: string;
  addUsername: (username: string) => void;
  addAddress?: (address: string) => void;
  addIdentityId?: (identityId: string) => void;
  addAccountId?: (accountId: string) => void;
  hideMenu?: boolean;
  setResults?: (
    results: {
      username: string;
      address?: string;
      identityId?: string;
      accountId?: string;
      profilePicture?: string;
    }[]
  ) => void;
}) => {
  const {data: usernameSuggestionsData, isLoading} =
    useLazyLoadQuery<NewPostCardDetailsUsernameSuggestionsQueryType>(
      NewPostCardDetailsUsernameSuggestionsQuery,
      {
        search: `%${triggerText.slice(1)}%`,
      },
      {
        skip: !triggerText || triggerText === '' || triggerText.length < 4,
      }
    );

  React.useEffect(() => {
    if (setResults) {
      setResults(
        usernameSuggestionsData?.profilesCollection?.edges?.map(
          (suggestion: any) => {
            const userAccount =
              suggestion.node.identities.accountsCollection?.edges?.[0]?.node;
            const userAddress =
              userAccount?.type === 'magic'
                ? userAccount.magic_accountsCollection?.edges?.[0]?.node
                    ?.public_key_hash
                : userAccount?.type === 'beacon'
                ? userAccount.beacon_accountsCollection?.edges?.[0]?.node
                    ?.public_key_hash
                : userAccount?.type === 'teztok'
                ? userAccount.teztok_accountsCollection?.edges?.[0]?.node
                    ?.public_key_hash
                : undefined;
            return {
              username: suggestion.node.username,
              address: userAddress,
              identityId: suggestion.node.identities.id,
              accountId:
                suggestion.node.identities.accountsCollection?.edges?.[0]?.node
                  ?.id || '',
              profilePicture: suggestion.node.avatar_uri,
            };
          }
        ) || []
      );

      return () => {
        setResults([]);
      };
    }
  }, [usernameSuggestionsData]);

  return (
    !hideMenu &&
    usernameSuggestionsData?.profilesCollection?.edges?.length !== 0 &&
    !isLoading && (
      <ul className={styles.usernameSuggestions}>
        {usernameSuggestionsData?.profilesCollection?.edges?.map(
          (suggestion, i) => {
            const userAccount =
              suggestion.node.identities.accountsCollection?.edges?.[0]?.node;
            const userAddress =
              userAccount?.type === 'magic'
                ? userAccount.magic_accountsCollection?.edges?.[0]?.node
                    ?.public_key_hash
                : userAccount?.type === 'beacon'
                ? userAccount.beacon_accountsCollection?.edges?.[0]?.node
                    ?.public_key_hash
                : userAccount?.type === 'teztok'
                ? userAccount.teztok_accountsCollection?.edges?.[0]?.node
                    ?.public_key_hash
                : undefined;
            return (
              <div
                className={styles.suggestion}
                key={suggestion.node.username + '-' + i}
                onClick={() => {
                  addUsername(`@${suggestion.node.username}`);
                  userAddress && addAddress?.(userAddress);
                  addAccountId?.(
                    suggestion.node.identities.accountsCollection?.edges?.[0]
                      ?.node?.id || ''
                  );
                  addIdentityId?.(suggestion.node.identities.id);
                }}
              >
                <Avatar
                  src={getImageUrl(suggestion.node.avatar_uri || '')}
                  alt=""
                >
                  <RemixIcon icon="user-line" size={20} />
                </Avatar>
                <div>
                  {!!suggestion.node.name && (
                    <span>{suggestion.node.name}</span>
                  )}
                  <span>
                    {!!suggestion.node.name && '@'}
                    {suggestion.node.username}
                  </span>
                </div>
                <FollowButton status id={suggestion.node.identities.id} />
              </div>
            );
          }
        )}
      </ul>
    )
  );
};

const NewPostCardDetailsHeaderFragment = graphql`
  fragment NewPostCardDetailsHeaderFragment on identities {
    id
    nodeId
    profilesCollection {
      edges {
        node {
          id
          nodeId
          username
          avatar_uri
        }
      }
    }
  }
`;

export function NewPostCardHeader({
  identityKey,
}: {
  identityKey: NewPostCardDetailsHeaderFragment$key;
}) {
  const identity = useFragment(NewPostCardDetailsHeaderFragment, identityKey);

  const username = identity?.profilesCollection?.edges?.[0].node.username
    ? `${identity?.profilesCollection?.edges?.[0].node.username}`
    : trim(identity?.id || '');

  return (
    <div
      className={styles.header}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className={styles.user}>
        {!!identity?.profilesCollection?.edges?.[0].node.avatar_uri ? (
          <Avatar
            src={getImageUrl(
              identity?.profilesCollection?.edges?.[0].node.avatar_uri || ''
            )}
            alt={''}
            className={styles.avatar}
          >
            <MemoIcon Component={'user-line'} size={24} />
          </Avatar>
        ) : (
          <MemoIcon Component={'user-line'} size={24} />
        )}
        <span>{username}</span>
      </div>
    </div>
  );
}
