import React, {useEffect, useState} from 'react';
import styles from '@/view/styles/components/NFTPageItem/NFTPageItem.module.scss';
import Paper from '../Paper';
import {updateTimeSince} from '@/utils/timer';
import {format} from 'timeago.js';
import clsx from 'clsx';
import FollowButton from '../FollowButton';
import {useAccount} from '@/kits/account-kit/src';
import UserProfileItem from '../UserProfileItem';
import {convertGqlMimeTypetoMimeType} from '@/utils/conversions/mimeTypeConversions';
import {MimeType} from '@/view/types/types';
import TextClamp from '../TextClamp';
import QuoteCard from '../FeedCard/QuoteCard';
import {NFTPageItemPostViewTokenFragment$key} from './__generated__/NFTPageItemPostViewTokenFragment.graphql';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import {NFTPageItemHeaderTokenFragment$key} from './__generated__/NFTPageItemHeaderTokenFragment.graphql';
import TimestampTooltip from '../TimestampTooltip';
import ManageItem from '../ManageItem/ManageItem';

const NFTPageItemPostViewTokenFragment = graphql`
  fragment NFTPageItemPostViewTokenFragment on tezos_tokens {
    id
    nodeId
    title
    description
    created_at
    mime_type
    accounts {
      id
      nodeId
      ...UserProfileItemFragment
    }
    ...NFTPageItemHeaderTokenFragment
    ...ManageItemTokenFragment
  }
`;

export default function PostView({
  tokenKey,
  aspectRatio,
  asset,
}: {
  tokenKey: NFTPageItemPostViewTokenFragment$key;
  aspectRatio: {
    width: number;
    height: number;
  };
  asset?: React.ReactNode;
}) {
  const token = useFragment(NFTPageItemPostViewTokenFragment, tokenKey);

  const {getAccount} = useAccount();

  const user = getAccount();
  const identityId = user?.isLoggedIn ? user.identityId : undefined;

  const [age, setAge] = useState('');

  useEffect(() => {
    updateTimeSince(() => {
      setAge(
        format(
          (token?.created_at
            ? new Date(token.created_at).getTime()
            : undefined) || Date.now()
        )
      );
    });
  }, [token?.created_at]);

  const isTextPost =
    convertGqlMimeTypetoMimeType(token.mime_type) === MimeType.TEXT;

  const [quoteItem, setQuoteItem] = React.useState<string | undefined>(
    undefined
  );

  React.useEffect(() => {
    setQuoteItem(undefined);
  }, [token.id]);

  return (
    <>
      <Paper
        className={clsx(
          styles.root,
          !isTextPost && styles.removePadding,
          aspectRatio.width / aspectRatio.height < 0.75
            ? styles.vertical
            : styles.horizontal,
          styles.textPost
        )}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Header tokenKey={token} asset={asset} />
        {token.description && (
          <div
            className={clsx(styles.content)}
            data-has-asset={!isTextPost ? true : false}
          >
            <TextClamp
              text={token.description}
              prefix={null}
              maxChars={Infinity}
              enableUrls
              enableMentions
              enableHashtags
              hideMore
              className={clsx(
                (token.description?.length || 0) > 200 && styles.contentLg,
                (token.description?.length || 0) > 400 && styles.contentXl,
                (token.description?.length || 0) > 600 && styles.contentXxl
              )}
              setQuoteItem={setQuoteItem}
            />
            {quoteItem && !Number.isNaN(Number(quoteItem)) && (
              <QuoteCard
                tokenId={quoteItem}
                parentHasAsset
                className={styles.quoteCard}
              />
            )}
            <TimestampTooltip date={token.created_at} className={styles.age} />
          </div>
        )}
        {!isTextPost && <>{asset}</>}
      </Paper>
      {isTextPost && (
        <ManageItem tokenKey={token} className={styles.assetManageItem} />
      )}
    </>
  );
}

const NFTPageItemHeaderTokenFragment = graphql`
  fragment NFTPageItemHeaderTokenFragment on tezos_tokens {
    accounts {
      id
      nodeId
      identities {
        id
        nodeId
        profilesCollection {
          edges {
            node {
              id
              nodeId
              username
            }
          }
        }
      }
      ...UserProfileItemFragment
    }
  }
`;

const Header = ({
  tokenKey,
  asset,
}: {
  tokenKey: NFTPageItemHeaderTokenFragment$key;
  asset?: React.ReactNode;
}) => {
  const token = useFragment(NFTPageItemHeaderTokenFragment, tokenKey);
  return (
    <div className={styles.header} data-has-asset={asset ? true : false}>
      <div className={styles.by}>
        <UserProfileItem user={token.accounts} hideUsername={true} />
        <FollowButton
          id={token.accounts?.identities.id || ''}
          username={
            token.accounts?.identities.profilesCollection?.edges?.[0].node
              .username || ''
          }
        />
      </div>
    </div>
  );
};
