/**
 * @generated SignedSource<<3150fab1f900bd5837ff2d604e8c3952>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type enum_account_type = "beacon" | "magic" | "teztok" | "%future added value";
export type accountsInsertInput = {
  created_at?: string | null;
  identity_id?: string | null;
  type?: enum_account_type | null;
  updated_at?: string | null;
};
export type insertAccountMutation$variables = {
  input: ReadonlyArray<accountsInsertInput>;
};
export type insertAccountMutation$data = {
  readonly insertIntoaccountsCollection: {
    readonly records: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null;
};
export type insertAccountMutation = {
  response: insertAccountMutation$data;
  variables: insertAccountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "insertAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "accountsInsertResponse",
        "kind": "LinkedField",
        "name": "insertIntoaccountsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "insertAccountMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "accountsInsertResponse",
        "kind": "LinkedField",
        "name": "insertIntoaccountsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nodeId",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "66beda4c96f1b50d6b98770107db92fb",
    "id": null,
    "metadata": {},
    "name": "insertAccountMutation",
    "operationKind": "mutation",
    "text": "mutation insertAccountMutation(\n  $input: [accountsInsertInput!]!\n) {\n  insertIntoaccountsCollection(objects: $input) {\n    records {\n      id\n      nodeId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0207d4503f9788996a4f7cc4fc4f8e64";

export default node;
