import {DependencyList, useEffect, useMemo, useRef, useState} from 'react';

export function useMediaLoaderStatus<
  ElementType extends HTMLImageElement | HTMLIFrameElement | HTMLMediaElement
>({
  resetDependencies,
  elementId,
}: {
  resetDependencies: DependencyList;
  elementId?: string;
}): {
  props: {
    onCanPlay: () => void;
    onLoad: () => void;
    onError: () => void;
    ref: React.RefObject<any>;
  };
  loaded: boolean;
  errored: boolean;
} {
  const [loaded, setLoaded] = useState(false);
  const [errored, setErrored] = useState(false);
  const ref = useRef<ElementType>(null);

  useEffect(() => {
    const element = elementId
      ? document.getElementById(elementId)
      : ref.current;

    if (!element) {
      setLoaded(false);
      setErrored(false);
      return;
    }

    if (element instanceof HTMLImageElement) {
      setLoaded(element.complete);
      setErrored(false);
      if (element.complete) {
        element.decode().catch(() => setErrored(true));
      }
    } else if (element instanceof HTMLIFrameElement) {
      setLoaded(element.contentDocument?.readyState === 'complete');
      setErrored(!element.contentWindow);
    } else if (element instanceof HTMLMediaElement) {
      setLoaded(
        element.readyState === HTMLMediaElement.HAVE_ENOUGH_DATA ||
          element.readyState === HTMLMediaElement.HAVE_FUTURE_DATA ||
          element.readyState === HTMLMediaElement.HAVE_CURRENT_DATA
      );
      setErrored(!!element.error);
    } else {
      setLoaded(false);
      setErrored(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementId, ...resetDependencies]);

  return {
    props: useMemo(
      () => ({
        onCanPlay() {
          setLoaded(true);
        },
        onLoad() {
          setLoaded(true);
        },
        onError() {
          setErrored(true);
        },
        ref,
      }),
      []
    ),
    loaded,
    errored,
  };
}
