import React from 'react';
import styles from '@/view/styles/components/Switch.module.scss';

export default function Switch({
  label,
  checked,
  setChecked,
}: {
  label?: string;
  checked: boolean;
  setChecked: (enabled: boolean) => void;
}) {
  return (
    <form
      onClick={() => {
        setChecked(!checked);
      }}
    >
      <div className={styles.root}>
        {label && <label className={styles.label}>{label}</label>}
        <div className={styles.switch} data-checked={checked}>
          <div className={styles.thumb} data-checked={checked} />
        </div>
      </div>
    </form>
  );
}
