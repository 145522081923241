export enum RightsEnum {
  NONE = 'No License / All Rights Reserved',
  PUBLIC = 'CC0 (Public Domain)',
  CC_BY = 'CC BY',
  CC_BY_SA = 'CC BY-SA',
}
export type FormatType = {
  uri: string;
  mimeType: any;
  fileSize: number;
  fileName: string;
  dimensions?: {
    value: string;
    unit: string;
  };
};
export type RoyaltiesType = {
  decimals: number;
  shares: {
    [key: string]: number;
  };
};
export type metadataImportType = {
  name?: string;
  description?: string;
  tags?: string[];
  editions?: number;

  thumbnailUri: string;
  artifactUri: string;
  displayUri: string;
  image: string;
  formats?: FormatType[];

  minter?: string;
  creators: string[];
  royalties?: RoyaltiesType;

  rights?: RightsEnum;
  nsfw?: boolean;
};
export type MetadataType = {
  name?: string;
  description?: string;
  tags?: string[];
  editions?: number;

  thumbnailUri?: string;
  artifactUri?: string;
  displayUri?: string;
  image?: string;
  formats?: FormatType[];

  mintingTool?: string;
  mintingToolVersion?: string;
  minter?: string;
  creators?: string[];
  royalties?: RoyaltiesType;

  rights?: RightsEnum;
  date?: string;

  symbol?: string;
  decimals?: number;
  shouldPreferSymbol?: boolean;
  isBooleanAmount?: boolean;

  contentRating?: string;
};
export interface IMetadata {
  cid: string;
  editions: number;
}
export const ACCEPTED_FILE_TYPES = [
  'image/jpg',
  'image/jfif',
  'image/pjpeg',
  'image/pjp',
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/webp',
  'image/bmp',
  'image/tiff',
  'image/tif',
  'video/mov',
  'video/mp4',
  'video/m4v',
  'video/mpeg',
  'video/mpg',
  'video/mpe',
  'video/webm',
  'video/quicktime',
  'audio/mpeg',
  'audio/mp3',
  'audio/wav',
];
export const ACCEPTED_ARTWORK_FILE_TYPES = [
  'image/jpg',
  'image/jfif',
  'image/pjpeg',
  'image/pjp',
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/webp',
  'image/bmp',
  'image/tiff',
  'image/tif',
];

export const ACCEPTED_VIDEO_FILE_TYPES = [
  'video/mov',
  'video/mp4',
  'video/m4v',
  'video/mpeg',
  'video/mpg',
  'video/mpe',
  'video/webm',
  'video/quicktime',
];
export const ACCEPTED_AUDIO_FILE_TYPES = [
  'audio/mpeg',
  'audio/mp3',
  'audio/wav',
];
