import {ReactionTo} from '@/controller/hooks/eventRollup';
import React, {useCallback} from 'react';
import styles from '@/view/styles/components/ReactionChips.module.scss';
import Button from './Button';
import Menu from './Menu';
import dynamic from 'next/dynamic';
const EmojiPickerComponent = dynamic(() => import('emoji-picker-react'), {
  ssr: false,
});
import {EmojiStyle, SuggestionMode, Theme} from 'emoji-picker-react';
import {emojiCategories} from '@/view/placeholders/placeholderData';
import RemixIcon from './RemixIcon';
import {useLoginModalActions} from '@/state/hooks/auth/loginModal';
import {REACT_LOGIN_MESSAGE} from '@/content';
import {useMutation} from '@/kits/relay-kit/src';
import insertTokenReactionMutation from '@/graphql/insert-token-reaction';
import type {insertTokenReactionMutation as InsertTokenReactionMutationType} from '@/graphql/__generated__/insertTokenReactionMutation.graphql';
import removeTokenReactionMutation from '@/graphql/remove-token-reaction';
import type {removeTokenReactionMutation as RemoveTokenReactionMutationType} from '@/graphql/__generated__/removeTokenReactionMutation.graphql';
import insertCommentReactionMutation from '@/graphql/insert-comment-reaction';
import type {insertCommentReactionMutation as CommentReactionMutationType} from '@/graphql/__generated__/insertCommentReactionMutation.graphql';
import removeCommentReactionMutation from '@/graphql/remove-comment-reaction';
import type {removeCommentReactionMutation as RemoveCommentReactionMutationType} from '@/graphql/__generated__/removeCommentReactionMutation.graphql';
import insertMessageReactionMutation from '@/graphql/insert-message-reaction';
import type {insertMessageReactionMutation as InsertMessageReactionMutationType} from '@/graphql/__generated__/insertMessageReactionMutation.graphql';
import removeMessageReactionMutation from '@/graphql/remove-message-reaction';
import type {removeMessageReactionMutation as RemoveMessageReactionMutationType} from '@/graphql/__generated__/removeMessageReactionMutation.graphql';
import {useAccount} from '@/kits/account-kit/src';
import clsx from 'clsx';

export default function ReactionChips({
  type,
  reactions,
  id,
  nodeId,
  max,
  hideChips = false,
  showPicker = false,
  label,
  asModal = true,
  className,
  addToReactionsState,
  removeFromReactionsState,
}: {
  type: ReactionTo;
  reactions:
    | {
        id: string;
        by: string;
        byUsername: string;
        reaction: string;
      }[]
    | undefined;
  id: string;
  nodeId: string;
  max?: number;
  hideChips?: boolean;
  showPicker?: boolean;
  label?: React.ReactNode;
  asModal?: boolean;
  className?: string;
  addToReactionsState?: (reaction: string, messageId: string) => void;
  removeFromReactionsState?: (reaction: string, messageId: string) => void;
}) {
  const {getAccount} = useAccount();

  const user = getAccount();
  const wallet = user?.isLoggedIn ? user.accountId : undefined;

  const [uniqueReactions, setUniqueReactions] = React.useState<
    {
      count: number;
      reaction: string;
      reactedBy: string[];
      reactedByMe?: boolean;
    }[]
  >();

  React.useEffect(() => {
    const uniques = reactions
      ?.filter(
        (r, i, arr) => arr.findIndex(a => a.reaction === r.reaction) === i
      )
      .sort((a, b) => b.reaction.localeCompare(a.reaction))
      .map(r => {
        return {
          count: reactions.filter(x => x.reaction === r.reaction).length,
          reaction: r.reaction,
          reactedBy:
            (reactions
              .map(x => x.reaction === r.reaction && x.byUsername)
              ?.filter(x => x !== 'Unknown')
              ?.filter(Boolean) as string[]) || [],
          reactedByMe:
            reactions.some(x => x.reaction === r.reaction && x.by === wallet) ||
            false,
        };
      });

    setUniqueReactions(uniques);
  }, [reactions, wallet]);

  if (type === ReactionTo.Post) {
    return (
      <PostReactionChips
        type={type}
        reactions={reactions}
        uniqueReactions={uniqueReactions}
        tokenId={id}
        tokenNodeId={nodeId}
        max={max}
        hideChips={hideChips}
        showPicker={showPicker}
        label={label}
        asModal={asModal}
        className={className}
      />
    );
  } else if (type === ReactionTo.Comment) {
    return (
      <CommentReactionChips
        type={type}
        reactions={reactions}
        uniqueReactions={uniqueReactions}
        commentId={id}
        commentNodeId={nodeId}
        showPicker={showPicker}
        max={max}
        asModal={asModal}
        className={className}
      />
    );
  } else if (type === ReactionTo.Message) {
    return (
      <MessageReactionChips
        type={type}
        reactions={reactions}
        uniqueReactions={uniqueReactions}
        messageId={id}
        messageNodeId={nodeId}
        max={max}
        asModal={asModal}
        className={className}
        addToReactionsState={addToReactionsState}
        removeFromReactionsState={removeFromReactionsState}
        showPicker={showPicker}
      />
    );
  }
}

const PostReactionChips = ({
  type,
  reactions,
  uniqueReactions,
  tokenId,
  tokenNodeId,
  max = 5,
  hideChips = false,
  showPicker = false,
  label = 'React',
  asModal = true,
  className,
}: {
  type: ReactionTo;
  reactions:
    | {
        id: string;
        by: string;
        byUsername: string;
        reaction: string;
      }[]
    | undefined;
  uniqueReactions:
    | {
        count: number;
        reaction: string;
        reactedBy: string[];
        reactedByMe?: boolean;
      }[]
    | undefined;
  tokenId: string;
  tokenNodeId: string;
  max?: number;
  hideChips?: boolean;
  showPicker?: boolean;
  label?: React.ReactNode;
  asModal?: boolean;
  className?: string;
}) => {
  const {getAccount} = useAccount();

  const user = getAccount();
  const wallet = user?.isLoggedIn ? user.accountId : undefined;

  const [addReaction, {loading: addingReaction}] =
    useMutation<InsertTokenReactionMutationType>(insertTokenReactionMutation);

  const [removeReaction, {loading: removingReaction}] =
    useMutation<RemoveTokenReactionMutationType>(removeTokenReactionMutation);

  const handleReaction = useCallback(
    async (reaction: string) => {
      if (!tokenId) return;
      if (addingReaction || removingReaction) return;
      if (reactions?.find(x => x.reaction === reaction && x.by === wallet)) {
        removeReaction({
          variables: {
            filter: {
              tezos_token_id: {
                eq: tokenId,
              },
              account_id: {
                eq: wallet,
              },
              reaction: {
                eq: reaction,
              },
            },
          },
          updater: store => {
            const affectedMutation = store.getRootField(
              'deleteFromtokens_reactionsCollection'
            );
            const newAddedRecords =
              affectedMutation?.getLinkedRecords('records');
            const newAddedRecord = newAddedRecords?.[0];
            const affectedPost = store.get(tokenNodeId);
            const reactionsCollection = affectedPost?.getLinkedRecord(
              'tokens_reactionsCollection'
            );
            const edges = reactionsCollection?.getLinkedRecords('edges');
            edges?.splice(
              edges.findIndex(
                x =>
                  x?.getLinkedRecord('node')?.getDataID() ===
                  newAddedRecord.getDataID()
              ),
              1
            );
            reactionsCollection?.setLinkedRecords(edges, 'edges');
            affectedPost?.setLinkedRecord(
              reactionsCollection || null,
              'tokens_reactionsCollection'
            );
            store.getRoot().setLinkedRecord(affectedPost || null, tokenNodeId);
          },
        }).then(result => result);
      } else {
        addReaction({
          variables: {
            input: [
              {
                created_at: 'now',
                account_id: wallet,
                tezos_token_id: tokenId,
                reaction: reaction,
                updated_at: 'now',
              },
            ],
          },
          updater: store => {
            const affectedMutation = store.getRootField(
              'insertIntotokens_reactionsCollection'
            );
            const newAddedRecords =
              affectedMutation?.getLinkedRecords('records');
            const newAddedRecord = newAddedRecords?.[0];
            const affectedPost = store.get(tokenNodeId);
            const reactionsCollection = affectedPost?.getLinkedRecord(
              'tokens_reactionsCollection'
            );
            const edges = reactionsCollection?.getLinkedRecords('edges');
            const newEdge = store.create(
              `client:tokens_reaction:${newAddedRecord?.getDataID()}`,
              'tokens_reaction'
            );
            newEdge?.setLinkedRecord(newAddedRecord || null, 'node');
            edges?.push(newEdge);
            reactionsCollection?.setLinkedRecords(edges, 'edges');
            affectedPost?.setLinkedRecord(
              reactionsCollection || null,
              'tokens_reactionsCollection'
            );
            store.getRoot().setLinkedRecord(affectedPost || null, tokenNodeId);
          },
        }).then(result => result);
      }
    },
    [
      tokenId,
      addingReaction,
      removingReaction,
      reactions,
      wallet,
      removeReaction,
      tokenNodeId,
      addReaction,
    ]
  );

  return (
    <div className={clsx(styles.root, className, styles[type])}>
      {!hideChips &&
        uniqueReactions
          ?.slice(0, max)
          .sort((a, b) => b.count - a.count)
          .map((r, i) => {
            return (
              <ReactionItem
                type={type}
                key={r.reaction + i}
                handleReaction={handleReaction}
                loading={addingReaction || removingReaction}
                count={r.count}
                reactedBy={r.reactedBy}
                reactedByMe={r.reactedByMe || false}
                reaction={r.reaction}
              />
            );
          })}
      {showPicker &&
        (user.isLoggedIn ? (
          <Button
            tooltip="React"
            icon
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              if (!tokenId) return;
            }}
            tooltipSide="top"
          >
            <Menu
              side="top"
              disabled={addingReaction || removingReaction}
              removePadding
              element={close => (
                <div>
                  <EmojiPickerComponent
                    theme={Theme.DARK}
                    onEmojiClick={(emojiObject, e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (addingReaction) return;
                      if (!tokenId) return;
                      const emoji = emojiObject.emoji;
                      handleReaction(emoji);
                      close?.();
                    }}
                    lazyLoadEmojis
                    suggestedEmojisMode={SuggestionMode.FREQUENT}
                    height={400}
                    width={300}
                    previewConfig={{
                      showPreview: false,
                    }}
                    categories={emojiCategories}
                    searchDisabled={false}
                    autoFocusSearch={false}
                    emojiVersion={'12.1'}
                    skinTonesDisabled
                    emojiStyle={EmojiStyle.NATIVE}
                  />
                </div>
              )}
              asModal={asModal}
              arrow={false}
              className={styles.reactionPicker}
            >
              <div>
                <RemixIcon icon="heart-add-line" size={24} />
                {label}
              </div>
            </Menu>
          </Button>
        ) : (
          <Button
            tooltip={REACT_LOGIN_MESSAGE}
            icon
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              useLoginModalActions.setShowLoginModal(true, REACT_LOGIN_MESSAGE);
            }}
            tooltipSide="top"
          >
            <div>
              <RemixIcon icon="heart-add-line" size={24} />
              {label}
            </div>
          </Button>
        ))}
    </div>
  );
};

const CommentReactionChips = ({
  type,
  reactions,
  uniqueReactions,
  showReactions,
  setShowReactions,
  commentId,
  commentNodeId,
  showPicker,
  max = 5,
  asModal = true,
  className,
}: {
  type: ReactionTo;
  reactions:
    | {
        id: string;
        by: string;
        byUsername: string;
        reaction: string;
      }[]
    | undefined;
  uniqueReactions:
    | {
        count: number;
        reaction: string;
        reactedBy: string[];
        reactedByMe?: boolean;
      }[]
    | undefined;
  showReactions?: boolean;
  setShowReactions?: React.Dispatch<React.SetStateAction<boolean>>;
  commentId: string;
  commentNodeId: string;
  showPicker?: boolean;
  max?: number;
  asModal?: boolean;
  className?: string;
}) => {
  const {getAccount} = useAccount();

  const user = getAccount();
  const wallet = user?.isLoggedIn ? user.accountId : undefined;

  const [addReaction, {loading: addingReaction}] =
    useMutation<CommentReactionMutationType>(insertCommentReactionMutation);

  const [removeReaction, {loading: removingReaction}] =
    useMutation<RemoveCommentReactionMutationType>(
      removeCommentReactionMutation
    );

  const handleReaction = useCallback(
    async (reaction: string) => {
      if (!commentId) return;
      if (addingReaction || removingReaction) return;
      if (reactions?.find(x => x.reaction === reaction && x.by === wallet)) {
        removeReaction({
          variables: {
            filter: {
              comment_id: {
                eq: commentId,
              },
              account_id: {
                eq: wallet,
              },
              reaction: {
                eq: reaction,
              },
            },
          },
          updater: store => {
            const affectedMutation = store.getRootField(
              'deleteFromcomments_reactionsCollection'
            );
            const newAddedRecords =
              affectedMutation?.getLinkedRecords('records');
            const newAddedRecord = newAddedRecords?.[0];
            const affectedComment = store.get(commentNodeId);
            const reactionsCollection = affectedComment?.getLinkedRecord(
              'comments_reactionsCollection'
            );
            const edges = reactionsCollection?.getLinkedRecords('edges');
            edges?.splice(
              edges.findIndex(
                x =>
                  x?.getLinkedRecord('node')?.getDataID() ===
                  newAddedRecord.getDataID()
              ),
              1
            );
            reactionsCollection?.setLinkedRecords(edges, 'edges');
            affectedComment?.setLinkedRecord(
              reactionsCollection || null,
              'comments_reactionsCollection'
            );
            store
              .getRoot()
              .setLinkedRecord(affectedComment || null, commentNodeId);
          },
        }).then(result => result);
      } else {
        addReaction({
          variables: {
            input: [
              {
                created_at: 'now',
                account_id: wallet,
                comment_id: commentId,
                reaction: reaction,
                updated_at: 'now',
              },
            ],
          },
          updater: store => {
            const affectedMutation = store.getRootField(
              'insertIntocomments_reactionsCollection'
            );
            const newAddedRecords =
              affectedMutation?.getLinkedRecords('records');
            const newAddedRecord = newAddedRecords?.[0];
            const affectedComment = store.get(commentNodeId);
            const reactionsCollection = affectedComment?.getLinkedRecord(
              'comments_reactionsCollection'
            );
            const edges = reactionsCollection?.getLinkedRecords('edges');
            const newEdge = store.create(
              `client:comments_reaction:${newAddedRecord?.getDataID()}`,
              'comments_reaction'
            );
            newEdge?.setLinkedRecord(newAddedRecord || null, 'node');
            edges?.push(newEdge);
            reactionsCollection?.setLinkedRecords(edges, 'edges');
            affectedComment?.setLinkedRecord(
              reactionsCollection || null,
              'comments_reactionsCollection'
            );
            store
              .getRoot()
              .setLinkedRecord(affectedComment || null, commentNodeId);
          },
        }).then(result => result);
      }
    },
    [
      commentId,
      addingReaction,
      removingReaction,
      reactions,
      wallet,
      removeReaction,
      commentNodeId,
      addReaction,
    ]
  );

  return (
    <div className={clsx(styles.root, className, styles[type])}>
      {uniqueReactions
        ?.slice(0, max)
        .sort((a, b) => b.count - a.count)
        .map((r, i) => {
          return (
            <ReactionItem
              type={type}
              key={r.reaction + i}
              handleReaction={handleReaction}
              loading={addingReaction || removingReaction}
              count={r.count}
              reactedBy={r.reactedBy}
              reactedByMe={r.reactedByMe || false}
              reaction={r.reaction}
            />
          );
        })}
      {showPicker && (
        <>
          {user.isLoggedIn ? (
            <Button
              tooltip={'React'}
              icon
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                if (!commentId) return;
                setShowReactions?.(!showReactions);
              }}
              tooltipSide="top"
              tooltipHidden={showReactions}
              className={styles.picker}
            >
              <Menu
                side="top"
                open={showReactions}
                onClose={() => {
                  setShowReactions?.(false);
                }}
                disabled={addingReaction || removingReaction}
                removePadding
                element={close => (
                  <div>
                    <EmojiPickerComponent
                      theme={Theme.DARK}
                      onEmojiClick={(emojiObject, e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (addingReaction) return;
                        if (!commentId) return;
                        const emoji = emojiObject.emoji;
                        handleReaction(emoji);
                        close?.();
                      }}
                      lazyLoadEmojis
                      suggestedEmojisMode={SuggestionMode.FREQUENT}
                      height={400}
                      width={300}
                      previewConfig={{
                        showPreview: false,
                      }}
                      categories={emojiCategories}
                      searchDisabled={false}
                      autoFocusSearch={false}
                      emojiVersion={'12.1'}
                      skinTonesDisabled
                      emojiStyle={EmojiStyle.NATIVE}
                    />
                  </div>
                )}
                asModal={asModal}
                arrow={false}
                className={styles.reactionPicker}
              >
                <div>
                  <RemixIcon icon="heart-add-line" size={12} />
                </div>
              </Menu>
            </Button>
          ) : (
            <Button
              tooltip={REACT_LOGIN_MESSAGE}
              icon
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                useLoginModalActions.setShowLoginModal(
                  true,
                  REACT_LOGIN_MESSAGE
                );
              }}
              className={styles.picker}
              tooltipSide="top"
            >
              <div>
                <RemixIcon icon="heart-add-line" size={12} />
              </div>
            </Button>
          )}
        </>
      )}
    </div>
  );
};

const MessageReactionChips = ({
  type,
  reactions,
  uniqueReactions,
  showReactions,
  setShowReactions,
  showPicker,
  messageId,
  max = 5,
  asModal = true,
  className,
  addToReactionsState,
  removeFromReactionsState,
}: {
  type: ReactionTo;
  reactions:
    | {
        id: string;
        by: string;
        byUsername: string;
        reaction: string;
      }[]
    | undefined;
  uniqueReactions:
    | {
        count: number;
        reaction: string;
        reactedBy: string[];
        reactedByMe?: boolean;
      }[]
    | undefined;
  showReactions?: boolean;
  setShowReactions?: React.Dispatch<React.SetStateAction<boolean>>;
  showPicker?: boolean;
  messageId: string;
  messageNodeId: string;
  max?: number;
  asModal?: boolean;
  className?: string;
  addToReactionsState?: (reaction: string, messageId: string) => void;
  removeFromReactionsState?: (reaction: string, messageId: string) => void;
}) => {
  const {getAccount} = useAccount();

  const user = getAccount();
  const wallet = user?.isLoggedIn ? user.accountId : undefined;

  const [addReaction, {loading: addingReaction}] =
    useMutation<InsertMessageReactionMutationType>(
      insertMessageReactionMutation
    );

  const [removeReaction, {loading: removingReaction}] =
    useMutation<RemoveMessageReactionMutationType>(
      removeMessageReactionMutation
    );

  const handleReaction = useCallback(
    async (reaction: string) => {
      if (!messageId) return;
      if (addingReaction || removingReaction) return;
      if (reactions?.find(x => x.reaction === reaction && x.by === wallet)) {
        removeReaction({
          variables: {
            filter: {
              message_id: {
                eq: messageId,
              },
              account_id: {
                eq: wallet,
              },
              reaction: {
                eq: reaction,
              },
            },
          },
          updater: store => {},
        }).then(result => {
          removeFromReactionsState?.(reaction, messageId);
          return result;
        });
      } else {
        addReaction({
          variables: {
            input: [
              {
                created_at: 'now',
                account_id: wallet,
                message_id: messageId,
                reaction: reaction,
                updated_at: 'now',
              },
            ],
          },
          updater: store => {},
        })
          .then(result => {
            addToReactionsState?.(reaction, messageId);
            return result;
          })
          .catch(error => error);
      }
    },
    [
      messageId,
      addingReaction,
      removingReaction,
      reactions,
      wallet,
      removeReaction,
      addReaction,
      addToReactionsState,
      removeFromReactionsState,
    ]
  );

  return (
    <div className={clsx(styles.root, className, styles[type])}>
      {uniqueReactions
        ?.slice(0, max)
        .sort((a, b) => b.count - a.count)
        .map((r, i) => {
          return (
            <ReactionItem
              type={type}
              key={r.reaction + i}
              handleReaction={handleReaction}
              loading={addingReaction || removingReaction}
              count={r.count}
              reactedBy={r.reactedBy}
              reactedByMe={r.reactedByMe || false}
              reaction={r.reaction}
            />
          );
        })}
      {showPicker && (
        <>
          {user.isLoggedIn ? (
            <Button
              tooltip={'React'}
              icon
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                if (!messageId) return;
                setShowReactions?.(!showReactions);
              }}
              className={styles.picker}
              tooltipSide="top"
              tooltipHidden={showReactions}
            >
              <Menu
                side="top"
                open={showReactions}
                onClose={() => {
                  setShowReactions?.(false);
                }}
                disabled={addingReaction || removingReaction}
                removePadding
                element={close => (
                  <div>
                    <EmojiPickerComponent
                      theme={Theme.DARK}
                      onEmojiClick={(emojiObject, e) => {
                        e.stopPropagation();
                        if (addingReaction) return;
                        if (!messageId) return;
                        const emoji = emojiObject.emoji;
                        handleReaction(emoji);
                        close?.();
                      }}
                      lazyLoadEmojis
                      suggestedEmojisMode={SuggestionMode.FREQUENT}
                      height={400}
                      width={300}
                      previewConfig={{
                        showPreview: false,
                      }}
                      categories={emojiCategories}
                      searchDisabled={false}
                      autoFocusSearch={false}
                      emojiVersion={'12.1'}
                      skinTonesDisabled
                      emojiStyle={EmojiStyle.NATIVE}
                    />
                  </div>
                )}
                asModal={asModal}
                arrow={false}
                className={styles.reactionPicker}
              >
                <div>
                  <RemixIcon icon="heart-add-line" size={12} />
                </div>
              </Menu>
            </Button>
          ) : (
            <Button
              tooltip={REACT_LOGIN_MESSAGE}
              icon
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                useLoginModalActions.setShowLoginModal(
                  true,
                  REACT_LOGIN_MESSAGE
                );
              }}
              className={styles.picker}
              tooltipSide="top"
            >
              <div>
                <RemixIcon icon="heart-add-line" size={12} />
              </div>
            </Button>
          )}
        </>
      )}
    </div>
  );
};

const ReactionItem = ({
  type,
  handleReaction,
  loading,
  count,
  reactedBy,
  reactedByMe,
  reaction,
}: {
  type: ReactionTo;
  handleReaction: (reaction: string) => Promise<void>;
  loading: boolean;
  count: number;
  reactedBy: string[];
  reactedByMe: boolean;
  reaction: string;
}) => {
  const {getAccount} = useAccount();

  const user = getAccount();

  const [active, setActive] = React.useState(() => reactedByMe);

  React.useEffect(() => {
    setActive(reactedByMe);

    return () => {
      setActive(false);
    };
  }, [reactedByMe]);

  return (
    <Button
      className={clsx(styles.chip, active && styles.active, styles[type])}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        if (!user.isLoggedIn) {
          useLoginModalActions.setShowLoginModal(true, REACT_LOGIN_MESSAGE);
          return;
        }
        handleReaction(reaction);
      }}
      disabled={loading}
      tooltip={
        <span className={styles.tooltip}>
          <span className={styles.more}>Reacted by:</span>
          {reactedBy.slice(0, 5).map(x => {
            return <span key={x}>{x}</span>;
          })}
          {reactedBy.length > 5 && (
            <span className={styles.more}>and {reactedBy.length - 5} more</span>
          )}
        </span>
      }
    >
      <span>{reaction}</span>
      <span>{count}</span>
    </Button>
  );
};
