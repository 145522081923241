import React, {PropsWithChildren, Suspense} from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import {useConvertViewToActions} from '@/utils/convertViewToActions';
import {
  ActionsMenuActions,
  ActionsView,
  useActionsMenu,
} from '@/state/hooks/actionsMenu';
import DialogContent from '../components/DialogContent';
import {useMutation} from '@/kits/relay-kit/src';
import updateShareMutation from '@/graphql/update-share';
import type {updateShareMutation as ShareMutationType} from '@/graphql/__generated__/updateShareMutation.graphql';
import InfiniteLoader from '../components/InfiniteLoader';

export default function ActionsWrapper({children}: PropsWithChildren<{}>) {
  const view = useActionsMenu().actionsView;

  const open = !!view;

  return (
    <>
      {children}
      <DialogPrimitive.Root
        open={open}
        onOpenChange={open => {
          if (!open) {
            ActionsMenuActions.closeActionsMenu();
          }
        }}
      >
        <Suspense fallback={<InfiniteLoader height={'240px'} />}>
          <DialogContentWrapper open={open} view={view} />
        </Suspense>
      </DialogPrimitive.Root>
    </>
  );
}

const DialogContentWrapper = ({
  view,
  open,
}: {
  view: ActionsView | undefined;
  open: boolean;
}) => {
  const [shareTo, {loading: sharingTo}] =
    useMutation<ShareMutationType>(updateShareMutation);

  const actions = useConvertViewToActions({
    view,
    loadingShares: sharingTo,
    share(item, type) {
      // TODO: fix share for list and blog type
      const count =
        view?.type === 'token' || view?.type === 'track'
          ? (view.shareCount || 0).toString()
          : '0';
      if (view?.type === 'token' || view?.type === 'track') {
        shareTo({
          variables: {
            input: {
              updated_at: 'now',
              share_count: (parseInt(count) + 1).toString(),
            },
            filter: {
              tezos_token_id: {
                eq: item.id,
              },
            },
          },
          updater: store => {
            const newShare = store.getRootField(
              'updatetokens_sharesCollection'
            );
            const newSharesRecords = newShare?.getLinkedRecords('records');
            const newSharesRecord = newSharesRecords?.[0];
            const newCount = newSharesRecord?.getValue('share_count');
            const affectedToken = store.get(item.nodeId);
            const affectedTokenShares = affectedToken?.getLinkedRecord(
              'tokens_sharesCollection'
            );
            const affectedEdges =
              affectedTokenShares?.getLinkedRecords('edges');
            const affectedEdge = affectedEdges?.[0];
            const affectedNode = affectedEdge?.getLinkedRecord('node');
            affectedNode?.setValue(newCount, 'share_count');
          },
        });
      }
    },
  });

  return (
    <DialogContent
      dialogClose={() => {
        ActionsMenuActions.closeActionsMenu();
      }}
      actions={actions}
      view={view}
      defaultOpen={open}
    />
  );
};
