import React, {PropsWithChildren} from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import styles from '@/view/styles/components/Tooltip.module.scss';
import clsx from 'clsx';
import KeyboardShortcut from './KeyboardShortcut';

const Tooltip = ({
  text,
  shortcut,
  separator,
  offset,
  defaultOpen,
  side,
  hidden,
  className,
  children,
  arrow = true,
  contentClassName,
}: PropsWithChildren<{
  text: string | React.ReactNode;
  shortcut?: string | React.ReactNode[] | React.ReactNode;
  separator?: string | React.ReactNode;
  offset?: number;
  defaultOpen?: boolean;
  side?: 'left' | 'right' | 'top' | 'bottom';
  hidden?: boolean;
  className?: string;
  arrow?: boolean;
  contentClassName?: string;
}>) => {
  const [open, setOpen] = React.useState(() => defaultOpen || false);

  React.useEffect(() => {
    setOpen(defaultOpen || false);
  }, [defaultOpen]);

  return (
    <TooltipPrimitive.Provider disableHoverableContent={open ? false : true}>
      <TooltipPrimitive.Root
        delayDuration={5}
        open={open}
        onOpenChange={setOpen}
      >
        <TooltipPrimitive.Trigger asChild>
          <span className={clsx(styles.trigger, className)}>{children}</span>
        </TooltipPrimitive.Trigger>
        {!hidden && (
          <TooltipPrimitive.Portal>
            <TooltipPrimitive.Content
              className={clsx(styles.content, contentClassName)}
              sideOffset={offset || 5}
              side={side}
            >
              <span>{text}</span>{' '}
              {shortcut && (
                <KeyboardShortcut text={shortcut} separator={separator} />
              )}
              {arrow && <TooltipPrimitive.Arrow className={styles.arrow} />}
            </TooltipPrimitive.Content>
          </TooltipPrimitive.Portal>
        )}
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
};

export default Tooltip;
