import {EVENT_ROLLUP_URI} from '@/config';
import {BlockchainActions} from '../../state/interfaces/blockchainActions';
import stringify from 'json-stable-stringify';
import {NotUndefined} from '@/utils/types';
import {fromString, toString} from 'uint8arrays';
import {hash} from '@stablelib/blake2b';

export type EventResponse<T = any> = {
  success: boolean;
  error: string;
  payload: T | null;
};

export enum EventType {
  ClaimUsername = 'claim-username',
  CreateAccount = 'create-account',
  AddWallet = 'add-wallet',
  RemoveWallet = 'remove-wallet',
  ChangePrimaryWallet = 'change-primary-wallet',
  Burn = 'burn',
  Comment = 'comment',
  Follow = 'follow',
  Unfollow = 'unfollow',
  ForceUnfollow = 'force-unfollow',
  Reaction = 'reaction',
  Mint = 'mint',
  Collect = 'collect',
  Buy = 'buy',
  AllowCollect = 'allow-collect',
  DisallowCollect = 'disallow-collect',
  Test = 'test',
  ProfileUpdate = 'profile-update',
  Block = 'block',
}

type TODO = {};

type EventDataMap<T extends EventType> = {
  [EventType.ClaimUsername]: ClaimUsername;
  [EventType.CreateAccount]: {};
  [EventType.Comment]: Comment;
  [EventType.Follow]: Follow;
  // [EventType.Like]: Like;
  [EventType.Reaction]: Reaction;
  [EventType.Mint]: Mint;
  [EventType.Burn]: Burn;
  [EventType.Collect]: Collect;
  [EventType.Buy]: Buy;
  [EventType.AllowCollect]: Collect;
  [EventType.DisallowCollect]: Collect;
  [EventType.Test]: {};
  [EventType.ProfileUpdate]: ProfileUpdate;
  [EventType.Unfollow]: Follow;
  [EventType.Block]: Block;
  [EventType.AddWallet]: AddWallet;
  [EventType.RemoveWallet]: RemoveWallet;
  [EventType.ChangePrimaryWallet]: ChangePrimaryWallet;
  [EventType.ForceUnfollow]: Follow;
}[T];

export type EventData<T extends EventType | undefined> = T extends undefined
  ? {}
  : EventDataMap<NotUndefined<T>>;

export enum EventSeverity {
  Critical = 'critical',
  Debug = 'debug',
  Success = 'success',
  Warning = 'warning',
}

export enum EventSource {
  UI = 'ui',
}

export type Event = {
  data: string;
  type: EventType;
  severity: EventSeverity;
  source: EventSource;
  timestamp: EpochTimeStamp;
};

export type Permit = {
  nonce: number;
  sig: string;
  pk: string;
};

export type AddWallet = {
  wallet: string;
  smartWallet: string;
  permit: Permit;
};

export type RemoveWallet = AddWallet;

export type ChangePrimaryWallet = AddWallet;

export type ClaimUsername = {
  resolver: string;
  username: string;
};

export type Block = {
  blocker: string;
  blockee: string;
  block: boolean;
};

export type Comment = {
  owner: string;
  tokenId: string;
  content: string;
  replyToCommentId: string | null;
};

export type Follow = {
  follower: string;
  followee: string;
};

export enum ReactionTo {
  Post = 'post',
  Comment = 'comment',
  Message = 'message',
}

export type Reaction = {
  owner: string;
  reactedTo: ReactionTo;
  reactedToId: string;
  type: string;
  reacted: boolean;
};

export type ProfileUpdate = {
  owner: string;
  bio: string | undefined;
  profilePicture: string | undefined;
  bannerPicture: string | undefined;
  socialContacts: SocialContacts | undefined;
};

export type SocialContacts = {
  discord: string | undefined;
  instagram: string | undefined;
  twitter: string | undefined;
  website: string | undefined;
};

export type Mint = {
  editions: number;
  metadata: string;
  allowCollects: boolean;
  allowComments: boolean;
  isNSFW: boolean;
  collectPrice: string;
  permit: Permit;
};

export type Buy = {
  account: string;
  price: string;
  saleId: number;
  permit: Permit;
};

export type Burn = {
  editions: number;
  tokenId: string;
  owner: string;
  permit: Permit;
};

type Collect = {
  tokenId: string;
  account: string;
  permit: Permit;
};

type EventMessage = {
  event: Event;
  signature: string;
  publicKey: string;
};

export const EventRollup = {
  async add(
    event: Event,
    b: BlockchainActions<'tezos'>
  ): Promise<EventResponse | undefined> {
    const signedEvent = {...event};
    signedEvent.data = toString(
      hash(fromString(signedEvent.data, 'utf8'), 32),
      'hex'
    );
    let signedMessage = await b.signPersonalMessage(
      'tezos',
      stringify(signedEvent)
    );

    if (!signedMessage) return;

    const msg: EventMessage = {
      event: event,
      signature: signedMessage.sig,
      publicKey: signedMessage.pk,
    };

    return fetch(EVENT_ROLLUP_URI + '/add-to-blockchain', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: stringify(msg),
    }).then(r => r.json());
  },
};
