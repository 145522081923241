/**
 * @generated SignedSource<<22d97f5505dfcc4753a34ce0533e5e38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type tokens_reactionsInsertInput = {
  account_id?: string | null;
  created_at?: string | null;
  reaction?: string | null;
  tezos_token_id?: string | null;
  updated_at?: string | null;
};
export type insertTokenReactionMutation$variables = {
  input: ReadonlyArray<tokens_reactionsInsertInput>;
};
export type insertTokenReactionMutation$data = {
  readonly insertIntotokens_reactionsCollection: {
    readonly records: ReadonlyArray<{
      readonly accounts: {
        readonly id: string;
        readonly nodeId: string;
      };
      readonly created_at: string;
      readonly id: string;
      readonly nodeId: string;
      readonly reaction: string;
      readonly tezos_token_id: string;
      readonly updated_at: string;
    }>;
  } | null;
};
export type insertTokenReactionMutation = {
  response: insertTokenReactionMutation$data;
  variables: insertTokenReactionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "input"
      }
    ],
    "concreteType": "tokens_reactionsInsertResponse",
    "kind": "LinkedField",
    "name": "insertIntotokens_reactionsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "tokens_reactions",
        "kind": "LinkedField",
        "name": "records",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created_at",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updated_at",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reaction",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "accounts",
            "kind": "LinkedField",
            "name": "accounts",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tezos_token_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "insertTokenReactionMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "insertTokenReactionMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "208c879ee9f288e6d5ba6ad50e304b8f",
    "id": null,
    "metadata": {},
    "name": "insertTokenReactionMutation",
    "operationKind": "mutation",
    "text": "mutation insertTokenReactionMutation(\n  $input: [tokens_reactionsInsertInput!]!\n) {\n  insertIntotokens_reactionsCollection(objects: $input) {\n    records {\n      nodeId\n      id\n      created_at\n      updated_at\n      reaction\n      accounts {\n        id\n        nodeId\n      }\n      tezos_token_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "28320f6ad34485e66149e1f30fb580ba";

export default node;
