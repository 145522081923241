/**
 * @generated SignedSource<<7e7eb0553b9d6bbe03769f88f3bfc192>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type enum_notification_type = "add_comment_reaction" | "add_reaction" | "add_token_to_playlist" | "collect_token" | "comment" | "comment_mention" | "comment_reply" | "follow" | "list_token_for_sale" | "mention" | "%future added value";
export type notificationsInsertInput = {
  comment_id?: string | null;
  created_at?: string | null;
  playlist_id?: string | null;
  price?: string | null;
  reaction?: string | null;
  read?: boolean | null;
  receiver_id?: string | null;
  sender_id?: string | null;
  tezos_token_id?: string | null;
  type?: enum_notification_type | null;
  updated_at?: string | null;
};
export type insertNotificationMutation$variables = {
  input: ReadonlyArray<notificationsInsertInput>;
};
export type insertNotificationMutation$data = {
  readonly insertIntonotificationsCollection: {
    readonly records: ReadonlyArray<{
      readonly created_at: string;
      readonly id: string;
      readonly nodeId: string;
      readonly receiver: {
        readonly id: string;
        readonly identities: {
          readonly profilesCollection: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly username: string | null;
              };
            }>;
          } | null;
        };
        readonly nodeId: string;
        readonly " $fragmentSpreads": FragmentRefs<"accountsFragment">;
      };
      readonly sender: {
        readonly id: string;
        readonly identities: {
          readonly profilesCollection: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly username: string | null;
              };
            }>;
          } | null;
        };
        readonly nodeId: string;
        readonly " $fragmentSpreads": FragmentRefs<"accountsFragment">;
      } | null;
      readonly tezos_tokens: {
        readonly id: string;
        readonly title: string;
      } | null;
      readonly type: enum_notification_type;
    }>;
  } | null;
};
export type insertNotificationMutation = {
  response: insertNotificationMutation$data;
  variables: insertNotificationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v7 = [
  (v3/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "identities",
    "kind": "LinkedField",
    "name": "identities",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "profilesConnection",
        "kind": "LinkedField",
        "name": "profilesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "profilesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "profiles",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "accountsFragment"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v9 = [
  (v3/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "public_key_hash",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "public_key",
    "storageKey": null
  }
],
v10 = [
  (v3/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "identities",
    "kind": "LinkedField",
    "name": "identities",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "profilesConnection",
        "kind": "LinkedField",
        "name": "profilesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "profilesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "profiles",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bio",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "avatar_uri",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "banner_uri",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "website",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "tezos_profilesConnection",
                    "kind": "LinkedField",
                    "name": "tezos_profilesCollection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "tezos_profilesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "tezos_profiles",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "logo",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "storageKey": null
  },
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "magic_accountsConnection",
    "kind": "LinkedField",
    "name": "magic_accountsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "magic_accountsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "magic_accounts",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "beacon_accountsConnection",
    "kind": "LinkedField",
    "name": "beacon_accountsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "beacon_accountsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "beacon_accounts",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "teztok_accountsConnection",
    "kind": "LinkedField",
    "name": "teztok_accountsCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "teztok_accountsEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "teztok_accounts",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "insertNotificationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "notificationsInsertResponse",
        "kind": "LinkedField",
        "name": "insertIntonotificationsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "notifications",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "accounts",
                "kind": "LinkedField",
                "name": "receiver",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "accounts",
                "kind": "LinkedField",
                "name": "sender",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "tezos_tokens",
                "kind": "LinkedField",
                "name": "tezos_tokens",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "insertNotificationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "notificationsInsertResponse",
        "kind": "LinkedField",
        "name": "insertIntonotificationsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "notifications",
            "kind": "LinkedField",
            "name": "records",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "accounts",
                "kind": "LinkedField",
                "name": "receiver",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "accounts",
                "kind": "LinkedField",
                "name": "sender",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "tezos_tokens",
                "kind": "LinkedField",
                "name": "tezos_tokens",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "249da499864f57632fb8362bfb704dd5",
    "id": null,
    "metadata": {},
    "name": "insertNotificationMutation",
    "operationKind": "mutation",
    "text": "mutation insertNotificationMutation(\n  $input: [notificationsInsertInput!]!\n) {\n  insertIntonotificationsCollection(objects: $input) {\n    records {\n      nodeId\n      id\n      created_at\n      type\n      receiver {\n        id\n        nodeId\n        identities {\n          profilesCollection {\n            edges {\n              node {\n                username\n                nodeId\n              }\n            }\n          }\n          nodeId\n        }\n        ...accountsFragment\n      }\n      sender {\n        id\n        nodeId\n        identities {\n          profilesCollection {\n            edges {\n              node {\n                username\n                nodeId\n              }\n            }\n          }\n          nodeId\n        }\n        ...accountsFragment\n      }\n      tezos_tokens {\n        id\n        title\n        nodeId\n      }\n    }\n  }\n}\n\nfragment accountsFragment on accounts {\n  nodeId\n  id\n  type\n  magic_accountsCollection {\n    edges {\n      node {\n        id\n        nodeId\n        public_key_hash\n        public_key\n      }\n    }\n  }\n  beacon_accountsCollection {\n    edges {\n      node {\n        id\n        nodeId\n        public_key_hash\n        public_key\n      }\n    }\n  }\n  teztok_accountsCollection {\n    edges {\n      node {\n        id\n        nodeId\n        public_key_hash\n        public_key\n      }\n    }\n  }\n  identities {\n    id\n    nodeId\n    profilesCollection {\n      edges {\n        node {\n          nodeId\n          id\n          bio\n          username\n          avatar_uri\n          banner_uri\n          name\n          website\n          tezos_profilesCollection {\n            edges {\n              node {\n                logo\n                description\n                nodeId\n              }\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "09ffc834ba69dc74cdf5ee8def4cb2ff";

export default node;
