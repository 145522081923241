import React, {Suspense} from 'react';
import styles from '@/view/styles/components/SendToken/SendToken.module.scss';
import clsx from 'clsx';
import Button from '../Button';
import Input from '../Input/Input';
import Image from '../Image';
import {trim} from '@/utils/trim';
import {getImageUrl} from '@/utils/conversions/conversions';
import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from '@/kits/relay-kit/src';
import updateTezosTokenMutation from '@/graphql/update-tezos-token';
import type {updateTezosTokenMutation as UpdateTezosTokenMutationType} from '@/graphql/__generated__/updateTezosTokenMutation.graphql';
import {useAccount} from '@/kits/account-kit/src';
import {UsernameSuggestions} from '../NewPostCard/NewPostCardDetails';
import RemixIcon from '../RemixIcon';
import {TezosToolkit} from '@taquito/taquito';
import {getBeaconWallet} from '@/kits/beacon-auth-kit/src';
import {getTezosToolkitWithMagicProvider} from '@/kits/authentication-kit/src';
import {TransferTokenToAddress} from '@/kits/tezos-nft-kit/src';
import {v4 as uuid} from 'uuid';
import {isValidTezosAddress} from '@/state/clients/tezos';
import Router from 'next/router';
import {Log} from '@/kits/logging-kit/src';
import {useOnchainActionStatusActions} from '@/state/hooks/onChainActionStatus';
import {useShoppingCart} from '@/state/hooks/shoppingCart';
import {SendTokenTokenFragment$key} from './__generated__/SendTokenTokenFragment.graphql';
import type {SendTokenOwnedCountQuery as SendTokenOwnedCountQueryType} from './__generated__/SendTokenOwnedCountQuery.graphql';

const SendTokenTokenFragment = graphql`
  fragment SendTokenTokenFragment on tezos_tokens {
    id
    nodeId
    token_id
    title
    thumbnail_uri
    editions
    editions_burned
    type
    accounts {
      id
      type
      identities {
        id
        nodeId
        profilesCollection {
          edges {
            node {
              id
              nodeId
              username
            }
          }
        }
      }
      magic_accountsCollection {
        edges {
          node {
            id
            nodeId
            public_key_hash
          }
        }
      }
      beacon_accountsCollection {
        edges {
          node {
            id
            nodeId
            public_key_hash
          }
        }
      }
      teztok_accountsCollection {
        edges {
          node {
            id
            nodeId
            public_key_hash
          }
        }
      }
    }
  }
`;

const SendTokenOwnedCountQuery = graphql`
  query SendTokenOwnedCountQuery($accountId: BigInt!, $tokenIds: [BigInt!]!) {
    eventsCollection(
      filter: {
        and: {
          type: {
            in: [
              mint_tezos_token
              pending_mint_tezos_token
              purchase_tezos_token
              pending_purchase_tezos_token
              collect_for_free
              mint_for_free
            ]
          }
          tezos_token_id: {in: $tokenIds}
          account_id: {eq: $accountId}
        }
      }
    ) {
      totalCount
    }
  }
`;

export default function SendToken({
  token: tokenKey,
  className,
  closeDialog,
  burn = false,
}: {
  token: SendTokenTokenFragment$key;
  className?: string;
  closeDialog: (initiated: boolean) => void;
  burn?: boolean;
}) {
  const {getAccount} = useAccount();
  const user = getAccount();
  const accountId = user.isLoggedIn ? user.accountId : undefined;
  const token = useFragment(SendTokenTokenFragment, tokenKey);

  const {data: ownedCount} = useLazyLoadQuery<SendTokenOwnedCountQueryType>(
    SendTokenOwnedCountQuery,
    {
      accountId: accountId || '',
      tokenIds: [token.id],
    },
    {
      skip: !accountId || accountId === '' || !token.id || token.id === '',
      onResponse(response) {
        const res = response;
        console.log('res');
      },
    }
  );

  const [sendingCount, setSendingCount] = React.useState<number>();

  const username = !!token.accounts?.identities?.profilesCollection?.edges?.[0]
    ?.node?.username
    ? token.accounts?.identities?.profilesCollection?.edges?.[0]?.node?.username
    : token.accounts?.type === 'magic'
    ? trim(
        token.accounts?.magic_accountsCollection?.edges?.[0]?.node
          ?.public_key_hash || ''
      )
    : token.accounts?.type === 'beacon'
    ? trim(
        token.accounts?.beacon_accountsCollection?.edges?.[0]?.node
          ?.public_key_hash || ''
      )
    : trim(
        token.accounts?.teztok_accountsCollection?.edges?.[0]?.node
          ?.public_key_hash || ''
      ) || token.accounts?.identities.id;

  const [updateTezosToken] = useMutation<UpdateTezosTokenMutationType>(
    updateTezosTokenMutation
  );

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [address, setAddress] = React.useState<string>('');

  const [triggerText, setTriggerText] = React.useState<string>('');

  React.useEffect(() => {
    const current = inputRef.current;
    if (!current) return;

    const handleInput = (e: Event) => {
      const target = e.target as HTMLInputElement;
      const value = target.value;
      const currentWord = value
        .slice(0, target.selectionStart || 0)
        .split(' ')
        .pop();
      const word = currentWord?.split('\n').pop();
      if (word) {
        setTriggerText(word);
      }
    };

    current.addEventListener('input', handleInput);

    return () => {
      current.removeEventListener('input', handleInput);
    };
  }, [address, setAddress]);

  const [validAddress, setValidAddress] = React.useState(false);

  React.useEffect(() => {
    const isValid = isValidTezosAddress(address);
    setValidAddress(isValid);

    return () => {
      setValidAddress(false);
    };
  }, [address]);

  const [sending, setSending] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string | null>(null);

  const showLogoutButton = error === 'Session expired, please log in again.';

  const [confirmBurn, setConfirmBurn] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!confirmBurn) return;
    const timeout = setTimeout(() => {
      setConfirmBurn(false);
    }, 5000);
    return () => clearTimeout(timeout);
  }, [confirmBurn]);

  const [tezos, setTezos] = React.useState<TezosToolkit>();

  const updateTezosToolkit = React.useCallback(async () => {
    if (!user.isLoggedIn) return;

    if (user.walletType === 'beacon') {
      const {tezosToolkit} = getBeaconWallet();
      setTezos(tezosToolkit);
    } else if (user.walletType === 'magic') {
      const t = await getTezosToolkitWithMagicProvider();
      if (!t) {
        Log(
          user.accountId,
          'SendToken',
          JSON.stringify({
            error: 'Failed to get Tezos Toolkit with Magic Provider',
          })
        );
        setError('Session expired, please log in again.');
        throw new Error('Unable to get Tezos Toolkit with Magic Provider');
      }
      setTezos(t);
    }
  }, [user]);

  React.useEffect(() => {
    updateTezosToolkit();
  }, [updateTezosToolkit]);

  return (
    <div className={clsx(styles.root, className)}>
      <div>
        <span>{burn ? 'Delete this item (burn)' : 'Transfer item'}</span>
        <span>You own {ownedCount?.eventsCollection?.totalCount || 0}</span>
      </div>
      <div className={styles.content}>
        <div>
          <div>ITEM</div>
          <div>
            <span>QUANTITY{burn ? ' TO BURN' : ''}</span>
          </div>
        </div>
        <div>
          <hr />
          <div>
            <div>
              <Image dynamic src={getImageUrl(token.thumbnail_uri)} alt="" />
              <div>
                <div>{token.title}</div>
                <span>by {username}</span>
              </div>
            </div>
            <div>
              <Input
                type="number"
                integer
                simple
                defaultValue={sendingCount}
                onChange={v => setSendingCount(parseInt(v))}
                placeholder="0"
                min={0}
                max={ownedCount?.eventsCollection?.totalCount || 0}
                onBlur={e => {
                  if (
                    Number(e.currentTarget.value) >
                    (ownedCount?.eventsCollection?.totalCount || 0)
                  ) {
                    setError('You do not own that many editions');
                  } else {
                    setError(null);
                  }
                }}
              />
            </div>
          </div>
          {!burn && (
            <div>
              {validAddress ? (
                <section>
                  <div>
                    <div>Destination Address:</div>
                    <span>{address}</span>
                  </div>
                  <Button
                    icon
                    onClick={() => {
                      setValidAddress(false);
                      setAddress('');
                    }}
                  >
                    <RemixIcon icon="pencil-line" size={20} />
                  </Button>
                </section>
              ) : (
                <div>
                  <Input
                    ref={inputRef}
                    type="text"
                    simple
                    fullWidth
                    defaultValue={address}
                    onChange={v => {
                      setValidAddress(false);
                      setAddress(v);
                    }}
                    placeholder="Enter address or username"
                  />
                </div>
              )}
              {triggerText && triggerText.length > 3 && (
                <Suspense fallback={null}>
                  <UsernameSuggestions
                    triggerText={triggerText}
                    addUsername={(username: string) => {
                      if (inputRef.current) {
                        const cursorPos = inputRef.current.selectionStart || 0;
                        const textBeforeAll = address.slice(0, cursorPos);
                        const textBefore = textBeforeAll.slice(
                          0,
                          textBeforeAll.length - triggerText.length
                        );
                        const textAfter = address.slice(cursorPos);
                        const newText = `${textBefore}${username} ${textAfter}`;
                        inputRef.current.value = newText;
                        setAddress(newText);
                        inputRef.current.focus();
                      }
                      setTriggerText('');
                    }}
                    addAddress={(address: string) => {
                      setAddress(address);
                      setValidAddress(true);
                    }}
                  />
                </Suspense>
              )}
            </div>
          )}
          {burn && <hr />}
        </div>
      </div>
      {burn && (
        <div className={styles.warning}>
          Are you sure?<span>This cannot be undone.</span>
        </div>
      )}
      <div className={styles.actions}>
        <Button
          text
          onClick={() => {
            closeDialog(false);
          }}
        >
          <span>Cancel</span>
        </Button>
        {burn ? (
          <>
            {confirmBurn ? (
              <Button
                className={styles.confirm}
                accent
                onClick={async e => {
                  e.preventDefault();
                  if (!user.isLoggedIn) return;
                  if (!token.token_id) return;
                  if (!sendingCount || sendingCount === 0) return;

                  setError(null);
                  setSending(true);

                  const transactionId = uuid();
                  useOnchainActionStatusActions.add([
                    {
                      type: 'transfer',
                      status: 'pending',
                      title: `Burning ${sendingCount} editions`,
                      ...(token?.thumbnail_uri
                        ? {thumbnail: token?.thumbnail_uri}
                        : {}),
                      retryFunction: () => {
                        setError(null);
                        setSending(true);
                        TransferToken(transactionId);
                      },
                      actionText: 'VIEW',
                      action: () => {
                        window.open(
                          `https://tzkt.io/${user.address}`,
                          '_blank',
                          'noopener noreferrer'
                        );
                      },
                      id: transactionId,
                    },
                  ]);

                  if (tezos) {
                    closeDialog(true);
                    await TransferToken(transactionId);
                  } else {
                    setError('Unable to burn item');
                    setSending(false);
                  }

                  async function TransferToken(transactionId: string) {
                    if (!user.isLoggedIn) return;
                    if (!token.token_id) return;
                    if (!tezos) {
                      setError('Session expired, please log in again.');
                      return;
                    }

                    await TransferTokenToAddress(
                      tezos,
                      '',
                      sendingCount || 0,
                      token.token_id,
                      true
                    ).then(
                      results => {
                        setSending(false);
                        useOnchainActionStatusActions.updateAction(
                          transactionId,
                          'done',
                          'Burn complete!'
                        );
                        updateTezosToken({
                          variables: {
                            filter: {
                              id: {
                                eq: token.id,
                              },
                            },
                            input: {
                              editions_burned: (
                                Number(token.editions_burned) +
                                Number(sendingCount)
                              ).toString(),
                              editions: (
                                Number(token.editions) - Number(sendingCount)
                              ).toString(),
                            },
                          },
                        });
                      },
                      e => {
                        useOnchainActionStatusActions.updateAction(
                          transactionId,
                          'error',
                          'Failed to burn item. Please try again.'
                        );
                        setError('Unable to burn item');
                        setSending(false);
                      }
                    );
                  }
                }}
                disabled={
                  sendingCount === 0 ||
                  ownedCount?.eventsCollection?.totalCount === 0 ||
                  sending ||
                  !tezos ||
                  !user.isLoggedIn ||
                  !token.token_id
                }
                data-burn="true"
              >
                <span>{sending ? 'Please wait...' : 'Yes!'}</span>
              </Button>
            ) : (
              <Button
                accent
                onClick={async e => {
                  e.preventDefault();
                  if (!user.isLoggedIn) return;
                  if (!sendingCount || sendingCount === 0) return;
                  setError(null);
                  setConfirmBurn(true);
                }}
                disabled={
                  !sendingCount ||
                  sendingCount === 0 ||
                  ownedCount?.eventsCollection?.totalCount === 0 ||
                  sendingCount >
                    (ownedCount?.eventsCollection?.totalCount || 0) ||
                  sending ||
                  !tezos ||
                  !user.isLoggedIn
                }
                data-burn="true"
              >
                <span>{'Burn'}</span>
              </Button>
            )}
          </>
        ) : (
          <Button
            accent
            onClick={async e => {
              e.preventDefault();
              if (!user.isLoggedIn) return;
              if (!sendingCount || sendingCount === 0) return;
              if (!token.token_id) return;
              setError(null);
              setSending(true);

              const transactionId = uuid();
              useOnchainActionStatusActions.add([
                {
                  type: 'transfer',
                  status: 'pending',
                  title: `Transferring ${sendingCount} editions to ${trim(
                    address
                  )}`,
                  ...(token?.thumbnail_uri
                    ? {thumbnail: token?.thumbnail_uri}
                    : {}),
                  retryFunction: () => {
                    setError(null);
                    setSending(true);
                    SendToken(transactionId);
                  },
                  actionText: 'VIEW',
                  action: () => {
                    window.open(
                      `https://tzkt.io/${address}`,
                      '_blank',
                      'noopener noreferrer'
                    );
                  },
                  id: transactionId,
                },
              ]);

              if (tezos && validAddress && address) {
                closeDialog(true);
                SendToken(transactionId);
              } else {
                setError('Unable to transfer item');
                setSending(false);
              }

              async function SendToken(transactionId: string) {
                if (!user.isLoggedIn) return;
                if (!token.token_id) return;
                if (!tezos) {
                  setError('Session expired, please log in again.');
                  return;
                }

                await TransferTokenToAddress(
                  tezos,
                  address,
                  sendingCount || 0,
                  token.token_id,
                  false
                ).then(
                  results => {
                    setSending(false);
                    useOnchainActionStatusActions.updateAction(
                      transactionId,
                      'done',
                      'Transfer complete!'
                    );
                  },
                  e => {
                    useOnchainActionStatusActions.updateAction(
                      transactionId,
                      'error',
                      'Failed to transfer item. Please try again.'
                    );
                    setError('Unable to transfer item');
                    setSending(false);
                  }
                );
              }
            }}
            disabled={
              !sendingCount ||
              sendingCount === 0 ||
              ownedCount?.eventsCollection?.totalCount === 0 ||
              sending ||
              sendingCount > (ownedCount?.eventsCollection?.totalCount || 0) ||
              !validAddress ||
              !tezos ||
              !user.isLoggedIn ||
              !token.token_id
            }
          >
            <span>{sending ? 'Please wait...' : 'Send'}</span>
          </Button>
        )}
      </div>
      {error && (
        <div className={styles.error}>
          <RemixIcon icon="error-warning-line" size={16} />
          <span>
            {error} {showLogoutButton && <TextLogoutButton />}
          </span>
        </div>
      )}
    </div>
  );
}

export const TextLogoutButton = () => {
  const {logoutAccount} = useAccount();
  const {clear} = useShoppingCart();
  return (
    <span
      onClick={() => {
        logoutAccount();
        clear();
        Router.reload();
      }}
      style={{
        cursor: 'pointer',
        textDecoration: 'underline',
        textUnderlineOffset: '4px',
        color: 'var(--textPrimary)',
      }}
    >
      logout.
    </span>
  );
};
