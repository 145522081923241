export function getImageUrl(urlOrHash: string | undefined): string {
  if (urlOrHash?.startsWith('ipfs://')) {
    return urlOrHash.replace(
      'ipfs://',
      `${process.env.NEXT_PUBLIC_IPFS_URI}/ipfs/`
    );
  }
  if (urlOrHash?.startsWith('http')) {
    if (urlOrHash.includes('ipfs.dns.xyz')) {
      return urlOrHash;
    }
    const hash = urlOrHash.split('/ipfs/')[1];
    return `${process.env.NEXT_PUBLIC_IPFS_URI}/ipfs/${hash}`;
  }
  if (urlOrHash?.startsWith('Qm')) {
    return `${process.env.NEXT_PUBLIC_IPFS_URI}/ipfs/${urlOrHash}`;
  }
  if (urlOrHash?.startsWith('file')) {
    return '';
  }
  return urlOrHash || '';
}

export function getIPFSuri(urlOrHash: string) {
  if (urlOrHash.startsWith('ipfs://')) {
    return urlOrHash;
  }
  if (urlOrHash.startsWith('Qm')) {
    return `ipfs://${urlOrHash}`;
  }
  return `ipfs://${urlOrHash.split('ipfs/')[1]}`;
}
