import React, {Suspense, useCallback} from 'react';
import styles from '@/view/styles/components/SendToken/SendTokenDialog.module.scss';
import BottomSheet from '@/view/components/BottomSheet/BottomSheet';
import SendToken from './SendToken';
import {graphql, useLazyLoadQuery} from '@/kits/relay-kit/src';
import type {SendTokenDialogLazyLoadTokenQuery as SendTokenDialogLazyLoadTokenQueryType} from './__generated__/SendTokenDialogLazyLoadTokenQuery.graphql';

export const SendTokenDialog = ({
  trigger,
  tokenId,
  defaultOpen,
  onClose,
  burn = false,
}: {
  trigger: React.ReactNode;
  tokenId?: string;
  defaultOpen?: boolean;
  onClose?: (initiated: boolean) => void;
  burn?: boolean;
}) => {
  return (
    <div>
      <BottomSheet
        defaultOpen={defaultOpen}
        dialogOnDesktop
        trigger={trigger}
        className={styles.bottomSheet}
        handleOnClose={(isPopState?: boolean) => {
          onClose?.(false);
        }}
        asModal={false}
      >
        {useCallback(
          (closeDialog: () => void) => (
            <Suspense fallback={<></>}>
              <LazyLoadSendToken
                tokenId={tokenId}
                closeDialog={closeDialog}
                onClose={onClose}
                burn={burn}
              />
            </Suspense>
          ),
          [burn, tokenId, onClose]
        )}
      </BottomSheet>
    </div>
  );
};

const SendTokenDialogLazyLoadTokenQuery = graphql`
  query SendTokenDialogLazyLoadTokenQuery($id: BigInt!) {
    tezos_tokensCollection(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          nodeId
          ...SendTokenTokenFragment
        }
      }
    }
  }
`;

const LazyLoadSendToken = ({
  tokenId,
  closeDialog,
  onClose,
  burn = false,
}: {
  tokenId?: string;
  closeDialog: () => void;
  onClose?: (initiated: boolean) => void;
  burn?: boolean;
}) => {
  const {data} = useLazyLoadQuery<SendTokenDialogLazyLoadTokenQueryType>(
    SendTokenDialogLazyLoadTokenQuery,
    {
      id: tokenId || '',
    },
    {
      skip: !tokenId || tokenId === '',
    }
  );

  const token = data?.tezos_tokensCollection?.edges?.[0]?.node;

  return (
    token && (
      <SendToken
        token={token}
        className={styles.modal}
        closeDialog={(initiated: boolean) => {
          closeDialog();
          onClose?.(initiated);
        }}
        burn={burn}
      />
    )
  );
};
