import React, {useCallback, useMemo} from 'react';
import styles from '@/view/styles/pages/create/Create.module.scss';
import NewPostCard from '@/view/components/NewPostCard/NewPostCard';
import {mintStore, ProgressType} from '../mint/mint-nfts/utils/MintStore';
import {MINTER_URI, MINTER_CONTRACT} from '@/config';
import {sleep} from '@/utils/promises';
import {buildMetadata} from '../mint/mint-nfts/utils/mint';
import {RightsEnum} from '@/view/types/mintTypes';
import {v4 as uuid} from 'uuid';
import {ToastActions} from '@/state/hooks/toasts';
import {MimeEnum} from '../mint/mint-nfts/utils/media';
import {useCreateStatus} from '@/state/hooks/createStatus';
import {trim} from '@/utils/trim';
import {processNFT} from './functions/processNFT';
import {disbleBodyScroll, enableBodyScroll} from '@/utils/scrollLock';
import {useAccount} from '@/kits/account-kit/src';
import {graphql, useLazyLoadQuery, useMutation} from '@/kits/relay-kit/src';
import insertTezosTokenMutation from '@/graphql/insert-tezos-token';
import type {insertTezosTokenMutation as TezosTokenMutationType} from '@/graphql/__generated__/insertTezosTokenMutation.graphql';
import insertEventMutation from '@/graphql/insert-event';
import type {insertEventMutation as EventMutationType} from '@/graphql/__generated__/insertEventMutation.graphql';
import updateShareMutation from '@/graphql/update-share';
import type {updateShareMutation as ShareMutationType} from '@/graphql/__generated__/updateShareMutation.graphql';
import {useCreateNotificationsForMentions} from '@/view/components/utils/useCreateNotificationsForMentions';
import insertPlaylistTokenMutation from '@/graphql/insert-playlist-token';
import type {insertPlaylistTokenMutation as InsertPlaylistTokenMutationType} from '@/graphql/__generated__/insertPlaylistTokenMutation.graphql';
import Router from 'next/router';
import {ItemDataType} from '@/view/types/types';
import type {createProfileQuery as createProfileQueryType} from './__generated__/createProfileQuery.graphql';
import type {createPlaylistsQuery as createPlaylistsQueryType} from './__generated__/createPlaylistsQuery.graphql';
import type {createCollabPlaylistsQuery as createCollabPlaylistsQueryType} from './__generated__/createCollabPlaylistsQuery.graphql';

const createProfileQuery = graphql`
  query createProfileQuery($id: BigInt!) {
    identitiesCollection(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          nodeId
          profilesCollection {
            edges {
              node {
                id
                nodeId
                username
                avatar_uri
              }
            }
          }
          ...NewPostCardProfileFragment
        }
      }
    }
  }
`;

const createPlaylistsQuery = graphql`
  query createPlaylistsQuery($identityIds: [BigInt!]) {
    playlistsCollection(filter: {identity_id: {in: $identityIds}}) {
      edges {
        node {
          id
          nodeId
        }
      }
    }
  }
`;

const createCollabPlaylistsQuery = graphql`
  query createCollabPlaylistsQuery($accountIds: [BigInt!]) {
    playlists_accountsCollection(filter: {account_id: {in: $accountIds}}) {
      edges {
        node {
          id
          nodeId
        }
      }
    }
  }
`;

export default function Create({
  onClose,
}: {
  onClose?: (isPopState: boolean) => void;
}) {
  const {getAccount} = useAccount();

  const user = getAccount();
  const identityId = user?.isLoggedIn ? user.identityId : undefined;
  const accountId = user?.isLoggedIn ? user.accountId : undefined;
  const tezosWallet = user?.isLoggedIn ? user.address : '';

  const {data: userProfileData} = useLazyLoadQuery<createProfileQueryType>(
    createProfileQuery,
    {
      id: identityId || '',
    },
    {
      skip: !identityId || identityId === '',
    }
  );

  const identity = userProfileData?.identitiesCollection?.edges?.[0]?.node;

  const {showCreateModal, view} = useCreateStatus();

  const online = true; // @tyler ???

  const wsRef = React.useRef<WebSocket>();
  const [reconnectTries, setReconnectTries] = React.useState(0);

  const [toastMessage, setToastMessage] = React.useState<{
    title: string;
    message: string;
    type?: 'success' | 'failure';
  }>();

  const [uploading, setUploading] = React.useState<string>();
  const [disabled, setDisabled] = React.useState(false);

  const DEFAULT_ITEM_DATA: ItemDataType = {
    title: '',
    description: '',
    file: undefined,
    fileDuration: undefined,
    multipleFiles: undefined,
    cover: undefined,
    editions: 1,
    allowComments: true,
    allowCollecting: false,
    royalty: 10.0, // 10% default
    royaltySplits: [
      {
        user: {
          address: tezosWallet,
          avatar:
            identity?.profilesCollection?.edges?.[0]?.node?.avatar_uri || '',
          username:
            identity?.profilesCollection?.edges?.[0]?.node?.username || '',
        },
        split: 10.0, // 10% default
      },
    ],
    coCreators: [
      {
        address: tezosWallet,
        avatar:
          identity?.profilesCollection?.edges?.[0]?.node?.avatar_uri || '',
        username:
          identity?.profilesCollection?.edges?.[0]?.node?.username || '',
      },
    ],
    attributes: [],
    tags: [],
    license: RightsEnum.NONE,
    photosensitiveWarning: false,
    nsfw: false,
    price: undefined,
    price_denomination: 'xtz',
  };

  const [itemData, setItemData] = React.useState<ItemDataType>(
    () => DEFAULT_ITEM_DATA
  );

  React.useEffect(() => {
    if (
      (view === 'image' || view === 'video' || view === 'audio') &&
      !itemData.file
    ) {
      setDisabled(true);
    } else if (view === 'post' && !itemData.file && !itemData.description) {
      setDisabled(true);
    } else if (Number.isNaN(itemData.editions)) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [itemData.description, itemData.editions, itemData.file, view]);

  const {currentMint, updateMint} = mintStore();

  const mintType = useCallback(() => {
    switch (view) {
      case 'image':
        return 'Image';
      case 'video':
        return 'Video';
      case 'audio':
        return 'Music';
      case 'post':
        return 'Post';
    }
  }, [view]);

  React.useEffect(() => {
    if (showCreateModal) {
      disbleBodyScroll();
    }
    return () => {
      enableBodyScroll();
    };
  }, [showCreateModal]);

  const username = identity?.profilesCollection?.edges?.[0].node.username
    ? `@${identity?.profilesCollection?.edges?.[0].node.username}`
    : identityId
    ? `${trim(identityId)}`
    : 'User';

  const fallbackTitle = useMemo(() => {
    return `${username} - ${mintType()}`;
  }, [mintType, username]);

  React.useEffect(() => {
    if (toastMessage) {
      ToastActions.addToast(
        uuid(),
        toastMessage.title,
        toastMessage.message,
        toastMessage.type || 'failure'
      );
    }
  }, [toastMessage]);

  const resetCreateFields = () => {
    setItemData(DEFAULT_ITEM_DATA);
  };

  React.useEffect(() => {
    if (online) {
      let pingPid: number;
      let connectionDropped = true;
      const ws = new WebSocket(
        `wss:${MINTER_URI.split('//')[1]}/ws-upload/ipfs`
      );

      ws.addEventListener('close', async e => {
        clearInterval(pingPid);
        await sleep(20);

        if (!connectionDropped) {
          return;
        }

        if (reconnectTries < 1 - 1) {
          setToastMessage({
            title: 'Connection has dropped.',
            message: `Trying to reconnect...`,
          });

          await sleep(1000);

          setReconnectTries(reconnectTries + 1);
        } else {
          setToastMessage({
            title: 'Connection has dropped.',
            message: `Retry limit exceeded. Please refresh the page.`,
          });
        }
      });

      const mintId = currentMint?.id || '';

      ws.addEventListener('open', () => {
        if (reconnectTries > 0) {
          setToastMessage({
            title: 'Connection restored.',
            message: 'You are now reconnected.',
            type: 'success',
          });

          setReconnectTries(0);
        }

        ws.send(JSON.stringify({type: 'LISTEN_ID', mintId}));

        pingPid = setInterval(() => {
          ws.send(JSON.stringify({type: 'PING'}));
        }, 15 * 1000) as unknown as number;
      });

      ws.addEventListener('message', async rawMsg => {
        const msg = JSON.parse(rawMsg.data.toString());

        switch (msg.type) {
          case 'CONNECTED':
            break;
          case 'PROGRESS_UPDATE':
            {
              const {id, maxProgress, progress, completeTasks} = msg;
              const {currentMint, updateMint} = mintStore.getState();

              if (!currentMint || currentMint.metadata.formats?.length !== 0)
                return;

              currentMint.processing.maxProgress = maxProgress + 1;
              currentMint.processing.progress = progress;
              currentMint.processing.completeTasks = completeTasks;

              updateMint(currentMint);
            }
            break;
          case 'ERROR':
            break;
          case 'RESULT':
            {
              const formatsOut = [];
              const {id, formats} = msg;
              const {currentMint: mint} = mintStore.getState();

              if (!mint || mint.ipfs) return;

              const mime = mint.type as keyof typeof MimeEnum;

              mint.ipfs = true;
              mint.ready = true;

              /**
               * buildMetadata is called during the end stages of the mint
               * This function MUST be called for it to successfully mint.
               * This means the minter should prevent the user from minting if the metedata of all NFTs is incomplete.
               */
              if (formats.artifact && formats.artifact !== '') {
                formatsOut.push({
                  uri: formats.artifact,
                  mimeType: mime,
                  fileSize: mint.size,
                  fileName: 'artifact',
                  dimensions: {
                    value: mint.dimensions,
                    unit: 'px',
                  },
                });
              }

              if (formats.cover && formats.cover !== '') {
                formatsOut.push({
                  uri: formats.cover,
                  mimeType: mime,
                  fileSize: formats.coverSize,
                  fileName: 'cover',
                  dimensions: {
                    value: formats.coverDimensions,
                    unit: 'px',
                  },
                });
              }

              if (formats.thumb && formats.thumb !== '') {
                formatsOut.push({
                  uri: formats.thumb,
                  mimeType: mime,
                  fileSize: formats.thumbSize,
                  fileName: 'thumb',
                  dimensions: {
                    value: formats.thumbDimensions,
                    unit: 'px',
                  },
                });
              }

              mint.metadata = buildMetadata({
                artifactUri: formats.artifact,
                displayUri: formats.cover,
                thumbnailUri: formats.thumb,
                image: formats.cover,
                formats: formatsOut,
                creators: [tezosWallet || ''],
                minter: MINTER_CONTRACT, // OBJKTCOM factory contract
              });

              mint.processing = {
                progress: 7,
                completeTasks: [
                  ProgressType.UPLOADED_ASSET,
                  ProgressType.RESIZED_COVER,
                  ProgressType.RESIZED_THUMB,
                  ProgressType.UPLOADED_COVER,
                  ProgressType.UPLOADED_THUMB,
                  ProgressType.DONE,
                ],
                maxProgress: 7,
              };

              // Peform the contract transactions (mint/update metadata)

              updateMint(mint);
              setTimeout(() => updateMint(mint), 0);
            }
            break;
          default:
            break;
        }
      });
      // @ts-ignore
      window.ws = ws;
      wsRef.current = ws;
      return () => {
        connectionDropped = false;
        ws.close();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, reconnectTries]);

  React.useEffect(() => {
    const handlePopState = () => {
      onClose?.(true);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [onClose]);

  const [insertTezosToken] = useMutation<TezosTokenMutationType>(
    insertTezosTokenMutation
  );
  const [insertEvent] = useMutation<EventMutationType>(insertEventMutation);

  const [updateRepostCount] =
    useMutation<ShareMutationType>(updateShareMutation);

  const {createNotifications, setMentions} =
    useCreateNotificationsForMentions();

  const [addToPlaylist] = useMutation<InsertPlaylistTokenMutationType>(
    insertPlaylistTokenMutation
  );

  const {retry} = useLazyLoadQuery<createPlaylistsQueryType>(
    createPlaylistsQuery,
    {
      identityIds: [identityId || ''],
    },
    {
      skip: !identityId || identityId === '',
    }
  );

  const {retry: retryCollabPlaylists} =
    useLazyLoadQuery<createCollabPlaylistsQueryType>(
      createCollabPlaylistsQuery,
      {
        accountIds: [accountId || ''],
      },
      {
        skip: !accountId || accountId === '',
      }
    );

  const [quoteItem, setQuoteItem] = React.useState<string | null>(null);
  const [quotedItemRepostCount, setQuotedItemRepostCount] =
    React.useState<number>();

  const userAvatar =
    userProfileData?.identitiesCollection?.edges?.[0]?.node?.profilesCollection
      ?.edges?.[0]?.node?.avatar_uri;

  const [selectedCollection, setSelectedCollection] = React.useState<
    string | null
  >(null);
  const [selectedCollectionTitle, setSelectedCollectionTitle] = React.useState<
    string | null
  >(null);

  return (
    <>
      <div tabIndex={-1}>
        <div className={styles.root}>
          <NewPostCard
            identityKey={
              userProfileData?.identitiesCollection?.edges?.[0]?.node
            }
            view={view}
            itemData={itemData}
            setItemData={setItemData}
            processNFT={() => {
              if (disabled || !wsRef) {
                return;
              }
              processNFT({
                wsRef,
                fallbackTitle,
                itemData: {
                  ...itemData,
                  description: quoteItem
                    ? `${itemData.description}\nhttps://dns.xyz/item/${quoteItem}`
                    : itemData.description,
                },
                setUploading,
                resetCreateFields,
                user,
                insertPost: insertTezosToken,
                onComplete(postId) {
                  insertEvent({
                    variables: {
                      input: [
                        {
                          created_at: 'now',
                          updated_at: 'now',
                          type: 'create_tezos_token',
                          tezos_token_id: postId,
                          account_id: accountId,
                        },
                      ],
                    },
                  });
                  if (!!selectedCollection) {
                    addToPlaylist({
                      variables: {
                        input: [
                          {
                            playlist_id: selectedCollection,
                            tezos_token_id: postId,
                            created_at: 'now',
                            updated_at: 'now',
                          },
                        ],
                      },
                      updater: store => {
                        const affectedMutation = store.getRootField(
                          'insertIntoplaylists_tokensCollection'
                        );
                        const newAddedRecords =
                          affectedMutation?.getLinkedRecords('records');
                        const newAddedRecord = newAddedRecords?.[0];
                        const tokenNodeId = newAddedRecord
                          .getLinkedRecord('tezos_tokens')
                          ?.getValue('nodeId');
                        const updatedSavedCount = newAddedRecord
                          .getLinkedRecord('tezos_tokens')
                          ?.getLinkedRecord('playlists_tokensCollection')
                          ?.getValue('totalCount');
                        store
                          .get(tokenNodeId)
                          ?.getLinkedRecord('playlists_tokensCollection')
                          ?.setValue(updatedSavedCount + 1 || 0, 'totalCount');
                      },
                    }).then(res => {
                      const playlistTitle =
                        res.insertIntoplaylists_tokensCollection?.records?.[0]
                          ?.playlists.title;
                      const playlistId =
                        res.insertIntoplaylists_tokensCollection?.records?.[0]
                          ?.playlist_id;
                      ToastActions.addToast(
                        uuid(),
                        `Added to ${playlistTitle}`,
                        '',
                        'success',
                        () => {
                          Router.push(`/collection/${playlistId}`);
                        },
                        'View',
                        undefined,
                        'checkbox-circle-line'
                      );
                      retry();
                      retryCollabPlaylists();
                    });
                  }
                  if (quoteItem && quotedItemRepostCount !== undefined) {
                    updateRepostCount({
                      variables: {
                        filter: {
                          tezos_token_id: {
                            eq: postId,
                          },
                        },
                        input: {
                          repost_count: (quotedItemRepostCount + 1).toString(),
                        },
                      },
                    });
                  }
                },
                view,
                createNotifications,
                setMentions,
                userAvatar: userAvatar || undefined,
              });
            }}
            uploading={uploading}
            disabled={disabled}
            onClose={onClose}
            quoteItem={quoteItem}
            setQuoteItem={setQuoteItem}
            setQuotedItemRepostCount={setQuotedItemRepostCount}
            selectedCollection={selectedCollection || ''}
            selectedCollectionTitle={selectedCollectionTitle || ''}
            setSelectedCollection={(c, t) => {
              setSelectedCollection(c);
              setSelectedCollectionTitle(t);
            }}
          />
        </div>
      </div>
    </>
  );
}
