import React from 'react';
import styles from '@/view/styles/components/KeyboardShortcut.module.scss';
import clsx from 'clsx';

export default function KeyboardShortcut({
  text,
  separator,
  className,
}: {
  text: string | React.ReactNode[] | React.ReactNode;
  separator?: string | React.ReactNode;
  className?: string;
}) {
  return (
    <>
      {typeof text === 'string' ? (
        <div
          className={clsx(styles.split, styles.splitSeparator)}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {text.split('+').map((key, i) => (
            <React.Fragment key={`${key}-${i}`}>
              <div className={clsx(styles.key, className)}>{key}</div>
              {i !== text.split('+').length - 1 && separator && (
                <span className={styles.separator}>{separator}</span>
              )}
            </React.Fragment>
          ))}
        </div>
      ) : Array.isArray(text) && text.length > 1 ? (
        text.map((key, i) => (
          <React.Fragment key={`${key}-${i}`}>
            <div
              className={clsx(styles.key, className)}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {key}
            </div>
            {i !== text.length - 1 && separator && (
              <span className={styles.separator}>{separator}</span>
            )}
          </React.Fragment>
        ))
      ) : (
        <div
          className={clsx(styles.key, className)}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          {text}
        </div>
      )}
    </>
  );
}
