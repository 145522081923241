import clsx from 'clsx';
import React from 'react';

export default function RemixIcon({
  size,
  color,
  icon,
  opacity,
  className,
}: {
  size?: string | number;
  color?: string;
  icon: string;
  opacity?: number;
  className?: string;
}) {
  return (
    <i
      className={clsx(icon.startsWith('ri') ? icon : `ri-${icon}`, className)}
      style={{
        fontSize: size,
        minWidth: size,
        minHeight: size,
        color: color,
        opacity: opacity,
      }}
    />
  );
}
