import React, {Suspense, useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import Head from 'next/head';
import {PRIMARY_DOMAIN} from '@/config';
import {graphql, useLazyLoadQuery} from '@/kits/relay-kit/src';
import {generateMetaTags} from '@/utils/generateMetaTags';
import {ItemModalActions} from '@/state/hooks/itemModal';
import InfiniteLoader from '../components/InfiniteLoader';
import type {itemTokenPageContainerQuery as itemTokenPageContainerQueryType} from './__generated__/itemTokenPageContainerQuery.graphql';
import {TokenPageContent} from '../components/TokenPageContent/TokenPageContent';
import {enum_token_type} from '@/view/components/TokenPageContent/__generated__/TokenPageContentTokenFragment.graphql';

export default function Item({
  props,
}: {
  props: {
    id: string;
    title: string | undefined;
    description: string | null | undefined;
    type: enum_token_type | undefined;
    mimeType: string | undefined;
    display: string | undefined;
    asset: string | undefined;
    creatorUsername: string | null | undefined;
    creatorIdentityId: string | null | undefined;
  };
}) {
  return (
    <>
      <Head>
        <title key="title">
          {props?.title ? `DNS.xyz | ${props.title}` : undefined}
        </title>
        {Array.from(
          Object.entries(
            generateMetaTags({
              id: props.id,
              title: props.title,
              description: props.description || undefined,
              ogType:
                props.type === 'audio'
                  ? 'music.song'
                  : props.type === 'video'
                  ? 'video.movie'
                  : 'website',
              audio: props.type === 'audio' ? props.asset : undefined,
              video: props.type === 'video' ? props.asset : undefined,
              image:
                props.mimeType === 'image/gif'
                  ? props.asset
                  : props.display || undefined,
              mediaType: props.type,
              url: props.creatorUsername
                ? `https://${PRIMARY_DOMAIN}/${props.creatorUsername}/item/${props.id}`
                : `https://${PRIMARY_DOMAIN}/item/${props.id}`,
              creator:
                props.creatorUsername || props.creatorIdentityId || undefined,
            })
          )
        )?.map(tag => (
          <meta
            name={tag[0]}
            property={tag[0]}
            content={tag[1]}
            key={tag[0]?.replace(':', '-')}
          />
        ))}
      </Head>
      <Suspense fallback={<InfiniteLoader loaderSize={42} />}>
        <TokenPageContainer />
      </Suspense>
    </>
  );
}

const itemTokenPageContainerQuery = graphql`
  query itemTokenPageContainerQuery($id: BigInt!) {
    tezos_tokensCollection(filter: {id: {eq: $id}}, first: 1) {
      edges {
        node {
          id
          nodeId
          title
          commentsCollection {
            totalCount
            edges {
              node {
                id
                nodeId
                accounts {
                  ...CommentIsReplyToFragment
                }
                parent_comment_id
                ...CommentFragment
              }
            }
          }
          ...TokenPageContentTokenFragment
        }
      }
    }
  }
`;

export const TokenPageContainer = ({modalId}: {modalId?: string}) => {
  const router = useRouter();
  const {itemId: rawId} = router.query;

  let id: string | undefined;
  if (Array.isArray(rawId)) {
    id = rawId[0];
  } else {
    id = rawId;
  }

  const {data} = useLazyLoadQuery<itemTokenPageContainerQueryType>(
    itemTokenPageContainerQuery,
    {
      id: modalId || id || '',
    },
    {
      skip: (!id || id === '') && !modalId,
    }
  );

  const token = data?.tezos_tokensCollection?.edges?.[0]?.node;

  const [hasHistory, setHasHistory] = useState(false);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasHistory(window.history.length > 2);
    }
  }, []);

  return (
    token && (
      <>
        {modalId && (
          <Head>
            <title>
              {token.title ? `DNS.xyz | ${token.title}` : undefined}
            </title>
          </Head>
        )}
        <Suspense fallback={<></>}>
          <TokenPageContent
            modalId={modalId}
            tokenKey={token}
            comments={
              token?.commentsCollection?.edges.map(edge => ({
                id: edge.node?.id,
                account: edge.node?.accounts,
                node: edge.node,
                parent_id: !!edge.node?.parent_comment_id
                  ? edge.node?.parent_comment_id
                  : null,
              })) || []
            }
            close={() => {
              if (!hasHistory) {
                router.push('/');
                return;
              }
              router.back();
              ItemModalActions.setItem(false, null, null);
            }}
          />
        </Suspense>
      </>
    )
  );
};
