import React, {useEffect, useState} from 'react';
import {TezBalanceActions} from '@/state/hooks/useTezBalance';
import {
  InitOnRampParams,
  initOnRamp,
  type CBPayInstanceType,
} from '@coinbase/cbpay-js';

export const WalletBuyXTZDialog = ({
  address,
  amount,
}: {
  address: string;
  amount: number;
}) => {
  let currentInstance: CBPayInstanceType | null = null;
  const [statusText, setStatusText] = useState<string>(
    () => 'Click anywhere to close'
  );
  useEffect(() => {
    setStatusText('Please wait...');
    const options: InitOnRampParams = {
      appId: '1e5fbd6e-f78e-42c9-b0b2-c04a5cee1425',
      widgetParameters: {
        presetFiatAmount: amount,
        defaultExperience: 'buy',
        destinationWallets: [
          {
            address: address,
            blockchains: ['tezos'],
          },
        ],
      },
      experienceLoggedIn: 'popup',
      experienceLoggedOut: 'popup',
      closeOnExit: true,
      closeOnSuccess: true,
      onSuccess: () => {
        console.log('COINBASE: success');
        setTimeout(() => {
          TezBalanceActions.updateBalance(address);
        }, 5000);
      },
      onExit: () => {
        console.log('COINBASE: exit');
      },
      onEvent: event => {
        console.log('COINBASE: event', event);
      },
    };
    initOnRamp(options, (_, instance) => {
      if (instance) {
        currentInstance = instance;
        currentInstance.open();
        setStatusText('Click anywhere to close');
      }
    });

    return () => {
      currentInstance?.destroy();
      currentInstance = null;
      setStatusText('Click anywhere to close');
    };
  }, []);

  return <span>{statusText}</span>;
};
