import {TrackActions} from '@/state/hooks/actionsMenu';
import {ToastActions} from '@/state/hooks/toasts';
import RemixIcon from '@/view/components/RemixIcon';
import {ActionType} from '@/view/types/types';
import {useMemo} from 'react';
import {v4 as uuid} from 'uuid';
import styles from '@/view/styles/providers/Dialog.module.scss';
import WarpcastIcon from '@/view/resources/icons/Warpcast.svg';
import Image from '@/view/components/Image';

const copyLinkText = 'Copy link';

export const useTrackViewToActions = ({
  view,
  loadingShares,
  share,
}: {
  view: TrackActions | undefined;
  loadingShares?: boolean;
  share?: (
    item: {
      id: string;
      nodeId: string;
    },
    type: 'post' | 'list' | 'user' | 'blog'
  ) => void;
}) => {
  const trackActions: ActionType[][] = useMemo(() => {
    const track = view?.type === 'track' ? view : undefined;
    if (!track) return [];

    const trackUrl =
      typeof window !== 'undefined'
        ? `${window.location.origin}/item/${track?.id}`
        : '';

    return [
      [],
      [
        {
          icon: <RemixIcon icon="arrow-left-s-line" size={22} />,
          ...((view?.page || 0) === 0
            ? {setPage: 0, doNotClose: true}
            : {
                onClick: () => {},
              }),
          title: 'Back',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="file-copy-line" size={22} />,
          onClick: () => {
            navigator.clipboard.writeText(trackUrl);
            ToastActions.addToast(
              uuid(),
              'Copied to clipboard!',
              '',
              'success',
              undefined,
              undefined,
              undefined,
              'file-copy-line'
            );
            if (loadingShares) return;
            share?.(
              {
                id: track.id,
                nodeId: track.nodeId,
              },
              'post'
            );
          },
          doNotClose: true,
          title: copyLinkText,
          override: false as false,
        },
        {
          icon: <RemixIcon icon="facebook-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://www.facebook.com/sharer/sharer.php?u=${trackUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              if (loadingShares) return;
              share?.(
                {
                  id: track.id,
                  nodeId: track.nodeId,
                },
                'post'
              );
            }
          },
          title: 'Share on Facebook',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="twitter-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://twitter.com/intent/tweet?url=${trackUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              if (loadingShares) return;
              share?.(
                {
                  id: track.id,
                  nodeId: track.nodeId,
                },
                'post'
              );
            }
          },
          title: 'Share on X',
          override: false as false,
        },
        {
          icon: (
            <Image
              src={WarpcastIcon}
              alt="Warpcast"
              className={styles.customIcon}
            />
          ),
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://warpcast.com/~/compose?embeds[]=${trackUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              if (loadingShares) return;
              share?.(
                {
                  id: track.id,
                  nodeId: track.nodeId,
                },
                'post'
              );
            }
          },
          title: 'Share on Warpcast',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="whatsapp-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://wa.me/?text=${trackUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              if (loadingShares) return;
              share?.(
                {
                  id: track.id,
                  nodeId: track.nodeId,
                },
                'post'
              );
            }
          },
          title: 'Share on WhatsApp',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="telegram-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://t.me/share/url?url=${trackUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              if (loadingShares) return;
              share?.(
                {
                  id: track.id,
                  nodeId: track.nodeId,
                },
                'post'
              );
            }
          },
          title: 'Share on Telegram',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="reddit-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `https://www.reddit.com/submit?url=${trackUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              if (loadingShares) return;
              share?.(
                {
                  id: track.id,
                  nodeId: track.nodeId,
                },
                'post'
              );
            }
          },
          title: 'Share on Reddit',
          override: false as false,
        },
        {
          icon: <RemixIcon icon="mail-line" size={22} />,
          onClick: () => {
            if (typeof window !== 'undefined') {
              window.open(
                `mailto:?subject=${track.title} on DNS.news&body=${trackUrl}`,
                '_blank',
                'noopener,noreferrer'
              );
              if (loadingShares) return;
              share?.(
                {
                  id: track.id,
                  nodeId: track.nodeId,
                },
                'post'
              );
            }
          },
          title: 'Share via email',
          override: false as false,
        },
      ],
    ];
  }, [loadingShares, share, view]);

  return trackActions;
};
