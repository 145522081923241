import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation removePlaylistTokenMutation($filter: playlists_tokensFilter!) {
    deleteFromplaylists_tokensCollection(filter: $filter) {
      records {
        id
        nodeId
        playlist_id
        order
        tezos_tokens {
          playlists_tokensCollection {
            totalCount
          }
          id
          nodeId
          mime_type
          ...tezosTokensFragment
        }
      }
    }
  }
`;
