// social actions
export const REACT_LOGIN_MESSAGE = 'Please login to react';
export const FOLLOW_LOGIN_MESSAGE = (username?: string) =>
  `Login to follow ${username ? `@${username}` : ''}`;
export const COMMENT_LOGIN_MESSAGE = 'Login to comment';
export const BOOKMARK_LOGIN_MESSAGE = 'Login to bookmark this!';
export const REPOST_LOGIN_MESSAGE = 'Login to repost!';
// general actions
export const CREATE_LOGIN_MESSAGE = 'Login to create';
export const COLLECT_LOGIN_MESSAGE = 'Login to collect!';
export const CREATE_COLLECTION_LOGIN_MESSAGE = 'Login to create a collection!';
export const ADD_TO_COLLECTION_LOGIN_MESSAGE = 'Login to add to collection';
// pages
export const PROFILE_LOGIN_MESSAGE = 'Login to view your profile';
export const WALLET_LOGIN_MESSAGE = 'Login to access your wallet';
export const SETTINGS_LOGIN_MESSAGE = 'Login to edit settings';
export const INBOX_LOGIN_MESSAGE = 'Login to view your inbox';
