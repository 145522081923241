/**
 * @generated SignedSource<<a02b297fcc0a365aa4d7b95adeba8b19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ManageItemContentIsItemOwnedQuery$variables = {
  accountId: string;
  tokenId: string;
};
export type ManageItemContentIsItemOwnedQuery$data = {
  readonly collectedForFree: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly nodeId: string;
      };
    }>;
  } | null;
  readonly eventsCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly nodeId: string;
      };
    }>;
  } | null;
  readonly mintedForFree: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly nodeId: string;
      };
    }>;
  } | null;
};
export type ManageItemContentIsItemOwnedQuery = {
  response: ManageItemContentIsItemOwnedQuery$data;
  variables: ManageItemContentIsItemOwnedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tokenId"
},
v2 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "eq",
      "variableName": "accountId"
    }
  ],
  "kind": "ObjectValue",
  "name": "account_id"
},
v3 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "eq",
      "variableName": "tokenId"
    }
  ],
  "kind": "ObjectValue",
  "name": "tezos_token_id"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "eventsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "events",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "Literal",
            "name": "type",
            "value": {
              "in": [
                "mint_tezos_token",
                "pending_mint_tezos_token",
                "purchase_tezos_token",
                "pending_purchase_tezos_token",
                "collect_for_free",
                "mint_for_free"
              ]
            }
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "eventsConnection",
    "kind": "LinkedField",
    "name": "eventsCollection",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": "collectedForFree",
    "args": [
      {
        "fields": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "Literal",
            "name": "type",
            "value": {
              "eq": "collect_for_free"
            }
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "eventsConnection",
    "kind": "LinkedField",
    "name": "eventsCollection",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  },
  {
    "alias": "mintedForFree",
    "args": [
      {
        "fields": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "Literal",
            "name": "type",
            "value": {
              "eq": "mint_for_free"
            }
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "eventsConnection",
    "kind": "LinkedField",
    "name": "eventsCollection",
    "plural": false,
    "selections": (v4/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageItemContentIsItemOwnedQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ManageItemContentIsItemOwnedQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "c17d47ae1a8c8f8c84c3548418d9f1e1",
    "id": null,
    "metadata": {},
    "name": "ManageItemContentIsItemOwnedQuery",
    "operationKind": "query",
    "text": "query ManageItemContentIsItemOwnedQuery(\n  $tokenId: BigInt!\n  $accountId: BigInt!\n) {\n  eventsCollection(filter: {type: {in: [mint_tezos_token, pending_mint_tezos_token, purchase_tezos_token, pending_purchase_tezos_token, collect_for_free, mint_for_free]}, tezos_token_id: {eq: $tokenId}, account_id: {eq: $accountId}}) {\n    edges {\n      node {\n        id\n        nodeId\n      }\n    }\n  }\n  collectedForFree: eventsCollection(filter: {type: {eq: collect_for_free}, tezos_token_id: {eq: $tokenId}, account_id: {eq: $accountId}}) {\n    edges {\n      node {\n        id\n        nodeId\n      }\n    }\n  }\n  mintedForFree: eventsCollection(filter: {type: {eq: mint_for_free}, tezos_token_id: {eq: $tokenId}, account_id: {eq: $accountId}}) {\n    edges {\n      node {\n        id\n        nodeId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7a1112d50a495319a7625a4f95dbb934";

export default node;
