import React from 'react';
import Button from '../Button';
import Input from '../Input/Input';
import styles from '@/view/styles/components/NewPostCard/NewPostCardActions.module.scss';
import Switch from '../Switch';
import clsx from 'clsx';
import NewPostCardAdvanced from './blocs/NewPostCardAdvanced';
import {ItemDataType} from '@/view/types/types';

export default function NewPostCardActions({
  processNFT,
  disabled,
  itemData,
  setItemData,
}: {
  processNFT: () => void;
  disabled: boolean;
  itemData: ItemDataType;
  setItemData: React.Dispatch<React.SetStateAction<ItemDataType>>;
}) {
  const editionsRef = React.useRef<HTMLInputElement>(null);

  return (
    <div
      className={styles.root}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className={styles.actions}>
        <div className={clsx(styles.group, styles.editionsForm)}>
          <span>Number of editions</span>
          <Input
            ref={editionsRef}
            id="editions"
            defaultValue={itemData.editions}
            onChange={val => {
              const newVal = Number(val);
              setItemData({...itemData, editions: newVal});
            }}
            type="number"
            integer
            placeholder="###"
            min={1}
            onBlur={e => {
              if (e.currentTarget.value === '') {
                setItemData({...itemData, editions: 1});
                e.currentTarget.value = '1';
              }
            }}
          />
        </div>
        <NewPostCardAdvanced itemData={itemData} setItemData={setItemData} />
        <div
          className={styles.switchWrapper}
          data-enabled={itemData.allowComments}
        >
          <Switch
            checked={itemData.allowComments}
            setChecked={checked => {
              setItemData({...itemData, allowComments: checked});
            }}
            label="Enable comments"
          />
        </div>
        <div className={styles.buttonGroup}>
          <Button
            filled
            accent
            onClick={() => {
              setItemData({...itemData, allowCollecting: false});
            }}
            data-selected={!itemData.allowCollecting}
          >
            Not for sale
          </Button>
          <Button
            filled
            accent
            onClick={() => {
              setItemData({...itemData, allowCollecting: true});
            }}
            data-selected={itemData.allowCollecting}
          >
            For sale
          </Button>
        </div>
      </div>
      <div className={styles.create}>
        <Button onClick={processNFT} filled accent disabled={disabled}>
          Create
        </Button>
      </div>
    </div>
  );
}
