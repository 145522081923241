/**
 * @generated SignedSource<<23dab9af1c0079b61cf1848d131523dd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserHoverCardFollowersAndFollowingCountQuery$variables = {
  id: string;
};
export type UserHoverCardFollowersAndFollowingCountQuery$data = {
  readonly followers: {
    readonly totalCount: number;
  } | null;
  readonly following: {
    readonly totalCount: number;
  } | null;
};
export type UserHoverCardFollowersAndFollowingCountQuery = {
  response: UserHoverCardFollowersAndFollowingCountQuery$data;
  variables: UserHoverCardFollowersAndFollowingCountQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "eq",
    "variableName": "id"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": "followers",
    "args": [
      {
        "fields": [
          {
            "fields": (v1/*: any*/),
            "kind": "ObjectValue",
            "name": "followee_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "followsConnection",
    "kind": "LinkedField",
    "name": "followsCollection",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  },
  {
    "alias": "following",
    "args": [
      {
        "fields": [
          {
            "fields": (v1/*: any*/),
            "kind": "ObjectValue",
            "name": "follower_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "filter"
      }
    ],
    "concreteType": "followsConnection",
    "kind": "LinkedField",
    "name": "followsCollection",
    "plural": false,
    "selections": (v2/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserHoverCardFollowersAndFollowingCountQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserHoverCardFollowersAndFollowingCountQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "7e5edab8efde5443e050b6bcd0db17b3",
    "id": null,
    "metadata": {},
    "name": "UserHoverCardFollowersAndFollowingCountQuery",
    "operationKind": "query",
    "text": "query UserHoverCardFollowersAndFollowingCountQuery(\n  $id: BigInt!\n) {\n  followers: followsCollection(filter: {followee_id: {eq: $id}}) {\n    totalCount\n  }\n  following: followsCollection(filter: {follower_id: {eq: $id}}) {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "e9c8a925ad0848ee617ef1f097e41860";

export default node;
