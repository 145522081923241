import {insertEventMutation$data} from '@/graphql/__generated__/insertEventMutation.graphql';
import {updatePostMutation$data} from '@/graphql/__generated__/updatePostMutation.graphql';
import {ShoppingCartItem, WithShoppingCartId} from '@/state/hooks/shoppingCart';
import {MutateWithVariables} from 'relay-hooks';
import {RecordSourceSelectorProxy} from 'relay-runtime';
import type {removeSaleMutation as RemoveSaleMutationType} from '@/graphql/__generated__/removeSaleMutation.graphql';
import type {updatePostMutation as PostMutationType} from '@/graphql/__generated__/updatePostMutation.graphql';
import type {insertEventMutation as EventMutationType} from '@/graphql/__generated__/insertEventMutation.graphql';
import type {updateSaleMutation as UpdateSaleMutationType} from '@/graphql/__generated__/updateSaleMutation.graphql';
import {Log} from '@/kits/logging-kit/src';
import {useOnchainActionStatusActions} from '@/state/hooks/onChainActionStatus';

export function updatePostEditionsCount(
  store: RecordSourceSelectorProxy<updatePostMutation$data>,
  token: {
    id: string;
    nodeId: string;
    editions_minted: string | null;
  }
) {
  const updatedRecord = store.getRootField('updatetezos_tokensCollection');
  const updatedEditionsMinted = updatedRecord
    ?.getLinkedRecords('records')?.[0]
    ?.getValue('editions_minted');
  console.log('updatedEditionsMinted', updatedEditionsMinted);
  const postToUpdate = store.get(token.nodeId);
  console.log('postToUpdate', postToUpdate);
  postToUpdate?.setValue(updatedEditionsMinted, 'editions_minted');
}

export function invalidateEvents(
  store: RecordSourceSelectorProxy<insertEventMutation$data>,
  accountId: string
) {
  const eventsCollection = store.get(
    `client:root:eventsCollection(first:10,orderBy:{"created_at":"DescNullsLast"})`
  );
  eventsCollection?.invalidateRecord();
  const libraryItems = store.get(
    `client:root:get_owned_tokens_for_account(account_id:"${accountId}",first:30,orderBy:{"created_at":"DescNullsLast"})`
  );
  libraryItems?.invalidateRecord();
}

export function runOnSuccessMutations(
  pricedItems: WithShoppingCartId<ShoppingCartItem>[],
  freeItems: WithShoppingCartId<ShoppingCartItem>[],
  removeSale: MutateWithVariables<RemoveSaleMutationType>,
  user: {
    accountId: string;
    identityId: string;
    address: string;
    blockchain: string;
    email: string;
    isLoggedIn: true;
    publicKey: string;
    walletType: string;
  },
  updateSale: MutateWithVariables<UpdateSaleMutationType>,
  insertEvent: MutateWithVariables<EventMutationType>,
  transactionId: string,
  total: number,
  setBuyingInProgress: React.Dispatch<React.SetStateAction<boolean>>,
  removeItems: (itemIds: string[]) => boolean,
  updatePost: MutateWithVariables<PostMutationType>
) {
  const totalItems = pricedItems.length + freeItems.length;
  pricedItems.forEach(
    (result: WithShoppingCartId<ShoppingCartItem>, i: number) => {
      const isSecondaryPurchase = result.sale !== undefined;
      const tokenId = result.token.id;
      const token = result.token;
      const sale = result.sale;

      const skipEvents = result.createCollectible === true;

      if (skipEvents) {
        if (totalItems - 1 === i) {
          useOnchainActionStatusActions.updateAction(
            transactionId,
            'done',
            'Purchase completed!',
            `${totalItems} ${
              totalItems === 1 ? `item for` : `items totalling to`
            } ${total} XTZ purchased, you will receive your items shortly.`
          );
          setBuyingInProgress(false);
          removeItems(pricedItems.map(c => c.shoppingCartItemId));
        }
        return;
      }

      if (isSecondaryPurchase) {
        if (Number(sale?.amount) === 1) {
          removeSale({
            variables: {
              filter: {
                sale_id: {
                  eq: sale?.saleId,
                },
              },
            },
            onCompleted(response) {
              Log(
                user.accountId,
                'removeSale onCompleted',
                JSON.stringify({
                  'token.id': token.id,
                  'sale.sale_id': sale?.saleId,
                  quantity: result.quantity || 1,
                })
              );
            },
            onError(error) {
              Log(
                user.accountId,
                'removeSale onError',
                JSON.stringify({
                  'token.id': token.id,
                  'sale.sale_id': sale?.saleId,
                  quantity: result.quantity || 1,
                  error: error.message,
                })
              );
            },
          });
        } else {
          updateSale({
            variables: {
              filter: {
                sale_id: {
                  eq: sale?.saleId,
                },
              },
              input: {
                amount: (
                  Number(sale?.amount) - (result.quantity || 1)
                ).toString(),
              },
            },
            onCompleted(response) {
              Log(
                user.accountId,
                'updateSale onCompleted',
                JSON.stringify({
                  'token.id': token.id,
                  'sale.sale_id': sale?.saleId,
                  quantity: result.quantity || 1,
                })
              );
            },
            onError(error) {
              Log(
                user.accountId,
                'updateSale onError',
                JSON.stringify({
                  'token.id': token.id,
                  'sale.sale_id': sale?.saleId,
                  quantity: result.quantity || 1,
                  error: error.message,
                })
              );
            },
          });
        }
        insertEvent({
          variables: {
            input: Array.from({length: result.quantity || 1}).map(() => ({
              created_at: 'now',
              updated_at: 'now',
              type: 'purchase_tezos_token',
              tezos_token_id: token.id,
              account_id: user.accountId,
            })),
          },
          updater: store => {
            invalidateEvents(store, user.accountId);
          },
          onCompleted(response) {
            Log(
              user.accountId,
              'purchase_tezos_token',
              JSON.stringify({
                event_id: response.insertIntoeventsCollection?.records?.[0]?.id,
                quantity: result.quantity || 1,
              })
            );
            if (totalItems - 1 === i) {
              useOnchainActionStatusActions.updateAction(
                transactionId,
                'done',
                'Purchase completed!',
                `${totalItems} ${
                  totalItems === 1 ? `item for` : `items totalling to`
                } ${total} XTZ purchased, you will receive your items shortly.`
              );
              setBuyingInProgress(false);
              removeItems(pricedItems.map(c => c.shoppingCartItemId));
            }
          },
          onError(error) {
            Log(
              user.accountId,
              'purchase_tezos_token',
              JSON.stringify({
                'token.id': token.id,
                'sale.sale_id': sale?.saleId,
                quantity: result.quantity || 1,
                error: error.message,
              })
            );
          },
        });
        // SECONDARY PURCHASE END
      } else {
        // PRIMARY MATERIALIZE START
        const getInputObject = () => {
          return {
            updated_at: 'now',
            editions_minted: (
              parseInt(token.editions_minted || '0') + (result.quantity || 1)
            ).toString(),
            token_id: tokenId,
          };
        };
        updatePost({
          variables: {
            filter: {
              id: {
                eq: token.id,
              },
            },
            input: getInputObject(),
          },
          updater: store => {
            updatePostEditionsCount(store, token);
          },
          onCompleted(response) {
            Log(
              user.accountId,
              'updatePost onCompleted',
              JSON.stringify({
                tezos_token_id:
                  response.updatetezos_tokensCollection?.records?.[0]?.id,
                quantity: result.quantity || 1,
              })
            );
            insertEvent({
              variables: {
                input: Array.from({length: result.quantity || 1}).map(() => ({
                  created_at: 'now',
                  updated_at: 'now',
                  type:
                    token.is_purchasable && token.purchase_price === 0
                      ? 'collect_for_free'
                      : 'mint_tezos_token',
                  tezos_token_id: token.id,
                  account_id: user.accountId,
                })),
              },
              updater: store => {
                invalidateEvents(store, user.accountId);

                if (token.is_purchasable && token.purchase_price === 0) {
                  const affectedEvent = store
                    .getRootField('insertIntoeventsCollection')
                    ?.getLinkedRecords('records')?.[0];
                  if (affectedEvent) {
                    const collectStatusQueryString = `client:root:eventsCollection(filter:{"account_id":{"eq":"${user.accountId}"},"tezos_token_id":{"eq":"${token.id}"},"type":{"in":["collect_for_free","mint_for_free"]}})`;
                    const collectedForFreeStatus = store.get(
                      collectStatusQueryString
                    );
                    const edges =
                      collectedForFreeStatus?.getLinkedRecords('edges');
                    const newEdge = store
                      .create(
                        `${collectStatusQueryString}:edges:${
                          edges?.length || 0
                        }`,
                        'eventsEdge'
                      )
                      .setLinkedRecord(affectedEvent, 'node');
                    edges?.push(newEdge);
                    collectedForFreeStatus?.setLinkedRecords(edges, 'edges');
                  }
                }
              },
              async onCompleted(response) {
                Log(
                  user.accountId,
                  'insertEvent onCompleted',
                  JSON.stringify({
                    event_id:
                      response.insertIntoeventsCollection?.records?.[0]?.id,
                    quantity: result.quantity || 1,
                  })
                );
                if (totalItems - 1 === i) {
                  useOnchainActionStatusActions.updateAction(
                    pricedItems[0].shoppingCartItemId,
                    'done',
                    'Purchase completed!',
                    `${totalItems} ${
                      totalItems === 1 ? `item for` : `items totalling to`
                    } ${total} XTZ purchased, you will receive your items shortly.`
                  );
                  setBuyingInProgress(false);
                  removeItems(pricedItems.map(c => c.shoppingCartItemId));
                }
              },
              onError(error) {
                Log(
                  user.accountId,
                  'insertEvent onError',
                  JSON.stringify({
                    'token.id': token.id,
                    quantity: result.quantity || 1,
                    error: error.message,
                  })
                );
              },
            });
          },
          onError(error) {
            Log(
              user.accountId,
              'updatePost onError',
              JSON.stringify({
                'token.id': token.id,
                quantity: result.quantity || 1,
                error: error.message,
              })
            );
          },
        });
      }
    }
  );
  freeItems.forEach(
    (result: WithShoppingCartId<ShoppingCartItem>, i: number) => {
      // const isSecondaryPurchase = result.sale !== undefined; // TODO: do we let free items be secondary purchases?
      const tokenId = result.token.id;
      const token = freeItems[i].token;
      // const sale = freeItems[i].sale;

      const skipEvents = result.createCollectible === true;

      if (skipEvents) {
        if (totalItems - 1 === i) {
          useOnchainActionStatusActions.updateAction(
            transactionId,
            'done',
            'Purchase completed!',
            `${totalItems} ${
              totalItems === 1 ? `item for` : `items totalling to`
            } ${total} XTZ purchased, you will receive your items shortly.`
          );
          setBuyingInProgress(false);
          removeItems(freeItems.map(c => c.shoppingCartItemId));
        }
        return;
      }

      // if (isSecondaryPurchase) {
      //   if (Number(sale?.amount) === 1) {
      //     removeSale({
      //       variables: {
      //         filter: {
      //           sale_id: {
      //             eq: sale?.saleId,
      //           },
      //         },
      //       },
      //       onCompleted(response) {
      //         Log(
      //           user.accountId,
      //           'removeSale onCompleted',
      //           JSON.stringify({
      //             'token.id': token.id,
      //             'sale.sale_id': sale?.saleId,
      //           })
      //         );
      //       },
      //       onError(error) {
      //         Log(
      //           user.accountId,
      //           'removeSale onError',
      //           JSON.stringify({
      //             'token.id': token.id,
      //             'sale.sale_id': sale?.saleId,
      //             error: error.message,
      //           })
      //         );
      //       },
      //     });
      //   } else {
      //     updateSale({
      //       variables: {
      //         filter: {
      //           sale_id: {
      //             eq: sale?.saleId,
      //           },
      //         },
      //         input: {
      //           amount: (Number(sale?.amount) - 1).toString(),
      //         },
      //       },
      //       onCompleted(response) {
      //         Log(
      //           user.accountId,
      //           'updateSale onCompleted',
      //           JSON.stringify({
      //             'token.id': token.id,
      //             'sale.sale_id': sale?.saleId,
      //           })
      //         );
      //       },
      //       onError(error) {
      //         Log(
      //           user.accountId,
      //           'updateSale onError',
      //           JSON.stringify({
      //             'token.id': token.id,
      //             'sale.sale_id': sale?.saleId,
      //             error: error.message,
      //           })
      //         );
      //       },
      //     });
      //   }
      //   insertEvent({
      //     variables: {
      //       input: [
      //         {
      //           created_at: 'now',
      //           updated_at: 'now',
      //           type: 'purchase_tezos_token',
      //           tezos_token_id: token.id,
      //           account_id: user.accountId,
      //         },
      //       ],
      //     },
      //     updater: store => {
      //       invalidateEvents(store, user.accountId);
      //     },
      //     onCompleted(response) {
      //       Log(
      //         user.accountId,
      //         'purchase_tezos_token',
      //         JSON.stringify({
      //           event_id: response.insertIntoeventsCollection?.records?.[0]?.id,
      //         })
      //       );
      //       if (paidItems.length - 1 === i) {
      //         useOnchainActionStatusActions.updateAction(
      //           transactionId,
      //           'done',
      //           'Purchase completed!',
      //           `${paidItems.length} ${
      //             paidItems.length === 1 ? `item for` : `items totalling to`
      //           } ${total} XTZ purchased, you will receive your items shortly.`
      //         );
      //         setBuyingInProgress(false);
      //         removeItems(paidItems.map(c => c.shoppingCartItemId));
      //       }
      //     },
      //     onError(error) {
      //       Log(
      //         user.accountId,
      //         'purchase_tezos_token',
      //         JSON.stringify({
      //           'token.id': token.id,
      //           'sale.sale_id': sale?.saleId,
      //           error: error.message,
      //         })
      //       );
      //     },
      //   });
      //   // SECONDARY PURCHASE END
      // } else {
      // PRIMARY MATERIALIZE START
      const getInputObject = () => {
        return {
          updated_at: 'now',
          editions_minted: (
            parseInt(token.editions_minted || '0') + 1
          ).toString(), // do not consider the quantity since only one at most is collectable for free
          token_id: tokenId,
        };
      };
      updatePost({
        variables: {
          filter: {
            id: {
              eq: token.id,
            },
          },
          input: getInputObject(),
        },
        updater: store => {
          updatePostEditionsCount(store, token);
        },
        onCompleted(response) {
          Log(
            user.accountId,
            'updatePost onCompleted',
            JSON.stringify({
              tezos_token_id:
                response.updatetezos_tokensCollection?.records?.[0]?.id,
            })
          );
          insertEvent({
            variables: {
              input: [
                {
                  created_at: 'now',
                  updated_at: 'now',
                  type: 'collect_for_free',
                  tezos_token_id: token.id,
                  account_id: user.accountId,
                },
              ],
            },
            updater: store => {
              invalidateEvents(store, user.accountId);
              const affectedEvent = store
                .getRootField('insertIntoeventsCollection')
                ?.getLinkedRecords('records')?.[0];
              if (affectedEvent) {
                const collectStatusQueryString = `client:root:eventsCollection(filter:{"account_id":{"eq":"${user.accountId}"},"tezos_token_id":{"eq":"${token.id}"},"type":{"in":["collect_for_free","mint_for_free"]}})`;
                const collectedForFreeStatus = store.get(
                  collectStatusQueryString
                );
                const edges = collectedForFreeStatus?.getLinkedRecords('edges');
                const newEdge = store
                  .create(
                    `${collectStatusQueryString}:edges:${edges?.length || 0}`,
                    'eventsEdge'
                  )
                  .setLinkedRecord(affectedEvent, 'node');
                edges?.push(newEdge);
                collectedForFreeStatus?.setLinkedRecords(edges, 'edges');
              }
            },
            async onCompleted(response) {
              Log(
                user.accountId,
                'insertEvent onCompleted',
                JSON.stringify({
                  event_id:
                    response.insertIntoeventsCollection?.records?.[0]?.id,
                })
              );
              if (totalItems - 1 === i) {
                useOnchainActionStatusActions.updateAction(
                  freeItems[0].shoppingCartItemId,
                  'done',
                  'Purchase completed!',
                  `${totalItems} ${
                    totalItems === 1 ? `item for` : `items totalling to`
                  } ${total} XTZ purchased, you will receive your items shortly.`
                );
                setBuyingInProgress(false);
                removeItems(freeItems.map(c => c.shoppingCartItemId));
              }
            },
            onError(error) {
              Log(
                user.accountId,
                'insertEvent onError',
                JSON.stringify({
                  'token.id': token.id,
                  error: error.message,
                })
              );
            },
          });
        },
        onError(error) {
          Log(
            user.accountId,
            'updatePost onError',
            JSON.stringify({
              'token.id': token.id,
              error: error.message,
            })
          );
        },
      });
      // }
    }
  );
}
