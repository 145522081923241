import {graphql, useFragment, useLazyLoadQuery} from '@/kits/relay-kit/src';
import Link from 'next/link';
import React, {Suspense} from 'react';
import HoverCard from './HoverCard';
import UserHoverCard from './UserHoverCard';
import Tooltip from '../Tooltip';
import {ItemModalActions} from '@/state/hooks/itemModal';
import {FetchUsernameHoverCardCardFragment$key} from './__generated__/FetchUsernameHoverCardCardFragment.graphql';
import type {FetchUsernameHoverCardAccountByUsernameQuery as FetchUsernameHoverCardAccountByUsernameQueryType} from './__generated__/FetchUsernameHoverCardAccountByUsernameQuery.graphql';

const FetchUsernameHoverCardAccountByUsernameQuery = graphql`
  query FetchUsernameHoverCardAccountByUsernameQuery($username: String) {
    profilesCollection(filter: {username: {ilike: $username}}, first: 1) {
      edges {
        node {
          id
          nodeId
          identities {
            accountsCollection {
              edges {
                node {
                  ...FetchUsernameHoverCardCardFragment
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function FetchUsernameHoverCard({
  username,
  className,
}: {
  username: string;
  className?: string;
}) {
  const isUsernameValid = () => {
    return !!username && username !== '' && /^[a-zA-Z0-9]+$/.test(username);
  };
  const {data, error, isLoading} =
    useLazyLoadQuery<FetchUsernameHoverCardAccountByUsernameQueryType>(
      FetchUsernameHoverCardAccountByUsernameQuery,
      {
        username: username,
      },
      {
        skip: !isUsernameValid(),
      }
    );

  return isLoading || error || data?.profilesCollection?.edges?.length === 0 ? (
    <Link
      className={className}
      data-user-does-not-exist={true}
      href={`/${username}`}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      <Tooltip text="Failed to find user">@{username}</Tooltip>
    </Link>
  ) : (
    <Suspense
      fallback={
        <Link
          className={className}
          href={`/${username}`}
          onClick={e => {
            e.stopPropagation();
            ItemModalActions.setItem(false, null, null);
          }}
        >
          @{username}
        </Link>
      }
    >
      <Card
        userKey={
          data?.profilesCollection?.edges?.[0]?.node?.identities
            ?.accountsCollection?.edges?.[0]?.node
        }
        username={username}
        className={className}
      />
    </Suspense>
  );
}

const FetchUsernameHoverCardCardFragment = graphql`
  fragment FetchUsernameHoverCardCardFragment on accounts {
    ...UserHoverCardFragment
  }
`;

const Card = ({
  userKey,
  username,
  className,
}: {
  userKey: FetchUsernameHoverCardCardFragment$key | null | undefined;
  username: string;
  className?: string;
}) => {
  const user = useFragment(FetchUsernameHoverCardCardFragment, userKey);
  return (
    <Link
      className={className}
      href={`/${username}`}
      onClick={e => {
        e.stopPropagation();
        ItemModalActions.setItem(false, null, null);
      }}
    >
      <HoverCard trigger={<span>@{username}</span>}>
        <UserHoverCard user={user} />
      </HoverCard>
    </Link>
  );
};
