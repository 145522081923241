import React, {Suspense} from 'react';
import styles from '@/view/styles/components/TextClamp.module.scss';
import clsx from 'clsx';
import Link from 'next/link';
import FetchUsernameHoverCard from './HoverCard/FetchUsernameHoverCard';
import Router from 'next/router';
import {ItemModalActions} from '@/state/hooks/itemModal';

const regex =
  /(?<!\S)((?:#|@)[a-zA-Z0-9_]+|\p{Emoji}(?=\/?[^\s]+(?:\/|\?|#|$))|https?:\/\/[^\s]+(?<=\w|\/)|http?:\/\/[^\s]+(?<=\w|\/)|\n)/gu;

export default function TextClamp({
  prefix,
  text,
  maxChars,
  className,
  prefixClassName,
  hideMore,
  enableHashtags = false,
  enableMentions = false,
  enableUrls = false,
  setQuoteItem,
}: {
  prefix: React.ReactNode;
  text: string;
  maxChars: number;
  className?: string;
  prefixClassName?: string;
  hideMore?: boolean;
  enableHashtags?: boolean;
  enableMentions?: boolean;
  enableUrls?: boolean;
  setQuoteItem?: (quoteItem: string) => void;
}) {
  const [expanded, setExpanded] = React.useState(false);

  const uncappedSplits = text?.split(regex).filter(s => s.length);

  React.useEffect(() => {
    if (
      // if the URL is a DNS link, set quoteItem
      uncappedSplits.some(
        s =>
          s.startsWith('https://dns.xyz/') ||
          s.startsWith('https://www.dns.xyz/')
      )
    ) {
      let itemLinkInstances: number = 0;

      uncappedSplits.forEach(split => {
        if (split.includes('/item/')) {
          itemLinkInstances++;
          const q = split.split('/item/')[1];
          if (setQuoteItem !== undefined) {
            setQuoteItem(q);
          }
        }
      });
    }

    return () => {
      if (setQuoteItem !== undefined) {
        setQuoteItem('');
      }
    };
  }, [setQuoteItem, text, uncappedSplits]);

  React.useEffect(() => {
    if (maxChars === Infinity) {
      setExpanded(true);
    }
  }, [maxChars, expanded]);

  const hasNoWhiteSpace = () => {
    return text.split(' ').length === 1;
  };

  const renderedText = () => {
    let rendered = expanded ? text : text.slice(0, maxChars);
    // const splits = rendered.split(/((?:#|@)[a-zA-Z_]+)/).filter(s => s.length); // WITHOUT URLS
    const splits = rendered
      // .split(/(?<!\S)((?:#|@)[a-zA-Z0-9_]+|https?:\/\/[^\s]+(?<=\w|\/)|\n)/) // HTTPS only
      .split(regex)
      .filter(s => s.length); // WITH URLS

    return splits.map((split, i) => {
      if (split === '\n') {
        return <br key={split + i} />;
      } else if (split.startsWith('@') && enableMentions) {
        return (
          <Suspense key={split + i} fallback={<>loading user</>}>
            <FetchUsernameHoverCard
              key={split + i}
              className={styles.mention}
              username={uncappedSplits[i].slice(1)}
            />
          </Suspense>
        );
      } else if (split.startsWith('#') && enableHashtags) {
        return (
          <Link
            className={styles.hashtag}
            key={split + i}
            href={`/tag/${uncappedSplits[i].slice(1)}`}
            onClick={() => {
              ItemModalActions.setItem(false, null, null);
            }}
          >
            {split}
          </Link>
        );
      } else if (
        (split.startsWith('https') || split.startsWith('http')) &&
        enableUrls
      ) {
        if (
          split.startsWith('https://dns.xyz/') &&
          setQuoteItem !== undefined
        ) {
          if (split.includes('/item/')) {
            return;
          }
        }
        return (
          <a
            key={split + i}
            className={styles.url}
            href={uncappedSplits[i]}
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              if (uncappedSplits[i].startsWith('https://dns.xyz/')) {
                Router.push(uncappedSplits[i].replace('https://dns.xyz', ''));
                ItemModalActions.setItem(false, null, null);
                return;
              }
              if (uncappedSplits[i].startsWith('https://www.dns.xyz/')) {
                Router.push(
                  uncappedSplits[i].replace('https://www.dns.xyz', '')
                );
                ItemModalActions.setItem(false, null, null);
                return;
              }
              if (
                confirm(
                  `You are about to leave DNS. Continue to ${uncappedSplits[i]}?`
                )
              ) {
                window.open(uncappedSplits[i], '_blank', 'noopener noreferrer');
              } else {
                return;
              }
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {split}
          </a>
        );
      } else {
        return <span key={split + i}>{split.trimStart()}</span>;
      }
    });
  };

  return (
    <div className={clsx(styles.clamp, className)} data-vaul-no-drag="">
      {prefix && (
        <div
          className={clsx(styles.prefix, prefixClassName)}
          data-vaul-no-drag=""
        >
          {prefix}
        </div>
      )}
      <div
        className={clsx(
          styles.text,
          expanded && styles.expanded,
          hasNoWhiteSpace() && styles.noWhiteSpace,
          !prefix && styles.noPrefix
        )}
        data-show-ellipsis={!expanded && text.length > maxChars}
        data-vaul-no-drag=""
      >
        {renderedText()}
      </div>
      {text.length > maxChars && (
        <span
          className={styles.more}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            !hideMore && setExpanded(!expanded);
          }}
          data-hide-more={hideMore}
          data-vaul-no-drag=""
        >
          {!hideMore && expanded ? 'less' : 'more'}
        </span>
      )}
    </div>
  );
}
