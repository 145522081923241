import {CommentActions} from '@/state/hooks/actionsMenu';
import RemixIcon from '@/view/components/RemixIcon';
import {ActionType} from '@/view/types/types';
import {useMemo} from 'react';
import UserProfileItem from '@/view/components/UserProfileItem';
import {trim} from '../trim';
import styles from '@/view/styles/providers/Dialog.module.scss';
import {useAccount} from '@/kits/account-kit/src';
import {commentsFragment$data} from '@/graphql/__generated__/commentsFragment.graphql';

export const useCommentViewToActions = ({
  view,
}: {
  view: CommentActions | undefined;
}) => {
  const {getAccount} = useAccount();

  const user = getAccount();
  const wallet = user?.isLoggedIn ? user.accountId : undefined;

  const commentActions: ActionType[][] = useMemo(() => {
    const comment = view?.type === 'comment' ? view.comment : undefined;
    if (!comment) return [];

    function groupReactionsByUser(
      commentData: commentsFragment$data | null | undefined
    ) {
      const reactions = commentData?.comments_reactionsCollection?.edges.map(
        r => {
          return {
            reaction: r.node.reaction,
            account: r.node.accounts,
            id: r.node.id,
          };
        }
      );
      if (!reactions) return [];

      let groupedReactions: {
        account: {
          readonly id: string;
          readonly identities: {
            readonly profilesCollection: {
              readonly edges: readonly {
                readonly node: {
                  readonly username: string | null;
                };
              }[];
            } | null;
          };
          readonly nodeId: string;
          ' $fragmentSpreads': any;
        };
        reactions: {
          account: {
            readonly id: string;
            readonly identities: {
              readonly profilesCollection: {
                readonly edges: readonly {
                  readonly node: {
                    readonly username: string | null;
                  };
                }[];
              } | null;
            };
            readonly nodeId: string;
            ' $fragmentSpreads': any;
          };
          id: string;
          reaction: string;
        }[];
      }[] = [];

      reactions.forEach(r => {
        const index = groupedReactions.findIndex(
          (g: any) => g.account.id === r.account.id
        );
        if (index === -1) {
          groupedReactions.push({
            account: r.account,
            reactions: [r],
          });
        } else {
          groupedReactions[index].reactions.push(r);
        }
      });

      return groupedReactions;
    }

    return [
      [
        ...(comment.accounts?.id === wallet
          ? [
              {
                icon: <RemixIcon icon="delete-bin-7-line" size={22} />,
                onClick: () => {},
                title: 'Delete comment',
                doNotClose: true,
                override: false as false,
              },
            ]
          : [
              {
                icon: <RemixIcon icon="error-warning-line" size={22} />,
                onClick: () => {},
                title: 'Report comment',
                doNotClose: true,
                override: false as false,
              },
            ]),
        {
          icon: <RemixIcon icon="emoji-sticker-line" size={22} />,
          onClick: () => {},
          title: 'View all reactions',
          doNotClose: true,
          setPage: 1,
          override: false as false,
        },
      ],
      [
        ...(groupReactionsByUser(comment)?.length !== 0
          ? groupReactionsByUser(comment).map((r, i) => {
              return {
                key: r.reactions?.[i]?.id || i.toString(),
                icon: <UserProfileItem user={r.account} hideText />,
                title: trim(
                  r.account.identities.profilesCollection?.edges?.[0].node
                    .username || r.account.id
                ),
                doNotClose: true,
                shortcut: (
                  <div className={styles.multipleReactions}>
                    {r.reactions?.slice(0, 4).map(r => {
                      return <span key={r.id}>{r.reaction}</span>;
                    })}
                    <div>
                      {r.reactions.length > 4
                        ? `+${r.reactions.length - 4}`
                        : ''}
                    </div>
                  </div>
                ),
                className: styles.reactionItem,
                type: 'reaction',
                override: false as false,
              };
            })
          : [
              {
                icon: <RemixIcon icon="information-line" size={22} />,
                title: 'No reactions yet.',
                doNotClose: true,
                override: false as false,
              },
            ]),
      ],
    ];
  }, [view, wallet]);

  return commentActions;
};
