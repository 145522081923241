import React, {Suspense, useCallback} from 'react';
import styles from '@/view/styles/components/ManageItem/ListForSale/ListForSaleDialog.module.scss';
import BottomSheet from '@/view/components/BottomSheet/BottomSheet';
import ListForSale from './ListForSale';
import {ListForSaleDialogTokenFragment$key} from './__generated__/ListForSaleDialogTokenFragment.graphql';
import {graphql, useFragment} from '@/kits/relay-kit/src';
import {ListForSaleSaleItemFragment$key} from './__generated__/ListForSaleSaleItemFragment.graphql';

const ListForSaleDialogTokenFragment = graphql`
  fragment ListForSaleDialogTokenFragment on tezos_tokens {
    ...ListForSaleTokenFragment
  }
`;

export const ListForSaleDialog = ({
  trigger,
  tokenKey,
  mySalesList,
  refetch,
}: {
  trigger: React.ReactNode;
  tokenKey: ListForSaleDialogTokenFragment$key;
  mySalesList?: {
    id: string;
    nodeId: string;
    node: ListForSaleSaleItemFragment$key | null | undefined;
  }[];
  refetch?: () => void;
}) => {
  const token = useFragment(ListForSaleDialogTokenFragment, tokenKey);
  return (
    <div>
      <BottomSheet
        dialogOnDesktop
        trigger={trigger}
        className={styles.bottomSheet}
        handleOnClose={(isPopState?: boolean) => {}}
      >
        {useCallback(
          (closeDialog: () => void) => (
            <Suspense fallback={<></>}>
              <ListForSale
                tokenKey={token}
                className={styles.modal}
                mySalesList={mySalesList}
                closeDialog={closeDialog}
                refetch={refetch}
              />
            </Suspense>
          ),
          [token, mySalesList, refetch]
        )}
      </BottomSheet>
    </div>
  );
};
