/**
 * @generated SignedSource<<872be7b73f86731f5d50c738d7925587>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddToCollectionPlaylistsQuery$variables = {
  identityIds?: ReadonlyArray<string> | null;
  loggedIn: boolean;
  tokenId?: string | null;
};
export type AddToCollectionPlaylistsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AddToCollectionPlaylistsPaginated">;
};
export type AddToCollectionPlaylistsQuery = {
  response: AddToCollectionPlaylistsQuery$data;
  variables: AddToCollectionPlaylistsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "identityIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "loggedIn"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tokenId"
},
v3 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "in",
            "variableName": "identityIds"
          }
        ],
        "kind": "ObjectValue",
        "name": "identity_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 30
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddToCollectionPlaylistsQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "identityIds",
            "variableName": "identityIds"
          },
          {
            "kind": "Variable",
            "name": "loggedIn",
            "variableName": "loggedIn"
          },
          {
            "kind": "Variable",
            "name": "tokenId",
            "variableName": "tokenId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "AddToCollectionPlaylistsPaginated"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddToCollectionPlaylistsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "playlistsConnection",
        "kind": "LinkedField",
        "name": "playlistsCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "playlistsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "playlists",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "image_uri",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "visibility",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "playlists_tokensConnection",
                    "kind": "LinkedField",
                    "name": "playlists_tokensCollection",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "condition": "loggedIn",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": "exists",
                        "args": [
                          {
                            "fields": [
                              {
                                "fields": [
                                  {
                                    "kind": "Variable",
                                    "name": "eq",
                                    "variableName": "tokenId"
                                  }
                                ],
                                "kind": "ObjectValue",
                                "name": "tezos_token_id"
                              }
                            ],
                            "kind": "ObjectValue",
                            "name": "filter"
                          }
                        ],
                        "concreteType": "playlists_tokensConnection",
                        "kind": "LinkedField",
                        "name": "playlists_tokensCollection",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "playlists_tokensEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "playlists_tokens",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v5/*: any*/),
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ]
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "filter"
        ],
        "handle": "connection",
        "key": "AddToCollectionPlaylistsPaginated_playlistsCollection",
        "kind": "LinkedHandle",
        "name": "playlistsCollection"
      }
    ]
  },
  "params": {
    "cacheID": "1ad1d106c023e7b916a688b7674c7c22",
    "id": null,
    "metadata": {},
    "name": "AddToCollectionPlaylistsQuery",
    "operationKind": "query",
    "text": "query AddToCollectionPlaylistsQuery(\n  $identityIds: [BigInt!]\n  $tokenId: BigInt\n  $loggedIn: Boolean!\n) {\n  ...AddToCollectionPlaylistsPaginated_Xgbve\n}\n\nfragment AddToCollectionPlaylistsPaginated_Xgbve on Query {\n  playlistsCollection(filter: {identity_id: {in: $identityIds}}, first: 30) {\n    edges {\n      node {\n        nodeId\n        id\n        title\n        image_uri\n        visibility\n        playlists_tokensCollection {\n          totalCount\n        }\n        exists: playlists_tokensCollection(filter: {tezos_token_id: {eq: $tokenId}}) @include(if: $loggedIn) {\n          edges {\n            node {\n              id\n              nodeId\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "47eab19b126f299b3c7e3b7d0b70b050";

export default node;
