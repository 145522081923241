import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation insertNotificationMutation($input: [notificationsInsertInput!]!) {
    insertIntonotificationsCollection(objects: $input) {
      records {
        nodeId
        id
        created_at
        type
        receiver {
          id
          nodeId
          identities {
            profilesCollection {
              edges {
                node {
                  username
                }
              }
            }
          }
          ...accountsFragment
        }
        sender {
          id
          nodeId
          identities {
            profilesCollection {
              edges {
                node {
                  username
                }
              }
            }
          }
          ...accountsFragment
        }
        tezos_tokens {
          id
          title
        }
      }
    }
  }
`;
