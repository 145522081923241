import React from 'react';
import TextClamp from '@/view/components/TextClamp';
import styles from '@/view/styles/components/FeedCard/blocs/FeedCardDescription.module.scss';
import clsx from 'clsx';
import TokenPageLink from '../../TokenPageLink';
import {FeedCardDescriptionTokenFragment$key} from './__generated__/FeedCardDescriptionTokenFragment.graphql';
import {graphql, useFragment} from '@/kits/relay-kit/src';

const FeedCardDescriptionTokenFragment = graphql`
  fragment FeedCardDescriptionTokenFragment on tezos_tokens {
    id
    nodeId
    title
    description
    type
    accounts {
      id
      nodeId
      identities {
        profilesCollection {
          edges {
            node {
              username
            }
          }
        }
      }
    }
  }
`;

export default function FeedCardDescription({
  tokenKey,
  showDescription,
  setQuoteItem,
  hidden,
}: {
  tokenKey: FeedCardDescriptionTokenFragment$key;
  showDescription: boolean;
  setQuoteItem?: (quoteItem: string) => void;
  hidden?: boolean;
}) {
  const token = useFragment(FeedCardDescriptionTokenFragment, tokenKey);

  const isTextPost = token.type === 'post';
  const [showMore, setShowMore] = React.useState<boolean>(() => {
    return isTextPost && token.description && token.description.length > 200
      ? false
      : true;
  });
  return (
    <div className={styles.details} data-hidden={hidden}>
      {showDescription ? (
        token.description && (
          <TextClamp
            maxChars={showMore ? Infinity : 200}
            text={token.description || ''}
            prefix={null}
            className={clsx(
              styles.description,
              token.description.length <= 25 && styles.biggerText
            )}
            prefixClassName={styles.prefix}
            enableUrls
            enableMentions
            enableHashtags
            setQuoteItem={setQuoteItem}
          />
        )
      ) : (
        <TextClamp
          maxChars={100}
          text={token.title || ''}
          prefix={null}
          className={styles.title}
          enableUrls
          enableMentions
          enableHashtags
          setQuoteItem={setQuoteItem}
        />
      )}
      {isTextPost && (
        <>
          {showMore ? (
            <TokenPageLink
              tokenId={token?.id || ''}
              username={
                token?.accounts.identities.profilesCollection?.edges?.[0]?.node
                  .username || undefined
              }
              className={styles.showMore}
            />
          ) : (
            <div
              className={styles.showMore}
              onClick={e => {
                setShowMore(true);
              }}
              data-expanded={false}
            />
          )}
        </>
      )}
    </div>
  );
}
