'use client';

import React, {PropsWithChildren, Component} from 'react';
import NavProvider from '@/view/providers/Nav/Nav';
import {queryClient, QueryClientProvider} from './providers/ReactQuery';
import ToastWrapper from './providers/Toast';
import RelayProvider from './providers/RelayProvider';
import ActionsWrapper from './providers/ActionsWrapper';
import OnboardingCheck from './providers/OnboardingCheck';
import LoginModalWrapper from './providers/LoginModal';

type ProviderItem<ProviderType> = ProviderType extends React.FC<
  PropsWithChildren<infer Props>
>
  ? {Provider: ProviderType; props: Props}
  : ProviderType extends Component<PropsWithChildren<infer Props>>
  ? {
      Provider: ProviderType;
      props: Props;
    }
  : never;

type Reverse<Tuple> = Tuple extends [infer Head, ...infer Rest]
  ? [...Reverse<Rest>, Head]
  : [];

const providers: [
  ProviderItem<typeof QueryClientProvider>,
  ProviderItem<typeof RelayProvider>,
  ProviderItem<typeof ToastWrapper>,
  ProviderItem<typeof ActionsWrapper>,
  ProviderItem<typeof OnboardingCheck>,
  ProviderItem<typeof LoginModalWrapper>,
  ProviderItem<typeof NavProvider>
] = [
  {Provider: QueryClientProvider, props: {client: queryClient}},
  {Provider: RelayProvider, props: {}},
  {Provider: ToastWrapper, props: {}},
  {Provider: ActionsWrapper, props: {}},
  {Provider: OnboardingCheck, props: {}},
  {Provider: LoginModalWrapper, props: {}},
  {Provider: NavProvider, props: {}},
];

const reversedProviders: Reverse<typeof providers> = (() => {
  const tempProviders = [...providers];
  tempProviders.reverse();
  return tempProviders as Reverse<typeof providers>;
})();

export function Providers({children}: PropsWithChildren<{}>) {
  for (const {Provider, props} of reversedProviders) {
    // @ts-ignore types are verified above
    children = <Provider {...props}>{children}</Provider>;
  }
  return <>{children}</>;
}
