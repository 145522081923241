/**
 * @generated SignedSource<<b413bc8c8a47454282b766ebe012496b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ManageItemContentMySalesQuery$variables = {
  accountId?: string | null;
  tokenId: string;
};
export type ManageItemContentMySalesQuery$data = {
  readonly salesCollection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly nodeId: string;
        readonly price: number;
        readonly " $fragmentSpreads": FragmentRefs<"ListForSaleSaleItemFragment">;
      };
    }>;
  } | null;
};
export type ManageItemContentMySalesQuery = {
  response: ManageItemContentMySalesQuery$data;
  variables: ManageItemContentMySalesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tokenId"
},
v2 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "accountId"
          }
        ],
        "kind": "ObjectValue",
        "name": "account_id"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "eq",
            "variableName": "tokenId"
          }
        ],
        "kind": "ObjectValue",
        "name": "tezos_token_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ManageItemContentMySalesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "salesConnection",
        "kind": "LinkedField",
        "name": "salesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "salesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "sales",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ListForSaleSaleItemFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ManageItemContentMySalesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "salesConnection",
        "kind": "LinkedField",
        "name": "salesCollection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "salesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "sales",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sale_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created_at",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fb70438cb9d3404e1789ce98c000d47c",
    "id": null,
    "metadata": {},
    "name": "ManageItemContentMySalesQuery",
    "operationKind": "query",
    "text": "query ManageItemContentMySalesQuery(\n  $tokenId: BigInt!\n  $accountId: BigInt\n) {\n  salesCollection(filter: {tezos_token_id: {eq: $tokenId}, account_id: {eq: $accountId}}) {\n    edges {\n      node {\n        id\n        nodeId\n        price\n        ...ListForSaleSaleItemFragment\n      }\n    }\n  }\n}\n\nfragment ListForSaleSaleItemFragment on sales {\n  id\n  sale_id\n  price\n  created_at\n}\n"
  }
};
})();

(node as any).hash = "dc087a749a461735e2e1241ac0c469bd";

export default node;
