import Button from '../Button';
import clsx from 'clsx';
import styles from '@/view/styles/components/CollectDialog/Collect.module.scss';
import {useAccount} from '@/kits/account-kit/src';
import {convertFromMutez} from '@/utils/tezos';
import {graphql, useFragment, useLazyLoadQuery} from '@/kits/relay-kit/src';
import Skeleton from '../Skeleton';
import {useLoginModalActions} from '@/state/hooks/auth/loginModal';
import {COLLECT_LOGIN_MESSAGE} from '@/content';
import {useShoppingCart} from '@/state/hooks/shoppingCart';
import {SECONDARY_MARKET_BUYING, SHOPPING_CART} from '@/config';
import {ToastActions} from '@/state/hooks/toasts';
import {v4 as uuid} from 'uuid';
import TezSymbol from '../TezSymbol';
import AddedToCartPopup from '../ShoppingCart/AddedToCartPopup';
import {useEffect, useState} from 'react';
import {CollectTokenFragment$key} from '@/view/components/CollectDialog/__generated__/CollectTokenFragment.graphql';
import type {CollectGetFreeCollectStatusQuery as CollectGetFreeCollectStatusQueryType} from './__generated__/CollectGetFreeCollectStatusQuery.graphql';

const CollectTokenFragment = graphql`
  fragment CollectTokenFragment on tezos_tokens {
    id
    nodeId
    title
    editions
    editions_minted
    purchase_price
    price_denomination
    thumbnail_uri
    is_purchasable
    allow_collection
    metadata_uri
    accounts {
      id
      type
      magic_accountsCollection {
        edges {
          node {
            public_key_hash
          }
        }
      }
      beacon_accountsCollection {
        edges {
          node {
            public_key_hash
          }
        }
      }
      teztok_accountsCollection {
        edges {
          node {
            public_key_hash
          }
        }
      }
      identities {
        profilesCollection {
          edges {
            node {
              username
            }
          }
        }
      }
    }
    salesCollection {
      edges {
        node {
          sale_id
          price
          amount
        }
      }
    }
    ...AddedToCartPopupTokenFragment
  }
`;

const CollectGetFreeCollectStatusQuery = graphql`
  query CollectGetFreeCollectStatusQuery(
    $tokenId: BigInt!
    $accountId: BigInt!
  ) {
    eventsCollection(
      filter: {
        type: {in: [collect_for_free, mint_for_free]}
        tezos_token_id: {eq: $tokenId}
        account_id: {eq: $accountId}
      }
    ) {
      edges {
        node {
          id
          nodeId
        }
      }
    }
  }
`;

export const Collect = ({
  token: tokenKey,
  triggerClassName,
  priceOnly,
  showAddedToCartPopup,
}: {
  token: CollectTokenFragment$key;
  triggerClassName?: string;
  priceOnly?: boolean;
  showAddedToCartPopup?: boolean;
}) => {
  const token = useFragment(CollectTokenFragment, tokenKey);
  const {getAccount} = useAccount();
  const user = getAccount();

  const accountId = user.isLoggedIn ? user.accountId : undefined;

  const canBeCollected = token.allow_collection;

  const sale = token.salesCollection?.edges?.[0]?.node;
  const lowestSecondaryPrice = sale?.price;

  const lowestPrice = Math.min(
    (token.purchase_price || 0) / 1000000 ?? Infinity,
    lowestSecondaryPrice ?? Infinity
  );

  const [previouslyCollected, setPreviouslyCollected] = useState<boolean>();

  const {data: collectedForFree} =
    useLazyLoadQuery<CollectGetFreeCollectStatusQueryType>(
      CollectGetFreeCollectStatusQuery,
      {
        accountId: accountId || '',
        tokenId: token.id,
      },
      {
        skip:
          (token.is_purchasable === false && token.purchase_price !== 0) ||
          !user.isLoggedIn,
      }
    );

  useEffect(() => {
    if (collectedForFree?.eventsCollection?.edges?.length !== 0) {
      setPreviouslyCollected(true);
    }

    return () => {
      setPreviouslyCollected(undefined);
    };
  }, [collectedForFree]);

  const {addItem, content, editItem} = useShoppingCart();

  const [openAddedToCartPopup, setOpenAddedToCartPopup] = useState(false);

  if (
    SECONDARY_MARKET_BUYING &&
    lowestPrice !== (token.purchase_price || 0) / 1000000
  ) {
    const saleData = sale;
    return (
      <div className={clsx(triggerClassName ?? null)}>
        <Button
          className={styles.root}
          onClick={async e => {
            e.preventDefault();
            e.stopPropagation();

            if (!user.isLoggedIn) {
              useLoginModalActions.setShowLoginModal(
                true,
                COLLECT_LOGIN_MESSAGE
              );
              return;
            }

            if (token.accounts.id === user.accountId) {
              return;
            }

            if (previouslyCollected === true) {
              return;
            }

            if (
              token?.is_purchasable &&
              token?.purchase_price === 0 &&
              content.find(item => item.token.id === token.id) !== undefined
            ) {
              return;
            }

            if (SHOPPING_CART) {
              const item = content.find(item => item.token.id === token.id);
              if (item) {
                editItem(item.shoppingCartItemId, {
                  ...item,
                  quantity: item.quantity + 1,
                });
              } else {
                addItem({
                  quantity: 1,
                  token: {
                    id: token.id,
                    nodeId: token.nodeId,
                    title: token.title,
                    editions_minted: token.editions_minted,
                    purchase_price: token.purchase_price,
                    is_purchasable: token.is_purchasable,
                    editions: token.editions,
                    metadata_uri: token.metadata_uri,
                    thumbnail_uri: token.thumbnail_uri,
                    creator: {
                      address:
                        token.accounts.type === 'magic'
                          ? token.accounts.magic_accountsCollection?.edges?.[0]
                              ?.node?.public_key_hash || null
                          : token.accounts.type === 'beacon'
                          ? token.accounts.beacon_accountsCollection?.edges?.[0]
                              ?.node?.public_key_hash || null
                          : token.accounts.type === 'teztok'
                          ? token.accounts.teztok_accountsCollection?.edges?.[0]
                              ?.node?.public_key_hash || null
                          : null,
                      username:
                        token.accounts.identities.profilesCollection?.edges?.[0]
                          ?.node?.username || null,
                    },
                  },
                  type: 'nft',
                  sale: {
                    saleId: saleData?.sale_id,
                    price: saleData?.price,
                    amount: saleData?.amount,
                  },
                });
              }
              if (showAddedToCartPopup) {
                setOpenAddedToCartPopup(true);
              }
              ToastActions.addToast(
                uuid(),
                'Item added to cart',
                `${token.title} was added to your cart.`,
                'success'
              );
              return;
            }
          }}
          disabled={user.isLoggedIn && token.accounts.id === user.accountId}
        >
          <span>
            {(() => {
              switch (true) {
                case parseInt(token.editions_minted || '0') >=
                  parseInt(token.editions):
                  return `Sold Out`;
                case previouslyCollected === true:
                  return `Collected`;
                case token?.is_purchasable && (token?.purchase_price ?? 0) > 0:
                  return `${!priceOnly ? `Buy for ` : ''}${
                    token.price_denomination === 'usd' ? '$' : ''
                  }${lowestPrice}${
                    token.price_denomination === 'xtz' ? (
                      <TezSymbol sizeOverride={10} />
                    ) : (
                      ''
                    )
                  }`; // TODO: format tez
                case token?.is_purchasable:
                  return 'Get for Free';
                default:
                  return 'Not for Sale';
              }
            })()}
          </span>
        </Button>
        {showAddedToCartPopup && (
          <AddedToCartPopup
            defaultOpen={openAddedToCartPopup}
            onClose={() => setOpenAddedToCartPopup(false)}
            token={token}
          />
        )}
      </div>
    );
  }

  const buttonText = () => {
    if (parseInt(token.editions_minted || '0') >= parseInt(token.editions)) {
      return <span>Sold Out</span>;
    } else if (previouslyCollected === true) {
      return <span>Collected</span>;
    } else if (token?.is_purchasable && (token?.purchase_price ?? 0) > 0) {
      return (
        <span>
          {`${!priceOnly ? `Buy for ` : ''}${
            token.price_denomination === 'usd' ? '$' : ''
          }${convertFromMutez(token.purchase_price!)}`}
          {token.price_denomination === 'xtz' ? (
            <TezSymbol sizeOverride={10} />
          ) : (
            ''
          )}
        </span>
      );
    } else if (token?.is_purchasable) {
      return <span>Get for Free</span>;
    } else {
      return <span>Not for Sale</span>;
    }
  };
  return (
    <div className={clsx(triggerClassName ?? null)}>
      <Button
        className={styles.root}
        onClick={async e => {
          e.preventDefault();
          e.stopPropagation();

          if (!user.isLoggedIn) {
            useLoginModalActions.setShowLoginModal(true, COLLECT_LOGIN_MESSAGE);
            return;
          }

          if (token.accounts.id === user.accountId) {
            return;
          }

          if (previouslyCollected === true) {
            return;
          }

          if (
            token?.is_purchasable &&
            token?.purchase_price === 0 &&
            content.find(item => item.token.id === token.id) !== undefined
          ) {
            return;
          }

          if (SHOPPING_CART) {
            const item = content.find(item => item.token.id === token.id);
            if (item) {
              editItem(item.shoppingCartItemId, {
                ...item,
                quantity: item.quantity + 1,
              });
            } else {
              addItem({
                quantity: 1,
                token: {
                  id: token.id,
                  nodeId: token.nodeId,
                  title: token.title,
                  editions_minted: token.editions_minted,
                  purchase_price: token.purchase_price,
                  is_purchasable: token.is_purchasable,
                  editions: token.editions,
                  metadata_uri: token.metadata_uri,
                  thumbnail_uri: token.thumbnail_uri,
                  creator: {
                    address:
                      token.accounts.type === 'magic'
                        ? token.accounts.magic_accountsCollection?.edges?.[0]
                            ?.node?.public_key_hash || null
                        : token.accounts.type === 'beacon'
                        ? token.accounts.beacon_accountsCollection?.edges?.[0]
                            ?.node?.public_key_hash || null
                        : token.accounts.type === 'teztok'
                        ? token.accounts.teztok_accountsCollection?.edges?.[0]
                            ?.node?.public_key_hash || null
                        : null,
                    username:
                      token.accounts.identities.profilesCollection?.edges?.[0]
                        ?.node?.username || null,
                  },
                },
                type: 'nft',
              });
            }
            if (showAddedToCartPopup) {
              setOpenAddedToCartPopup(true);
            }
            ToastActions.addToast(
              uuid(),
              'Item added to cart',
              `${token.title} was added to your cart.`,
              'success'
            );
            return;
          }
        }}
        disabled={
          !canBeCollected ||
          token.purchase_price === null ||
          parseInt(token.editions_minted || '0') >= parseInt(token.editions) ||
          (user.isLoggedIn && token.accounts.id === user.accountId) ||
          previouslyCollected === true ||
          (token.is_purchasable &&
            token.purchase_price === 0 &&
            content.find(item => item.token.id === token.id) !== undefined)
        }
      >
        <span>{buttonText()}</span>
      </Button>
      {showAddedToCartPopup && (
        <AddedToCartPopup
          defaultOpen={openAddedToCartPopup}
          onClose={() => setOpenAddedToCartPopup(false)}
          token={token}
        />
      )}
    </div>
  );
};

export const CollectButtonSkeleton = () => {
  return (
    <Skeleton
      variant="rect"
      animation="wave"
      borderRadius={5}
      height={32}
      width={84}
    />
  );
};

export default Collect;
