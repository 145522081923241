import {graphql} from '@/kits/relay-kit/src';

export default graphql`
  mutation insertCommentReactionMutation(
    $input: [comments_reactionsInsertInput!]!
  ) {
    insertIntocomments_reactionsCollection(objects: $input) {
      records {
        nodeId
        id
        reaction
        accounts {
          id
          nodeId
          identities {
            profilesCollection {
              edges {
                node {
                  username
                  avatar_uri
                }
              }
            }
          }
          ...accountsFragment
        }
        ...commentsReactionsFragment
      }
    }
  }
`;
