/**
 * @generated SignedSource<<c0be31885577cc5c4f65bcc48e4c4eb2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FilterIs = "NOT_NULL" | "NULL" | "%future added value";
export type enum_denomination_type = "usd" | "xtz" | "%future added value";
export type enum_token_type = "audio" | "image" | "link" | "post" | "video" | "%future added value";
export type tezos_tokensFilter = {
  account_id?: BigIntFilter | null;
  allow_collection?: BooleanFilter | null;
  allow_comments?: BooleanFilter | null;
  and?: ReadonlyArray<tezos_tokensFilter> | null;
  artifact_uri?: StringFilter | null;
  artist_address?: StringFilter | null;
  contract_address?: StringFilter | null;
  created_at?: DatetimeFilter | null;
  description?: StringFilter | null;
  display_uri?: StringFilter | null;
  editions?: BigIntFilter | null;
  editions_burned?: BigIntFilter | null;
  editions_minted?: BigIntFilter | null;
  id?: BigIntFilter | null;
  is_purchasable?: BooleanFilter | null;
  metadata_uri?: StringFilter | null;
  mime_type?: StringFilter | null;
  minted_at?: DatetimeFilter | null;
  minter_address?: StringFilter | null;
  minting_platform?: StringFilter | null;
  nodeId?: IDFilter | null;
  not?: tezos_tokensFilter | null;
  or?: ReadonlyArray<tezos_tokensFilter> | null;
  price?: BigIntFilter | null;
  price_denomination?: enum_denomination_typeFilter | null;
  purchase_price?: FloatFilter | null;
  symbol?: StringFilter | null;
  thumbnail_uri?: StringFilter | null;
  title?: StringFilter | null;
  token_id?: BigIntFilter | null;
  type?: enum_token_typeFilter | null;
  updated_at?: DatetimeFilter | null;
};
export type BigIntFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type DatetimeFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  in?: ReadonlyArray<string> | null;
  is?: FilterIs | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
};
export type StringFilter = {
  eq?: string | null;
  gt?: string | null;
  gte?: string | null;
  ilike?: string | null;
  in?: ReadonlyArray<string> | null;
  iregex?: string | null;
  is?: FilterIs | null;
  like?: string | null;
  lt?: string | null;
  lte?: string | null;
  neq?: string | null;
  regex?: string | null;
  startsWith?: string | null;
};
export type BooleanFilter = {
  eq?: boolean | null;
  is?: FilterIs | null;
};
export type FloatFilter = {
  eq?: number | null;
  gt?: number | null;
  gte?: number | null;
  in?: ReadonlyArray<number> | null;
  is?: FilterIs | null;
  lt?: number | null;
  lte?: number | null;
  neq?: number | null;
};
export type enum_token_typeFilter = {
  eq?: enum_token_type | null;
  in?: ReadonlyArray<enum_token_type> | null;
  is?: FilterIs | null;
  neq?: enum_token_type | null;
};
export type enum_denomination_typeFilter = {
  eq?: enum_denomination_type | null;
  in?: ReadonlyArray<enum_denomination_type> | null;
  is?: FilterIs | null;
  neq?: enum_denomination_type | null;
};
export type IDFilter = {
  eq?: string | null;
};
export type removeTezosTokenMutation$variables = {
  filter: tezos_tokensFilter;
};
export type removeTezosTokenMutation$data = {
  readonly deleteFromtezos_tokensCollection: {
    readonly records: ReadonlyArray<{
      readonly id: string;
      readonly is_purchasable: boolean;
      readonly nodeId: string;
      readonly price_denomination: enum_denomination_type;
      readonly purchase_price: number | null;
    }>;
  };
};
export type removeTezosTokenMutation = {
  response: removeTezosTokenMutation$data;
  variables: removeTezosTokenMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filter",
        "variableName": "filter"
      }
    ],
    "concreteType": "tezos_tokensDeleteResponse",
    "kind": "LinkedField",
    "name": "deleteFromtezos_tokensCollection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "tezos_tokens",
        "kind": "LinkedField",
        "name": "records",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nodeId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "purchase_price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price_denomination",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "is_purchasable",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "removeTezosTokenMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "removeTezosTokenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "070200570301104712db7f40d4eb2ff0",
    "id": null,
    "metadata": {},
    "name": "removeTezosTokenMutation",
    "operationKind": "mutation",
    "text": "mutation removeTezosTokenMutation(\n  $filter: tezos_tokensFilter!\n) {\n  deleteFromtezos_tokensCollection(filter: $filter) {\n    records {\n      nodeId\n      id\n      purchase_price\n      price_denomination\n      is_purchasable\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1fd4a824573bfba36fd77435276c3189";

export default node;
