import React from 'react';
import {useRouter} from 'next/router';
import styles from '@/view/styles/components/BackButton.module.scss';
import Button from './Button';
import {MemoIcon} from './utils/MemoChildren';
import clsx from 'clsx';

export default function BackButton({
  className,
  onBack,
  icon,
}: {
  className?: string;
  onBack?: () => void;
  icon?: string;
}) {
  const router = useRouter();

  return (
    <Button
      icon
      onClick={onBack ? onBack : () => router.back()}
      className={clsx(styles.root, className)}
      tooltip="Go back"
      tooltipOffset={20}
    >
      <MemoIcon Component={icon || 'close-line'} size={24} />
    </Button>
  );
}
