'use client';

export type Milliseconds = number;

let updateInterval: NodeJS.Timeout;

export function updateTimeSince(
  onUpdate: (timeElapsled: Milliseconds) => any,
  startTime?: Milliseconds,
  maxAccurracy: Milliseconds = 500
) {
  const actualStartTime = startTime || Date.now();

  onUpdate(Date.now() - actualStartTime);

  updateInterval = createUpdateInterval(maxAccurracy);

  function createUpdateInterval(interval: Milliseconds) {
    clearInterval(updateInterval);
    return setInterval(() => {
      const update = Date.now() - actualStartTime;
      onUpdate(update);
      if (update > 60 * 1000) {
        updateInterval = createUpdateInterval(30 * 1000);
      }
    }, interval);
  }

  function cancel() {
    clearInterval(updateInterval);
  }

  return cancel;
}
