import React from 'react';
import styles from '@/view/styles/components/ManageItem/EditListing/EditListing.module.scss';
import clsx from 'clsx';
import Button from '../../Button';
import Input from '../../Input/Input';
import Image from '../../Image';
import {trim} from '@/utils/trim';
import {getImageUrl} from '@/utils/conversions/conversions';
import {graphql, useFragment, useMutation} from '@/kits/relay-kit/src';
import updateTezosTokenMutation from '@/graphql/update-tezos-token';
import type {updateTezosTokenMutation as UpdateTezosTokenMutationType} from '@/graphql/__generated__/updateTezosTokenMutation.graphql';
import Switch from '../../Switch';
import {ToastActions} from '@/state/hooks/toasts';
import {v4 as uuid} from 'uuid';
import {EditListingTokenFragment$key} from './__generated__/EditListingTokenFragment.graphql';

const EditListingTokenFragment = graphql`
  fragment EditListingTokenFragment on tezos_tokens {
    id
    nodeId
    title
    thumbnail_uri
    purchase_price
    is_purchasable
    allow_collection
    accounts {
      id
      nodeId
      type
      identities {
        id
        nodeId
        profilesCollection {
          edges {
            node {
              id
              nodeId
              username
            }
          }
        }
      }
      magic_accountsCollection {
        edges {
          node {
            id
            nodeId
            public_key_hash
          }
        }
      }
      beacon_accountsCollection {
        edges {
          node {
            id
            nodeId
            public_key_hash
          }
        }
      }
      teztok_accountsCollection {
        edges {
          node {
            id
            nodeId
            public_key_hash
          }
        }
      }
    }
  }
`;

export default function EditListing({
  tokenKey,
  className,
  closeDialog,
}: {
  tokenKey: EditListingTokenFragment$key;
  className?: string;
  closeDialog: () => void;
}) {
  const token = useFragment(EditListingTokenFragment, tokenKey);

  const [price, setPrice] = React.useState(
    () => (token.purchase_price || 0) / 1000000 || 0
  );

  const [isPurchasable, setIsPurchasable] = React.useState(
    () => token.is_purchasable || token.allow_collection || false
  );

  const username = !!token.accounts?.identities?.profilesCollection?.edges?.[0]
    ?.node?.username
    ? token.accounts?.identities?.profilesCollection?.edges?.[0]?.node?.username
    : token.accounts?.type === 'magic'
    ? trim(
        token.accounts?.magic_accountsCollection?.edges?.[0]?.node
          ?.public_key_hash || ''
      )
    : token.accounts?.type === 'beacon'
    ? trim(
        token.accounts?.beacon_accountsCollection?.edges?.[0]?.node
          ?.public_key_hash || ''
      )
    : trim(
        token.accounts?.teztok_accountsCollection?.edges?.[0]?.node
          ?.public_key_hash || ''
      ) || token.accounts?.identities.id;

  const [updateTezosToken] = useMutation<UpdateTezosTokenMutationType>(
    updateTezosTokenMutation
  );

  return (
    <div className={clsx(styles.root, className)}>
      <span>Edit your listing</span>
      <div className={styles.content}>
        <div>
          <div>ITEM</div>
          <div>
            <span>PRICE</span>
          </div>
        </div>
        <div>
          <hr />
          <div>
            <div>
              <Image dynamic src={getImageUrl(token.thumbnail_uri)} alt="" />
              <div>
                <div>{token.title}</div>
                <span>by {username}</span>
              </div>
            </div>
            <div>
              <Input
                type="number"
                defaultValue={undefined}
                onChange={v => setPrice(parseFloat(v))}
                placeholder={price.toString()}
                min={0}
                disabled={!isPurchasable}
              />
            </div>
          </div>
          <hr />
          <div className={styles.togglePurchasable}>
            <span data-can-be-collected={isPurchasable}>Allow collection</span>
            <Switch checked={isPurchasable} setChecked={setIsPurchasable} />
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <Button
          text
          onClick={() => {
            closeDialog();
          }}
        >
          <span>Close</span>
        </Button>
        <Button
          accent
          onClick={() => {
            updateTezosToken({
              variables: {
                filter: {
                  id: {
                    eq: token.id,
                  },
                },
                input: {
                  purchase_price: price * 1000000,
                  is_purchasable: isPurchasable || false,
                  allow_collection: isPurchasable || false,
                },
              },
            })
              .then(() => {
                ToastActions.addToast(
                  uuid(),
                  'Updated!',
                  `Your listing has been updated.`,
                  'success'
                );
                closeDialog();
              })
              .catch(e => {
                ToastActions.addToast(
                  uuid(),
                  'Error',
                  `There was an error updating your listing.`,
                  'failure'
                );
                console.error(e);
              });
          }}
        >
          <span>Save</span>
        </Button>
      </div>
    </div>
  );
}
